import { createEndPoint, fetchWithToken } from './util'
import { getCourseGuid } from '../assets/data/api'
import { getCurrentLangString } from '../i18n/initI18n'

export async function fetchLearningMap() {
  const courseGuid = await getCourseGuid()

  const currentLang = getCurrentLangString()

  let currentCustomLanguage = window.localStorage.getItem('bb_custom_language')
  currentCustomLanguage = currentCustomLanguage || currentLang

  // En iPad parece fallar por cacheo de llamada
  const iPadFix =
    '?tmstp=' + new Date().getTime() + '&lang=' + currentCustomLanguage
  const endPoint = `lms/courses/${courseGuid}/blueberry/units${iPadFix}`

  return fetchWithToken(createEndPoint(endPoint)).then((json) => {
    return json.data
  })
}
