import React, { useRef } from 'react'
import './toogle-switch.scss'

function ToggleSwitch({ initialValue, onChange, label }) {
  const toggle = useRef()
  const isChecked = toggle.current?.checked ? 'checked' : ''
  return (
    <label className="toggle-switch">
      <div className="toggle-switch__wrapper">
        <input
          ref={toggle}
          type="checkbox"
          defaultValue={initialValue}
          onChange={onChange}
        />
        <span className="toggle-switch__slider round"></span>
      </div>
      <span className={'toggle-switch__label ' + isChecked}>{label}</span>
    </label>
  )
}

export default ToggleSwitch
