/* eslint-disable */
import React from 'react'

import './loading.scss'

function Loading({ display, color }) {
  return (
    <div
      className={`loading loading--display-${
        display ? display : 'default'
      } loading--color-${color ? color : 'default'}`}
    >
      <div className="loading__balls">
        <div className="loading__balls-item"></div>
        <div className="loading__balls-item"></div>
        <div className="loading__balls-item"></div>
      </div>
    </div>
  )
}

export default Loading
