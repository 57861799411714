import React from 'react'

const PricingPanel = ({ children }) => {
  return (
    <div className="pricing-panel">
      <div className="pricing-panel__content">{children}</div>
    </div>
  )
}

export default PricingPanel
