const AccessHelper = {
  ACCESS_TYPE_CREDENTIALS: 'credentials',
  ACCESS_TYPE_BB_SSO: 'blueberry_sso',
  ACCESS_TYPE_GOOGLE_SSO: 'google_sso',
  ACCESS_TYPE_BB_MAGIC: 'blueberry_magic_link',

  setAccessInformation: (justAccessed, accessType) => {
    window.localStorage.setItem(
      'bb_access',
      JSON.stringify({ justAccessed, accessType })
    )
  },

  getAccessInformation: () => {
    const bbAccess = window.localStorage.getItem('bb_access')
    return JSON.parse(bbAccess)
  }
}

export default AccessHelper
