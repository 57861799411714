import React, { useState } from 'react'
import { ReactComponent as ShowIcon } from '../../../../assets/icons/show_password.svg'
import { ReactComponent as HideIcon } from '../../../../assets/icons/hide_password.svg'
import when from '@aqrojo/when'
import './input-with-label.scss'

function InputWithLabel({
  id,
  className = '',
  isError = '',
  label,
  inputType,
  placeholder,
  value,
  onChange,
  disabled = false
}) {
  const [visible, setVisible] = useState(false)

  const _inputType = when()
    .case(inputType !== 'password', () => inputType)
    .case(visible, () => 'text')
    .resolve('password')

  return (
    <div className={`input-with-label ${className} ${isError}`}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        title={placeholder}
        placeholder={placeholder}
        type={_inputType}
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className="input-with-label__right-icon">
        <PasswordIcon
          isPassword={inputType === 'password'}
          visible={visible}
          setVisible={setVisible}
        />
      </div>
    </div>
  )
}

function PasswordIcon({ isPassword, visible, setVisible }) {
  return !isPassword ? null : (
    <div className="password-icon" onClick={() => setVisible((v) => !v)}>
      {visible ? <ShowIcon /> : <HideIcon />}
    </div>
  )
}

export default InputWithLabel
