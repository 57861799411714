import React from 'react'

const PricingLayout = ({ children }) => {
  return (
    <div className="pricing-layout">
      <div className="pricing-layout__content">{children}</div>
      <div className="pricing-layout__footer">© Oneclick 2023</div>
    </div>
  )
}

export default PricingLayout
