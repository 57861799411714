import React from 'react'
import translate from '../../../i18n/translate'
import spaceRocket from '../../../assets/img/spaceRocket2.png'
import ModalSimple from '../ModalSimple/ModalSimple'

function ModalTeacherPractice({
  onCloseStart = () => {},
  onCloseComplete = () => {},
  startHandle = () => {},
  showModal = false,
  withCloseButton = false,
  insideLobby,
  insidePractice
}) {
  return !insideLobby && !insidePractice ? (
    <ModalSimple
      showModal={showModal}
      startHandle={startHandle}
      onCloseStart={onCloseStart}
      onCloseComplete={onCloseComplete}
      withCloseButton={withCloseButton}
      modalTopIcon={spaceRocket}
      title={translate('teacher_practice_init_title')}
      message={translate('teacher_practice_init_subtitle')}
      buttonText={translate('teacher_practice_init_button')}
    ></ModalSimple>
  ) : (
    <></>
  )
}

export default ModalTeacherPractice
