import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  color_scheme: 'light',
  panelRenderCount: 0,
  soundAssistance: 0
}

export const SettingsSlice = createSlice({
  name: 'Settings',
  initialState,
  reducers: {
    setSettings: (state, { payload }) => {
      state.color_scheme = payload.color_scheme
      state.ClonePanel = payload.ClonePanel
      state.panelRenderCount = payload.panelRenderCount
    },
    setColorScheme: (state, { payload }) => {
      state.color_scheme = payload.color_scheme
    },
    setPanelRenderCount: (state, { payload }) => {
      state.panelRenderCount = payload.panelRenderCount
    },
    setSoundAssistance: (state, { payload }) => {
      state.soundAssistance = payload
    },
    resetSettings: () => initialState
  }
})

// Action creators are generated for each case reducer function
export const {
  setSettings,
  setColorScheme,
  setPanelRenderCount,
  setSoundAssistance,
  resetSettings
} = SettingsSlice.actions

export default SettingsSlice.reducer
