import React, { useEffect } from 'react'
import { logout } from '../../services/logoutService'
import Loading from '../../modules/components/Loading/Loading'
import translate from '../../i18n/translate'

const LogoutPage = () => {
  useEffect(() => {
    window.localStorage.removeItem('bb_game_initialized')
    logout()
  }, [])

  return (
    <div className="lemonade-loading">
      <div className="lemonade-loading__text">
        {translate('logout_loading')}
      </div>
      <div className="lemonade-loading__animation">
        <Loading display="inline" color="purple" />
      </div>
    </div>
  )
}

export default LogoutPage
