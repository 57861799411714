import React from 'react'
import checkImg from '../../../assets/icons/check.png'

const MetaOptionsMenuToast = ({ text }) => {
  return (
    <div className="meta-options-menu-toast">
      <div className="meta-options-menu-toast__icon">
        <img src={checkImg} alt="" />
      </div>
      <div className="meta-options-menu-toast__text">{text}</div>
    </div>
  )
}

export default MetaOptionsMenuToast
