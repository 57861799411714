import { getToken } from '../../services/util'

export const ANDROID_FROM_NO_ANDROID =
  'https://play.google.com/store/apps/details?id=com.oneclick.blueberry&pcampaignid=web_share'
export const ANDROID_INTENT_WITHOUT_PARAMS =
  'intent://com.oneclick.blueberry://main/#Intent;package=com.oneclick.blueberry;scheme=com.oneclick.blueberry;end'
export const ANDROID_INTENT_WITH_PARAMS =
  'intent://com.oneclick.blueberry://main?utm_source=[SOURCE]&token=[TOKEN]&host=[HOST]/#Intent;package=com.oneclick.blueberry;scheme=com.oneclick.blueberry;end'

export const IOS_STORE_URL =
  'https://itunes.apple.com[LANGUAGE]/app/id6472714046'
const IOS_SCHEME_WITH_PARAMS = '&utm_source=[SOURCE]&token=[TOKEN]&host=[HOST]'

export const getSsoAppParams = (ssoSource) => {
  return IOS_SCHEME_WITH_PARAMS.replace('[SOURCE]', ssoSource)
    .replace('[TOKEN]', getToken())
    .replace('[HOST]', window.location.hostname)
}
