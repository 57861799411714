import React from 'react'
import { createPortal } from 'react-dom'
import './ConfirmPopupOptions.scss'
import translate from '../../../i18n/translate'
import { ReactComponent as CloseIconColor } from '../../../assets/icons/close_color.svg'

const ConfirmPopupOptions = ({
  aditionalClass,
  size,
  children,
  onClose,
  acceptButtonText,
  cancelButtonText,
  onAcceptButtonClick,
  isAcceptButtonDisabled,
  showBtnCancel = true
}) => {
  return (
    <>
      {createPortal(
        <>
          <div
            className={`confirm-popup-options__backdrop ${
              aditionalClass || ''
            }`}
            onClick={onClose}
          ></div>
          <div
            className={`confirm-popup-options confirm-popup-options--size-${
              size || 'default'
            } ${aditionalClass || ''}`}
          >
            <div
              className="confirm-popup-options-close-button"
              onClick={onClose}
            >
              <CloseIconColor />
            </div>
            <div className="confirm-popup-options__content">{children}</div>

            <div
              className={`confirm-popup-options__actions ${
                !showBtnCancel && 'confirm-popup-options__button--only'
              }`}
            >
              {showBtnCancel && (
                <div
                  className="confirm-popup-options__button confirm-popup-options__button--default"
                  onClick={onClose}
                >
                  {cancelButtonText || translate('pets_cancel')}
                </div>
              )}

              {acceptButtonText && onAcceptButtonClick && (
                <div
                  className={`confirm-popup-options__button ${
                    isAcceptButtonDisabled
                      ? 'confirm-popup-options__button--disabled'
                      : 'confirm-popup-options__button--available'
                  }`}
                  onClick={onAcceptButtonClick}
                >
                  {acceptButtonText}
                </div>
              )}
            </div>
          </div>
        </>,
        document.body
      )}
    </>
  )
}

export default ConfirmPopupOptions
