import { createSlice } from '@reduxjs/toolkit'
import omit from '../../util/omit'
import { store } from '../configureStore'
import { ProgressBarTransitionHelper } from '../../services/progresBarTransitionHelper'

const initialState = {
  practiceName: '',
  progress: 0,
  pointsEarned: 0,
  totalPoints: 0,
  isFinished: false,
  currentPieces: 0,
  totalPieces: 0,
  currentLives: 0,
  totalLives: 0,
  livesExp: 0,
  debugData: {},
  // challengeProgress: 0,
  // challengeTotal: 6,
  metadata: {},
  question: {},
  mainUserId: null,
  programId: null,
  // practiceSessionId: null
  exerciseData: null,
  isHintUsed: false,
  isAnswerCorrect: true
}

export const PracticeSlice = createSlice({
  name: 'Practice',
  initialState,
  reducers: {
    configurePractice: (state, { payload }) => {
      state.practiceName = payload.practiceName
      state.totalPoints = payload.totalPoints
      state.currentPieces = payload.currentPieces
      state.totalPieces = payload.totalPieces
      state.currentLives = payload.totalLives
      state.totalLives = payload.totalLives
      state.livesExp = 0
      state.progress = 0
      state.extraPoints = 0
      state.pointsEarned = 0
      state.metadata = {}
      state.question = {}
      state.isFinished = false
      state.exercisesCorrect = 0
      state.exercisesIncorrect = 0
      state.practiceStartAt = new Date().getTime()
    },

    initPractice: (state, { payload }) => {
      const source = payload?.next_lo || payload
      state.isFinished = false
      state.question = source.question
      state.metadata = omit(['question'], source)
      state.challengeProgress = payload?.bar_progress || 0
      state.challengeTotal = payload?.bar_total || 6
    },

    endPractice: (state, { payload }) => {
      state.question = null
      state.metadata = payload
      state.pointsEarned = payload.exp
      state.progress = payload.exp
      state.livesExp = payload?.lives_exp || 0
      state.currentLives = payload?.lives || 0
      state.currentPieces = payload?.have || 0
      state.totalPieces = payload?.pieces || 0
      state.challengeProgress = payload?.bar_progress || 0
      state.challengeTotal = payload?.bar_total || 6
      state.extraPoints = payload?.surplus_exp
      state.isFinished = true
    },

    resetPractice: (state) => {
      state.practiceName = ''
      state.totalPoints = 0
      state.currentPieces = 0
      state.totalPieces = 0
      state.currentLives = 0
      state.totalLives = 0
      state.livesExp = 0
      state.progress = 0
      state.pointsEarned = 0
      state.extraPoints = 0

      state.challengeProgress = 0
      state.challengeTotal = 6

      state.metadata = {}
      state.question = {}
      state.isFinished = false
    },

    loseLive: (state) => {
      state.currentLives -= 1
    },

    setProgress: (state, { payload }) => {
      ProgressBarTransitionHelper.setTransitionState(false)
      state.progress += payload
      state.pointsEarned = payload
    },

    setMainUserId: (state, { payload }) => {
      state.mainUserId = payload
    },

    setProgramId: (state, { payload }) => {
      state.programId = payload
    },

    /*
    setPracticeSessionId: (state, { payload }) => {
      state.practiceSessionId = payload
    },
    */

    setExerciseData: (state, { payload }) => {
      state.exerciseData = payload
    },

    setHintUsed: (state, { payload }) => {
      state.isHintUsed = payload
    },

    setAnswerCorrect: (state, { payload }) => {
      state.isAnswerCorrect = payload
    },

    incrementExercisesCorrect: (state) => {
      state.exercisesCorrect++
    },

    incrementExercisesIncorrect: (state) => {
      state.exercisesIncorrect++
    }
  }
})

export const {
  initPractice,
  configurePractice,
  endPractice,
  setProgress,
  resetPractice,
  loseLive,
  setMainUserId,
  setProgramId,
  // setPracticeSessionId
  setExerciseData,
  setHintUsed,
  setAnswerCorrect,
  incrementExercisesCorrect,
  incrementExercisesIncorrect
} = PracticeSlice.actions

window.endPractice = () => store.dispatch(endPractice({ payload: {} }))
window.addProgress = (earned) => store.dispatch(setProgress(earned))

export default PracticeSlice.reducer
