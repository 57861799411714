import { useState, useRef } from 'react'
import io from 'socket.io-client'

export default function useSocket(
  setInsideLobby,
  setStatusPractice,
  statusMapPractice,
  setIsTeacherDisconnected,
  setIsDeletePractice,
  setInsidePractice,
  setIsPlayerJoinGameStarted,
  Analysis
) {
  let server = 'https://school.blueberry-dev.oneclicklabs.es'
  const ROOM = 'personal-notification'

  const statusMapPracticeReset = {
    IN_QUESTION: 'question_playing',
    IN_RANKING: 'question_ranking',
    IN_PODIUM: 'finished_podium',
    IN_SOLUTION: 'solution',
    IN_LOBBY: 'lobby_waiting'
  }

  if (window.location.hostname !== 'localhost') {
    server = window.location.protocol + '//' + window.location.hostname
  }

  const socket = useRef(null)

  const [message, setMessage] = useState({})
  const [players, setPlayers] = useState([])
  const [numberQuestion, setNumberQuestion] = useState(null)
  const [secondsToAnswer, setSecondsToAnswer] = useState(null)
  const [syncTimestamp, setSyncTimestamp] = useState(null)
  const [showSolution, setShowSolution] = useState(false)
  const [usersDisconnected, setUsersDisconnected] = useState([])

  function connectSocket(token) {
    socket.current = io(server, {
      path: '/api/socket.io',
      transports: ['websocket'],
      query: { token }
    })

    socket.current.on(ROOM, (message) => {
      setMessage(message)
    })

    socket.current.on('quiz:user:connected', function (users) {
      setPlayers(users)
    })

    socket.current.on('quiz:user:disconnected', function (users) {
      const userGuids = users.map((user) => user.guid)
      setUsersDisconnected(userGuids)
      for (const user of users) {
        if (user.role_guid === 'R02') {
          setIsTeacherDisconnected(true)
          break // Salimos del bucle una vez que encontramos a un usuario con role_guid como 'R02'
        }
      }
    })

    socket.current.on('showQuestion', function (event) {
      // if (event.numberQuestion) {
      setNumberQuestion(event.numberQuestion)
      setSecondsToAnswer(event.secondsToAnswer)
      setSyncTimestamp(event.syncTimestamp)
      setShowSolution(false)
      // }
    })

    socket.current.on('showSolution', function (event) {
      if (event.showSolution) setShowSolution(event.showSolution)
    })

    socket.current.on('showRanking', function (event) {
      if (event.showRanking) {
        setStatusPractice({ phase: statusMapPractice.IN_RANKING })
        setShowSolution(false)
      }
    })

    socket.current.on('endPractice', function (event) {
      if (event.showPodium) {
        setStatusPractice({
          phase: statusMapPractice.IN_PODIUM,
          playerList: event.playerList
        })
      }
    })
  }

  function disconnectSocket() {
    if (socket.current) socket.current.disconnect()
  }

  function joinGame(gameGuid, quizGuid, numberTotalPlayers) {
    socket.current.on('responseStatusGame', function (event) {
      if (event.status === statusMapPracticeReset.IN_LOBBY) {
        setInsideLobby(true)
        setIsPlayerJoinGameStarted(false)
        Analysis.sendSegmentPageEvent(Analysis.EVENT.PRACTICE_LOBBY_OPENED, {
          practice_id: quizGuid,
          number_of_students: numberTotalPlayers
        })
      } else {
        setStatusPractice({ phase: statusMapPractice.IN_RANKING })
        setInsidePractice(true)
        setIsPlayerJoinGameStarted(true)
      }
      // goFullScreen()
      // cuando nos diga en que pantalla nos encontramos y los datos necesarios eliminamos la escucha de ese evento
      socket.current.off('responseStatusGame')
    })
    socket.current.emit(
      'quiz:game:join',
      { game_guid: gameGuid },
      function (error) {
        if (error) {
          setIsDeletePractice(true)
        } else {
          // se hace un emit al evento para preguntar al profesor en que estado está la partida
          socket.current.emit(
            'quiz:game:event',
            {
              event: 'statusGame',
              game_guid: gameGuid
            },
            function (response) {}
          )

          // el profesor nos responde en otro evento que estamos escuchando "responseStatusJoin"

          // setInsideLobby(true)
        }
      }
    )
  }

  function sendAnswer(gameGuid, userGuid, resultAnswer, timeInMs) {
    socket.current.emit(
      'quiz:game:event',
      {
        event: 'sendAnswer',
        game_guid: gameGuid,
        userGuid,
        resultAnswer,
        numberQuestion,
        timeInMs
      },
      function (re) {}
    )
  }

  return {
    messageSocket: message,
    playersSocketTeacherPractice: players,
    connectSocket,
    disconnectSocket,
    joinGame,
    numberQuestion,
    setNumberQuestion,
    secondsToAnswer,
    syncTimestamp,
    showSolution,
    setShowSolution,
    sendAnswer,
    usersDisconnected
  }
}
