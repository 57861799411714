import React, { useEffect } from 'react'
import { ReactComponent as SvgOk } from '../../../assets/icons/ok.svg'
import { ReactComponent as SvgNotOk } from '../../../assets/icons/notOk.svg'
import './toast.scss'

const Toast = ({ closeToast, title, message, isOk }) => {
  useEffect(() => {
    const closeToastTimer = setTimeout(() => {
      closeToast()
    }, 3000)

    // Cleanup function
    return () => clearTimeout(closeToastTimer)
  }, [])

  return (
    <div className="toast-blueberry" onClick={closeToast}>
      <div className="toast-blueberry_frame">
        {isOk ? (
          <SvgOk className="toast-blueberry_icon" />
        ) : (
          <SvgNotOk className="toast-blueberry_icon" />
        )}
        <div className="toast-blueberry_title">{title}</div>
        <div className="toast-blueberry_message">{message}</div>
      </div>
    </div>
  )
}

export default Toast
