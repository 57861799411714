import React, { useEffect } from 'react'
import translate from '../../i18n/translate'
import starIcon from '../../assets/img/star-icon.svg'
import pieceIcon from '../../assets/img/piece-icon.svg'
import coinsIcon from '../../assets/img/coins-icon.svg'

const PracticeReward = ({
  practiceProgressData,
  setPracticeBaseData,
  earnedPiecesAux,
  earnedExperienceAux,
  earnedCoinsAux
}) => {
  useEffect(() => {
    console.log('earnedCoinsAux', earnedCoinsAux)
  }, [])

  return (
    <div className="practice-complete_reward">
      <p className="practice-complete-view__rewards-title">
        {translate('app_practice_complete_subtitle')}
      </p>

      <div className="practice-complete-view_rewards-container">
        <div className="practice-complete-view__rewards-content">
          <div className="practice-complete-view__reward-item">
            <img src={starIcon} alt="EXP" className="reward-icon" />
            <p className="practice-complete-view_text">
              + {earnedExperienceAux} EXP
            </p>
          </div>

          {earnedPiecesAux > 0 && (
            <div className="practice-complete-view__reward-item">
              <img src={pieceIcon} alt="Piezas" className="reward-icon" />
              <p className="practice-complete-view_text">
                + {earnedPiecesAux}
                {'  '}
                {earnedPiecesAux > 1
                  ? translate('app_practice_complete_pieces_text')
                  : translate('app_practice_complete_piece_text')}
              </p>
            </div>
          )}
          {earnedCoinsAux > 0 && (
            <div className="practice-complete-view__reward-item">
              <img src={coinsIcon} alt="Monedas" className="reward-icon" />
              <p className="practice-complete-view_text">
                + {earnedCoinsAux}{' '}
                {earnedCoinsAux > 1
                  ? translate('app_practice_complete_coins_text')
                  : translate('app_practice_complete_coin_text')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default PracticeReward
