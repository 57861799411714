import React, { useEffect, useState } from 'react'
import './AvatarIcon.scss'
import { getUserAvatar } from '../../../services/settingsService'

const AvatarIcon = ({ onClick }) => {
  const [userAvatarImg, setUserAvatarImg] = useState(
    getUserAvatar().single_image
  )

  const [avatarSize, setAvatarSize] = useState(false)

  const checkAvatarSize = (userAvatarImgUrl) => {
    const avatarCode = userAvatarImgUrl.split('/').pop().split('_')[0]
    console.log('userAvatarImgUrl', userAvatarImgUrl)

    if (avatarCode === 'AV-02') setAvatarSize('small')
    else {
      if (avatarCode === 'AV-06') setAvatarSize('large')
      else setAvatarSize('regular')
    }
  }

  useEffect(() => {
    window.addEventListener('player-avatar-change', updateAvatarIcon)

    return () => {
      window.removeEventListener('player-avatar-change', updateAvatarIcon)
    }
  }, [])

  useEffect(() => {
    checkAvatarSize(userAvatarImg)
  }, [userAvatarImg])

  const updateAvatarIcon = () => {
    setUserAvatarImg(getUserAvatar().single_image)
  }

  const BgGradient = () => (
    <div className="avatar-icon__bg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52"
        height="35"
        viewBox="0 0 52 35"
        fill="none"
      >
        <ellipse
          cx="26.2605"
          cy="17.3242"
          rx="25.375"
          ry="17.2187"
          fill="url(#paint0_linear_4015_6408)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_4015_6408"
            x1="26.2605"
            y1="0.105469"
            x2="26.2605"
            y2="34.543"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#C5C5C5" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )

  return (
    <div className="avatar-icon" onClick={onClick}>
      <BgGradient />
      <div
        className={`avatar-icon__image avatar-icon__image--${avatarSize}`}
        style={{ backgroundImage: `url(${userAvatarImg})` }}
      ></div>
    </div>
  )
}

export default AvatarIcon
