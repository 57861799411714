import { React, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import BlueberryFamilyLayout from './components/BlueberryFamilyLayout/BlueberryFamilyLayout'
import LicenseForm from './forms/LicenseForm/LicenseForm'
import ProfilesForm from './forms/ProfilesForm/ProfilesForm'
import SignupForm from './forms/SignupForm/SignupForm'
import SignupComplete from './forms/SignupComplete/SignupComplete'
import AccessHelper from '../../services/util/accessHelper'
import {
  getLicenseData,
  resetPlayerState,
  getLicensePrograms,
  sendMagicLinkEmail
} from '../../services/userManagementService'
import usePopupManager from '../../modules/components/PopupManager/usePopupManager'
import { getWhoAmI } from '../../services/courseInfoService'
import { isSubscriptionHost } from '../../services/licenseService'
import { isDeviceSupported } from '../../util/mobile'
import { getToken } from '../../services/util'

// TODO: Refactor handle go to con un solo data

// TODO: Cambiar pasos de número a texto

const BlueberryFamilySignup = ({ trialLicense }) => {
  // console.log('BlueberryFamilySignup', trialLicense)

  const history = useHistory()
  const { resetPopupStore } = usePopupManager()

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  const toAddPlayers = queryParameters.get('toAddPlayers')

  // signupStep -> 1: License, 2: Signup, 3:Terms, 4:Profile, 5:Complete
  const [signupStep, setSignupStep] = useState(0) // useState(1)
  // const [isGoogleSignup, setIsGoogleSignup] = useState(false)

  const [licenseData, setLicenseData] = useState(null)
  const [savedSignupData, setSavedSignupData] = useState(null)
  const [afterSignupData, setAfterSignupData] = useState(null)

  const [errorMessageCode, setErrorMessageCode] = useState('')

  useEffect(() => {
    // Con sesión abierta (si no es para añadir juagdores) se redirige al juego
    if (!toAddPlayers && getToken()) {
      window.location.replace('/game')
    }

    if (typeof trialLicense === 'string') {
      if (!toAddPlayers) {
        if (!isSubscriptionHost() || trialLicense === '') {
          setSignupStep(1)
        } else {
          // Trial
          useTrialLicense().then((licenseData) => {
            if (licenseData.success) {
              setLicenseData({
                code: trialLicense,
                data: licenseData.success
              })
            } else {
              setErrorMessageCode(licenseData.error)
            }

            setSignupStep(2)
          })
        }
      } else {
        // Si terminó el registro, pero no añadió ningún usuario, al volver a entrar va directamente a ese paso
        fetchData()
          .then(() => {
            setSignupStep(4)
          })
          .catch((error) => {
            console.error('BlueberryFamilySignup toAddPlayers error', error)
          })
      }
    }
  }, [trialLicense])

  const useTrialLicense = async () => {
    const licenseResponse = await getLicenseData(trialLicense)

    if (licenseResponse?.programs === undefined) {
      let errorMessageCode = ''

      // console.log('licenseResponse', licenseResponse)
      switch (licenseResponse?.error?.message) {
        case 'Code not found':
        case 'No left uses':
        case 'Code expired':
        default:
          errorMessageCode = 'family_signup_license_error_not_valid'
      }

      return { error: errorMessageCode }
    }

    return { success: licenseResponse }
  }

  const fetchData = async () => {
    const whoAmI = await getWhoAmI(true)
    // console.log('whoAmI', whoAmI)
    if (whoAmI?.guid) {
      setAfterSignupData({ signup: { guid: whoAmI.guid } })
    } else throw Error('Error getting license personal data')

    const licenseResponse = await getLicensePrograms()
    // console.log('licenseResponse', licenseResponse)
    if (licenseResponse?.programs) {
      setLicenseData({ code: licenseResponse.code, data: licenseResponse })
    } else throw Error('Error getting license personal data')
  }

  const handleCancel = () => history.push('/blueberry-family')

  const handleLogout = () => history.push('/logout')

  const handleGoToSignUpForm = (licenseData) => {
    setLicenseData(licenseData)
    // console.log('1 license data', licenseData)
    setSignupStep(2)
  }

  const handleGoToProfilesForm = async (signupCompleteData, signupError) => {
    if (signupError) {
      if (signupError.message === 'USERNAME_EXIST') {
        setErrorMessageCode('family_signup_signup_error_email_exists')
      } else {
        setErrorMessageCode('family_signup_signup_error')
      }
      // si hay error volver a formulario
      setSignupStep(2)
    } else {
      setAfterSignupData(signupCompleteData)

      // console.log('3 signup complete data', signupCompleteData)

      // Se resetea el GUID del curso y el estado del store y establecer acceso reciente y tipo
      resetPlayerState()
      resetPopupStore()
      AccessHelper.setAccessInformation(
        true,
        !signupCompleteData.isGoogle
          ? AccessHelper.ACCESS_TYPE_CREDENTIALS
          : AccessHelper.ACCESS_TYPE_GOOGLE_SSO
      )

      setSignupStep(4)
    }
  }

  const handleGoToSignupFinished = () => {
    if (isDeviceSupported()) {
      setSignupStep(5)
    } else {
      handleGoToSelectProfile()
    }
  }

  const handleGoToSelectProfile = () => {
    const isBlueberryFamily = window.organizationData.isBlueberryFamily

    if (isDeviceSupported()) {
      history.push('/select-profile')
    } else {
      if (!isBlueberryFamily) {
        history.replace({
          pathname: '/download-app'
        })
      } else {
        sendMagicLinkEmail()
        history.replace({
          pathname: '/unsupported'
        })
      }
    }
  }

  return (
    <div className="blueberry-family-signup">
      <BlueberryFamilyLayout>
        <div className="blueberry-family-panel__form">
          {signupStep === 1 && (
            <LicenseForm
              onCancel={handleCancel}
              onNext={handleGoToSignUpForm}
            />
          )}
          {signupStep === 2 && (
            <SignupForm
              onCancel={handleCancel}
              onNextGoToProfiles={handleGoToProfilesForm}
              errorMessageCode={errorMessageCode}
              licenseData={licenseData}
              settedFirstName={
                savedSignupData?.firstName ? savedSignupData.firstName : ''
              }
              settedLastName={
                savedSignupData?.lastName ? savedSignupData.lastName : ''
              }
              settedEmail={savedSignupData?.email ? savedSignupData.email : ''}
              settedPassword={
                savedSignupData?.password ? savedSignupData.password : ''
              }
              saveSignupData={setSavedSignupData}
            />
          )}
          {signupStep === 4 && (
            <ProfilesForm
              onCancel={handleLogout}
              onNext={handleGoToSignupFinished}
              licenseData={licenseData}
              userData={afterSignupData}
            />
          )}
          {signupStep === 5 && (
            // welcome page
            <SignupComplete onNext={handleGoToSelectProfile} />
          )}
        </div>
      </BlueberryFamilyLayout>
    </div>
  )
}

export default BlueberryFamilySignup
