import React, { useState } from 'react'
import './mapBarTooltip.scss'

const MapBarTooltip = ({
  width = '160px',
  arrowPosition = 'center',
  children,
  message,
  contentComponent
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleClick = () => {
    setIsVisible(!isVisible)
  }

  return (
    <>
      {isVisible && (
        <div className="map-bar-tooltip__backdrop" onClick={handleClick}></div>
      )}
      <div className="map-bar-tooltip">
        <div className="map-bar-tooltip__element" onClick={handleClick}>
          {children}
        </div>

        <div
          style={{ width: width }}
          className={`map-bar-tooltip__tooltip map-bar-tooltip__tooltip--${arrowPosition} ${
            isVisible ? 'map-bar-tooltip__tooltip--visible' : ''
          }`}
        >
          {message && <div className="map-bar-tooltip__message">{message}</div>}
          {contentComponent && (
            <div className="map-bar-tooltip__content">{contentComponent}</div>
          )}
        </div>
      </div>
    </>
  )
}

export default MapBarTooltip
