require('polyfill-object.fromentries')

export function getSourceInfoParam() {
  const urlParams = new URLSearchParams(window.location.search)
  let source
  if (urlParams.has('source')) source = urlParams.get('source')
  else source = document.referrer
  // console.log('>>>> Service Source', source)
  return source
}

export function getSourceMetadataParams() {
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.delete('source')
  const extraParams = Object.fromEntries(urlParams)
  // console.log('>>>> Service ExtraParams', extraParams)
  return JSON.stringify(extraParams)
}

export function removeSourceParams() {
  const url = window.location.href
  const urlObj = new URL(url)
  urlObj.search = ''
  const resultURL = urlObj.toString()
  window.history.replaceState(null, null, resultURL)
}
