import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import translate from '../../../i18n/translate'
import MissionDailyRewardCard from './components/MissionDailyRewardCard/MissionDailyRewardCard'
import ShopLevel from '../MetaTabCommons/ShopLevel/ShopLevel'
import ShopCoinsCounter from '../MetaTabCommons/ShopCoinsCounter/ShopCoinsCounter'
import useSound from '../../views/MetaberryView/hooks/useSound'
import { getMissionDailyReward } from '../../../services/rewardsService'
import { store } from '../../../store/configureStore'
import { setCoinsAccumulated } from '../../../store/RewardsSlice/RewardsSlice'
import { setDailyGoalProgress } from '../../../store/DailyGoalSlice/DailyGoalSlice'
import { Analysis } from '../../../services/analysisService'

const MetaTabMissions = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [showTooltip, setShowTooltip] = useState(0)
  const [fadeAnimation, setFadeAnimation] = useState(true)
  const userCurrentLevel = useSelector((s) => s.userExperience.level)
  const coinsAccumulated = useSelector((s) => s.rewards.coinsAccumulated)

  const { renderSoundFXController, playSoundEffect } = useSound()

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Missions Page Viewed']
    )

    setIsLoading(false)
  }, [])

  useEffect(() => {
    let animationTimer = null
    let closeTimer = null

    if (showTooltip > 0) {
      Analysis.sendSegmentPageEvent(
        showTooltip === 1
          ? Analysis.SEGMENT_EVENTS['Level Icon Clicked']
          : Analysis.SEGMENT_EVENTS['Coin Icon Clicked'],
        { source: 'missions page' }
      )

      animationTimer = setTimeout(() => setFadeAnimation(false), 0)
      closeTimer = setTimeout(() => {
        setShowTooltip(0)
        setFadeAnimation(true)
      }, 4000)
    }

    return () => {
      clearTimeout(animationTimer)
      clearTimeout(closeTimer)
    }
  }, [showTooltip])

  const handleShowTooltip = (area) => {
    setShowTooltip(area)
  }

  const handleCloseTooltip = () => {
    setShowTooltip(0)
    setFadeAnimation(true)
  }

  const handleGetDailyReward = () => {
    setTimeout(async () => {
      const dailyGoalState = store.getState().dailyGoal

      const missionDailyRewardResponse = await getMissionDailyReward(
        dailyGoalState.sessionId,
        dailyGoalState.sessionAttempId
      )

      if (missionDailyRewardResponse?.coins_accumulated) {
        Analysis.sendSegmentTrackEvent(
          Analysis.SEGMENT_EVENTS['Daily Reward Collected'],
          {
            coins_earned: 1,
            coins_acumulated: missionDailyRewardResponse?.coins_accumulated
          }
        )

        store.dispatch(
          setCoinsAccumulated(missionDailyRewardResponse?.coins_accumulated)
        )

        // Actualizar los valores que cambian para 'daily reward'
        store.dispatch(
          setDailyGoalProgress({
            progress: dailyGoalState.progress,
            totalExp: dailyGoalState.totalExp,
            sessionId: dailyGoalState.sessionId,
            sessionAttempId: dailyGoalState.sessionAttempId,
            canGetDailyReward: false,
            nextDailyReward: missionDailyRewardResponse?.next_daily_coins_reward
          })
        )
      }
    }, 1)
  }

  return (
    <div className="-meta-tab-missions">
      {renderSoundFXController()}
      <div className="-meta-tab-missions__header">
        <div className="-meta-tab-missions__header-top">
          <div className="-meta-tab-missions__header-left">
            <div className="-meta-tab-missions__title">
              {translate('missions_title')}
            </div>
          </div>
          <div className="-meta-tab-missions__header-right">
            <div onClick={() => handleShowTooltip(1)}>
              <ShopLevel level={userCurrentLevel} isLocked={false} />
            </div>
            <div onClick={() => handleShowTooltip(2)}>
              <ShopCoinsCounter totalCoins={coinsAccumulated} />
            </div>
            {showTooltip > 0 && (
              <div
                className={`-meta-tab-missions__header-tooltip ${
                  fadeAnimation ? 'fade-out' : 'fade-in'
                }`}
                onAnimationEnd={fadeAnimation ? handleCloseTooltip : null}
              >
                {translate('info_tooltip_shop')}
              </div>
            )}
          </div>
        </div>

        <div className="-meta-tab-missions__header-bottom">
          <div className="-meta-tab-missions__subtitle">
            {translate('missions_subtitle')}
          </div>
        </div>
      </div>

      <div className="-meta-tab-missions__content">
        {!isLoading && (
          <div className="-meta-tab-missions__grid">
            <div className="-meta-tab-missions__grid-item">
              <MissionDailyRewardCard
                onClickGetReward={() => {
                  playSoundEffect('uiButton')
                  handleGetDailyReward()
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MetaTabMissions
