import { useEffect, useState } from 'react'
import Podium1 from '../../assets/quizz/podium_1.svg'
import Podium2 from '../../assets/quizz/podium_2.svg'
import Podium3 from '../../assets/quizz/podium_3.svg'
import Podium4 from '../../assets/quizz/podium_4.svg'
import confetti from '../../../../util/conffeti'

export default function usePodium(
  playerGuid,
  playerList,
  analysis,
  quizGuid,
  questionsRight
) {
  const [position, setPosition] = useState(null)
  const [userPodium, setUserPodium] = useState(null)
  const [points, setPoints] = useState(0)
  const [playerAvatarUrl, setPlayerAvatarUrl] = useState(0)

  useEffect(() => {
    const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
    setPlayerAvatarUrl(settings.blueberry.avatar.single_image)

    let confettiTimeout = null

    const sortedPlayerList = [...playerList].sort((a, b) => b.points - a.points)

    for (const p in sortedPlayerList) {
      if (sortedPlayerList[p].user_guid === playerGuid) {
        const _position = parseInt(p) + 1

        if (_position === 1) {
          confettiTimeout = setTimeout(() => {
            confetti.maxCount = 250
            confetti.start()
          }, 3500)
        }

        setPosition(_position)

        if (_position < 4) {
          setUserPodium(
            _position < 3 ? (_position < 2 ? Podium1 : Podium2) : Podium3
          )
        } else {
          setUserPodium(Podium4)
        }

        setPoints(sortedPlayerList[p].points)

        analysis.sendSegmentPageEvent(analysis.EVENT.PRACTICE_POSITION_VIEWED, {
          Practice_ID: quizGuid,
          Position: _position,
          Number_of_Points: sortedPlayerList[p].points,
          Number_of_Correct_Answers: questionsRight
        })

        break
      } else {
        setUserPodium(Podium4)
      }
    }

    return () => {
      clearTimeout(confettiTimeout)
      confetti.remove()
    }
  }, [])

  return {
    position,
    userPodium,
    points,
    playerAvatarUrl
  }
}
