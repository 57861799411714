import React from 'react'
import translate from '../../../../i18n/translate'
import FormButton from '../../components/FormButton/FormButton'
import FormInput from '../../components/FormInput/FormInput'
import FormSelect from '../../components/FormSelect/FormSelect'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/trash.svg'
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg'
import { ReactComponent as UploadIcon } from '../../../../assets/icons/upload.svg'
import { ReactComponent as UpdateIcon } from '../../../../assets/icons/update.svg'
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg'
import useMyProfilePlayers from './useMyProfilePlayers'

const MyProfilePlayers = ({ onBackToMap, onSelectPlayer }) => {
  const {
    licenseCourseOptions,
    showAddButton,
    isEditMode,
    isAdding,
    isDisabledButton,
    profileList,
    isWorking,
    isCurrentPlayerDeleted,
    formErrorMessage,
    handleEditProfile,
    handleDeleteProfile,
    addEmptyProfile,
    handleSaveNewProfile,
    handleUpdateProfile,
    onChangeName,
    onChangeCourse
  } = useMyProfilePlayers()

  return (
    <div className="my-profile-players">
      <form autoComplete="off">
        <div className="profiles-form__profile-list">
          {profileList.map((singleProfile, index) => (
            <div className="profiles-form__single-profile" key={index}>
              <div className="profiles-form__single-profile-row">
                {singleProfile.isSaved && !isEditMode && (
                  <div className="profiles-form__single-profile-edit">
                    <FormButton
                      variant="transparent"
                      icon={<EditIcon />}
                      onClick={() => handleEditProfile(index)}
                    />
                  </div>
                )}
                <FormInput
                  id="name"
                  value={singleProfile.name}
                  errorMessage={singleProfile.nameErrorMessage}
                  onChange={(e) => onChangeName(e, index)}
                  inputType="text"
                  placeholder={translate(
                    'family_signup_profiles_name_placeholder'
                  )}
                  handleChangeOutside
                  disabled={singleProfile.isSaved}
                />

                <FormSelect
                  id="course"
                  value={singleProfile.course}
                  options={licenseCourseOptions}
                  errorMessage={singleProfile.courseErrorMessage}
                  onChange={(value) => onChangeCourse(value, index, 'course')}
                  placeholder={translate(
                    'family_signup_profiles_course_placeholder'
                  )}
                  handleChangeOutside
                  disabled={singleProfile.isSaved || !isAdding}
                  tooltip={
                    !singleProfile.isSaved && !isAdding
                      ? translate('family_my_profile_course_tooltip')
                      : false
                  }
                />

                {!singleProfile.isSaved && profileList.length > 1 && (
                  <div className="profiles-form__single-profile-delete">
                    <FormButton
                      variant="transparent"
                      icon={<DeleteIcon />}
                      onClick={() => handleDeleteProfile(index)}
                      disabled={index === 0 && singleProfile.name === ''}
                    />
                  </div>
                )}
              </div>

              {!singleProfile.isSaved && (
                <div className="profiles-form__single-profile-row">
                  <div className="profiles-form__single-profile-save">
                    {isAdding ? (
                      <FormButton
                        variant="transparent"
                        icon={<UploadIcon />}
                        text={translate('family_signup_profiles_save_profile')}
                        textAlign="left"
                        onClick={() => handleSaveNewProfile(index)}
                        disabled={isWorking || isDisabledButton}
                      />
                    ) : (
                      <FormButton
                        variant="transparent"
                        icon={<UpdateIcon />}
                        text={translate(
                          'family_signup_profiles_update_profile'
                        )}
                        textAlign="left"
                        onClick={() => handleUpdateProfile(index)}
                        disabled={isWorking || isDisabledButton}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="profiles-form__actions">
          {showAddButton && (
            <FormButton
              variant="transparent"
              icon={<PlusIcon />}
              text={translate('family_signup_profiles_add_profile')}
              textAlign="left"
              onClick={addEmptyProfile}
            />
          )}
        </div>
        {formErrorMessage && (
          <div className="profiles-form__error">{formErrorMessage}</div>
        )}
      </form>
      <div className="blueberry-family-panel__actions blueberry-family-panel__actions--row">
        <FormButton
          variant="transparent"
          text={translate('family_signup_back')}
          onClick={isCurrentPlayerDeleted ? onSelectPlayer : onBackToMap}
        />
      </div>
    </div>
  )
}

export default MyProfilePlayers
