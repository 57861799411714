import React from 'react'

const FormTabs = ({ tabTitles, currentTab, handleTabChange }) => {
  return (
    <div className="form-tabs">
      {tabTitles.map((tabTitle, index) => (
        <div
          className={`form-tabs__tab-item${
            currentTab === index ? ' form-tabs__tab-item--active' : ''
          }`}
          key={index}
          onClick={() => handleTabChange(index)}
        >
          {tabTitle}
        </div>
      ))}
    </div>
  )
}

export default FormTabs
