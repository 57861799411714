import React from 'react'
import './inlineButton.scss'

function InlineButton({ label, className = '', href }) {
  return (
    <a href={href} className={'inline-button ' + className}>
      {label}
    </a>
  )
}

export default InlineButton
