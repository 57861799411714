import React, { useState, useEffect } from 'react'
import translate from '../../../../i18n/translate'
import BlueberryFamilyPanel from '../../components/BlueberryFamilyPanel/BlueberryFamilyPanel'
// import FormSteps from '../../components/FormSteps/FormSteps'
import FormButton from '../../components/FormButton/FormButton'
import FormInput from '../../components/FormInput/FormInput'
import { getLicenseData } from '../../../../services/userManagementService'
import { Analysis } from '../../../../services/analysisService'
import {
  getSourceInfoParam,
  getSourceMetadataParams
} from '../../../../services/sourceService'

const LicenseForm = ({
  // currentStep,
  // totalSteps,
  // offsetSteps,
  onCancel,
  onNext
}) => {
  const ERROR_MESSAGES = {
    LICENSE_NOT_VALID: translate('family_signup_license_error_not_valid')
  }

  const [license, setLicense] = useState('')
  const [isValidLicense, setIsValidLicense] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const sourceMetadata = getSourceMetadataParams()
    // console.log('sourceMetadata', sourceMetadata)
    const metadataDeconstruction = {}
    Object.keys(sourceMetadata).forEach((key) => {
      metadataDeconstruction['analytics_metadata_' + key] = sourceMetadata[key]
    })

    const sourceObj = {
      sourceOfUser: getSourceInfoParam(),
      ...metadataDeconstruction
    }

    Analysis.sendEvent(Analysis.EVENT.SIGN_UP_LICENSE_SCREEN, { sourceObj })

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Signup,
      Analysis.SEGMENT_EVENTS['Sign Up License Viewed'],
      {
        sourceObj
      }
    )
  }, [])

  const validateLicenseFormat = () => {
    if (license.length > 0) {
      if (license.length < 4) {
        setErrorMessage(ERROR_MESSAGES.LICENSE_NOT_VALID)
        setIsValidLicense(false)
      } else {
        setErrorMessage('')
        setIsValidLicense(true)
      }
    } else {
      setErrorMessage('')
      setIsValidLicense(false)
    }
  }

  const useLicense = async () => {
    const licenseResponse = await getLicenseData(license)

    if (licenseResponse?.programs === undefined) {
      let errorMessage = ''

      switch (licenseResponse?.error?.message) {
        case 'Code not found':
        case 'No left uses':
        case 'Code expired':
        default:
          errorMessage = translate('family_signup_license_error_not_valid')
      }

      setErrorMessage(errorMessage)
      setIsValidLicense(false)
    } else {
      onNext({ code: license, data: licenseResponse })
    }
  }

  return (
    <div className="license-form">
      <BlueberryFamilyPanel>
        {/* <FormSteps
          currentStep={currentStep}
          totalSteps={totalSteps}
          offsetSteps={offsetSteps}
        /> */}

        <div className="blueberry-family-panel__title">
          {translate('family_signup_license_title')}
        </div>

        <FormInput
          id="license"
          value={license}
          errorMessage={errorMessage}
          onChange={setLicense}
          inputType="text"
          placeholder={translate('family_signup_license_placeholder')}
          handleFormatValidation={validateLicenseFormat}
        />

        <div className="blueberry-family-panel__info">
          {/* {translate('family_signup_license_location')} */}
        </div>

        <div className="blueberry-family-panel__actions blueberry-family-panel__actions--row">
          <FormButton
            variant="transparent"
            text={translate('family_signup_cancel')}
            onClick={onCancel}
          />
          <FormButton
            text={translate('family_signup_next')}
            onClick={useLicense}
            disabled={!isValidLicense}
          />
        </div>
      </BlueberryFamilyPanel>
    </div>
  )
}

export default LicenseForm
