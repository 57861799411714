import React from 'react'

const BlueberryFamilyPanel = ({ children }) => {
  return (
    <div className="blueberry-family-panel">
      <div className="blueberry-family-panel__content">{children}</div>
    </div>
  )
}

export default BlueberryFamilyPanel
