import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import translate from '../../../i18n/translate'
import ModalSimple from '../ModalSimple/ModalSimple'

function ModalScreenPortraitTeacherPractice({ showModal = false }) {
  const [pathSchool] = useState(
    useSelector((state) => state.configuration.pathSchool)
  )
  const [blueberroNoStroke, setBlueberroNoStroke] = useState()

  useEffect(() => {
    setBlueberroNoStroke(
      pathSchool
        ? `/assets/graphics/gamification/${pathSchool}/blueberro_no_stroke.svg`
        : `/assets/graphics/gamification/blueberro_no_stroke.svg`
    )
  }, [])

  return (
    <ModalSimple
      showModal={showModal}
      modalTopIcon={blueberroNoStroke}
      title={translate('teacher_practice_exit_title')}
      message={translate('teacher_practice_error_portrait')}
      customClass={'modal-screen-portrait-practice'}
    ></ModalSimple>
  )
}

export default ModalScreenPortraitTeacherPractice
