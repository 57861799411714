import Phaser from 'phaser'
import { v4 as uuidv4 } from 'uuid'
import { getEffectsUserConfig } from '../../../../services/settingsService.js'
import { store } from '../../../../store/configureStore'
import defaultAnimationJson from '../../../../assets/data/character-animation.json'
import { reject } from 'q'

const CHARACTER_FLOAT_LAPSE = 50
const CHARACTER_FLOAT_FRAMES = 4
const CHARACTER_REDUCTION_RATIO = 0.98 // From tileSize
const CHARACTER_FACING_RIGHT = false

export default class Character {
  // spriteFilename se refiere al nombre compartido por su png y json
  constructor(
    scene,
    matterLabel,
    tileSize,
    keepAspectRatio,
    startPoint,
    camera,
    mapMarginX,
    mapMarginY,
    isSecondary,
    stepsOutsideAudio,
    stepsInsideAudio
  ) {
    this.isCharacterDone = false

    this.scene = scene
    this.matterLabel = matterLabel || 'character-' + uuidv4()
    this.tileSize = tileSize
    this.keepAspectRatio = keepAspectRatio === true
    this.startPoint = startPoint
    this.camera = camera

    this.fullMapMarginX = mapMarginX
    this.fullMapMarginY = mapMarginY
    const playerMarginX = ((mapMarginX / tileSize) % 1) * tileSize
    const playerMarginY = ((mapMarginY / tileSize) % 1) * tileSize

    this.mapMarginX = playerMarginX
    this.mapMarginY = playerMarginY
    this.startPoint.x += mapMarginX
    this.startPoint.y += mapMarginY

    this.isSecondary = isSecondary

    this.sprite = null
    this.spriteKey = null
    this.spriteCenter = null

    this.width = tileSize * CHARACTER_REDUCTION_RATIO
    this.height = tileSize * CHARACTER_REDUCTION_RATIO
    this.floatDespacementMax = (tileSize - this.height) / 2 - 1
    this.floatDespacement = this.floatDespacementMax / CHARACTER_FLOAT_FRAMES

    this.path = null
    this.destination = null
    // Para movimiento con cursores
    this.moveDesplacement = this.tileSize / 8 > 1 ? this.tileSize / 8 : 1
    this.maxSpeed = this.tileSize / 9

    this.fromCursorUp = false
    this.fromCursorDown = false
    this.fromCursorLeft = false
    this.fromCursorRight = false
    this.xCenterPixel = null
    this.yCenterPixel = null

    this.characterTime = null
    this.characterYModdifier = 0
    this.isCharacterYDirectionUp = true

    this.isTextureChanging = false
    this.hasToChangeAgain = false

    this.stepsInsideAudio = stepsInsideAudio
    this.stepsOutsideAudio = stepsOutsideAudio
  }

  generateCharacter(characterTextureUrl, animationJson) {
    return this.loadSprite(characterTextureUrl, animationJson).then(() =>
      this.configureCharacter(animationJson)
    )
  }

  loadSprite(textureUrl, animationJson) {
    const actualAnimationJson = animationJson || defaultAnimationJson
    // console.log('C loadSprite ' + textureUrl)
    // console.log('this.scene.textures.list ', this.scene.textures.list)
    return new Promise((resolve, reject) => {
      // Destruir textura y animaciones previas (si las hay)
      if (this.spriteKey && this.scene.textures.list[this.spriteKey]) {
        for (const anim in actualAnimationJson.animations) {
          this.scene.anims.remove(this.matterLabel + '-' + anim)
        }

        this.scene.textures.list[this.spriteKey].destroy()
      }

      this.spriteKey = uuidv4()

      this.scene.load.on(
        'filecomplete-spritesheet-' + this.spriteKey,
        (key, type, data) => resolve({ key, type, data }),
        this
      )

      const avatarURL = textureUrl // settings

      // TODO: si cambia tamaño estandar character ya no vale el del json por defecto
      this.scene.load.spritesheet(
        this.spriteKey,
        avatarURL,
        {
          frameWidth: actualAnimationJson.width,
          frameHeight: actualAnimationJson.height
        },
        {
          headers: { 'Cache-Control': 'no-cache' }
        }
      )

      this.scene.load.start()
    })
  }

  configureCharacter(animationJson) {
    this.createCharacter(this.startPoint, animationJson)

    if (!this.isSecondary) {
      this.setCameraFollowCharacter(this.camera)
      this.camera.setDeadzone(this.tileSize, this.tileSize)
    }

    this.spriteCenter = this.sprite.getCenter()
    this.isCharacterDone = true

    return true
  }

  createCharacter(startPoint, animationJson) {
    // console.log('C createCharacter ' + this.spriteKey)
    // TODO: Cargar forma ajustada al sprite (hay herramientas para ello...)
    this.sprite = this.scene.matter.add
      .sprite(startPoint.x, startPoint.y, this.spriteKey)
      .setOrigin(0.5, 0.5)
      .setDepth(this.isSecondary ? 1 : 2)

    this.sprite.setDisplaySize(this.width, this.height)

    // Establecer animaciones
    this.createCharacterAnimations(animationJson)

    this.setCharacterSpriteProperties(startPoint)
  }

  createCharacterAnimations(animationJson) {
    const actualAnimationJson = animationJson || defaultAnimationJson

    for (const anim in actualAnimationJson.animations) {
      const currentAnimation = actualAnimationJson.animations[anim]

      // TODO es una prueba para el rendimiento CPU, si mejora, quitarlo y cambiar los JSONs 1/2
      const frameRate = Math.floor(currentAnimation.frameRate / 3) || 12

      this.scene.anims.create({
        key: this.matterLabel + '-' + anim,
        frames: this.scene.anims.generateFrameNumbers(this.spriteKey, {
          frames: currentAnimation.frames
        }),

        // TODO es una prueba para el rendimiento CPU, si mejora, quitarlo y cambiar los JSONs 2/2
        frameRate: frameRate, // currentAnimation.frameRate,
        repeat: currentAnimation.repeat
      })
    }
  }

  setCharacterSpriteProperties(positionPoint) {
    this.setCharacterCustomBody(positionPoint)
    this.sprite.setX(positionPoint.x)
    this.sprite.setY(positionPoint.y)
    this.sprite.setFixedRotation()

    // Para que no se choque con otros sprites
    this.sprite.setCollisionGroup(-1)

    this.characterTime = Date.now()
  }

  setCharacterCustomBody(positionPoint) {
    // console.log('C setCharacterCustomBody ' + this.matterLabel)
    // ini --------------------------------------------------
    const Bodies = Phaser.Physics.Matter.Matter.Bodies
    const mainBody = Bodies.rectangle(
      positionPoint.x + 1,
      positionPoint.y + 1,
      this.tileSize - 2,
      this.tileSize - 2,
      {
        label: this.matterLabel + '-main',
        onCollideActiveCallback: this.bodyCollision.bind(this)
      }
    )
    const compoundBody = Phaser.Physics.Matter.Matter.Body.create({
      parts: [mainBody],
      label: this.matterLabel
    })

    this.sprite.setExistingBody(compoundBody)
  }

  characterCenterXPosition(fromRight, fromCollition) {
    const xTile =
      Math.round((this.sprite.x - this.mapMarginX) / this.tileSize) -
      1 +
      (fromRight ? 1 : 0)

    const newX = xTile * this.tileSize + this.tileSize / 2 + this.mapMarginX
    return newX
  }

  characterCenterYPosition(fromDown, fromCollition) {
    const yTile =
      Math.round((this.sprite.y - this.mapMarginY) / this.tileSize) -
      1 +
      (fromDown ? 1 : 0)

    const newY = yTile * this.tileSize + this.tileSize / 2 + this.mapMarginY

    return newY
  }

  fixVerticalAlignment(collisionOn) {
    this.sprite.setVelocityY(0)
    const movementFromDown = collisionOn === 'top'
    this.sprite.setY(this.characterCenterYPosition(movementFromDown, true))
  }

  fixHorizontalAlignment(collisionOn) {
    this.sprite.setVelocityX(0)
    const movementFromRight = collisionOn === 'left'
    this.sprite.setX(this.characterCenterXPosition(movementFromRight, true))
  }

  bodyCollision(collisionData) {
    const tangent = collisionData.collision.tangent
    // console.log(tangent)

    if (tangent.y > 0) this.isCollidingRight = true
    else if (tangent.y < 0) this.isCollidingLeft = true

    if (tangent.x > 0) this.isCollidingTop = true
    else if (tangent.x < 0) this.isCollidingBottom = true
  }

  isMoving(cursorPressed) {
    const isCursorPressed =
      cursorPressed &&
      (cursorPressed.left ||
        cursorPressed.right ||
        cursorPressed.up ||
        cursorPressed.down)

    return isCursorPressed || this.path?.length
  }

  isBuilding() {
    const currentState = store.getState()
    return !!currentState.metaberry.unitGuid
  }

  update(time, delta, cursorPressed) {
    if (this.isCharacterDone) {
      this.checkCollitions()
      cursorPressed && this.moveByCursors(cursorPressed)
      this.moveByPath()

      // TODO: Movimiento de flotación
      // Aunque está mejor, sigue fallando, lo quito por ahora
      // El problema posiblemente venga del movimiento con cursores
      // this.floatCharacter()

      this.spriteCenter = this.sprite.getCenter()

      if (this.isMoving(cursorPressed)) {
        // En movimiento
        // Inicialr sonidos de movimiento
        if (getEffectsUserConfig() === 1) {
          if (this.isBuilding()) {
            this.stepsInsideAudio &&
              !this.stepsInsideAudio.isPlaying &&
              this.stepsInsideAudio.play({ volume: 0.05, loop: true, rate: 2 })
          } else {
            this.stepsOutsideAudio &&
              !this.stepsOutsideAudio.isPlaying &&
              this.stepsOutsideAudio.play({ volume: 0.05, loop: true })
          }
        }

        // Iniciar animación de movimiento
        if (!this.sprite.anims.isPlaying) {
          clearTimeout(this.animationStopTimer)
          this.sprite.play(this.matterLabel + '-walk')
        }
      } else {
        // Parado
        // Detener sonidos de movimiento
        if (getEffectsUserConfig() === 1) {
          this.stepsOutsideAudio &&
            this.stepsOutsideAudio.isPlaying &&
            this.stepsOutsideAudio.stop()

          this.stepsInsideAudio &&
            this.stepsInsideAudio.isPlaying &&
            this.stepsInsideAudio.stop()
        }

        // Detener animación de movimiento
        if (this.sprite.anims.isPlaying) {
          if (this.animationStopPause) return

          this.animationStopPause = true
          this.animationStopTimer = setTimeout(() => {
            this.sprite.stop()
            this.sprite.setFrame(0) // reset animation so the pets don't fly
            this.animationStopPause = false
          }, 180)
        }
      }
    }
  }

  checkCollitions() {
    if (this.isCollidingLeft) {
      this.isCollidingLeft = false
      this.fixHorizontalAlignment('left')
    }
    if (this.isCollidingRight) {
      this.isCollidingRight = false
      this.fixHorizontalAlignment('right')
    }
    if (this.isCollidingTop) {
      this.isCollidingTop = false
      this.fixVerticalAlignment('top')
    }
    if (this.isCollidingBottom) {
      this.isCollidingBottom = false
      this.fixVerticalAlignment('bottom')
    }
  }

  moveByCursors(cursorPressed) {
    // X axis
    if (cursorPressed.left && !cursorPressed.right) {
      this.fromCursorLeft = true
      this.fromCursorRight = false
      this.xCenterPixel = null
      this.sprite.setFlipX(CHARACTER_FACING_RIGHT)
      this.sprite.setVelocityX(-this.maxSpeed)
    } else if (cursorPressed.right && !cursorPressed.left) {
      this.fromCursorRight = true
      this.fromCursorLeft = false
      this.xCenterPixel = null
      this.sprite.setFlipX(!CHARACTER_FACING_RIGHT)
      this.sprite.setVelocityX(this.maxSpeed)
    } else if (this.fromCursorLeft || this.fromCursorRight) {
      // Al terminar de presionar controles izq/drch centrar personaje en tile si no lo está
      // En una primera vuelta calcula el centro y en la siguiente si hace falta usarlo
      if (!this.xCenterPixel) {
        this.xCenterPixel = this.characterCenterXPosition(
          this.fromCursorRight,
          false
        )
      } else if (
        (this.fromCursorLeft && this.sprite.x <= this.xCenterPixel) ||
        (this.fromCursorRight && this.sprite.x >= this.xCenterPixel)
      ) {
        this.sprite.setVelocityX(0)
        this.sprite.x = this.xCenterPixel
        this.xCenterPixel = null
        this.fromCursorLeft = false
        this.fromCursorRight = false
      }
    }

    // Y axis
    if (cursorPressed.up && !cursorPressed.down) {
      this.fromCursorUp = true
      this.fromCursorDown = false
      this.yCenterPixel = null
      this.sprite.setVelocityY(-this.maxSpeed)
    } else if (cursorPressed.down && !cursorPressed.up) {
      this.fromCursorDown = true
      this.fromCursorUp = false
      this.yCenterPixel = null
      this.sprite.setVelocityY(this.maxSpeed)
    } else if (this.fromCursorUp || this.fromCursorDown) {
      // Al terminar de presionar controles izq/drch centrar personaje en tile si no lo está
      if (!this.yCenterPixel) {
        this.yCenterPixel = this.characterCenterYPosition(
          this.fromCursorDown,
          false
        )
      } else if (
        (this.fromCursorUp && this.sprite.y <= this.yCenterPixel) ||
        (this.fromCursorDown && this.sprite.y >= this.yCenterPixel)
      ) {
        this.sprite.setVelocityY(0)
        this.sprite.y = this.yCenterPixel
        this.yCenterPixel = null
        this.fromCursorUp = false
        this.fromCursorDown = false
      }
    }
  }

  goToDestination() {
    if (this.destination) {
      const spritePosition = this.sprite.getCenter()
      const vectorX = this.destination.x - spritePosition.x
      const vectorY = this.destination.y - spritePosition.y

      const modX = vectorX === 0 ? 0 : vectorX / Math.abs(vectorX)
      const modY = vectorY === 0 ? 0 : vectorY / Math.abs(vectorY)

      if (modX === -1) this.sprite.flipX = CHARACTER_FACING_RIGHT
      else if (modX === 1) this.sprite.flipX = !CHARACTER_FACING_RIGHT

      if (Math.abs(vectorX) > this.moveDesplacement) {
        this.sprite.x += modX * this.moveDesplacement
      } else {
        this.sprite.x = this.destination.x
      }

      if (Math.abs(vectorY) > this.moveDesplacement) {
        this.sprite.y += modY * this.moveDesplacement
      } else {
        this.sprite.y = this.destination.y
      }

      if (
        this.sprite.x === this.destination.x &&
        this.sprite.y === this.destination.y
      ) {
        this.destination = null
      }
    }
  }

  setDestination(destination) {
    if (destination) {
      destination.x += this.fullMapMarginX
      destination.y += this.fullMapMarginY
    }

    this.destination = destination
  }

  setPath(path) {
    this.path = path?.length ? path : null

    if (!path?.length) this.setDestination(null)
  }

  moveByPath() {
    this.goToDestination()

    if (!this.destination && this.path?.length) {
      const tilePath = this.path.shift()

      if (this.path.length === 0) this.path = null

      this.setDestination({
        x: (tilePath.x + 1) * this.tileSize - this.tileSize / 2,
        y: (tilePath.y + 1) * this.tileSize - this.tileSize / 2
      })
    }
  }

  floatCharacter() {
    const timeNow = Date.now()
    if (timeNow - this.characterTime > CHARACTER_FLOAT_LAPSE) {
      if (this.isCharacterYDirectionUp) {
        this.characterYModdifier -= this.floatDespacement
        this.sprite.y -= this.floatDespacement
      } else {
        this.characterYModdifier += this.floatDespacement
        this.sprite.y += this.floatDespacement
      }

      if (
        (this.isCharacterYDirectionUp &&
          this.characterYModdifier <= -this.floatDespacementMax) ||
        (!this.isCharacterYDirectionUp &&
          this.characterYModdifier >= this.floatDespacementMax)
      ) {
        this.isCharacterYDirectionUp = !this.isCharacterYDirectionUp
      }

      this.characterTime = Date.now()
    }
  }

  getCenter() {
    return this.spriteCenter
  }

  setCameraFollowCharacter(camera) {
    camera.startFollow(this.sprite, true, 0.1, 0.1)
  }

  async changeTexture(textureUrl, animationJson) {
    // console.log('1 changeTexture t.isTextureChanging', this.isTextureChanging)
    // console.log('1 changeTexture t.hasToChangeAgain', this.hasToChangeAgain)
    // No quitar esto
    if (this.isTextureChanging === undefined) return

    if (this.isTextureChanging) {
      this.hasToChangeAgain = true
      return
    }

    this.isTextureChanging = true
    // console.log('2 changeTexture t.isTextureChanging', this.isTextureChanging)

    return new Promise((resolve, reject) => {
      // console.log('3 changeTexture')
      this.isCharacterDone = false
      this.scene.scene.pause()

      this.sprite.setVisible(false)
      // this.sprite.setActive(false)
      resolve(true)
    })
      .then(() => {
        // console.log('4 changeTexture')
        return this.loadSprite(textureUrl, animationJson)
      })
      .then(() => {
        // console.log('5 changeTexture ' + this.spriteKey)
        this.scene.scene.resume()
        this.sprite.setTexture(this.spriteKey)
        this.scene.scene.pause()

        // Establecer animaciones
        this.createCharacterAnimations(animationJson)

        // this.sprite.setActive(true)
        this.sprite.setVisible(true)

        this.scene.scene.resume()
        this.isCharacterDone = true

        this.isTextureChanging = false
        if (this.hasToChangeAgain) {
          this.hasToChangeAgain = false
          setTimeout(() => {
            this.changeTexture(textureUrl)
          }, 50)
        }
      })
      .catch((error) => {
        console.error('changeTexture error', error)

        this.isTextureChanging = false
        this.hasToChangeAgain = false

        reject(error)
      })
  }

  teleport(point, tileMap) {
    // console.log('teleport')

    this.camera.removeBounds()

    // hacer persosanje invisible
    this.scene.add.tween({
      targets: [this.sprite],
      ease: 'Sine.easeInOut',
      duration: 600,
      delay: 0,
      alpha: {
        getStart: () => 1,
        getEnd: () => 0
      },

      onComplete: () => {
        // mover camara a nueva posicion
        if (!this.isSecondary) {
          this.camera.stopFollow()
        }

        this.camera.pan(point.x, point.y, 1200, 'Power2', false, () => {
          this.sprite.x = point.x
          this.sprite.y = point.y

          // hacer personaje visible
          this.add.tween({
            targets: [this.sprite],
            ease: 'Sine.easeInOut',
            duration: 600,
            delay: 0,
            alpha: {
              getStart: () => 0,
              getEnd: () => 1
            },
            onComplete: () => {
              this.camera.setBounds(
                0,
                0,
                tileMap.tileMap.widthInPixels,
                tileMap.tileMap.heightInPixels
              )

              if (!this.isSecondary) {
                this.setCameraFollowCharacter(this.camera)
              }
            }
          })
        })
      }
    })
  }

  updateMapMargins(fullMapMarginX, fullMapMarginY) {
    const modX = this.fullMapMarginX - fullMapMarginX
    const modY = this.fullMapMarginY - fullMapMarginY
    let newX = null
    let newY = null
    if (this.sprite) {
      newX = this.sprite.x - modX
      newY = this.sprite.y - modY
    }

    this.fullMapMarginX = fullMapMarginX
    this.fullMapMarginY = fullMapMarginY
    this.mapMarginX = ((fullMapMarginX / this.tileSize) % 1) * this.tileSize
    this.mapMarginY = ((fullMapMarginY / this.tileSize) % 1) * this.tileSize

    // Reconstruir matter body desplazado
    if (this.sprite) {
      this.sprite.body.destroy()

      this.setCharacterSpriteProperties({ x: newX, y: newY })
    }
  }

  destroy() {
    this.sprite.destroy()
    this.path = null
    this.destination = null
  }
}
