import React, { useEffect } from 'react'
import BlueberryFamilyPanel from '../../components/BlueberryFamilyPanel/BlueberryFamilyPanel'
import FormButton from '../../components/FormButton/FormButton'
import WelcomeImage from '../../../../assets/img/blueberro_fam_welcome.png'
import translate from '../../../../i18n/translate'
import { Analysis } from '../../../../services/analysisService'
import {
  getSourceInfoParam,
  getSourceMetadataParams
} from '../../../../services/sourceService'

const SignupComplete = ({ onNext }) => {
  useEffect(() => {
    // Para evento
    const sourceMetadata = JSON.parse(getSourceMetadataParams())
    const metadataDeconstruction = {}
    Object.keys(sourceMetadata).forEach((key) => {
      metadataDeconstruction['analytics_metadata_' + key] = sourceMetadata[key]
    })

    Analysis.sendEvent(Analysis.EVENT.WELCOME_SCREEN, {
      sourceOfUser: getSourceInfoParam(),
      ...metadataDeconstruction
    })

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Signup,
      Analysis.SEGMENT_EVENTS['Signup Page Welcome'],
      {
        // ...metadataDeconstruction,
        source_of_user: getSourceInfoParam()
      }
    )
  }, [])

  return (
    <div className="blueberry-family-complete">
      <BlueberryFamilyPanel>
        <div className="blueberry-family-panel__complete-img">
          <img src={WelcomeImage} alt="" />
        </div>
        <div className="blueberry-family-panel__title">
          {translate('family_signup_complete_title')}
        </div>
        <div className="blueberry-family-panel__text">
          {translate('family_signup_complete_subtitle')}
        </div>
        <FormButton
          text={translate('family_signup_complete_button')}
          onClick={onNext}
        />
      </BlueberryFamilyPanel>
    </div>
  )
}

export default SignupComplete
