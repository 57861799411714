/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { usePhaserGame } from '../../../hooks/usePhaserGame'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { GAME_CONFIGURATION } from './config'
import './metaberryView.scss'
import MetaOptionsMenuDrawer from '../../../modules/components/MetaOptionsMenuDrawer/MetaOptionsMenuDrawer'

import PopupManager from '../../components/PopupManager/PopupManager'
import usePopupManager from '../../components/PopupManager/usePopupManager'
import { setPetsNotification } from '../../../services/rewardsService'

import {
  setIsPracticing,
  setIsInQuizz,
  setAreLessonsUpdatedAfterPracticing,
  setIsGameKeyboardBlocked
} from '../../../store/MetaberrySlice/MetaberrySlice'

import {
  getMusicUserConfig,
  updateMusicUserConfig,
  getEffectsUserConfig,
  updateEffectsUserConfig,
  getDevMode,
  getSoundAssistanceApiConfig,
  setIsDailyGoalCompleted,
  isDailyGoalCompleted
} from '../../../services/settingsService'

import { store } from '../../../store/configureStore'
import { getCourseGuid } from '../../../assets/data/api'
import {
  createEndPoint,
  fetchWithToken,
  getToken
} from '../../../services/util'
import { setDailyGoalProgress } from '../../../store/DailyGoalSlice/DailyGoalSlice'
import { setUserExperienceProgress } from '../../../store/UserExperienceSlice/UserExperienceSlice'
import {
  setPiecesAccumulated,
  setCoinsAccumulated,
  setCoinsDailyGoal,
  setCoinsStreak,
  setStreakDay,
  setCoinsDailyGoalEarned
} from '../../../store/RewardsSlice/RewardsSlice'
import getLessons from './utils/getLessons'
import { updateUnitsAndLessons } from '../../../store/UnitsSlice/UnitsSlice'

import { updateDevMode } from '../../../store/Configuration/Configuration'

import { fetchLearningMap } from '../../../services/learningMapService'
import { setProgramId } from '../../../store/PracticeSlice/PracticeSlice'

import useSound from './hooks/useSound'
import ReactHowler from 'react-howler'
import unitMusicFile from '../../../assets/sound/little-apprentice-loop.mp3'
import lessonMusicFile from '../../../assets/sound/sun-shine-loop.mp3'
import introMusicFile from '../../../assets/sound/the-mountains-loop.mp3'

import { Analysis } from '../../../services/analysisService'
import {
  getAloneUserData,
  getFamilyUserData
} from '../../../services/userTypeService'
import AppPracticePage from '../../../pages/AppPracticePage/AppPracticePage'

import ModalTeacherPractice from '../../modals/ModalTeacherPractice/ModalTeacherPractice'
import useSocket from './hooks/useSocket'
import useQuiz from './hooks/useQuiz'

import LobbyTeacherPracticePage from '../../../pages/LobbyTeacherPracticePage/LobbyTeacherPracticePage'
import TeacherPracticePage from '../../../pages/TeacherPracticePage/TeacherPracticePage'
import ModalExitTeacherPractice from '../../modals/ModalTeacherPractice/ModalExitTeacherPractice'
import ModalScreenPortraitTeacherPractice from '../../modals/ModalTeacherPractice/ModalScreenPortraitTeacherPractice'
import MapScoreBar from '../../components/MapScoreBar/MapScoreBar'
import MapOptionsBar from '../../components/MapOptionsBar/MapOptionsBar'
import MapBottomBar from '../../components/MapBottomBar/MapBottomBar'
import {
  getAvatars,
  setAvatarsNotification
} from '../../../services/avatarService'
import { increaseCountProgress } from '../../../util/loadingProgressBar'

const USER_NAME_MAX_LENGTH = 20

const MetaberryView = () => {
  const history = useHistory()
  const { renderSoundFXController, playSoundEffect, toggleSoundEffects } =
    useSound()

  const { showIntroduction } = usePopupManager()

  const [playerUserName, setPlayerUserName] = useState('')
  const [playerGuid, setPlayerGuid] = useState('')

  const [playerEnteredActivity, setPlayerEnteredActivity] = useState(false)
  const [isLessonActive, setIsLessonActive] = useState(false)
  const [practiceData, setPracticeData] = useState(null)

  const initialPathSchool = useSelector(
    (state) => state.configuration.pathSchool
  )
  const [pathAssetsSchool, setPathAssetsSchool] = useState()

  // QUIZ
  const handleExitTeacherPractice = async () => {
    store.dispatch(setIsInQuizz(false))
    handleExitAnyPractice()
  }
  const {
    dataPlayersTeacherPractice,
    isCreatedTeacherPractice,
    isInsideLobbyTeacherPractice,
    totalPlayersTeacherPractice,
    conectedPlayersTeacherPractice,
    isInsidePracticeTeacherPractice,
    contentQuiz,
    setInsideLobby,
    keyCountDown,
    countDownTeacherPractice,
    gameStartedHandle,
    onCloseModalTeacherPractice,
    statusMapPractice,
    statusPractice,
    numberQuestion,
    numberCorrectQuestion,
    showSolution,
    sendAnswerData,
    formatTime,
    timerQuestion,
    showModalExitPractice,
    onCloseTeacherPractice,
    userAvatarImg,
    isDeletePractice,
    goFullScreen,
    exitFullScreen,
    isFullscreen,
    isPlayerJoinGameStarted,
    langQuiz,
    userIsDemo,
    forcedQuizExit,
    setForcedQuizExit,
    onActivityEventQuiz,
    finishedTimeQuestion
  } = useQuiz(getToken, Analysis, handleExitTeacherPractice, playSoundEffect)

  const { disconnectSocket } = useSocket(setInsideLobby)

  useEffect(() => {
    if (forcedQuizExit) {
      onCloseTeacherPractice()
      handleExitTeacherPractice()
      Analysis.sendSegmentTrackEvent(Analysis.EVENT.PRACTICE_EXITED, {
        practice_id: contentQuiz.guid
      })
      setForcedQuizExit(false)
    }
  }, [forcedQuizExit])

  // Para control de back button en browser
  useEffect(() => {
    if (isLessonActive) {
      // Agrega una entrada a la historia del navegador
      window.history.pushState(null, null)

      // Escucha el evento popstate
      const handlePopState = (event) => {
        if (playerEnteredActivity) {
          event.preventDefault()
          handleExitStandarPractice()
        }
      }

      window.addEventListener('popstate', handlePopState)

      // Limpia el evento cuando se desmonte el componente
      return () => {
        window.removeEventListener('popstate', handlePopState)
      }
    }
  }, [isLessonActive, playerEnteredActivity])

  useEffect(() => {
    setPathAssetsSchool(
      initialPathSchool
        ? `/assets/graphics/gamification/${initialPathSchool}`
        : `/assets/graphics/gamification`
    )
  }, [initialPathSchool])

  useEffect(async () => {
    console.log(window._getTestTime() + ' - MetaberryView tms-')
    increaseCountProgress()

    window.launchMapLesson = launchMapLesson
    checkDevMode()
    getSoundAssistanceApiConfig()

    const aloneMemberData = getAloneUserData()
    if (aloneMemberData) {
      setPlayerUserName(
        trimUserName(aloneMemberData.name, USER_NAME_MAX_LENGTH)
      )
      setPlayerGuid(aloneMemberData.guid)
    } else {
      const familyMemberData = getFamilyUserData()
      if (familyMemberData) {
        setPlayerUserName(
          trimUserName(familyMemberData.name, USER_NAME_MAX_LENGTH)
        )
        setPlayerGuid(familyMemberData.guid)
      }
    }

    window.addEventListener('enter-unit', handleEnterUnit) // Cambiar música de fondo
    window.addEventListener('enter-lesson', handleEnterLesson) // Cambiar música de fondo
    // TODO:
    window.addEventListener('keydown', handleHideMenus) // Cerrar menú de opciones al pulsar una tecla
    document.addEventListener('visibilitychange', toogleSoundByVisibility)
    window.addEventListener('start-practice', handleStartStandarPractice)
    window.addEventListener('exit-activities', handleExitStandarPractice)
    window.addEventListener('game-started', gameStartedHandle)

    return () => {
      window.removeEventListener('enter-unit', handleEnterUnit)
      window.removeEventListener('enter-lesson', handleEnterLesson)
      window.removeEventListener('keydown', handleHideMenus)
      document.removeEventListener('visibilitychange', toogleSoundByVisibility)
      window.removeEventListener('start-practice', handleStartStandarPractice)
      window.removeEventListener('exit-activities', handleExitStandarPractice)
      window.removeEventListener('game-started', gameStartedHandle)
      disconnectSocket()
    }
  }, [])

  const dailyProgress = useSelector((state) => ({
    progress: state.dailyGoal.progress,
    totalExp: state.dailyGoal.totalExp
  }))

  const userExperience = useSelector((state) => ({
    level: state.userExperience.level,
    progress: state.userExperience.progress,
    totalExperience: state.userExperience.totalExperience,
    previousExperience: state.userExperience.previousExperience
  }))

  const prevLevelRef = useRef(null)
  useEffect(() => {
    if (
      prevLevelRef.current !== null &&
      userExperience?.level &&
      prevLevelRef.current < userExperience.level
    ) {
      checkPetsRewardsLevel(userExperience.level).then((result) => {
        if (result) {
          setTimeout(() => {
            setPetsNotification(true)

            const currentState = store.getState()
            Analysis.sendSegmentTrackEvent(
              Analysis.SEGMENT_EVENTS['Pet Alert Notification Showed'],
              {
                current_level: currentState.userExperience.level,
                current_points: currentState.rewards.coinsAccumulated
              }
            )
          }, 150)
        }
      })

      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent('user-level-up', {
            detail: { level: userExperience.level }
          })
        )
      }, 825) // Tiempo del fade

      checkAvatarsLevel(userExperience.level).then((result) => {
        if (result) {
          setTimeout(() => {
            setAvatarsNotification(true)

            const currentState = store.getState()
            Analysis.sendSegmentTrackEvent(
              Analysis.SEGMENT_EVENTS['Avatar Notification Showed'],
              {
                current_level: currentState.userExperience.level,
                current_points: currentState.rewards.coinsAccumulated
              }
            )
          }, 150)
        }
      })
    }

    prevLevelRef.current = userExperience.level
  }, [userExperience.level])

  async function getPetAvatarsList() {
    const endPoint = `blueberry/rewards-galleries`
    return fetchWithToken(createEndPoint(endPoint)).then((json) => json.data)
  }

  async function checkPetsRewardsLevel(level) {
    const apiData = await getPetAvatarsList()
    const result = apiData.find((item) => {
      return parseInt(item.reward_level) === level
    })
    return result
  }

  async function checkAvatarsLevel(level) {
    const apiData = await getAvatars()
    const result = apiData.find((item) => {
      return parseInt(item.level) === level
    })
    return result
  }

  const rewards = useSelector((s) => ({
    piecesAccumulated: s.rewards.piecesAccumulated,
    coinsAccumulated: s.rewards.coinsAccumulated
  }))

  const gameRef = useRef(null)
  usePhaserGame(GAME_CONFIGURATION, gameRef)

  const trimUserName = (userName, maxLength) => {
    return userName.length <= maxLength
      ? userName
      : userName.substring(0, length - 1) + '…'
  }

  const launchMapLesson = (url) => {
    history.push(url)
  }

  // Activa el modo developer/debug si es necesario
  const checkDevMode = () => {
    const blueberry_devmode = getDevMode()
    store.dispatch(updateDevMode({ isDevMode: blueberry_devmode }))
  }

  const updateMapData = async () => {
    const courseGuid = await getCourseGuid()
    // console.log('COURSE GUID PRUEBA --- ', courseGuid)

    const mapsEndPoint = `lms/courses/${courseGuid}/blueberry/maps`
    const courseData = await fetchWithToken(createEndPoint(mapsEndPoint)).then(
      (json) => json.data
    )

    store.dispatch(setProgramId(courseData.program_template_guid))

    Analysis.sendEvent(Analysis.EVENT.EXPERIENCE, {
      user_experience: courseData.exp,
      session_experience: courseData.session.exp
    })

    // Control para enviar evento dailyGoal a true sólo cuando corresponda
    const wasDailyGoalCompleted = isDailyGoalCompleted()
    const isDailyGoalCompletedNow =
      courseData.session.exp >= courseData.session.exp_daily_go
    const isDailyGoalCompletedEvent =
      !wasDailyGoalCompleted && isDailyGoalCompletedNow

    if (isDailyGoalCompletedEvent) {
      Analysis.sendEvent(Analysis.EVENT.DAILY_GOAL)
    }

    // Establecer estado ACTUAL 'daily goal' para comprobar al gananar experiencia si es o no la 1º vez que se llega a la necesaria de daily goal
    setIsDailyGoalCompleted(isDailyGoalCompletedNow)

    store.dispatch(
      setDailyGoalProgress({
        progress: courseData.session.exp,
        totalExp: courseData.session.exp_daily_go,
        canGetDailyReward: courseData.session.can_get_daily_coins_reward,
        nextDailyReward: courseData.session.next_daily_coins_reward,
        sessionId: courseData.session.id,
        sessionAttempId: courseData.session.attempt_id
      })
    )

    store.dispatch(setUserExperienceProgress(courseData))

    // Piezas acumuladas
    store.dispatch(
      setPiecesAccumulated(
        courseData?.total_pieces?.have?.reduce((a, b) => a + b, 0)
      )
    )
    // Monedas acumuladas
    store.dispatch(setCoinsAccumulated(courseData.coins_accumulated))
    // Monedas ganadas en daily goal
    store.dispatch(setCoinsDailyGoal(courseData.session.coins_daily_go))
    // Streak de monedas ganadas en daily goal
    store.dispatch(setCoinsStreak(courseData.session.coins_streak))
    // Dias seguidos con daily goal
    store.dispatch(setStreakDay(courseData.session.streak_day))
    // Monedas ganadas en daily goal en total
    store.dispatch(
      setCoinsDailyGoalEarned(courseData.session.coinsDailyGoalEarned)
    )

    const unitGuid = store.getState().metaberry.unitGuid
    if (unitGuid) {
      const unitData = await fetchLearningMap()
      const lessons = await getLessons(unitGuid)

      store.dispatch(
        updateUnitsAndLessons({
          unit_guid: unitGuid,
          units: unitData,
          lessons: lessons
        })
      )

      store.dispatch(setAreLessonsUpdatedAfterPracticing(true))
    }
  }

  const [showMapIntro, setShowMapIntro] = useState(!showIntroduction())
  const [showOptionsDrawer, setShowOptionsDrawer] = useState(false)
  const [optionsDrawerTab, setOptionsDrawerTab] = useState(0)

  // Botones

  const onCloseMenu = () => {
    setOptionsDrawerTab(0)
    playSoundEffect('controlPanel')
    setShowOptionsDrawer(!showOptionsDrawer)
    store.dispatch(setIsGameKeyboardBlocked(!showOptionsDrawer))
  }

  const handleGoToAvatarSelector = () => {
    playSoundEffect('controlPanel')
    setOptionsDrawerTab(1)
    setShowOptionsDrawer(!showOptionsDrawer)
    store.dispatch(setIsGameKeyboardBlocked(!showOptionsDrawer))
  }

  const handleGoToPetsSelector = () => {
    playSoundEffect('controlPanel')
    setOptionsDrawerTab(2)
    setShowOptionsDrawer(!showOptionsDrawer)
    store.dispatch(setIsGameKeyboardBlocked(!showOptionsDrawer))
  }

  const handleOpenMissionsPanel = () => {
    playSoundEffect('controlPanel')
    setOptionsDrawerTab(3)
    setShowOptionsDrawer(!showOptionsDrawer)
    store.dispatch(setIsGameKeyboardBlocked(!showOptionsDrawer))
  }

  // Preferencias Sonido
  const MUSIC_VOLUME = 0.1
  const [musicOn, setMusicOn] = useState(getMusicUserConfig() === 1)
  const [effectsOn, setEffectsOn] = useState(getEffectsUserConfig() === 1)
  const [musicVolume, setMusicVolume] = useState(
    showIntroduction() ? MUSIC_VOLUME / 4 : MUSIC_VOLUME
  )
  const [musicFile, setMusicFile] = useState(
    showIntroduction() ? introMusicFile : unitMusicFile
  )
  const howlerMusicRef = useRef(null)

  const handleToggleMusicConfig = () => {
    const isMusicActive = getMusicUserConfig() === 1

    if (isMusicActive) {
      updateMusicUserConfig(0)
      setMusicOn(false)
    } else {
      updateMusicUserConfig(1)
      setMusicOn(true)
    }

    Analysis.sendEvent(
      isMusicActive ? Analysis.EVENT.MUSIC_OFF : Analysis.EVENT.MUSIC_ON
    )
  }

  const handleToggleEffectsConfig = () => {
    const isSoundsActive = getEffectsUserConfig() === 1

    if (isSoundsActive) {
      updateEffectsUserConfig(0)
      setEffectsOn(false)
    } else {
      updateEffectsUserConfig(1)
      setEffectsOn(true)
    }

    Analysis.sendEvent(
      isSoundsActive ? Analysis.EVENT.EFX_OFF : Analysis.EVENT.EFX_ON
    )
  }

  const toogleSoundByVisibility = () => {
    // Este función desactiva la música y sonidos al cambiar la visibilidad de la pestaña
    //  por lo que en caso de práctica o quiz se ignora
    const metaberryState = store.getState().metaberry

    if (!metaberryState.isPracticing && !metaberryState.isInQuizz) {
      const muteSounds = document.hidden

      const isMusicActive = getMusicUserConfig() === 1

      if (isMusicActive && muteSounds) {
        setMusicOn(false)
      } else if (isMusicActive && !muteSounds) {
        setMusicOn(true)
      }

      const isSoundsActive = getEffectsUserConfig() === 1

      if (isSoundsActive && muteSounds) {
        setEffectsOn(false)
      } else if (isSoundsActive && !muteSounds) {
        setEffectsOn(true)
      }
    }
  }

  const musicFadeOut = () => {
    const interval = 50 // 50ms interval
    let _musicVolume = showIntroduction() ? MUSIC_VOLUME / 4 : MUSIC_VOLUME
    const fadeoutInterval = setInterval(function () {
      if (_musicVolume > 0) {
        _musicVolume -= 0.01
        setMusicVolume(_musicVolume)
      } else {
        setMusicVolume(0)
        clearInterval(fadeoutInterval)
      }
    }, interval)
  }

  const musicFadeIn = () => {
    const interval = 50 // 50ms interval
    let _musicVolume = 0
    const maxMusicVolume = showIntroduction() ? MUSIC_VOLUME / 4 : MUSIC_VOLUME
    const fadeoutInterval = setInterval(function () {
      if (_musicVolume < maxMusicVolume) {
        _musicVolume += 0.01
        setMusicVolume(_musicVolume)
      } else {
        setMusicVolume(MUSIC_VOLUME)
        clearInterval(fadeoutInterval)
      }
    }, interval)
  }

  const handleEnterUnit = useCallback(() => {
    // console.log('Entra a unidad')
    musicFadeOut()
    setTimeout(() => setMusicFile(unitMusicFile), 500)
  }, [])

  const handleEnterLesson = useCallback(() => {
    // console.log('Entra a leccion')
    musicFadeOut()
    setTimeout(() => setMusicFile(lessonMusicFile), 500)
  }, [])

  const handleStartAnyPractice = async () => {
    store.dispatch(setIsGameKeyboardBlocked(true))

    if (getMusicUserConfig() === 1) {
      musicFadeOut()
      setTimeout(() => {
        setMusicOn(false)
      }, 1000)
    }
  }

  const handleExitAnyPractice = async () => {
    const metaberryState = store.getState().metaberry

    if (!metaberryState.isPracticing && !metaberryState.isInQuizz) {
      store.dispatch(setIsGameKeyboardBlocked(false))

      if (getMusicUserConfig() === 1) {
        setTimeout(() => {
          setMusicOn(true)
          musicFadeIn()
        }, 1000)
      }
    }
  }

  const handleStartStandarPractice = async (event) => {
    setPlayerEnteredActivity(true)
    setPracticeData(event.detail)
    setIsLessonActive(true)
    store.dispatch(setIsPracticing(true))
    handleStartAnyPractice()
  }

  const handleExitStandarPractice = useCallback(() => {
    setPlayerEnteredActivity(false)
    setPracticeData(null)
    setIsLessonActive(false)
    store.dispatch(setIsPracticing(false))
    handleExitAnyPractice()

    updateMapData().catch(console.error)
  }, [])

  const handleStartTeacherPractice = async () => {
    store.dispatch(setIsInQuizz(true))
    handleStartAnyPractice()
  }

  useEffect(() => {
    if (isLessonActive === true) {
      launchPractice()
    }
  }, [isLessonActive])

  const launchPractice = async () => {
    let ttsSettings = window.localStorage.getItem('bb_tts')
    ttsSettings = JSON.parse(ttsSettings)

    const practiceObject = {
      function: 'startPracticeWeb',
      params: {
        apiHost: createEndPoint(),
        userToken: getToken(),
        courseGuid: await getCourseGuid(),
        lesson: {
          unit_guid: practiceData.unit_guid,
          lesson_guid: practiceData.lesson_guid,
          challenges: practiceData.challenges,
          status: practiceData.status,
          pieces: {
            have: practiceData.pieces.have,
            pieces: practiceData.pieces.pieces
          }
        },
        language: ttsSettings.programLanguage,
        requiresAudioAssistance: ttsSettings.requiresSoundAssistance
      }
    }

    // console.log('start pr ->\n lesson', practiceObject)
    window.postMessage(JSON.stringify(practiceObject))
  }

  const handleHideMenus = () => {
    // setShowSoundMenu(false)
  }

  const isPhaserVisible = () =>
    store.getState().metaberry.isPhaserVisible === true

  const isGameUIVisible = () =>
    store.getState().metaberry.isGameUIVisible === true
  return (
    <div
      className={
        'metaberry-view' +
        (!isPhaserVisible() && !isLessonActive
          ? ' metaberry-view__phaser_loading'
          : '')
      }
    >
      {/* MUSIC AND FX */}
      {renderSoundFXController()}

      <ReactHowler
        ref={howlerMusicRef}
        src={musicFile}
        playing={musicOn}
        volume={musicVolume}
        loop={true}
        onPlay={() => {
          musicFadeIn()
          // console.log('Music playing', musicFile)
        }}
        onLoad={() => {}}
        onLoadError={() => console.error('Music load error')}
        onPlayError={(id, errorCode) =>
          console.error('Music play error: ', errorCode)
        }
      />

      {/* QUIZ */}
      <ModalTeacherPractice
        showModal={isCreatedTeacherPractice}
        startHandle={handleStartTeacherPractice}
        onCloseComplete={onCloseModalTeacherPractice}
        insideLobby={isInsideLobbyTeacherPractice}
        insidePractice={isInsidePracticeTeacherPractice}
        onCloseStart={() => {
          onCloseTeacherPractice()
          handleExitTeacherPractice()
        }}
        withCloseButton={userIsDemo}
      ></ModalTeacherPractice>

      <ModalScreenPortraitTeacherPractice
        showModal={isCreatedTeacherPractice}
      ></ModalScreenPortraitTeacherPractice>

      <LobbyTeacherPracticePage
        showPage={isInsideLobbyTeacherPractice}
        totalPlayers={totalPlayersTeacherPractice}
        conectedPlayers={conectedPlayersTeacherPractice}
        players={dataPlayersTeacherPractice}
        countDownTeacherPractice={countDownTeacherPractice}
        keyCountDown={keyCountDown}
        goFullScreen={goFullScreen}
        exitFullScreen={exitFullScreen}
        isFullscreen={isFullscreen}
      ></LobbyTeacherPracticePage>

      {isInsidePracticeTeacherPractice && (
        <TeacherPracticePage
          contentQuizz={contentQuiz}
          showPage={isInsidePracticeTeacherPractice}
          statusMapPractice={statusMapPractice}
          statusPractice={statusPractice}
          numberQuestion={numberQuestion}
          numberCorrectQuestion={numberCorrectQuestion}
          showSolution={showSolution}
          sendAnswerData={sendAnswerData}
          formatTime={formatTime}
          timerQuestion={timerQuestion}
          userAvatarImg={userAvatarImg}
          playerGuid={playerGuid}
          onExitPractice={() => {
            onCloseTeacherPractice()
            handleExitTeacherPractice()
            Analysis.sendSegmentTrackEvent(Analysis.EVENT.PRACTICE_EXITED, {
              practice_id: contentQuiz.guid
            })
          }}
          playSoundEffect={playSoundEffect}
          goFullScreen={goFullScreen}
          exitFullScreen={exitFullScreen}
          isFullscreen={isFullscreen}
          isPlayerJoinGameStarted={isPlayerJoinGameStarted}
          langQuiz={langQuiz}
          analysis={Analysis}
          onActivityEvent={onActivityEventQuiz}
          finishedTimeQuestion={finishedTimeQuestion}
          pathAssetsSchool={pathAssetsSchool}
        ></TeacherPracticePage>
      )}

      <ModalExitTeacherPractice
        showModal={
          Boolean(showModalExitPractice || isDeletePractice) &&
          statusPractice.phase !== statusMapPractice.IN_PODIUM
        }
        onCloseTeacherPractice={() => {
          onCloseTeacherPractice()
          handleExitTeacherPractice()
        }}
      ></ModalExitTeacherPractice>

      {/* GAME UI */}

      <div
        className={
          isLessonActive || !isGameUIVisible()
            ? ' metaberry-view__activity_on'
            : ''
        }
      >
        <MapScoreBar
          userExperience={userExperience}
          dailyProgress={dailyProgress}
          totalCoins={rewards.coinsAccumulated}
          totalPieces={rewards.piecesAccumulated}
          pathSchool={initialPathSchool}
        />

        <MapOptionsBar
          handleGoToAvatarSelector={handleGoToAvatarSelector}
          onCloseAvatarSelector={onCloseMenu}
          playerUserName={playerUserName}
          playSoundEffect={playSoundEffect}
          handleToggleMusicConfig={handleToggleMusicConfig}
          musicOn={musicOn}
          handleToggleEffectsConfig={handleToggleEffectsConfig}
          effectsOn={effectsOn}
          pathAssetsSchool={pathAssetsSchool}
        />

        <MapBottomBar
          handleOpenMissionsPanel={handleOpenMissionsPanel}
          handleGoToPetsSelector={handleGoToPetsSelector}
          pathSchool={initialPathSchool}
        />
      </div>

      {/* PHASER GAME */}
      <div
        id="phaser-game"
        className={
          'phaser-game' +
          (isLessonActive ? ' metaberry-view__activity_on' : '') +
          (!showMapIntro ? ' metaberry-view__intro_on' : '')
        }
        ref={gameRef}
      ></div>

      {/* Info Popups */}
      {!isLessonActive && isGameUIVisible() && (
        <PopupManager
          handleFinishIntro={() => {
            handleEnterUnit()
            setShowMapIntro(true)
          }}
          handleGoToPetsSelector={handleGoToPetsSelector}
        />
      )}

      {/* Avatars and pets modal */}
      <MetaOptionsMenuDrawer
        showOptionsDrawer={showOptionsDrawer}
        onCloseDrawer={onCloseMenu}
        optionsDrawerTab={optionsDrawerTab}
        userExperience={userExperience}
      />

      {/* Activities view */}
      {isLessonActive && <AppPracticePage />}
    </div>
  )
}

export default MetaberryView
