import React, { useState, useEffect } from 'react'
import translate from '../../../../i18n/translate'
import BlueberryFamilyPanel from '../../components/BlueberryFamilyPanel/BlueberryFamilyPanel'
import FormButton from '../../components/FormButton/FormButton'
import FormInput from '../../components/FormInput/FormInput'
import { Analysis } from '../../../../services/analysisService'
import {
  getSourceMetadataParams,
  getSourceInfoParam
} from '../../../../services/sourceService'
import FormPopup from '../../components/FormPopup/FormPopup'
import TermsText from '../Terms/TermsText'
import { getCurrentLangString } from '../../../../i18n/initI18n'

import { GoogleLogin } from '@react-oauth/google'
import {
  signUpCredentials,
  signUpGoogle
} from '../../../../services/userManagementService'
import { login, loginProvider } from '../../../../services/loginService'
import { clearToken } from '../../../../services/util'

const SignupForm = ({
  onCancel,
  errorMessageCode,
  settedFirstName,
  settedLastName,
  settedEmail,
  settedPassword,
  licenseData,
  onNextGoToProfiles,
  saveSignupData
}) => {
  const MIN_PASSWORD_CHARACTERS = 8
  const MIN_NAME_CHARACTERS = 2

  const ERROR_MESSAGES = {
    EMAIL_NOT_VALID: translate('family_signup_signup_error_email_not_valid'),
    EMAIL_EXISTS: translate('family_signup_signup_error_email_exists'),
    PASSWORD_CHARACTERS: translate(
      'family_signup_signup_error_password_characters'
    ),
    FIRST_NAME_CHARACTERS: translate(
      'family_signup_signup_error_first_name_characters'
    ),
    LAST_NAME_CHARACTERS: translate(
      'family_signup_signup_error_last_name_characters'
    ),
    TERMS: translate('family_signup_signup_error_terms'),
    SSO_TERMS: translate('family_signup_signup_error_sso_terms')
  }

  const [firstName, setFirstName] = useState(settedFirstName)
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('')

  const [lastName, setLastName] = useState(settedLastName)
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState('')

  const [email, setEmail] = useState(settedEmail)
  const [emailErrorMessage, setEmailErrorMessage] = useState('')

  const [password, setPassword] = useState(settedPassword)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('')

  useEffect(() => {
    const sourceMetadata = JSON.parse(getSourceMetadataParams())
    const metadataDeconstruction = {}
    Object.keys(sourceMetadata).forEach((key) => {
      metadataDeconstruction['analytics_metadata_' + key] = sourceMetadata[key]
    })

    Analysis.sendEvent(Analysis.EVENT.SIGN_UP_FORM_SCREEN, {
      sourceOfUser: getSourceInfoParam(),
      ...metadataDeconstruction
    })

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Signup,
      Analysis.SEGMENT_EVENTS['Sign Up Form Viewed'],
      {
        // ...metadataDeconstruction,
        source_of_user: getSourceInfoParam()
      }
    )
  }, [])

  const [showTermsPopup, setShowTermsPopup] = useState(false)

  useEffect(() => {
    if (showTermsPopup === true) {
      Analysis.sendSegmentTrackEvent(
        Analysis.SEGMENT_EVENTS['Sign Up Terms And Conditions Viewed'],
        {
          // ...metadataDeconstruction,
          source_of_user: getSourceInfoParam()
        }
      )
    }
  }, [showTermsPopup])

  const globalErrorMessage = errorMessageCode ? translate(errorMessageCode) : ''

  const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)

  const validateFirstNameFormat = (isFromButton) => {
    if (firstName.length > 0 || isFromButton)
      if (firstName.length < MIN_NAME_CHARACTERS) {
        setFirstNameErrorMessage(ERROR_MESSAGES.FIRST_NAME_CHARACTERS)
      } else {
        setFirstNameErrorMessage('')
      }
  }

  const validateLastNameFormat = (isFromButton) => {
    if (lastName.length > 0 || isFromButton) {
      if (lastName.length < MIN_NAME_CHARACTERS) {
        setLastNameErrorMessage(ERROR_MESSAGES.LAST_NAME_CHARACTERS)
      } else {
        setLastNameErrorMessage('')
      }
    }
  }

  const validateEmailFormat = (isFromButton) => {
    if (email.length > 0 || isFromButton) {
      if (!isEmail) {
        setEmailErrorMessage(ERROR_MESSAGES.EMAIL_NOT_VALID)
      } else {
        setEmailErrorMessage('')
      }
    }
  }

  const validatePasswordFormat = (isFromButton) => {
    if (password.length > 0 || isFromButton)
      if (password.length < MIN_PASSWORD_CHARACTERS) {
        setPasswordErrorMessage(ERROR_MESSAGES.PASSWORD_CHARACTERS)
      } else {
        setPasswordErrorMessage('')
      }
  }

  const hasErrorsForm =
    firstNameErrorMessage !== '' ||
    lastNameErrorMessage !== '' ||
    passwordErrorMessage !== '' ||
    emailErrorMessage !== ''

  const handleSignup = async () => {
    validateFirstNameFormat(true)
    validateLastNameFormat(true)
    validateEmailFormat(true)
    validatePasswordFormat(true)

    if (
      !hasErrorsForm &&
      firstName !== '' &&
      lastName !== '' &&
      password !== '' &&
      email !== ''
    ) {
      // console.log('logn')
      handleSignupCredentials()
      onNextGoToProfiles({
        type: 'credentials',
        firstName,
        lastName,
        email,
        password
      })
    }
  }

  const googleOnSuccess = (successResponse) => {
    // console.log('Google signup successResponse', successResponse)
    continueSignupGoogle(successResponse)
  }

  const googleOnError = (errorResponse) => {
    // console.log('Google signup errorResponse', errorResponse)
    handleErrors({ error: errorResponse, message: 'google' })
  }

  const continueSignupGoogle = async (googleResponse) => {
    try {
      let signupResponse = { error: googleResponse, message: 'google' }

      if (googleResponse.credential) {
        signupResponse = await signUpGoogle({
          role: 'school-admin',
          wellcomeSend: 1,
          wellcomeLang: getCurrentLangString(),
          wellcomeURL: window.location.origin,
          licenses: [licenseData.code],
          ssoToken: googleResponse.credential
        })
      }

      if (signupResponse.error) {
        throw signupResponse
      }

      clearToken()
      // let isLogged = getToken().length > 0
      // console.log('islogged 1', isLogged)
      const loginGoogleResponse = await loginProvider(
        googleResponse.credential,
        'google'
      )
      // isLogged = getToken().length > 0
      // console.log('islogged 2', isLogged)

      if (loginGoogleResponse.error) {
        throw loginGoogleResponse
      }
      // setIsGoogleSignup(true)

      onNextGoToProfiles({
        signup: signupResponse,
        login: loginGoogleResponse,
        isGoogle: true
      })
    } catch (error) {
      // console.log('Google signup error', error)
      handleErrors(error)
    }
  }

  const handleSignupCredentials = async () => {
    try {
      const signupResponse = await signUpCredentials({
        role: 'school-admin',
        wellcomeSend: 1,
        wellcomeLang: getCurrentLangString(),
        wellcomeURL: window.location.origin,
        licenses: [licenseData.code],

        name: firstName,
        lastname: lastName,
        email: email,
        username: email,
        password: password
      })

      saveSignupData({
        firstName: firstName,
        lastName: lastName,
        email: email,
        username: email,
        password: password
      })

      // console.log('Credentials signup response', signupResponse)

      if (signupResponse.error) {
        throw signupResponse
      }

      clearToken()
      const loginCredentialsResponse = await login({ email, password })

      if (loginCredentialsResponse.error) {
        throw loginCredentialsResponse
      }

      // setIsGoogleSignup(false)
      onNextGoToProfiles({
        signup: signupResponse,
        login: loginCredentialsResponse,
        isGoogle: false
      })
    } catch (error) {
      // console.log('Credentials signup  error', error)
      handleErrors(error)
    }
  }

  const handleErrors = (errorData) => {
    const error = { error: errorData, message: undefined }

    // console.log('Signup handleErrors errorData', errorData)

    if (errorData.message === 'google') {
      error.message = 'google'
    } else if (
      errorData?.error?.error === 'USERNAME_EXIST' ||
      errorData?.error.errorKey === 'OAUTH_EMAIL_EXISTS'
    ) {
      error.message = 'USERNAME_EXIST'
    }

    console.error('Signup handleErrors error', error)

    onNextGoToProfiles(undefined, error)
  }

  // console.log('SignupForm', firstName, lastName, email, password, termsConsent)

  return (
    <div className="signup-form">
      <BlueberryFamilyPanel>
        <div className="blueberry-family-panel__title">
          {translate('family_signup_signup_title')}
        </div>
        <div className="blueberry-family-panel__form">
          <div className="google-signup-button__wrapper">
            <div className="google-signup-button">
              <GoogleLogin
                context="signup"
                theme="filled_blue"
                logo_alignment="left"
                size="medium"
                width="325px"
                text="signup_with"
                onSuccess={googleOnSuccess}
                onError={googleOnError}
              />
            </div>
          </div>
        </div>
        <div className="signup-form__separator">
          <span className="signup-form__separator-line"></span>
          <span className="signup-form__separator-text">
            {translate('or_separator')}
          </span>
          <span className="signup-form__separator-line"></span>
        </div>
        <div className="signup-form__row">
          <FormInput
            id="firstName"
            label={translate('family_signup_signup_first_name_label')}
            value={firstName}
            errorMessage={firstNameErrorMessage}
            onChange={setFirstName}
            inputType="text"
            placeholder={translate(
              'family_signup_signup_first_name_placeholder'
            )}
            handleFormatValidation={validateFirstNameFormat}
          />

          <FormInput
            id="lastName"
            label={translate('family_signup_signup_last_name_label')}
            value={lastName}
            errorMessage={lastNameErrorMessage}
            onChange={setLastName}
            inputType="text"
            placeholder={translate(
              'family_signup_signup_last_name_placeholder'
            )}
            handleFormatValidation={validateLastNameFormat}
          />
        </div>
        <FormInput
          id="email"
          label={translate('family_signup_signup_email_label')}
          value={email}
          errorMessage={emailErrorMessage}
          onChange={setEmail}
          inputType="text"
          placeholder={translate('family_signup_signup_email_placeholder')}
          handleFormatValidation={validateEmailFormat}
        />
        <FormInput
          id="password"
          label={translate('family_signup_signup_password_label')}
          value={password}
          errorMessage={passwordErrorMessage}
          onChange={setPassword}
          inputType="password"
          placeholder={translate('family_signup_signup_password_placeholder')}
          handleFormatValidation={validatePasswordFormat}
        />

        {globalErrorMessage && (
          <div className="blueberry-family-panel__error">
            {globalErrorMessage}
          </div>
        )}
        <div className="signup-form__login-next-button">
          <FormButton
            text={translate('family_signup_next')}
            onClick={handleSignup}
          />
        </div>

        <div className="signup-form__terms">
          <span className="signup-form__terms-text">
            {translate('family_signup_terms_text')}
          </span>
          <span
            className="signup-form__terms-link"
            onClick={() => setShowTermsPopup(true)}
          >
            {translate('family_signup_terms_link')}.
          </span>
        </div>

        <div className="blueberry-family-panel__actions signup-form__login-actions">
          <span className="signup-form__login-text">
            {translate('family_signup_account')}
          </span>
          <span className="signup-form__login-link" onClick={onCancel}>
            {translate('family_signup_login')}
          </span>
        </div>

        {showTermsPopup && (
          <FormPopup
            aditionalClass="signup-form__terms-popup"
            title="Términos y condiciones"
            actions={
              <FormButton
                text={translate('family_signup_back')}
                onClick={() => setShowTermsPopup(false)}
              />
            }
          >
            <div className="terms__scroll-text-wrapper">
              <div className="terms__scroll-text">
                <TermsText lang={getCurrentLangString()} />
              </div>
            </div>
          </FormPopup>
        )}
      </BlueberryFamilyPanel>
    </div>
  )
}

export default SignupForm
