import React, { useEffect, useState } from 'react'
import './mapOptionsBar.scss'
import AvatarIcon from '../AvatarIcon/AvatarIcon'
import { useSelector } from 'react-redux'
import { ReactComponent as SoundBtn } from '../../../assets/img/metaberry_ui/sound_on.svg'
import { ReactComponent as DevBtn } from '../../../assets/img/metaberry_ui/book.svg'
import { ReactComponent as UserIcon } from '../../../assets/img/metaberry_ui/user.svg'
import { store } from '../../../store/configureStore'
import { setIsGameKeyboardBlocked } from '../../../store/MetaberrySlice/MetaberrySlice'
import MapUserMenuModal from '../MapUserMenuModal/MapUserMenuModal'
import MapSearchMenuModal from '../MapSearchMenuModal/MapSearchMenu'
import MetaLogoutModal from '../MetaLogoutModal/MetaLogoutModal'
import MapSoundMenu from '../MapSoundMenu/MapSoundMenu'
import { Analysis } from '../../../services/analysisService'
import { getDevMode } from '../../../services/settingsService'
import translate from '../../../i18n/translate'

const MapOptionsBar = ({
  handleGoToAvatarSelector,
  onCloseAvatarSelector,
  playerUserName,
  playSoundEffect,
  handleToggleMusicConfig,
  musicOn,
  handleToggleEffectsConfig,
  effectsOn,
  pathAssetsSchool
}) => {
  const [showUserMenuModal, setShowUserMenuModal] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [showSoundMenu, setShowSoundMenu] = useState(false)
  const [showSearchMenu, setShowSearchMenu] = useState(false)
  const blueberry_devmode = getDevMode()
  const [avatarsNotificationActive, setAvatarsNotificationActive] =
    useState(false)

  const avatarsNotificationState = useSelector(
    (state) => state.rewards.avatarsNotification
  )

  const onLogoutClick = () => {
    playSoundEffect('uiSettings')
    setShowLogoutModal(!showLogoutModal)
    store.dispatch(setIsGameKeyboardBlocked(!showLogoutModal))
  }

  const onSoundMenuClick = () => {
    playSoundEffect('uiSettings')
    setShowSoundMenu(!showSoundMenu)
  }

  const onSearchActivityClick = () => {
    setShowSearchMenu(!showSearchMenu)
  }

  const onUserMenuClick = () => {
    playSoundEffect('uiSettings')
    setShowUserMenuModal(!showUserMenuModal)
    store.dispatch(setIsGameKeyboardBlocked(!showUserMenuModal))

    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['Menu Clicked'])
  }

  useEffect(() => {
    if (avatarsNotificationState) {
      setAvatarsNotificationActive(true)
    } else {
      setAvatarsNotificationActive(false)
    }
  }, [avatarsNotificationState])

  return (
    <div className="map-options-bar">
      {/* Avatar selector */}
      <div className="map-options-bar__avatars-button">
        {avatarsNotificationActive && (
          <>
            <div className="map-options-bar__avatars-tooltip">
              {translate('info_tooltip_new_avatars')}
            </div>
            <div className="map-options-bar__avatars-button-halo"></div>
            <div className="map-options-bar__avatars-button-exclamation">!</div>
          </>
        )}
        <AvatarIcon onClick={handleGoToAvatarSelector} />
      </div>

      <div className="map-options-bar__wrapper">
        {/* Options menu */}
        <div
          className="map-options-bar__user-menu-button"
          onClick={onUserMenuClick}
        >
          <UserIcon />
          <span>{playerUserName}</span>
        </div>

        {/* Sound options */}
        <div className="map-options-bar__sound-button">
          <SoundBtn onClick={onSoundMenuClick} />
        </div>

        {blueberry_devmode === 1 && (
          <div className="map-options-bar__search-button">
            <DevBtn onClick={onSearchActivityClick} />
          </div>
        )}
      </div>

      {showUserMenuModal && (
        <MapUserMenuModal
          onClose={onUserMenuClick}
          onLogoutClick={onLogoutClick}
        />
      )}

      {showSearchMenu && (
        <MapSearchMenuModal
          onCloseMenu={onSearchActivityClick}
          pathAssetsSchool={pathAssetsSchool}
        />
      )}

      {showSoundMenu && (
        <MapSoundMenu
          onChangeMusicConfig={handleToggleMusicConfig}
          musicOn={musicOn}
          onChangeEffectsConfig={handleToggleEffectsConfig}
          effectsOn={effectsOn}
          onCloseMenu={onSoundMenuClick}
        />
      )}
      {showLogoutModal && <MetaLogoutModal onClose={onLogoutClick} />}
    </div>
  )
}

export default MapOptionsBar
