import {
  createEndPoint,
  errorMessages,
  fetcher,
  fetchNoToken,
  fetchWithToken,
  getToken,
  setToken
} from './util'
import { updateSettings, getSettings } from '../services/settingsService'
import { updateRewardsSettings } from '../services/rewardsService'
import { setFamilyUserToken } from './userTypeService'
import AccessHelper from './util/accessHelper'

export async function getOrganization() {
  return fetchNoToken(createEndPoint('organization')).then((json) => json.data)
}

export function login({ email, password }) {
  if (getToken()) return getToken()

  return fetcher(createEndPoint('login'), {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((json) => {
    if (json.status === 'fail') {
      switch (json.error?.errorKey) {
        case errorMessages.USER_NOT_FOUND:
          throw errorMessages.USER_NOT_FOUND

        case errorMessages.PASS_INCORRECT:
          throw errorMessages.PASS_INCORRECT

        default:
          throw errorMessages.LOGIN_ERROR
      }
    }

    setToken(json.data.token)
    updateSettings(json.data.settings)
    // console.log('cre json.data', json.data)
    // console.log('>>>>>>>>>>>>> login settings', json.data)

    return json.data
  })
}

export function loginProvider(token, provider) {
  if (getToken()) return getToken()
  // console.log('loginProvider', token, provider)

  return fetcher(createEndPoint('login-oauth'), {
    method: 'POST',
    body: JSON.stringify({
      token: token,
      identity_provider: provider
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async (json) => {
    // console.log('loginProvider json', json)

    if (json.status === 'fail') {
      if (json.error.errorKey !== 'EMAIL_NOT_FOUND') {
        throw errorMessages.LOGIN_ERROR
      } else {
        throw errorMessages.USER_NOT_FOUND
      }
    }

    try {
      const settings = await getSettings(json.data.token)
      // console.log('>>>>>>>>>>>>> google login settings', settings)

      updateSettings(settings.data)
      setToken(json.data.token)

      // Solicitar settings recompensas
      const memberRewardsSettings = await fetchWithToken(
        createEndPoint('users/rewards-settings')
      )
      updateRewardsSettings(memberRewardsSettings.data)

      return { ...json.data, settings: settings.data }
    } catch (err) {
      throw errorMessages.LOGIN_ERROR
    }
  })
}

export function loginWithLayers({
  layersSession,
  layersUserId,
  layersCommunityId
}) {
  return fetcher(createEndPoint('login-layers'), {
    method: 'POST',
    body: JSON.stringify({
      layers_session: layersSession,
      layers_user_id: layersUserId,
      layers_community_id: layersCommunityId
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(async (json) => {
      if (json.status === 'fail') {
        if (json.error.errorKey !== 'EMAIL_NOT_FOUND') {
          throw errorMessages.LOGIN_ERROR
        } else {
          throw errorMessages.USER_NOT_FOUND
        }
      }

      return { ...json.data }
    })
    .catch((err) => {
      return {
        error: err
      }
    })
}

export function loginFamilyMember(familyUserGuid) {
  const mustUseMaintoken = true

  return fetch(createEndPoint('users/' + familyUserGuid + '/login'), {
    method: 'POST',
    headers: {
      Authorization: getToken(mustUseMaintoken),
      'Content-Type': 'application/json'
    }
  }).then(async (response) => {
    if (response.status === 'fail') throw errorMessages.LOGIN_ERROR
    const data = (await response.json()).data

    const accessInformation = AccessHelper.getAccessInformation()
    AccessHelper.setAccessInformation(true, accessInformation.accessType)

    setFamilyUserToken(data.token)

    // Solicitar settings usuario
    const memberSettings = await getSettings(data.token)
    updateSettings(memberSettings.data)

    // Solicitar settings recompensas
    const memberRewardsSettings = await fetchWithToken(
      createEndPoint('users/rewards-settings')
    )
    updateRewardsSettings(memberRewardsSettings.data)

    return data
  })
}
