import React, { useState, useRef } from 'react'
import ReactHowler from 'react-howler'
import {
  getEffectsUserConfig,
  updateEffectsUserConfig
} from '../../../../services/settingsService'
import PortalInfo from '../../../../assets/sound/portal-info.mp3'
import UiButton from '../../../../assets/sound/ui-button.mp3'
import UiToggle from '../../../../assets/sound/ui-toggle.mp3'
import UiSettings from '../../../../assets/sound/ui-settings.mp3'
import ControlPanel from '../../../../assets/sound/control-panel.mp3'
import UiTab from '../../../../assets/sound/ui-tab.mp3'
import Slider from '../../../../assets/sound/slider.mp3'
import SpendCash from '../../../../assets/sound/cash-register.mp3'
import Expand from '../../../../assets/sound/expand.mp3'
import ActivityButton from '../../../../assets/sound/activity-button.mp3'
import Correct from '../../../../assets/sound/correct.mp3'
import Incorrect from '../../../../assets/sound/incorrect.mp3'
import Hint from '../../../../assets/sound/hint.mp3'
import PowerUp from '../../../../assets/sound/power-up.mp3'
import LevelUp from '../../../../assets/sound/level-up.mp3'
import LevelMax from '../../../../assets/sound/level-max.mp3'
import NewPiece from '../../../../assets/sound/new-piece.mp3'
import PowerUpQuiz from '../../../../assets/sound/power-up-quiz.wav'
import PowerDownQuiz from '../../../../assets/sound/power-down-quiz.wav'
import ButtonHoverQuiz from '../../../../assets/sound/button-hover-quiz.wav'
import ButtonHoverQuizResult from '../../../../assets/sound/button-hover-quiz-result.wav'
import CollectCoins from '../../../../assets/sound/collect-coins.mp3'
import CollectGear from '../../../../assets/sound/collect-piece.mp3'
import AvatarSelected from '../../../../assets/sound/avatar-selected.mp3'
import PetSelected from '../../../../assets/sound/pet-selected.mp3'

import onboardingFirstUserLevel_es from '../../../components/PopupManager/assets/audio/onboardingFirstUserLevel_es.mp3'
import onboardingFirstUserLevel_pt from '../../../components/PopupManager/assets/audio/onboardingFirstUserLevel_pt.mp3'
import onboardingFirstUserLevel_en from '../../../components/PopupManager/assets/audio/onboardingFirstUserLevel_en.mp3'
import onboardingFirstBatteryCharged_es from '../../../components/PopupManager/assets/audio/onboardingFirstBatteryFullyCharged_es.mp3'
import onboardingFirstBatteryCharged_pt from '../../../components/PopupManager/assets/audio/onboardingFirstBatteryFullyCharged_pt.mp3'
import onboardingFirstBatteryCharged_en from '../../../components/PopupManager/assets/audio/onboardingFirstBatteryFullyCharged_en.mp3'
import onboardingFirstBatteryComplete_es from '../../../components/PopupManager/assets/audio/onboardingFirstBatteryComplete_es.mp3'
import onboardingFirstBatteryComplete_pt from '../../../components/PopupManager/assets/audio/onboardingFirstBatteryComplete_pt.mp3'
import onboardingFirstBatteryComplete_en from '../../../components/PopupManager/assets/audio/onboardingFirstBatteryComplete_en.mp3'
import onboardingFirstBatteryCompletePieces_es from '../../../components/PopupManager/assets/audio/onboardingFirstBatteryCompletePieces_es.mp3'
import onboardingFirstBatteryCompletePieces_pt from '../../../components/PopupManager/assets/audio/onboardingFirstBatteryCompletePieces_pt.mp3'
import onboardingFirstBatteryCompletePieces_en from '../../../components/PopupManager/assets/audio/onboardingFirstBatteryCompletePieces_en.mp3'
import onboardingUnlockedUnit_es from '../../../components/PopupManager/assets/audio/onboardingUnlockedUnit_es.mp3'
import onboardingUnlockedUnit_pt from '../../../components/PopupManager/assets/audio/onboardingUnlockedUnit_pt.mp3'
import onboardingUnlockedUnit_en from '../../../components/PopupManager/assets/audio/onboardingUnlockedUnit_en.mp3'
import onboardingUnlockedLesson_es from '../../../components/PopupManager/assets/audio/onboardingUnlockedLesson_es.mp3'
import onboardingUnlockedLesson_pt from '../../../components/PopupManager/assets/audio/onboardingUnlockedLesson_pt.mp3'
import onboardingUnlockedLesson_en from '../../../components/PopupManager/assets/audio/onboardingUnlockedLesson_en.mp3'
import onboardingFirstPiece_es from '../../../components/PopupManager/assets/audio/onboardingFirstPiece_es.mp3'
import onboardingFirstPiece_pt from '../../../components/PopupManager/assets/audio/onboardingFirstPiece_pt.mp3'
import onboardingFirstPiece_en from '../../../components/PopupManager/assets/audio/onboardingFirstPiece_en.mp3'
import onboardingBrokenBattery_es from '../../../components/PopupManager/assets/audio/onboardingBrokenBattery_es.mp3'
import onboardingBrokenBattery_en from '../../../components/PopupManager/assets/audio/onboardingBrokenBattery_en.mp3'
import onboardingBrokenBattery_pt from '../../../components/PopupManager/assets/audio/onboardingBrokenBattery_pt.mp3'

import rewardUnlockObject_es from '../../../../assets/sound/rewards/unlock-object-es.mp3'
import rewardUnlockObject_en from '../../../../assets/sound/rewards/unlock-object-en.mp3'
import rewardUnlockObject_pt from '../../../../assets/sound/rewards/unlock-object-pt.mp3'
import rewardDailyGoal_es from '../../../../assets/sound/rewards/daily-goal-es.mp3'
import rewardDailyGoal_en from '../../../../assets/sound/rewards/daily-goal-en.mp3'
import rewardDailyGoal_pt from '../../../../assets/sound/rewards/daily-goal-pt.mp3'

import intro01_es from '../../../components/PopupManager/assets/audio/intro01_es.mp3'
import intro01_pt from '../../../components/PopupManager/assets/audio/intro01_pt.mp3'
import intro01_en from '../../../components/PopupManager/assets/audio/intro01_en.mp3'
import intro02_es from '../../../components/PopupManager/assets/audio/intro02_es.mp3'
import intro02_pt from '../../../components/PopupManager/assets/audio/intro02_pt.mp3'
import intro02_en from '../../../components/PopupManager/assets/audio/intro02_en.mp3'
import intro03_es from '../../../components/PopupManager/assets/audio/intro03_es.mp3'
import intro03_pt from '../../../components/PopupManager/assets/audio/intro03_pt.mp3'
import intro03_en from '../../../components/PopupManager/assets/audio/intro03_en.mp3'
import intro04_es from '../../../components/PopupManager/assets/audio/intro04_es.mp3'
import intro04_pt from '../../../components/PopupManager/assets/audio/intro04_pt.mp3'
import intro04_en from '../../../components/PopupManager/assets/audio/intro04_en.mp3'
import intro05_es from '../../../components/PopupManager/assets/audio/intro05_es.mp3'
import intro05_pt from '../../../components/PopupManager/assets/audio/intro05_pt.mp3'
import intro05_en from '../../../components/PopupManager/assets/audio/intro05_en.mp3'

import intro01_es_humans_cartoons from '../../../components/PopupManager/assets/audio/intro01_es_humans_cartoons.mp3'
import intro01_pt_humans_cartoons from '../../../components/PopupManager/assets/audio/intro01_pt_humans_cartoons.mp3'
import intro01_en_humans_cartoons from '../../../components/PopupManager/assets/audio/intro01_en_humans_cartoons.mp3'

import onBoardingFirstMoves01_es from '../../../components/PopupManager/assets/audio/onBoardingFirstMoves01_es.mp3'
import onBoardingFirstMoves01_pt from '../../../components/PopupManager/assets/audio/onBoardingFirstMoves01_pt.mp3'
import onBoardingFirstMoves01_en from '../../../components/PopupManager/assets/audio/onBoardingFirstMoves01_en.mp3'
import onBoardingFirstMoves02_es from '../../../components/PopupManager/assets/audio/onBoardingFirstMoves02_es.mp3'
import onBoardingFirstMoves02_pt from '../../../components/PopupManager/assets/audio/onBoardingFirstMoves02_pt.mp3'
import onBoardingFirstMoves02_en from '../../../components/PopupManager/assets/audio/onBoardingFirstMoves02_en.mp3'

export default function useSound() {
  const EFFECTS_VOLUME = 0.8
  const EFFECTS = {
    portalInfo: PortalInfo,
    uiButton: UiButton,
    uiToggle: UiToggle,
    uiSettings: UiSettings,
    controlPanel: ControlPanel,
    uiTab: UiTab,
    slider: Slider,
    spendCash: SpendCash,
    expand: Expand,
    activityButton: ActivityButton,
    correct: Correct,
    incorrect: Incorrect,
    hint: Hint,
    powerUp: PowerUp,
    levelUp: LevelUp,
    levelMax: LevelMax,
    newPiece: NewPiece,
    collectCoins: CollectCoins,
    collectGear: CollectGear,
    intro01_es: intro01_es,
    intro01_pt: intro01_pt,
    intro01_en: intro01_en,
    intro02_es: intro02_es,
    intro02_pt: intro02_pt,
    intro02_en: intro02_en,
    intro03_es: intro03_es,
    intro03_pt: intro03_pt,
    intro03_en: intro03_en,
    intro04_es: intro04_es,
    intro04_pt: intro04_pt,
    intro04_en: intro04_en,
    intro05_es: intro05_es,
    intro05_pt: intro05_pt,
    intro05_en: intro05_en,
    onBoardingFirstMoves01_es: onBoardingFirstMoves01_es,
    onBoardingFirstMoves01_pt: onBoardingFirstMoves01_pt,
    onBoardingFirstMoves01_en: onBoardingFirstMoves01_en,
    onBoardingFirstMoves02_es: onBoardingFirstMoves02_es,
    onBoardingFirstMoves02_pt: onBoardingFirstMoves02_pt,
    onBoardingFirstMoves02_en: onBoardingFirstMoves02_en,
    onboardingFirstUserLevel_es: onboardingFirstUserLevel_es,
    onboardingFirstUserLevel_pt: onboardingFirstUserLevel_pt,
    onboardingFirstUserLevel_en: onboardingFirstUserLevel_en,
    onboardingFirstBatteryCharged_es: onboardingFirstBatteryCharged_es,
    onboardingFirstBatteryCharged_pt: onboardingFirstBatteryCharged_pt,
    onboardingFirstBatteryCharged_en: onboardingFirstBatteryCharged_en,
    onboardingFirstBatteryComplete_es: onboardingFirstBatteryComplete_es,
    onboardingFirstBatteryComplete_pt: onboardingFirstBatteryComplete_pt,
    onboardingFirstBatteryComplete_en: onboardingFirstBatteryComplete_en,
    onboardingFirstBatteryCompletePieces_es:
      onboardingFirstBatteryCompletePieces_es,
    onboardingFirstBatteryCompletePieces_pt:
      onboardingFirstBatteryCompletePieces_pt,
    onboardingFirstBatteryCompletePieces_en:
      onboardingFirstBatteryCompletePieces_en,
    onboardingUnlockedUnit_es: onboardingUnlockedUnit_es,
    onboardingUnlockedUnit_pt: onboardingUnlockedUnit_pt,
    onboardingUnlockedUnit_en: onboardingUnlockedUnit_en,
    onboardingUnlockedLesson_es: onboardingUnlockedLesson_es,
    onboardingUnlockedLesson_pt: onboardingUnlockedLesson_pt,
    onboardingUnlockedLesson_en: onboardingUnlockedLesson_en,
    onboardingFirstPiece_es: onboardingFirstPiece_es,
    onboardingFirstPiece_pt: onboardingFirstPiece_pt,
    onboardingFirstPiece_en: onboardingFirstPiece_en,
    onboardingBrokenBattery_es: onboardingBrokenBattery_es,
    onboardingBrokenBattery_pt: onboardingBrokenBattery_pt,
    onboardingBrokenBattery_en: onboardingBrokenBattery_en,
    rewardUnlockObject_es: rewardUnlockObject_es,
    rewardUnlockObject_en: rewardUnlockObject_en,
    rewardUnlockObject_pt: rewardUnlockObject_pt,
    rewardDailyGoal_es: rewardDailyGoal_es,
    rewardDailyGoal_en: rewardDailyGoal_en,
    rewardDailyGoal_pt: rewardDailyGoal_pt,
    powerUpQuiz: PowerUpQuiz,
    powerDownQuiz: PowerDownQuiz,
    buttonHoverQuiz: ButtonHoverQuiz,
    buttonHoverQuizResult: ButtonHoverQuizResult,
    avatarSelected: AvatarSelected,
    petSelected: PetSelected,
    intro01_es_humans_cartoons,
    intro01_pt_humans_cartoons,
    intro01_en_humans_cartoons
  }

  const howlerFXRef = useRef(null)
  const [soundFile, setSoundFile] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const toggleSoundEffects = () => {
    if (getEffectsUserConfig() === 1) {
      updateEffectsUserConfig(0)
    } else {
      updateEffectsUserConfig(1)
    }
  }

  const playSoundEffect = (type) => {
    if (getEffectsUserConfig() === 1) {
      setSoundFile(EFFECTS[type])
      setIsPlaying(true)
    }
  }

  const renderSoundFXController = () => {
    return (
      <div style={{ display: 'none' }}>
        {soundFile && (
          <ReactHowler
            ref={howlerFXRef}
            src={soundFile}
            playing={isPlaying}
            volume={EFFECTS_VOLUME}
            loop={false}
            onPlay={() => {}}
            onLoad={() => setIsPlaying(true)}
            onEnd={() => setIsPlaying(false)}
            onLoadError={() => console.error('Sound FX load error')}
            onPlayError={(id, errorCode) =>
              console.error('Music play error: ', errorCode)
            }
          />
        )}
      </div>
    )
  }

  return {
    renderSoundFXController,
    playSoundEffect,
    toggleSoundEffects
  }
}
