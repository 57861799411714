import Hotjar from '@hotjar/browser'
import AnalyticsMiddleware from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import AccessHelper from './util/accessHelper'
import currentDate from '../util/currentDate'
import { isQAMode } from './qaModeService'
import { getSettings } from './settingsService'
import { getWhoAmI } from './courseInfoService'
import { getFamilyUserToken } from './userTypeService'

const HOTJAR_HOSTNAMES = [
  'family.blueberrymath.ai',
  'school.blueberrymath.ai',
  'school.blueberrymath.com',
  'escola.blueberrymath.ai',
  'escuela.blueberrymath.ai',
  'blueberry-sa.oneclicklabs.es',
  'blueberry.oneclick.es'
]
const IS_HOTJAR_ENVIRONMENT = HOTJAR_HOSTNAMES.includes(
  window.location.hostname
)
console.log('HJ. ENV.:', IS_HOTJAR_ENVIRONMENT)

const IS_EDITOR_LEMONADE_PREVIEW =
  window.location.href.indexOf('/__lemonade-preview') !== -1
if (IS_EDITOR_LEMONADE_PREVIEW) {
  console.log('NO ANALYSIS FOR L.P.')
}

// -- Hotjar constants ----------------------------------------------------
const HOTJAR_SITE_ID = 3546455
const HOTJAR_VERSION = 6

// -- Google analytics constants -------------------------------------------
const GOOGLE_ANALYTICS_ID = 'G-2VKV4YLBY1' // 'G-0DHZWH6V28'

export const Analysis = {
  // --- MIXED ----------------------------------------------------------------
  EVENT: {
    LOGIN: 'login', // 0
    LOGIN_COMPLETE: 'login_complete', // 0
    START_MAP: 'start_map', // 1
    ENTER_UNIT: 'enter_unit', // 2
    EXIT_UNIT: 'exit_unit', // 3
    OPEN_RANKING: 'open_ranking', // 4
    CLOSE_RANKING: 'close_ranking', // 5
    EXPERIENCE: 'experience', // 6
    MUSIC_ON: 'music_on', // 7
    MUSIC_OFF: 'music_off', // 8
    EFX_ON: 'efx_on', // 9
    EFX_OFF: 'efx_off', // 10
    UI_LIGHT: 'ui_light', // 11
    UI_DARK: 'ui_dark', // 12
    JOYSTICK_ON: 'joystick_on', // 13
    JOYSTICK_OFF: 'joystick_off', // 14
    CHANGE_AVATAR: 'change_avatar', // 15
    LOGOUT: 'logout', // 16
    START_ACTIVITY_SCREEN: 'start_act_screen', // 17
    LEAVE_ACTIVITY_SCREEN: 'leave_act_screen', // 18
    START_EXERCISE: 'start_exercise', // 19
    FINISH_EXERCISE: 'finish_exercise', // 20
    HINT: 'hint', // 21
    CHECK: 'check', // 22
    SSO_ACCESS_COMPLETE: 'sso_access_complete', // 23
    ACTIVITY_ENDS_CHOOSE_KEEP_PRACTICING:
      'activity_ends_choose_keep_practicing', // 24
    ACTIVITY_ENDS_CHOOSE_GO_TO_MAP: 'activity_ends_choose_go_to_map', // 25
    REQUEST_TEXT_TO_SPEECH: 'request_text_to_speech', // 26
    DAILY_GOAL: 'daily_goal_completed', // 27
    RECOVER_PASSWORD_SCREEN: 'recover_password_screen',
    RECOVER_PASSWORD: 'recover_password',
    GO_TO_MAP_FROM_CUSTOM_SSO: 'go_to_map_from_custom_sso',
    SIGN_UP_ADD_PLAYER_SCREEN: 'sign_up_add_player_screen',
    SIGN_UP: 'sign_up',
    WELCOME_SCREEN: 'welcome_screen',
    UNSUPPORTED_SCREEN: 'unsupported_screen',
    PROFILE_SCREEN: 'profile_screen',
    CHOOSE_PLAYER_SCREEN: 'choose_player_screen',
    GO_TO_SWITCH_PLAYER: 'go_to_switch_player',
    ADD_PLAYER: 'add_player',
    REMOVE_PLAYER: 'remove_player',
    EDIT_PLAYER: 'edit_player',
    CHOOSE_PLAYER: 'choose_player',
    SWITCH_PLAYER: 'switch_player',
    CONTACT_REQUEST: 'contact_request',
    GO_TO_SUBSCRIPTION_PLANS: 'go_to_subscription_plans_screen',
    SUBSCRIPTION_PLANS_SCREEN: 'subscription_plans_screen',
    GO_TO_MANAGE_SUBSCRIPTION: 'go_to_manage_subscription',
    SUBSCRIPTON_ACTION_OUTSIDE: 'subscription_action_outside',
    SIGN_UP_LICENSE_SCREEN: 'sign_up_license_screen',
    SIGN_UP_FORM_SCREEN: 'sign_up_form_screen',
    SIGN_UP_TERMS_SCREEN: 'sign_up_terms_screen',
    SELECT_PET: 'select_pet',
    REMOVE_PET: 'remove_pet',
    COINS_EARNED: 'coins_earned',
    PET_PURCHASED: 'pet_purchased',
    MISSION_POP_UP_VIEWED: 'Mission Pop up Viewed',
    MISSION_POP_UP_OPENED: 'Mission Pop up Opened',
    PRACTICE_POP_UP_VIEWED: 'Practice Pop up Viewed',
    PRACTICE_POP_UP_OPENED: 'Practice Pop up Opened',
    PRACTICE_LOBBY_OPENED: 'Practice Lobby Opened',
    PRACTICE_ACTIVITY_VIEWED: 'Practice Activity Viewed',
    PRACTICE_ACTIVITY_ANSWERED: 'Practice Activity Answered',
    PRACTICE_ABORTED_POP_UP_VIEWED: 'Practice Aborted Pop Up Viewed',
    PRACTICE_POSITION_VIEWED: 'Practice Position Viewed',
    PRACTICE_EXITED: 'Practice Exited'
  },

  isUserSetted: false,

  currentUserData: {},

  isEditorLemonadePreview: () => IS_EDITOR_LEMONADE_PREVIEW === true,

  // Si no está inicializado se considera que no es demo (no se puede saber...)
  isDemoUser: () => {
    return Analysis.isUserSetted && Analysis.currentUserData.is_demo_user === 1
  },

  setUser: async (userData) => {
    if (
      !isQAMode() &&
      !Analysis.isUserSetted &&
      userData.is_demo_user !== 1 &&
      !IS_EDITOR_LEMONADE_PREVIEW
    ) {
      // Obtener información sobre el origen del usuario ("desde donde vino a BB")
      const mainUserSettings = await getSettings(undefined, true)
      // console.log('- mainUserSettings:', mainUserSettings)
      const analyticsMetadata = mainUserSettings?.data?.analyticsMetadata
      const analyticsObject = {}
      const sourceOfUser = mainUserSettings?.data?.sourceOfUser
      if (analyticsMetadata) {
        Object.keys(analyticsMetadata).forEach((key) => {
          analyticsObject['analytics_metadata_' + key] = analyticsMetadata[key]
        })
      }

      const _userData = {
        ...userData,
        ...analyticsObject,
        source_of_user: sourceOfUser,
        hostname: window.location.hostname
      }

      if (_userData.session) {
        Object.keys(_userData.session).forEach((key) => {
          _userData['session_' + key] = _userData.session[key]
        })

        delete _userData.session
      }

      const accessInformation = AccessHelper.getAccessInformation()
      const accessType = accessInformation
        ? accessInformation.accessType
        : 'error_getting_acces_type'

      Analysis.currentUserData = { ..._userData, access_type: accessType }

      try {
        Analysis.setSegmentUser(_userData)
      } catch (segmentError) {
        console.error(segmentError)
      }

      try {
        if (!window.isReactiveNativeApp()) {
          Analysis.initializeHotjar(_userData)
          console.log('HJ. initialized')
          Analysis.setHotjarUser(_userData)
        }
      } catch (hotjarError) {
        console.error(hotjarError)
      }

      try {
        Analysis.setGoogleAnalyticsUser(_userData)
      } catch (googleAnalyticsError) {
        console.error(googleAnalyticsError)
      }

      Analysis.isUserSetted = true
    } else if (
      !isQAMode() &&
      !Analysis.isUserSetted &&
      userData.is_demo_user === 1
    ) {
      Analysis.currentUserData = { is_demo_user: userData.is_demo_user }
      Analysis.isUserSetted = true
    }
  },

  clearUser: () => {
    if (!isQAMode() && Analysis.isUserSetted && !IS_EDITOR_LEMONADE_PREVIEW) {
      try {
        Analysis.clearHotjarUser()
      } catch (hotjarError) {
        console.error(hotjarError)
      }

      try {
        Analysis.clearGoogleAnalyticsUser()
      } catch (googleAnalyticsError) {
        console.error(googleAnalyticsError)
      }

      Analysis.currentUserData = {}
      Analysis.isUserSetted = false
    }
  },

  sendEvent: async (eventName, eventProperties) => {
    // Algunos eventos pueden mandarse sin usuario
    if (!isQAMode() && !Analysis.isDemoUser() && !IS_EDITOR_LEMONADE_PREVIEW) {
      console.log('- sEvent')

      const accessInformation = AccessHelper.getAccessInformation()
      const accessType = accessInformation
        ? accessInformation.accessType
        : 'error_getting_acces_type'

      const propertiesPlusAccesType = {
        ...eventProperties,
        access_type: accessType,
        when: currentDate()
      }

      // Si no hay usuario inicializado se trata de obtener su guid con whoAmI
      // No siempre será posible; por ejemplo eventos anteriores al login
      try {
        const auxUserData = Analysis?.currentUserData
        if (Object.keys(auxUserData).length === 0 && getFamilyUserToken()) {
          const whoAmI = await getWhoAmI(true)
          propertiesPlusAccesType.user_id = whoAmI?.guid ? whoAmI.guid : ''
        }
      } catch (guidError) {
        console.error(guidError)
      }

      try {
        Analysis.sendHotjarEvent(eventName)
      } catch (hotjarError) {
        console.error(hotjarError)
      }

      try {
        Analysis.sendGoogleAnalyticsEvent(eventName, propertiesPlusAccesType)
      } catch (hotjarError) {
        console.error(hotjarError)
      }
    } else if (isQAMode()) {
      console.log('- QA Mode active')
    }
  },

  // --- HOTJAR ----------------------------------------------------------------
  isHotjarInitialized: false,

  isHotjarUserSetted: false,

  initializeHotjar: (userData) => {
    if (
      window.isProductionEnvironment &&
      IS_HOTJAR_ENVIRONMENT &&
      !isQAMode() &&
      !IS_EDITOR_LEMONADE_PREVIEW &&
      userData.is_demo_user !== 1
    ) {
      try {
        if (!Analysis.isHotjarInitialized) {
          Hotjar.init(HOTJAR_SITE_ID, HOTJAR_VERSION)
          Analysis.isHotjarInitialized = true
        }
      } catch (hotjarError) {
        console.error(hotjarError)
      }
    }
  },

  setHotjarUser: (userData) => {
    if (
      Analysis.isHotjarInitialized &&
      window.isProductionEnvironment &&
      IS_HOTJAR_ENVIRONMENT &&
      !isQAMode() &&
      userData.is_demo_user !== 1 &&
      !IS_EDITOR_LEMONADE_PREVIEW
    ) {
      Hotjar.identify(userData.user_id, userData)
      Analysis.isHotjarUserSetted = true
    }
  },

  clearHotjarUser: () => {
    if (
      Analysis.isHotjarInitialized &&
      window.isProductionEnvironment &&
      IS_HOTJAR_ENVIRONMENT &&
      !isQAMode() &&
      !IS_EDITOR_LEMONADE_PREVIEW
    ) {
      Analysis.isHotjarUserSetted = false
    }
  },

  sendHotjarEvent: (eventName) => {
    if (
      Analysis.isHotjarInitialized &&
      window.isProductionEnvironment &&
      IS_HOTJAR_ENVIRONMENT &&
      !isQAMode() &&
      !Analysis.isDemoUser() &&
      !IS_EDITOR_LEMONADE_PREVIEW
    ) {
      Hotjar.event(eventName)
    }
  },

  // --- GOOGLE ANALYTICS

  googleAnalyticsObject: null,
  isGoogleAnalyticsUserSetted: false,

  // Se inicializa con script en el html si entorno de producción
  initializeGoogleAnalytics: () => {
    if (
      window.isProductionEnvironment &&
      !isQAMode() &&
      !IS_EDITOR_LEMONADE_PREVIEW
    ) {
      Analysis.googleAnalyticsObject = AnalyticsMiddleware({
        app: 'Blueberry',
        plugins: [
          googleAnalytics({
            measurementIds: [GOOGLE_ANALYTICS_ID]
          })
        ]
      })
    }
  },

  setGoogleAnalyticsUser: (userData) => {
    if (
      window.isProductionEnvironment &&
      !isQAMode() &&
      Analysis.googleAnalyticsObject &&
      userData.is_demo_user !== 1 &&
      !IS_EDITOR_LEMONADE_PREVIEW
    ) {
      Analysis.googleAnalyticsObject.identify(userData.user_id, userData)
      Analysis.isGoogleAnalyticsUserSetted = true
    }
  },

  clearGoogleAnalyticsUser: () => {
    if (
      window.isProductionEnvironment &&
      !isQAMode() &&
      Analysis.googleAnalyticsObject &&
      !IS_EDITOR_LEMONADE_PREVIEW
    ) {
      Analysis.isGoogleAnalyticsUserSetted = false
    }
  },

  sendGoogleAnalyticsEvent: (eventName, eventProperties) => {
    if (
      window.isProductionEnvironment &&
      !isQAMode() &&
      !Analysis.isDemoUser() &&
      !IS_EDITOR_LEMONADE_PREVIEW
    ) {
      Analysis.googleAnalyticsObject.track(eventName, eventProperties)
    }
  },

  // Segment ------------------------------------------------------
  /* eslint-disable */
  SEGMENT_PAGE_CATEGORIES: {
    Signup: 'Signup',
    Signin: 'Signin',
    Start: 'Start',
    Game: 'Game',
    Error: 'Error',
    Subscription: 'Subscription'
  },

  SEGMENT_EVENTS: {
    'Sign Up License Viewed': 'Sign Up License Viewed',
    'Sign Up Form Viewed': 'Sign Up Form Viewed',
    'Sign Up Terms And Conditions Viewed':
      'Sign Up Terms And Conditions Viewed',
    'Signup Page Add Players Viewed': 'Signup Page Add Players Viewed',
    'Log In Form Viewed': 'Log In Form Viewed',
    'Select Player Page Viewed': 'Select Player Page Viewed',
    'Challenge Intro Page Viewed': 'Challenge Intro Page Viewed',
    'Activity Viewed': 'Activity Viewed',
    'Activity Feedback Viewed': 'Activity Feedback Viewed',
    'Show Solution Clicked': 'Show Solution Clicked',
    'See My Answer Clicked': 'See My Answer Clicked',
    'Keep Practicing Button Clicked': 'Keep Practicing Button Clicked',
    'Exit Practice Button Clicked': 'Exit Practice Button Clicked',
    'Hint Clicked': 'Hint Clicked',
    'Practice Session Aborted': 'Practice Session Aborted',
    'World Entered': 'World Entered',
    'Unit Entered': 'Unit Entered',
    'Unit Exited': 'Unit Exited',
    'Error Page 404': 'Error Page 404',
    'Error Page 405': 'Error Page 405',
    'Error Page 500': 'Error Page 500',
    'Menu Clicked': 'Menu Clicked',
    'Pricing Page Viewed': 'Pricing Page Viewed',
    'Subscription Error Page': 'Subscription Error Page',
    'Change Player Page Viewed': 'Change Player Page Viewed',
    'Select Player': 'Select Player',
    'Player Changed': 'Player Changed',
    'Contact Clicked': 'Contact Clicked',
    'Pet Selector Page Viewed': 'Pet Selector Page Viewed',
    'Pet Popup Button Clicked': 'Pet Popup Button Clicked',
    'Pet Removed': 'Pet Removed',
    'Lesson Practice Popup Viewed': 'Lesson Practice Popup Viewed',
    'Unit Popup Viewed': 'Unit Practice Popup Viewed',
    'Pet Alert Notification Showed': 'Pet Alert Notification Showed',
    'Daily Goal Icon Clicked': 'Daily Goal Icon Clicked',
    'Coin Icon Clicked': 'Coin Icon Clicked',
    'Level Icon Clicked': 'Level Icon Clicked',
    'Avatar Selector Page Viewed': 'Avatar Selector Page Viewed',
    'Map Zoomed Clicked': 'Map Zoomed Clicked',
    'Daily Goal Popup Viewed': 'Daily Goal Popup Viewed',
    'Unit Where Button Clicked': 'Unit Where Button Clicked',
    'Lesson Where Button Clicked': 'Lesson Where Button Clicked',
    //------

    'Signup Page Welcome': 'Signup Page Welcome',
    'Map Page': 'Map Page',
    Onboarding: 'Onboarding',
    'Select Avatar': 'Select Avatar',
    'Activity Page': 'Activity Page',
    'Start Page': 'Start Page',
    'Error Page Expired License': 'Error Page Expired License',
    'Subscription Success Page': 'Subscription Success Page',
    'Subscribe From Trial': 'Subscribe From Trial',
    'Manage Subscription': 'Manage Subscription',
    'Go To Change Player': 'Go To Change Player',
    'Open My Profile': 'Open My Profile',
    'View FAQ': 'View FAQ',
    'Enter Lesson Clicked': 'Enter Lesson Clicked',
    'Logout Button Click': 'Logout Button Click',
    'TTS Clicked': 'TTS Clicked',
    'Missions Notification Showed': 'Missions Notification Showed',
    'Missions Page Viewed': 'Missions Page Viewed',
    'Daily Reward Available': 'Daily Reward Available',
    'Daily Reward Collected': 'Daily Reward Collected',
    'Activity Issue Reported': 'Activity Issue Reported',
    'Device Not Supported Page Viewed': 'Device Not Supported Page Viewed',
    'Contest Page Viewed': 'Contest Page Viewed',
    'Error Notification': 'Error Notification'
  },
  /* eslint-enable */

  setSegmentUser: (userData) => {
    try {
      if (
        !isQAMode() &&
        !Analysis.isDemoUser() &&
        !IS_EDITOR_LEMONADE_PREVIEW &&
        window.isProductionEnvironment
      ) {
        // eslint-disable-next-line no-undef
        analytics.identify(userData.user_id, userData)
      }
    } catch (segmentError) {
      console.error(segmentError)
    }
  },

  sendSegmentPageEvent: (pageCategory, pageName, pageProperties) => {
    try {
      if (
        !isQAMode() &&
        !Analysis.isDemoUser() &&
        !IS_EDITOR_LEMONADE_PREVIEW &&
        window.isProductionEnvironment
      ) {
        // eslint-disable-next-line no-undef
        analytics.page(pageCategory, pageName, pageProperties)
      } else {
        console.log(
          'Segment page [' + pageCategory + '][' + pageName + ']',
          pageProperties
        )
      }
    } catch (segmentError) {
      console.error(segmentError)
    }
  },

  sendSegmentTrackEvent: (eventName, eventProperties) => {
    // analytics.track(event, [properties], [options], [callback]);
    //   event      String              The name of the event you’re tracking. You can read more about the track method and recommended event names.
    //   properties Object    Optional. A dictionary of properties for the event. If the event was 'Added to Cart', it might have properties like price and productType.
    //   options    Object    Optional. A dictionary of options. For example, enable or disable specific destinations for the call. Note: If you do not pass a properties object, pass an empty object (like ‘{}’) before options.
    //   callback   Function  Optional. A function that runs after a timeout of 300 ms, giving the browser time to make outbound requests first.
    try {
      if (
        !isQAMode() &&
        !Analysis.isDemoUser() &&
        !IS_EDITOR_LEMONADE_PREVIEW &&
        window.isProductionEnvironment
      ) {
        // eslint-disable-next-line no-undef
        analytics.track(eventName, eventProperties)
      } else {
        console.log('Segment track [' + eventName + ']', eventProperties)
      }
    } catch (segmentError) {
      console.error(segmentError)
    }
  }
}
