import React from 'react'
import coinImg from '../../../../assets/img/metaberry_ui/coin.svg'
import './ShopCoinsCounter.scss'

// Variants: default, available, locked, expensive

const ShopCoinsCounter = ({ totalCoins, variant }) => {
  return (
    <div
      className={`shop-coins-counter shop-coins-counter--${
        variant || 'default'
      }`}
    >
      <div className="shop-coins-counter__text">{totalCoins}</div>
      <div className="shop-coins-counter__img">
        <img src={coinImg} alt="" />
      </div>
    </div>
  )
}

export default ShopCoinsCounter
