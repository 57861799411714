import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import translate from '../../../i18n/translate'
import ModalSimple from '../ModalSimple/ModalSimple'

function ModalExitTeacherPractice({
  onCloseTeacherPractice = () => {},
  showModal = false
}) {
  const [pathSchool] = useState(
    useSelector((state) => state.configuration.pathSchool)
  )
  const [blueberroError, setBlueberroError] = useState()

  useEffect(() => {
    setBlueberroError(
      pathSchool
        ? `/assets/graphics/gamification/${pathSchool}/error_500.svg`
        : `/assets/graphics/gamification/blueberro_error.svg`
    )
  }, [])

  return (
    <ModalSimple
      showModal={showModal}
      onCloseComplete={onCloseTeacherPractice}
      modalTopIcon={blueberroError}
      title={translate('teacher_practice_exit_title')}
      message={translate('teacher_practice_exit_subtitle')}
      buttonText={translate('teacher_practice_exit_button')}
      customClass={'modal-teacher-practice-exit'}
    ></ModalSimple>
  )
}

export default ModalExitTeacherPractice
