import React, { useCallback, useEffect, useState } from 'react'
import translate from '../../../../i18n/translate'
import InputWithLabel from '../InputWithLabel/InputWithLabel'
import ToggleSwitch from '../../../../modules/atoms/ToogleSwitch/ToggleSwitch'
import InlineButton from '../../../../modules/atoms/InlineButton/InlineButton'

function LoginForm({
  onSubmit,
  error,
  isWaitingFromServerResponse,
  isBlueberryFamily,
  errorUser = false
}) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberUser, setRememberUser] = useState(false)
  const errorClass = 'error'
  const isFormError = error ? errorClass : ''
  const [isDataValid, setIsDataValid] = useState(false)
  const [errorUserText, setErrorUserText] = useState()
  const [errorPassText, setErrorPassText] = useState()

  const checkValidData = () => {
    setIsDataValid(email && password)
  }

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit({ email, password })
    },
    [onSubmit, email, password]
  )

  useEffect(() => {
    checkValidData()
  }, [email, password])

  useEffect(() => {
    if (errorUser) {
      setErrorUserText(error)
    } else {
      setErrorPassText(error)
    }
  }, [error])

  return (
    <div className={'login-form ' + isFormError}>
      <form>
        <div className="form-group">
          <InputWithLabel
            id="email"
            label={translate(isBlueberryFamily ? 'email' : 'user')}
            value={email}
            isError={errorUserText ? errorClass : ''}
            onChange={(value) => {
              setEmail(value)
              setErrorUserText('')
            }}
            inputType="email"
            placeholder={translate(
              isBlueberryFamily ? 'email_placeholder' : 'username'
            )}
            disabled={isWaitingFromServerResponse}
          />
          {errorUserText && (
            <div className="login-form_error-message">{errorUserText}</div>
          )}
        </div>

        <div className="form-group">
          <InputWithLabel
            id="password"
            label={translate('password')}
            value={password}
            isError={errorPassText ? errorClass : ''}
            onChange={(value) => {
              setPassword(value)
              setErrorPassText('')
            }}
            inputType="password"
            placeholder={translate('password')}
            disabled={isWaitingFromServerResponse}
          />
          {errorPassText && (
            <div className="login-form_error-message">{errorPassText}</div>
          )}
        </div>

        <div className="form-group double-column">
          <div style={{ display: 'none' }}>
            <ToggleSwitch
              onChange={setRememberUser}
              value={rememberUser}
              label={translate('remember_user')}
            />
          </div>

          <InlineButton
            className="forgot"
            href="/recover-password"
            label={translate('recover_password')}
          />
        </div>

        <div className="submit-control">
          <button
            type="submit"
            className="btn"
            onClick={handleSubmit}
            disabled={isWaitingFromServerResponse || !isDataValid}
          >
            {translate('login_next')}
          </button>
        </div>
      </form>
    </div>
  )
}

export default LoginForm
