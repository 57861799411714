import React, { useEffect, useRef } from 'react'
import Modal from '../Modal/Modal'
import translate from '../../../i18n/translate'
import PracticeButton from '../../../pages/AppPracticePage/modules/atoms/PracticeButton/PracticeButton'

import Cloze_math from '../../../assets/img/lemonadeOnboarding/1644-cloze-math.gif'
import Cloze_with_drop_down from '../../../assets/img/lemonadeOnboarding/1646-dropdown.gif'
import Cloze_with_drag_drop from '../../../assets/img/lemonadeOnboarding/1647-drag-and-drop.gif'
import Cloze_with_text from '../../../assets/img/lemonadeOnboarding/1648-cloze-with-text.gif'
import Multiple_choice_standard from '../../../assets/img/lemonadeOnboarding/1645-single-choice.gif'
import Multiple_choice_standard_click from '../../../assets/img/lemonadeOnboarding/1659-click-single.gif'
import Multiple_choice_multiple_response from '../../../assets/img/lemonadeOnboarding/1651-multiple-choice.gif'
import Multiple_choice_multiple_response_click from '../../../assets/img/lemonadeOnboarding/1662-click-multiple.gif'
import Choice_matrix_inline from '../../../assets/img/lemonadeOnboarding/1650-true-or-false-2.gif'
import Match_list from '../../../assets/img/lemonadeOnboarding/5129-new-matchlist.gif'
import Clock_analog from '../../../assets/img/lemonadeOnboarding/1661-clock-analogico.gif'
import Clock_digital from '../../../assets/img/lemonadeOnboarding/1663-clock-digital.gif'
import Counting_count from '../../../assets/img/lemonadeOnboarding/1665-counting-count.gif'
import Counting_add from '../../../assets/img/lemonadeOnboarding/1666-counting-add.gif'
import Counting_substract from '../../../assets/img/lemonadeOnboarding/1667-counting-subtract.gif'
import Pathway from '../../../assets/img/lemonadeOnboarding/1664-pathway.gif'
import Order_list from '../../../assets/img/lemonadeOnboarding/1660-order.gif'
// import Number_line from '../../../assets/img/lemonadeOnboarding/1668-number-line.gif'
import Line_chart from '../../../assets/img/lemonadeOnboarding/2300-linechart-output.gif'
import Bar_chart from '../../../assets/img/lemonadeOnboarding/2299-barchart-output.gif'
import Pictograph from '../../../assets/img/lemonadeOnboarding/2301-pictograph-output.gif'

function ModalLemonadeOnboarding({
  open,
  handleClose,
  handleCloseBtn,
  template
}) {
  let templateImage

  switch (template) {
    case 'Cloze_math':
      templateImage = Cloze_math
      break
    case 'Cloze_with_drop_down':
      templateImage = Cloze_with_drop_down
      break
    case 'Cloze_with_drag_drop':
      templateImage = Cloze_with_drag_drop
      break
    case 'Cloze_with_text':
      templateImage = Cloze_with_text
      break
    case 'Choice_matrix_inline':
      templateImage = Choice_matrix_inline
      break
    case 'Match_list':
      templateImage = Match_list
      break
    case 'Multiple_choice_standard':
      templateImage = Multiple_choice_standard
      break
    case 'Multiple_choice_standard_click':
      templateImage = Multiple_choice_standard_click
      break
    case 'Multiple_choice_multiple_response':
      templateImage = Multiple_choice_multiple_response
      break
    case 'Multiple_choice_multiple_response_click':
      templateImage = Multiple_choice_multiple_response_click
      break
    case 'Counting_count':
      templateImage = Counting_count
      break
    case 'Counting_add':
      templateImage = Counting_add
      break
    case 'Counting_substract':
      templateImage = Counting_substract
      break
    case 'Clock_analog':
      templateImage = Clock_analog
      break
    case 'Clock_digital':
      templateImage = Clock_digital
      break
    case 'Order_list':
      templateImage = Order_list
      break
    case 'Number_line':
      templateImage = null
      break
    case 'Pathway':
      templateImage = Pathway
      break
    case 'Line_chart':
      templateImage = Line_chart
      break
    case 'Bar_chart':
      templateImage = Bar_chart
      break
    case 'Pictograph':
      templateImage = Pictograph
      break

    default:
      templateImage = null
      break
  }

  const focusedElement = useRef(null)

  useEffect(() => {
    // Para evitar la posibilidad de que el teclado virtual se muestre por encima
    // se quita el foco al input que lo tenga y al cerrar se le devuelve
    blurFocusedElement(document.activeElement)
    document.addEventListener('focus', blurFocusedElementEvent, true)

    return () => {
      document.removeEventListener('focus', blurFocusedElementEvent, true)
      focusFocusedElement()
    }
  }, [open])

  const blurFocusedElementEvent = (event) => {
    event.preventDefault()
    blurFocusedElement(event.target)
  }

  const blurFocusedElement = (element) => {
    if (element && element.blur && element.tagName !== 'BODY') {
      focusedElement.current = element
      element.blur()
    }
  }

  const focusFocusedElement = () => {
    if (focusedElement.current && focusedElement.current.focus) {
      focusedElement.current.focus()
    }
  }

  return (
    templateImage && (
      <Modal
        className="modal-lemonade-onboarding"
        open={open}
        onCloseStart={handleClose}
      >
        <div className="modal__body">
          <h1>{translate('lemonade_onboarding_title')}</h1>
          <div className="modal-lemonade-onboarding__img">
            <img src={templateImage} />
          </div>
          <div className="modal__controls">
            <div onClick={handleCloseBtn}>
              <PracticeButton
                text={translate('lemonade_onboarding_button')}
                doNotStopPropatation={true}
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  )
}

export default ModalLemonadeOnboarding
