import { store } from '../store/configureStore'
import { createEndPoint, fetchWithToken, getToken } from './util'
import { getCourseGuid, getSchoolGroup } from '../assets/data/api'
import { setDailyGoalProgress } from '../store/DailyGoalSlice/DailyGoalSlice'
import {
  setPiecesAccumulated,
  setCoinsAccumulated,
  setCoinsDailyGoal,
  setCoinsStreak,
  setStreakDay,
  setCoinsDailyGoalEarned
} from '../store/RewardsSlice/RewardsSlice'
import { setUserExperienceProgress } from '../store/UserExperienceSlice/UserExperienceSlice'
import { setUserGuid } from '../store/MetaberrySlice/MetaberrySlice'
import { Analysis } from '../services/analysisService'
import AccessHelper from './util/accessHelper'
import { getFamilyUserData } from './userTypeService'
import {
  getSettings,
  getStoredSettings,
  setIsDailyGoalCompleted,
  updateSettings
} from './settingsService'
import { getLicenseInformation, isSubscriptionHost } from './licenseService'
import { updateRewardsSettings } from './rewardsService'
import {
  setProgramId,
  setMainUserId
} from '../store/PracticeSlice/PracticeSlice'
import {
  updateUpperCaseRequiredUser,
  updatePathSchool
} from '../store/Configuration/Configuration'
import {
  setUser as setUserSentry,
  setContext as setContextSentry,
  setTag as setTagSentry
} from '@sentry/core'
import { errorRedirection } from './errorService'

export async function getCourseInfo() {
  const courseGuid = await getCourseGuid()
  const endPoint = `lms/courses/${courseGuid}/blueberry/maps`
  return fetchWithToken(createEndPoint(endPoint)).then((json) => json.data)
}

const allStoredWhoAmI = {}

export async function getWhoAmI(isMainUser) {
  const token = getToken(isMainUser)
  // No se puede con redux, el ciclo de vida no actuliza con tiempo suficiente
  const storedWhoAmI = allStoredWhoAmI[token]
  if (storedWhoAmI) {
    return storedWhoAmI
  }

  return fetchWithToken(createEndPoint('users/whoami'), isMainUser).then(
    (json) => {
      const currentCustomLanguage =
        window.localStorage.getItem('bb_custom_language')

      const isParent = isMainUser && window.organizationData.isBlueberryFamily

      if (json?.data?.schools && json?.data?.schools.length > 0) {
        store.dispatch(updatePathSchool(json?.data?.schools[0].school_path))
      }

      if (!isParent) {
        // Si se logean con otro idoma se refresca
        if (json?.data?.lang_id !== currentCustomLanguage) {
          json?.data?.lang_id
            ? window.localStorage.setItem(
                'bb_custom_language',
                json?.data?.lang_id
              )
            : window.localStorage.removeItem('bb_custom_language')
          location.reload()
        }
      }

      allStoredWhoAmI[token] = json.data

      return json.data
    }
  )
}

export async function updateCourseInfo() {
  return getCourseInfo().then(async (data) => {
    if (data) {
      store.dispatch(setUserGuid(data.user_guid))
      store.dispatch(setProgramId(data.program_template_guid))

      // Etablecer valores de usuario para análisis
      const schoolGroup = await getSchoolGroup()
      const userWho = await getWhoAmI()
      // console.log('--------------- userWho', userWho)
      let userEmail = userWho?.email
      const isPlayerDemoUser = userWho?.is_demo

      const school =
        userWho.schools && userWho.schools.length > 0
          ? userWho.schools[0]
          : null

      const userData = {
        ...data,
        school_group_guid: schoolGroup.guid,
        school_group_name: schoolGroup.name,
        role_guid: userWho.role_guid,
        role_name: userWho.role_name,
        school_guid: school ? school.guid : null,
        school_name: school ? school.name : null,
        name: userWho.name,
        lastname: userWho.lastname,
        username: userWho.username,
        // TODO cuando producto termine de revisar se queda 1 sóla de las 2 siguientes (guid, user_id)
        guid: userWho.guid,
        user_id: userWho.guid,
        player_id: userWho.guid
      }

      // Si el usuario es 'familiar', se solicita la información del principal para añadirla
      const currentMemberData = getFamilyUserData()
      // eslint-disable-next-line no-undef-init
      let isMainUserDemoUser = undefined
      if (currentMemberData) {
        // console.log('--------------- currentMemberData', currentMemberData)
        const mainUserWho = await getWhoAmI(true)
        store.dispatch(setMainUserId(mainUserWho.guid))

        // console.log('demo log mainUserWho', mainUserWho)
        isMainUserDemoUser = mainUserWho?.is_demo

        // Si el usuario que juega no es el principal, se cambia userGuid y se establece playerGuid
        // TODO revisar cuando producto termine de definir
        // ( no_prefix / parent_ ) deberían desaparer en favor de ( user_ / player_ )
        userData.guid = mainUserWho.guid
        userData.user_id = mainUserWho.guid
        userData.parent_id = mainUserWho.guid

        userData.parent_role_guid = mainUserWho.role_guid
        userData.parent_role_name = mainUserWho.role_name

        userData.parent_name = mainUserWho.name
        userData.parent_lastname = mainUserWho.lastname
        userData.parent_username = mainUserWho.username

        userEmail = mainUserWho?.email
      } else {
        store.dispatch(setMainUserId(userWho.guid))
      }

      userData.user_email = userEmail

      userData.is_demo_user =
        isMainUserDemoUser !== undefined
          ? isMainUserDemoUser
          : isPlayerDemoUser !== undefined
          ? isPlayerDemoUser
          : 1

      userData.is_blueberry_family = window.organizationData?.isBlueberryFamily

      if (isSubscriptionHost()) {
        const licenseInformation = await getLicenseInformation()
        const isPaidUser =
          typeof licenseInformation?.status === 'string' &&
          licenseInformation.status.includes('subscription-')

        userData.is_paid_user = isPaidUser
        userData.plan_interval = isPaidUser
          ? licenseInformation.stripe.plan_interval
          : 'trial_user'
        userData.subscription_status = isPaidUser
          ? licenseInformation.stripe.subscription_status
          : 'trial_user'
      }

      // Incluir nombre del tenant
      userData.tenant_name = window.organizationData.tenantName

      // console.log('--------------- set user', userData)
      // userData.user_id && console.log('- UG: ' + userData.user_id)
      // userData.player_id && console.log('- PG: ' + userData.player_id)

      const isLemonadePreview =
        window.location.pathname.includes('__lemonade-preview')
      if (window.isProductionEnvironment && !isLemonadePreview) {
        // Eliminar todo lo previo por si es un cambio de usuario
        setUserSentry(null)
        setContextSentry(null)
        setTagSentry('course_guid', null)
        setTagSentry('education_level_name', null)
        setTagSentry('education_year_name', null)
        setTagSentry('education_discipline_name', null)

        // Establecer nuevos valores de usuario
        setUserSentry({ email: userData.user_email })

        setContextSentry('user_additional_information', {
          parent_guid: userData?.parent_id
            ? userData.parent_id
            : userData.user_id,
          player_guid: userData?.player_id
            ? userData.player_id
            : userData.user_id
        })

        setTagSentry('course_guid', userData.course_guid)
        setTagSentry('education_level_name', userData.education_level_name)
        setTagSentry('education_year_name', userData.education_year_name)
        setTagSentry(
          'education_discipline_name',
          userData.education_discipline_name
        )
      }

      Analysis.clearUser()
      Analysis.setUser(userData)

      // Esto es para que solo mande evento de login si realmente se ha logueado
      //  no puede hacer en el propio login porque no se ha establecido el usuario para analisis (se acaba de hacer)
      //  por lo que uso un valor de estado para comprobarlo y lanzarlo justo tras esablecer al usuario
      const accessInformation = AccessHelper.getAccessInformation()
      if (!accessInformation) {
        console.error('Error getting acces information')
        window.location.href = `${window.location.protocol}//${window.location.host}/login`
        return
      }

      const hasJustAccessed = accessInformation.justAccessed

      // Solicitar settings de usuario. Ya se hizo con el usuario principal en Analysis.setUser(userData)
      let userSettings = null
      if (!hasJustAccessed && currentMemberData) {
        userSettings = await getSettings()
        if (userSettings.status === 'success' && userSettings.data) {
          userSettings = userSettings.data
        } else userSettings = null
      } else {
        userSettings = getStoredSettings()
      }

      if (userSettings) {
        updateSettings(userSettings)
        store.dispatch(
          updateUpperCaseRequiredUser(
            userSettings.blueberry.letter_case === 'upperCase'
          )
        )
      }

      // Solicitar settings recompensas
      if (!hasJustAccessed) {
        const rewardsSettings = await fetchWithToken(
          createEndPoint('users/rewards-settings')
        )
        if (rewardsSettings.status === 'success' && rewardsSettings.data) {
          updateRewardsSettings(rewardsSettings.data)
        }
      }

      const accessType = accessInformation.accessType

      // console.log('updateCourseInfo hasJustAccessed', hasJustAccessed)

      if (hasJustAccessed === true) {
        if (accessType === AccessHelper.ACCESS_TYPE_CREDENTIALS) {
          Analysis.sendEvent(Analysis.EVENT.LOGIN_COMPLETE)
        } else {
          Analysis.sendEvent(Analysis.EVENT.SSO_ACCESS_COMPLETE)
        }

        AccessHelper.setAccessInformation(false, accessType)
      }

      // Establecer estado INICIAL 'daily goal' para comprobar al gananar experiencia si es o no la 1º vez que se llega a la necesaria de daily goal
      setIsDailyGoalCompleted(data.session.exp >= data.session.exp_daily_go)

      store.dispatch(
        setDailyGoalProgress({
          progress: data.session.exp,
          totalExp: data.session.exp_daily_go,
          canGetDailyReward: data.session.can_get_daily_coins_reward,
          nextDailyReward: data.session.next_daily_coins_reward,
          sessionId: data.session.id,
          sessionAttempId: data.session.attempt_id
        })
      )

      store.dispatch(setUserExperienceProgress(data))

      // Piezas acumuladas
      store.dispatch(
        setPiecesAccumulated(
          data?.total_pieces?.have?.reduce((a, b) => a + b, 0)
        )
      )
      // Monedas acumuladas
      store.dispatch(setCoinsAccumulated(data.coins_accumulated))
      // Monedas ganadas en daily goal
      store.dispatch(setCoinsDailyGoal(data.session.coins_daily_go))
      // Streak de monedas ganadas en daily goal
      store.dispatch(setCoinsStreak(data.session.coins_streak))
      // Dias seguidos con daily goal
      store.dispatch(setStreakDay(data.session.streak_day))
      // Monedas ganadas en daily goal en total
      store.dispatch(setCoinsDailyGoalEarned(data.session.coinsDailyGoalEarned))
    } else {
      errorRedirection('/error-BBE-100', true)
    }
  })
}
