import { createEndPoint, fetchWithToken, getToken } from '../../services/util'
import {
  getTextToSpeech,
  setIsRankingShowed,
  setTextToSpeech
} from '../../services/settingsService'
import { store } from '../../store/configureStore'
import {
  updateUpperCaseRequiredGroup,
  updatePathSchool
} from '../../store/Configuration/Configuration'
import { getCurrentLangString } from '../../i18n/initI18n'
import { errorRedirection } from '../../services/errorService'
import { getFamilyUserData } from '../../services/userTypeService'
import { setLemonadeCustomConfig } from '../../_practice-components'

// course endpoint is different based if the user is a teacher or student.
// if the user is a teacher, the course endpoint is /courses/{courseGuid}
// if the user is a student, the course endpoint is /courses?hasBlueBerry=1

let courseGuid
let schoolGroupGuid
let schoolGroupName
let requiresSoundAssistance
let programLanguage

// Almacena los cursos para evitar llamadas redundantes
// No se puede con redux, el ciclo de vida no actuliza con tiempo suficiente
const allStoredCourses = {}
const getStoredCourses = (token) => {
  return allStoredCourses[token] ? allStoredCourses[token] : null
}
const storeCourses = (token, data) => {
  allStoredCourses[token] = data
}
export const removeStoredCourses = (token) => {
  delete allStoredCourses[token]
}

const getCourseEndpoint = () => {
  // Student default endpoint
  let courseEndpoint = 'front/courses?hasBlueBerry=1'
  // get bb_settings from local storage
  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  // check isTeacher from bb_settings
  const isTeacher = settings && settings.isTeacher ? settings.isTeacher : false

  // generate the courseEndpoint based on the user role
  if (isTeacher) {
    // get courseGuid from local storage
    courseGuid = window.localStorage.getItem('bb_courseGuid')
    if (courseGuid) {
      courseEndpoint = `front/courses/${courseGuid}`
    }
  }

  return courseEndpoint
}

const getAllCoursesData = async (hasToUseMainToken) => {
  const token = getToken(hasToUseMainToken)
  const storedCourses = getStoredCourses(token)
  if (storedCourses) {
    return storedCourses
  }

  const courseEndpoint = getCourseEndpoint()
  const courseRequest = await fetchWithToken(
    createEndPoint(courseEndpoint),
    hasToUseMainToken
  )

  if (courseRequest.status !== 'success') {
    console.error('Error: This user has no courses.')
    errorRedirection('/error-BBE-100', true)
  }

  const data = courseRequest.data
  if (Array.isArray(data)) {
    // if student has no courses, show 405
    if (data.length === 0) {
      console.error('Error: This user has no courses.')
      errorRedirection('/error-BBE-100', true)
      return {}
    }
  }

  storeCourses(token, data)

  return data
}

export const getMainCourseData = async () => {
  const coursesData = await getAllCoursesData()
  const mainCourse = Array.isArray(coursesData) ? coursesData[0] : coursesData

  const isUserInactive = mainCourse.school_group_is_active === false
  const isFamilyUser = getFamilyUserData() !== null
  if (isUserInactive && !isFamilyUser) {
    console.error('Error: This user has no active courses.')
    errorRedirection('/error-BBE-108', true)
    return {}
  }

  // 0 NO mostrar   -   1 SI mostrar
  const isRankingShowed = mainCourse.blueberry_ranking === 0 ? 0 : 1
  setIsRankingShowed(isRankingShowed)

  store.dispatch(
    updateUpperCaseRequiredGroup(
      mainCourse.school_group_letter_case === 'upperCase'
    )
  )

  return mainCourse
}

// Específico para obtener todos los cursos en una cuenta de family
export const getFamilyCourses = async () => {
  const coursesList = await getAllCoursesData(true)

  // console.log('si bbf sin chicos no error, sino añadir', courseRequest)
  if (Array.isArray(coursesList) && coursesList.length === 0) {
    const redirectUrl = `${window.location.protocol}//${window.location.host}/blueberry-family-signup?toAddPlayers=true`
    window.location.href = redirectUrl
  }

  return coursesList
}

export const setCourseGuid = (_courseGuid) => {
  window.localStorage.setItem('bb_courseGuid', _courseGuid)
  courseGuid = _courseGuid
}

export const getCourseGuid = async () => {
  if (!courseGuid) {
    const data = await getMainCourseData()
    setCourseGuid(data.guid)
    setSchoolGroup(data.school_group_guid, data.school_group_name)
    setPathSchool(data.school_path ? data.school_path : null)
    setApiTextToSpeech(data.sound_assitance, data.program_lang)
    setLemonadeCustomConfig({
      thousands_separator: data.thousands_separator
        ? data.thousands_separator?.toUpperCase()
        : 'SPACE',
      decimal_separator: data.decimal_separator
        ? data.decimal_separator?.toUpperCase()
        : 'POINT'
    })
  }

  return courseGuid
}

export const setSchoolGroup = (_schoolGroupGuid, _schoolGroupName) => {
  schoolGroupGuid = _schoolGroupGuid
  schoolGroupName = _schoolGroupName
}

export const setPathSchool = (_pathSchool) => {
  store.dispatch(updatePathSchool(_pathSchool))
}

export const getSchoolGroup = async () => {
  if (!schoolGroupGuid) {
    const data = await getMainCourseData()
    setCourseGuid(data.guid)
    setSchoolGroup(data.school_group_guid, data.school_group_name)

    setPathSchool(data.school_path ? data.school_path : null)

    const _textToSpeech = getTextToSpeech()
    const _programLanguage =
      _textToSpeech && _textToSpeech.programLanguage
        ? _textToSpeech.programLanguage
        : data.program_lang

    setApiTextToSpeech(data.sound_assitance, _programLanguage)
    // setLemonadeCustomConfig({
    //   thousands_separator: data.thousands_separator?.toUpperCase() || 'SPACE',
    //   decimal_separator: data.decimal_separator?.toUpperCase() || 'COMMA'
    // })
  }

  return { guid: schoolGroupGuid, name: schoolGroupName }
}

export const setApiTextToSpeech = (
  _requiresSoundAssistance,
  _programLanguage
) => {
  requiresSoundAssistance = _requiresSoundAssistance
  programLanguage = _programLanguage

  const storedLanguage = window.localStorage.getItem('bb_custom_language')
  if (storedLanguage && storedLanguage !== 'default') {
    programLanguage = storedLanguage.toUpperCase()
  } else {
    programLanguage = getCurrentLangString().toUpperCase()
  }

  setTextToSpeech(requiresSoundAssistance, programLanguage)
}

export const getQuizCourseUsers = async (course_guid) => {
  const data = await getAllCoursesData()
  const coursePractice = Array.isArray(data)
    ? data.filter((course) => course.guid === course_guid)
    : data
  return coursePractice?.length && coursePractice[0]?.users?.length
    ? coursePractice[0].users
    : coursePractice
    ? coursePractice.users
    : []
}

export const getContent = async (contentGuid) => {
  const contentEndpoint = `cms/contents/${contentGuid}`
  const contentRequest = await fetchWithToken(
    createEndPoint(contentEndpoint),
    false
  )

  if (contentRequest.error) {
    console.error('Error getting quiz:', contentRequest.error)
  }

  return contentRequest.data
}

export const sendAnswerQuiz = async (
  quizGuid,
  questionGuid,
  gameGuid,
  timeInMs,
  resultAnswer,
  lemonadeResponseData
) => {
  const courseEndpoint = 'lrs/records'
  const courseRequest = await fetchWithToken(
    createEndPoint(courseEndpoint),
    true,
    {
      content_guid: quizGuid,
      reference_id: questionGuid,
      scope: gameGuid,
      completion_status: 'completed',
      session_time: timeInMs,
      score: resultAnswer,
      total: 1,
      data: lemonadeResponseData
    }
  )

  // console.log('si bbf sin chicos no error, sino añadir', courseRequest)
  // if student has no courses, show 405
  if (Array.isArray(courseRequest.data) && courseRequest.data.length === 0) {
    const redirectUrl = `${window.location.protocol}//${window.location.host}/blueberry-family-signup?toAddPlayers=true`
    window.location.href = redirectUrl
  }

  return courseRequest.data
}

export const getDataSeed = async (inputSeedData, langId) => {
  const endPoint = `lms/personalized-learning/seeds/${inputSeedData}/generate?lang=${langId}`
  const data = await fetchWithToken(createEndPoint(endPoint)).then((json) => {
    return json
  })
  return data
}
