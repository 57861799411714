import React from 'react'
import translate from '../../../../i18n/translate'
import './quizLoading.scss'
// import ship from '../../assets/quizz/ship_blueberro.svg'
// import smoke from '../../assets/quizz/ship_smoke.svg'

const QuizLoading = ({
  message,
  goFullScreen,
  exitFullScreen,
  isFullscreen,
  isPlayerJoinGameStarted,
  userAvatarImg
}) => {
  return (
    <div className="quiz-loading">
      <div className="quiz-infinite-space"></div>

      <div className="container-fullscreen">
        <div className="container-fullscreen-child"></div>
      </div>
      <div className="quiz-loading__wrapper">
        <div
          className={
            isPlayerJoinGameStarted
              ? 'quiz-loading__title quiz-loading__title-game-started'
              : 'quiz-loading__title'
          }
        >
          <span dangerouslySetInnerHTML={{ __html: message }}></span>
        </div>

        <div className="quiz-loading__ship_all">
          {/* <img className="quiz-loading__smoke" src={smoke} alt="smoke" /> */}
          <img className="quiz-loading__ship" src={userAvatarImg} alt="ship" />
        </div>
      </div>
      <div className="quiz-loading_text">{translate('map_loading_text')}</div>
    </div>
  )
}

export default QuizLoading
