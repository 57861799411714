import { createEndPoint, fetchNoToken, fetchWithToken } from './util'
import {
  setCourseGuid,
  setSchoolGroup,
  getFamilyCourses
} from '../assets/data/api'
import { resetStore } from '../store/configureStore'
import { isDeviceSupported } from '../util/mobile'
import {
  getSourceInfoParam,
  getSourceMetadataParams,
  removeSourceParams
} from './sourceService'

export async function resetPlayerState(justAccessed, accessType) {
  // Se resetea el GUID del curso y el estado del store
  setCourseGuid()
  setSchoolGroup()
  resetStore()
}

export async function getLicenseData(licenseCode) {
  const endPoint = `front/sign-up/license/${licenseCode}`
  return fetchNoToken(createEndPoint(endPoint)).then((response) => {
    if (response.status === 'fail') return response

    // console.log('license data', response.data)
    return response.data
  })
}

export function signUpCredentials(userInformation) {
  // console.log('aaa' + window.origin + '/login?shortToken=[TOKEN]')

  return fetch(createEndPoint('front/sign-up/user'), {
    method: 'POST',
    body: JSON.stringify({
      role: 'school-admin',
      wellcomeSend: 1,
      wellcomeLang: userInformation.wellcomeLang,
      wellcomeURL: userInformation.wellcomeURL,
      licenses: userInformation.licenses,

      name: userInformation.name,
      lastname: userInformation.lastname,
      email: userInformation.email,
      username: userInformation.username,
      password: userInformation.password,

      isMobile: !isDeviceSupported(),
      magicLinkURL: window.origin + '/login?shortToken=[TOKEN]',

      sourceOfUser: getSourceInfoParam(),
      analyticsMetadata: getSourceMetadataParams()
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async (response) => {
    const responseJson = await response.json()

    if (responseJson.status === 'fail') return responseJson
    else {
      removeSourceParams()
      return responseJson.data
    }
  })
}

export function sendMagicLinkEmail() {
  return fetchWithToken(createEndPoint('users/magic-link'), true, {
    url: window.origin + '/login?shortToken=[TOKEN]',
    method: 'sendgrid'
  })
}

export function signUpGoogle(userInformation) {
  const payload = {
    role: 'school-admin',
    wellcomeSend: 1,
    wellcomeLang: userInformation.wellcomeLang,
    wellcomeURL: userInformation.wellcomeURL,
    licenses: userInformation.licenses,

    identity_provider: 'google',
    sso_token: userInformation.ssoToken,

    isMobile: !isDeviceSupported(),
    magicLinkURL: window.origin + '/login?shortToken=[TOKEN]',

    sourceOfUser: getSourceInfoParam(),
    analyticsMetadata: getSourceMetadataParams()
  }

  if (userInformation.name) {
    payload.name = userInformation.name
  }

  if (userInformation.lastname) {
    payload.name = userInformation.lastname
  }

  return fetch(createEndPoint('front/sign-up/user'), {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async (response) => {
    const responseJson = await response.json()

    if (responseJson.status === 'fail') return responseJson
    else {
      removeSourceParams()
      return responseJson.data
    }
  })
}

export function createPlayer(userInformation) {
  return fetchWithToken(createEndPoint('users'), true, {
    name: userInformation.name,
    role: userInformation.role,
    username: userInformation.username,
    sendInstructions: 0
  }).then(async (response) => {
    if (response.status === 'fail') return response

    return response.data
  })
}

export function removePlayer(courseGuid, playerGuid) {
  return fetchWithToken(
    createEndPoint('front/courses/' + courseGuid + '/users'),
    true,
    { guid: [playerGuid] },
    'DELETE'
  ).then(async (response) => {
    if (response.status === 'fail') return response

    return response.data
  })
}

export function updatePlayerName(playerGuid, name) {
  return fetchWithToken(
    createEndPoint('users/' + playerGuid),
    true,
    { name: name },
    'PUT'
  ).then((response) => {
    if (response.status === 'fail') throw response

    return response.data
  })
}

function zeroPad(number) {
  return (number < 10 ? '0' : '') + number
}

function getTimezoneOffset() {
  let offset = new Date().getTimezoneOffset()
  const sign = offset < 0 ? '+' : '-'
  offset = Math.abs(offset)
  return sign + zeroPad((offset / 60) | 0) + ':' + zeroPad(offset % 60)
}

export function createCourse(courseInformation) {
  return fetchWithToken(createEndPoint('front/courses'), true, {
    name: courseInformation.name, // 'Curso [NAME_EDUCATION_YEAR] [NAME_EDUCACION_LEVEL]',
    education_year_guid: courseInformation.education_year_guid,
    education_discipline_guid: courseInformation.education_discipline_guid,
    programs: courseInformation.programs, // ['[GUID_PROGRAM]'],
    is_referenced: 1,
    time_zone: getTimezoneOffset(), // '[TIME_ZONE_BROWSER]'
    blueberry_ranking: 0
  }).then(async (response) => {
    if (response.status === 'fail') throw response

    return response.data
  })
}

export function linkCourseWithPlayer(courseGuid, playerGuid) {
  return fetchWithToken(
    createEndPoint('front/courses/' + courseGuid + '/users'),
    true,
    {
      guid: [playerGuid]
    }
  ).then(async (response) => {
    if (response.status === 'fail') throw response

    return response.data
  })
}

export function getLicensePrograms() {
  return fetchWithToken(createEndPoint('front/profile/licenses'), true).then(
    (response) => {
      if (response.status === 'fail') throw response

      return response.data.licensePersonal[0]
    }
  )
}

export async function getPlayersList() {
  const apiCoursesData = await getFamilyCourses()
  // console.log('data', apiCoursesData)

  const playersList = []

  for (const c in apiCoursesData) {
    const course = apiCoursesData[c]
    for (const u in course.users) {
      const user = course.users[u]

      playersList.push({
        name: user.name,
        nameErrorMessage: '',
        course: {
          value: c,
          label: course.education_year_name + ' ' + course.education_level_name
        },
        courseErrorMessage: '',
        isSaved: true,
        courseGuid: course.guid,
        playerGuid: user.person_guid
      })
    }
  }

  return playersList
}

export function recoverAccount(email, host, language) {
  // console.log('rec acc ', email, host, language)
  return fetchNoToken(createEndPoint('front/recover-account'), {
    email: email,
    url: host,
    lang: language,
    link: host + '/recover-password-change/[TOKEN]'
  }).then(async (response) => {
    if (response.status === 'fail') return response

    return response
  })
}

export function recoverAccountPassword(token, newPassword) {
  // console.log('rec pass ', token, newPassword)
  return fetchNoToken(createEndPoint('front/recover-account-password'), {
    token: token,
    new_password: newPassword
  }).then(async (response) => {
    if (response.status === 'fail') return response

    return response
  })
}

export function setUserCustomLanguageApi(languageCode) {
  return fetchWithToken(
    createEndPoint('front/profile'),
    false,
    { lang_id: languageCode },
    'PUT'
  ).then((response) => {
    if (response.status === 'fail') throw response

    return response.data
  })
}

export async function setUserCustomLanguage(languageCode) {
  const currentCustomLanguage =
    window.localStorage.getItem('bb_custom_language')

  if (currentCustomLanguage !== languageCode) {
    window.localStorage.setItem('bb_custom_language', languageCode)
    await setUserCustomLanguageApi(
      languageCode !== 'default' ? languageCode : null
    ) // PUT para usuario
    location.reload()
  }
}
