import React, { useEffect, useState } from 'react'
import anime from 'animejs'
import './mapLevelProgress.scss'
import MapLevelCounter from '../MapLevelCounter/MapLevelCounter'

const MapLevelProgress = ({
  progress,
  totalExperience,
  previousExperience,
  currentLevel,
  nextLevel
}) => {
  const [currentProgress, setCurrentProgress] = useState(progress)

  const levelPercent = Math.min(
    Math.max(
      ((progress - previousExperience) /
        (totalExperience - previousExperience)) *
        100,
      0
    ),
    100
  )

  useEffect(() => {
    animateCounter()
    animateProgressBar()
    setCurrentProgress(progress)
  }, [progress])

  function animateCounter() {
    anime({
      targets: '.map-level-progress__progress-bar-current-progress',
      textContent: [currentProgress, progress],
      round: 1,
      easing: 'linear',
      duration: 1000
    })
  }

  function animateProgressBar() {
    anime({
      targets: '.map-level-progress__progress-bar-progress',
      width: `${levelPercent}%`,
      easing: 'linear',
      duration: 1000
    })
  }

  return (
    <div className="map-level-progress">
      <div className={`map-level-progress__progress-bar`}>
        <span className="map-level-progress__progress-bar-text">
          <span className="map-level-progress__progress-bar-current-progress"></span>
          {`/${totalExperience}`}
        </span>

        <div className="map-level-progress__progress-bar-bg" />
        <div
          className="map-level-progress__progress-bar-progress"
          style={{ width: `${levelPercent}%` }}
        />
      </div>
      <div className="map-level-progress__current-badge">
        <MapLevelCounter level={currentLevel} />
      </div>
      <div className="map-level-progress__next-badge">
        <MapLevelCounter level={nextLevel} />
      </div>
    </div>
  )
}

export default MapLevelProgress
