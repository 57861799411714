import { createSlice } from '@reduxjs/toolkit'
import createLearningMap from './helpers/createLearningMap'
import getStatus from '../helpers/getStatus'
import getShowAsDisabled from './helpers/getShowAsDisabled'

const initialState = []

export const LearningMapSlice = createSlice({
  name: 'LearningMap',
  initialState,
  reducers: {
    initLearningMap: (state, { payload }) => {
      if (payload?.learningMapData?.length) {
        return createLearningMap(payload)
      }
    },

    updateLearningMap: (state, { payload }) => {
      return state.forEach((mapData, idx) => {
        mapData.status = getStatus(payload[idx])
        mapData.isFirstTime = getShowAsDisabled(idx, payload[idx])
        mapData.showAsDisabled = getShowAsDisabled(idx, payload)
      })
    },

    addFirstTimeClouds: (state, { payload }) => {
      state[payload.idx].isFirstTime = true
    },

    removeFirstTimeClouds: (state, { payload }) => {
      const unitIndex = state.findIndex((unit) => unit.unit_guid === payload)
      if (unitIndex > -1) state[unitIndex].isFirstTime = false
    },

    resetLearningMap: () => {
      return initialState
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  initLearningMap,
  updateLearningMap,
  addFirstTimeClouds,
  removeFirstTimeClouds,
  resetLearningMap
} = LearningMapSlice.actions

export function getLearningMapByGuid(guid, state, idx = 0) {
  if (idx === state.length) return undefined
  if (state[idx].unit_guid === guid) return state[idx]
  return getLearningMapByGuid(guid, state, idx + 1)
}

export default LearningMapSlice.reducer
