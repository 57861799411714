import React, { useEffect, useState } from 'react'
import { toPng as domToPng } from 'html-to-image'
import PracticeButton from '../../atoms/PracticeButton/PracticeButton'
import SvgClose from '../../../assets/icons/close.svg'
import SvgCheckboxDefault from '../../../assets/icons/check-default.svg'
import SvgCheckboxFilled from '../../../assets/icons/check-filled.svg'
import translate from '../../../../../i18n/translate'
import { isAndroidDevice } from '../../../../../util/mobile'
import './issueReport.scss'
import Loading from '../../../../../modules/components/Loading/Loading'

const IssueReport = ({
  setShowIssueReportPopup,
  sendIssuesReport,
  isFromApp
}) => {
  const [issuesTypes, setIssuesTypes] = useState([])
  const [userComment, setUserComment] = useState('')
  const [hasIssueTypeSelected, setHasIssueTypeSelected] = useState(false)
  const [isAndroid] = useState(isAndroidDevice())
  const [keyboardStyle, setKeyboardStyle] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [screenshot, setScreenshot] = useState(undefined)

  useEffect(() => {
    const _issueTypes = Array.from({ length: 7 }, (_, i) => ({
      label: translate(`issues_report_${i + 1}`),
      checked: false
    }))
    setIssuesTypes(_issueTypes)

    if (isFromApp && isAndroid) {
      document.addEventListener('message', handleAndroidKeyboardApp)
    }

    getScreenshot()

    return () => {
      if (isFromApp && isAndroid) {
        document.removeEventListener('message', handleAndroidKeyboardApp)
      }
    }
  }, [])

  const getScreenshot = () => {
    const issueScreenshotElement = document.querySelector('.activity-view')

    domToPng(issueScreenshotElement, {
      backgroundColor: '#FFFFFF',
      quality: 0.8
    })
      .then((dataUrl) => {
        const issueScreenshotImage = document.createElement('img')
        issueScreenshotImage.src = dataUrl
        document.body.appendChild(issueScreenshotImage)
        setScreenshot(dataUrl)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const handleAndroidKeyboardApp = (event) => {
    const dataFromReactNative = JSON.parse(event.data)
    if (dataFromReactNative.function === 'keyboardDidShow') {
      setKeyboardStyle({
        top: 'auto',
        bottom: dataFromReactNative?.params?.height
          ? Math.ceil(dataFromReactNative.params.height)
          : '52%',
        transform: 'translate(-50%, 0)'
      })
    } else if (dataFromReactNative.function === 'keyboardDidHide') {
      setKeyboardStyle({})
    }
  }

  const closeIssueReportPopup = () => {
    setShowIssueReportPopup(false)
  }

  const onChangeIssueType = (index) => {
    const newIssuesTypes = [...issuesTypes]
    newIssuesTypes[index].checked = !newIssuesTypes[index].checked
    setIssuesTypes(newIssuesTypes)

    let _hasIssueTypeSelected = false
    for (let i = 0; i < newIssuesTypes.length && !_hasIssueTypeSelected; i++) {
      if (newIssuesTypes[i].checked) {
        _hasIssueTypeSelected = true
      }
    }

    setHasIssueTypeSelected(_hasIssueTypeSelected)
  }

  const handleUserCommentChange = (event) => {
    setUserComment(event.target.value)
  }

  const sendReport = () => {
    setShowIssueReportPopup(false)

    sendIssuesReport({
      issues: issuesTypes
        .filter((item) => item.checked)
        .map((item) => item.label),
      userComment,
      screenshot
    })
  }

  return (
    <div className="issue-report" onClick={closeIssueReportPopup}>
      {isLoading ? (
        <Loading />
      ) : (
        <div
          className={
            'issue-report_frame' +
            (!isFromApp ? '' : ' issue-report_frame-app-version')
          }
          style={keyboardStyle}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className="issue-report_header">
            <div className="issue-report_header_title">
              {translate('issues_report_title')}
            </div>

            <img
              className="issue-report_header_close_x"
              src={SvgClose}
              onClick={closeIssueReportPopup}
            />
          </div>

          <div className="issue-report_body">
            <div className="issue-report_error-types">
              {issuesTypes.map((item, index) => (
                <div
                  key={index}
                  className="issue-report_error-types_checkbox-container"
                >
                  <span
                    className={
                      'issue-report_error-types_checkbox-text' +
                      (!item.checked
                        ? ''
                        : ' issue-report_error-types_checkbox-text-checked')
                    }
                    onClick={() => onChangeIssueType(index)}
                  >
                    {item.label}
                  </span>
                  <img
                    className="issue-report_error-types_checkbox-box"
                    src={!item.checked ? SvgCheckboxDefault : SvgCheckboxFilled}
                    onClick={() => onChangeIssueType(index)}
                  />
                </div>
              ))}
            </div>

            <textarea
              className={
                'issue-report_user-comment' +
                (!userComment ? ' issue-report-without-comment' : '')
              }
              value={userComment}
              onChange={handleUserCommentChange}
              placeholder={translate('issues_report_user_comment_payload')}
            ></textarea>
          </div>

          <div className="issue-report__action-buttons">
            <PracticeButton
              text={translate('issues_report_activity_submit')}
              action={sendReport}
              type={1}
              disabled={!hasIssueTypeSelected || !userComment}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default IssueReport
