import EasyStar from '../easystarjs/easystar'
import { getToken } from '../../../../services/util'
import { increaseCountProgress } from '../../../../util/loadingProgressBar'

const TILED_MAP_KEY = 'tiledMap' // Nombre de la clave para el mapa
// const BELOW_LAYER_NAME = 'below' // Nombre para las capas (segido de -N) que se pintan antes (debajo) del jugador
const COLLIDING_LAYER_NAME = 'player' // Nombre para la capa que se pintan a la misma altura que el jugador (colisiones)
const ABOVE_LAYER_NAME = 'above' // Nombre para las capas (segido de -N) que se pintan despues (encima) del jugador
const COLLISION_PROPERTY_NAME = 'collides' // Nombre de propiedad que si es 'true' indica que es un objeto sólido (personajes colisionan)

const MAP_MATTER_OBJECT_PREFIX = 'tilmap-'

const WALKABLE_GENERIC_TILE = -1
const UNWALKABLE_GENERIC_TILE = -2

export default class Map {
  constructor(scene, mapUrl, tileSetsUrl, currentState) {
    // console.log('Map')

    // path map
    // map.JSON
    // tilesN.png
    // objects.png con estado
    // nubes o bloqueo visual

    this.scene = scene
    this.mapUrl = mapUrl
    this.tileSetsUrl = tileSetsUrl

    this.tileMap = null
    this.tileSetsKeys = []

    this.mapObjets = {}
    this.finder = null
    this.finderGrid = []
    this.walkableTiles = []

    this.mapMarginX = 0
    this.mapMarginY = 0
    this.solidLayer = null
  }

  generateMap(zoom) {
    return this.loadMap()
      .then((mapData) => this.loadTileSets(mapData))
      .then(() => this.configureMap(zoom))
  }

  loadMap() {
    return new Promise((resolve, reject) => {
      console.log(window._getTestTime() + ' - Map loadMap i tms-')
      increaseCountProgress()
      // Si existe JSON con esa key, eliminarlo antes
      if (this.scene.cache.tilemap.exists(TILED_MAP_KEY)) {
        this.scene.cache.tilemap.remove(TILED_MAP_KEY)
      }

      this.scene.load.on(
        'filecomplete-tilemapJSON-' + TILED_MAP_KEY,
        (key, type, data) => {
          console.log(window._getTestTime() + ' - Map loadMap 1 tms-')
          increaseCountProgress()
          const _tilemapJson = data?.data ? data.data : data
          return resolve(_tilemapJson)
        },
        this
      )

      // ---- LOCAL VS SERVER
      // this.scene.load.tilemapTiledJSON(TILED_MAP_KEY, 'assets/maps/m.json')
      //*
      this.scene.load.tilemapTiledJSON(TILED_MAP_KEY, this.mapUrl, {
        headers: {
          Authorization: getToken(),
          // referer: 'referer-dominain'
          'Content-Type': 'application/json'
        }
      })
      // */

      this.scene.load.start()
    })
  }

  loadTileSets(mapData) {
    return new Promise((resolve, reject) => {
      console.log(window._getTestTime() + ' - Map loadTileSets i tms-')
      increaseCountProgress()
      this.tileMap = this.scene.make.tilemap({ key: TILED_MAP_KEY })
      this.tileSetPromises = []

      // Cargar imágenes con tiles
      for (let t = 0, tMax = mapData.tilesets.length; t < tMax; t++) {
        const tileSetData = mapData.tilesets[t]
        const tileSetName = tileSetData.name

        // ---- LOCAL VS SERVER
        // const tileSetFile = 'assets/maps/m.png'
        const tileSetFile = tileSetData.image

        this.tileSetsKeys.push(tileSetName)

        this.tileSetPromises.push(
          new Promise((resolve, reject) => {
            this.scene.load.on(
              'filecomplete-image-' + tileSetName,
              () => {
                console.log(
                  window._getTestTime() + ' - Map loadTileSets x tms-'
                )
                increaseCountProgress()
                resolve()
              },
              this
            )

            this.scene.load.image(tileSetName, tileSetFile)
            this.scene.load.start()
          })
        )
      }

      Promise.all(this.tileSetPromises).then((values) => resolve())
    })
  }

  calculateMarginToCenterMap(zoom) {
    // console.log('calculateMarginToCenterMap', zoom)
    zoom = zoom || 1
    const canvasWidth = this.scene.game.canvas.width
    const canvasHeight = this.scene.game.canvas.height
    const mapMinWidth = zoom * this.tileMap.widthInPixels
    const mapMinHeight = zoom * this.tileMap.heightInPixels

    this.mapMarginX = (canvasWidth - mapMinWidth) / 2 / zoom
    if (this.mapMarginX < 0) this.mapMarginX = 0

    this.mapMarginY = (canvasHeight - mapMinHeight) / 2 / zoom
    if (this.mapMarginY < 0) this.mapMarginY = 0

    // Ya existía el mapa
    if (this.solidLayer) {
      //  - Ajuscar capas
      for (let l = 0, lMax = this.tileMap.layers.length; l < lMax; l++) {
        this.tileMap.layers[l].tilemapLayer.setPosition(
          this.mapMarginX,
          this.mapMarginY
        )
      }

      // Ajustar tiles solidas (objetos matter asociados)
      this.scene.matter.world.convertTilemapLayer(this.solidLayer, {
        label: MAP_MATTER_OBJECT_PREFIX
      })

      //  Ajustar limites "físicos"
      this.scene.matter.world.setBounds(
        this.mapMarginX,
        this.mapMarginY,
        this.tileMap.widthInPixels,
        this.tileMap.heightInPixels
      )
    }
  }

  configureMap(zoom) {
    console.log(window._getTestTime() + ' - Map configureMap i tms-')
    increaseCountProgress()
    // Añada las imágenes cargadas para los tileSets
    for (let s = 0, sMax = this.tileSetsKeys.length; s < sMax; s++) {
      this.tileMap.addTilesetImage(
        this.tileSetsKeys[s],
        this.tileSetsKeys[s],
        this.tileMap.tileWidth,
        this.tileMap.tileHeight,
        1,
        2
      )
    }

    console.log(window._getTestTime() + ' - Map configureMap 1 tms-')
    increaseCountProgress()

    this.calculateMarginToCenterMap(zoom)

    console.log(window._getTestTime() + ' - Map configureMap 2 tms-')
    increaseCountProgress()

    // Crear y configurar capas (profundidad y colisiones)
    for (let l = 0, lMax = this.tileMap.layers.length; l < lMax; l++) {
      const layerData = this.tileMap.layers[l]
      const layerName = layerData.name
      const mapLayer = this.tileMap.createLayer(
        layerName,
        this.tileMap.tilesets
      )

      mapLayer.setPosition(this.mapMarginX, this.mapMarginY)

      if (layerName.startsWith(ABOVE_LAYER_NAME)) mapLayer.setDepth(3)

      if (layerName.startsWith(COLLIDING_LAYER_NAME)) {
        this.solidLayer = mapLayer
        const collisionTileProperties = {}
        collisionTileProperties[COLLISION_PROPERTY_NAME] = true

        mapLayer.setDepth(1)
        mapLayer.setCollisionByProperty(collisionTileProperties) // Set colliding tiles before converting the layer to Matter bodies

        this.scene.matter.world.convertTilemapLayer(mapLayer, {
          label: MAP_MATTER_OBJECT_PREFIX
        }) // Any colliding tiles will be given a Matter body.
      }
    }

    console.log(window._getTestTime() + ' - Map configureMap 3 tms-')
    increaseCountProgress()

    // Establecer límites fisicos para todo el mapa
    this.scene.matter.world.setBounds(
      this.mapMarginX,
      this.mapMarginY,
      this.tileMap.widthInPixels,
      this.tileMap.heightInPixels
    )

    console.log(window._getTestTime() + ' - Map configureMap 4 tms-')
    increaseCountProgress()

    this.getObjetsLayers()
    this.createPathFinder() // TODO: Habría que incluir las tiles ocupadas con objetos sólidos o mejorar el pathPinder para evitar objetos sólidos

    console.log(window._getTestTime() + ' - Map configureMap e tms-')
    increaseCountProgress()
  }

  // Obtener información para los objetos, como:
  //  - portales (unidades / lecciones)
  //  - actividades
  //  - areas restringidas
  getObjetsLayers() {
    // A partir de las capas de objetos
    for (let l = 0, lMax = this.tileMap.objects.length; l < lMax; l++) {
      // Obtener capa de objetos
      const objectsLayer = this.tileMap.objects[l]
      this.mapObjets[objectsLayer.name] = { list: [] }

      // Insertar propiedades si las tiene
      if (objectsLayer.properties) {
        for (const pl in objectsLayer.properties) {
          this.mapObjets[objectsLayer.name][pl] = objectsLayer.properties[pl]
        }
      }

      // Obtener objetos de la capa
      for (let o = 0, oMax = objectsLayer.objects.length; o < oMax; o++) {
        const currentObject = { ...objectsLayer.objects[o] }

        // Añadirles sus propiedades si las tienen
        if (currentObject.properties) {
          for (const po in currentObject.properties) {
            currentObject[currentObject.properties[po].name] =
              currentObject.properties[po].value

            // Si en sus propiedades se indica que es "colisionable" agregar el objeto a 'matter'
            if (po === COLLISION_PROPERTY_NAME)
              currentObject.matter = this.createMatterObject(currentObject)
          }

          delete currentObject.properties
        }

        this.mapObjets[objectsLayer.name].list.push(currentObject)
      }
    }
  }

  getPolygonCenter(vertex) {
    const x = vertex.map((point) => point.x)
    const y = vertex.map((point) => point.y)
    const cx = (Math.min(...x) + Math.max(...x)) / 2
    const cy = (Math.min(...y) + Math.max(...y)) / 2

    return { x: cx, y: cy }
  }

  createMatterObject(mapObject) {
    let matterObject = null
    const dafaultOptions = {
      isStatic: true,
      label: MAP_MATTER_OBJECT_PREFIX + mapObject.name
    }

    if (mapObject.rectangle) {
      matterObject = this.matter.add.rectangle(
        mapObject.x,
        mapObject.y,
        mapObject.width,
        mapObject.height,
        dafaultOptions
      )
    } else if (mapObject.ellipse) {
      // TODO hacer elipse
      matterObject = this.matter.add.ellipse(
        mapObject.x,
        mapObject.y,
        mapObject.width,
        mapObject.height,
        dafaultOptions
      )
    } else if (mapObject.polygon) {
      let polygonCenter = this.getPolygonCenter(mapObject.polygon)
      polygonCenter = {
        x: mapObject.x + polygonCenter.x,
        y: mapObject.y + polygonCenter.y
      }

      matterObject = this.matter.add.fromVertices(
        polygonCenter.x,
        polygonCenter.y,
        mapObject.polygon,
        dafaultOptions
      )
    } else {
      // point u otros
      matterObject = this.matter.add.rectangle(
        mapObject.x,
        mapObject.y,
        1,
        1,
        dafaultOptions
      )
    }

    return matterObject
  }

  getTileAtColumnRow(column, row) {
    return this.tileMap.getTileAt(column, row, true, COLLIDING_LAYER_NAME)
  }

  getTilePropertiesByLayerAtColumnRow(column, row, removeCollides) {
    const tileProperties = {}
    for (let l = 0, lMax = this.tileMap.layers.length; l < lMax; l++) {
      const layerName = this.tileMap.layers[l].name
      const auxTile = this.tileMap.getTileAt(column, row, true, layerName)

      if (
        auxTile &&
        auxTile.properties &&
        Object.keys(auxTile.properties).length !== 0
      ) {
        tileProperties[layerName] = { ...auxTile.properties }

        if (
          removeCollides &&
          tileProperties[layerName].collides !== undefined
        ) {
          delete tileProperties[layerName].collides
          if (Object.keys(tileProperties[layerName]).length === 0) {
            delete tileProperties[layerName]
          }
        }
      }
    }

    return tileProperties
  }

  getTileWorldXY(column, row, camera) {
    return this.tileMap.tileToWorldXY(
      column,
      row,
      undefined,
      camera,
      COLLIDING_LAYER_NAME
    )
  }

  // Crea y configura el buscardor de caminos en base al mapa
  createPathFinder() {
    // eslint-disable-next-line new-cap
    this.finder = new EasyStar.js()
    this.finderGrid = [] // En cada celda se guarda el identificador de la tile (para saber si es o no "sólida")
    this.walkableTiles = [WALKABLE_GENERIC_TILE] // Indica las tiles que NO son "sólidas", -1 es que no hay tile, por lo tanto

    const hasCollisionLayer = this.getLayerByName(COLLIDING_LAYER_NAME) !== null

    for (let y = 0; y < this.tileMap.height; y++) {
      const tilesColumn = []

      for (let x = 0; x < this.tileMap.width; x++) {
        let tileId = WALKABLE_GENERIC_TILE

        if (hasCollisionLayer) {
          const tile = this.getTileAtColumnRow(x, y)
          tileId = tile.index

          if (
            tile.collides !== true &&
            this.walkableTiles.indexOf(tileId) === -1
          ) {
            this.walkableTiles.push(tileId)
          }
        }

        tilesColumn.push(tileId)
      }

      this.finderGrid.push(tilesColumn)
    }

    this.finder.setGrid(this.finderGrid)
    this.finder.setAcceptableTiles(this.walkableTiles)

    // this.finder.enableDiagonals() Si habilito se queda choncandose en algunas esquintas
    // X  F  inicio hasta final, por diagonarl puede (el camino) pero por solidez se choca
    // i  X
  }

  getLayerByName(name) {
    for (const l in this.tileMap.layers) {
      if (this.tileMap.layers[l].name === name) return this.tileMap[l]
    }

    return null
  }

  isTileWalkable(column, row) {
    if (
      this.finderGrid &&
      row < this.finderGrid.length &&
      column < this.finderGrid[row].length
    ) {
      return this.walkableTiles.indexOf(this.finderGrid[row][column]) !== -1
    } else return false
  }

  // Calcula el camino mas corto de una tile a otra evitando las sólidas
  async calculateBestPath(fromColumn, fromRow, toColumn, toRow) {
    if (fromColumn !== toColumn || fromRow !== toRow) {
      if (this.isTileWalkable(toColumn, toRow)) {
        // Clicked tile
        return await this.calculatePath(fromColumn, fromRow, toColumn, toRow)
      } else {
        let finalPath = null

        // Si no es justo la siguiente en los ejes X o Y (en cuyo caso no se desplaza)
        const columnDistance = Math.abs(fromColumn - toColumn)
        const rowDistance = Math.abs(fromRow - toRow)
        if (columnDistance + rowDistance > 1) {
          // From clicked tile:
          //        (Left Up)    (Up)     (Right Up)
          //         (Left)    (Clicked)    (Right)
          //      (Left Down)   (Down)   (Right Down)

          const possiblePaths = []
          // Left and Up
          possiblePaths.push({
            hasPriority: false,
            path: await this.calculatePath(
              fromColumn,
              fromRow,
              toColumn - 1,
              toRow - 1
            )
          })
          // Left
          possiblePaths.push({
            hasPriority: true,
            path: await this.calculatePath(
              fromColumn,
              fromRow,
              toColumn - 1,
              toRow
            )
          })
          // Left and Down
          possiblePaths.push({
            hasPriority: false,
            path: await this.calculatePath(
              fromColumn,
              fromRow,
              toColumn - 1,
              toRow + 1
            )
          })
          // Up
          possiblePaths.push({
            hasPriority: true,
            path: await this.calculatePath(
              fromColumn,
              fromRow,
              toColumn,
              toRow - 1
            )
          })
          // Down
          possiblePaths.push({
            hasPriority: true,
            path: await this.calculatePath(
              fromColumn,
              fromRow,
              toColumn,
              toRow + 1
            )
          })
          // Right and Up
          possiblePaths.push({
            hasPriority: false,
            path: await this.calculatePath(
              fromColumn,
              fromRow,
              toColumn + 1,
              toRow - 1
            )
          })
          // Right
          possiblePaths.push({
            hasPriority: true,
            path: await this.calculatePath(
              fromColumn,
              fromRow,
              toColumn + 1,
              toRow
            )
          })
          // Right and Down
          possiblePaths.push({
            hasPriority: false,
            path: await this.calculatePath(
              fromColumn,
              fromRow,
              toColumn + 1,
              toRow + 1
            )
          })

          for (let p = 0, pMax = possiblePaths.length; p < pMax; p++) {
            const newPath = possiblePaths[p]
            if (!finalPath) {
              if (newPath.path) finalPath = newPath
            } else if (newPath.path) {
              const haveSamePriority =
                newPath.hasPriority === finalPath.hasPriority
              const hasNewPriority =
                newPath.hasPriority && !finalPath.hasPriority

              if (haveSamePriority) {
                if (finalPath.path.length > newPath.path.length) {
                  finalPath = newPath
                }
              } else if (hasNewPriority) {
                if (newPath.path.length - 1 <= finalPath.path.length) {
                  finalPath = newPath
                }
              } else if (newPath.path.length < finalPath.path.length - 1) {
                finalPath = newPath
              }
            }
          }
        }

        return finalPath?.path
      }
    }

    return null
  }

  calculatePath(fromColumn, fromRow, toColumn, toRow) {
    return new Promise((resolve, reject) => {
      if (
        toColumn >= 0 &&
        toRow >= 0 &&
        toColumn < this.tileMap.width &&
        toRow < this.tileMap.height
      ) {
        // Configura el cálculo
        this.finder.findPath(
          fromColumn,
          fromRow,
          toColumn,
          toRow,
          (foundPath) => {
            if (foundPath) foundPath.shift() // Quito la primera posición, es donde está situado el jugador y no hace falta
            resolve(foundPath)
          }
        )
        // Inicia el cálculo
        this.finder.calculate()
      } else {
        resolve(null)
      }
    })
  }

  // Actualiza la grid del finder para añadir solided proveniente de objetos y no tiles
  updateFinderGridCell(tileColumn, tileRow, isWalkable) {
    this.finderGrid[tileRow][tileColumn] = isWalkable
      ? WALKABLE_GENERIC_TILE
      : UNWALKABLE_GENERIC_TILE

    this.finder.setGrid(this.finderGrid)
  }

  destroy() {
    // Eliminar el buscador de caminos
    this.finder.cancelPath()
    this.finder = null

    // Eliminar los objetos generados
    for (const l in this.mapObjets) {
      for (let o = 0, oMax = this.mapObjets[l].list.length; o < oMax; o++) {
        const currentObject = this.mapObjets[l].list[o]
        if (currentObject.matter) currentObject.matter.destroy()
      }
      this.mapObjets[l].list = []
      this.mapObjets[l] = null
      delete this.mapObjets[l]
    }
    this.mapObjets = null

    // Eliminar imágenes de tiles
    for (let t = 0, tMax = this.tileSetsKeys.length; t < tMax; t++) {
      const tileSetName = this.tileSetsKeys[t]
      this.scene.textures.get(tileSetName).destroy()
    }
    this.tileSetsKeys = null

    this.tileMap.removeAllLayers()
    this.tileMap.destroy()
    this.tileMap = null

    if (this.scene.cache.tilemap.exists(TILED_MAP_KEY)) {
      this.scene.cache.tilemap.remove(TILED_MAP_KEY)
    }

    // console.log('constraints', this.scene.matter.world.getAllConstraints())
    // console.log('composites', this.scene.matter.world.getAllComposites())
    // console.log('bodies', this.scene.matter.world.getAllBodies())
    this.scene.matter.world.getAllBodies()

    // this.scene.matter.world.resetCollisionIDs()
    this.scene.matter.world.setBounds(
      0,
      0,
      this.scene.game.canvas.width,
      this.scene.game.canvas.height
    )

    this.mapUrl = ''
    this.tileSetsUrl = ''
  }
}
