/*
 * Aplica los valores del objeto values a un literal con este modelo $key
 *  applyTemplate({ color: 'rojo' }, 'Mi color preferido es el $color')
 * */

// forzar update ...

export default function applyTemplate(values, literal) {
  let tpl = literal
  for (const key in values)
    tpl = tpl.replace(new RegExp('\\$' + key, 'gi'), values[key])
  return tpl
}
