import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  totalExp: 0,
  progress: 0,
  extraExperience: 0,
  canGetDailyReward: false,
  nextDailyReward: '',
  sessionId: null,
  sessionAttempId: null
}

export const DailyGoalSlice = createSlice({
  name: 'DailyGoal',
  initialState,
  reducers: {
    setDailyGoalProgress: (state, { payload }) => {
      state.totalExp = payload.totalExp
      state.progress = payload.progress
      state.canGetDailyReward = payload.canGetDailyReward
      state.nextDailyReward = payload.nextDailyReward
      state.sessionId = payload.sessionId
      state.sessionAttempId = payload.sessionAttempId
    },

    setDailyGoalExtraExperience: (state, { payload }) => {
      state.extraExperience = payload
    },

    resetDailyGoal: () => initialState
  }
})

// Action creators are generated for each case reducer function
export const {
  setDailyGoalProgress,
  setDailyGoalExtraExperience,
  resetDailyGoal
} = DailyGoalSlice.actions

export default DailyGoalSlice.reducer
