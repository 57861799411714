import React, { useEffect } from 'react'
import { clearToken } from '../../services/util'
import GenericError from './GenericError'

const Error405ContactPage = () => {
  // silent logout for user on 405
  // silent means no redirect to login page
  // This is to allow the user to go to login page on refresh because the page does not have any actions
  // Message indicating that the user should contact the school admin

  const logout = () => {
    clearToken()
    window.localStorage.clear()
  }

  useEffect(() => {
    setTimeout(() => {
      logout()
    }, 1000)
  }, [])

  return <GenericError />
}

export default Error405ContactPage
