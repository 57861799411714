import React from 'react'
import PropTypes from 'prop-types'

import './simpleButton.scss'
import translate from '../../../i18n/translate'
import useClickOnce from '../../../hooks/useClickOnce'

function SimpleButton({
  onClick,
  text,
  experience,
  variant,
  size,
  color,
  display,
  disabled,
  isOnce = false
}) {
  const handleClick = useClickOnce(onClick, isOnce)
  return (
    <button
      className={`simple-button button-variant--${variant} button--display-${display} button--color-${color} button--size-${size} ${
        disabled ? 'disabled' : ''
      }`}
      role="button"
      onClick={handleClick}
      disabled={disabled}
    >
      <div className="simple-button__wrapper">
        <span className="simple-button__text">{text}</span>
        {experience && (
          <span className="simple-button__experience">
            +{experience} {translate('exp')}
          </span>
        )}
      </div>
    </button>
  )
}

SimpleButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  experience: PropTypes.number,
  disabled: PropTypes.bool,
  display: PropTypes.oneOf(['default', 'inline']),
  variant: PropTypes.oneOf(['default', 'outline', 'minimal']),
  size: PropTypes.oneOf(['default', 'small', 'large']),
  color: PropTypes.oneOf([
    'default',
    'grey',
    'blue',
    'red',
    'pink',
    'green',
    'yellow',
    'orange',
    'purple',
    'white'
  ])
}

SimpleButton.defaultProps = {
  onClick: () => {
    return true
  },
  text: 'Click me!',
  disabled: false,
  display: 'default',
  variant: 'default',
  size: 'default',
  color: 'default'
}

export default SimpleButton
