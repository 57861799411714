import Phaser from 'phaser'
export default class BootScene extends Phaser.Scene {
  constructor() {
    super('BootScene')
  }

  preload() {}

  create() {
    if (this.scene.get('MainScene')) {
      this.scene.stop('MainScene')
    }

    this.scene.start('MainScene')
    this.scene.stop('BootScene')
  }
}
