import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import translate from '../../../../../i18n/translate'
import { getCurrentLangString } from '../../../../../i18n/initI18n'
import {
  getSoundAssistance,
  getEffectsUserConfig
} from '../../../../../services/settingsService'
import { Analysis } from '../../../../../services/analysisService'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
// import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'
import StreakCard from './components/StreakCard/StreakCard'
import StreakProgress from './components/StreakProgress/StreakProgress'
import './RewardDailyGoal.scss'

const RewardDailyGoal = ({ handleCheck }) => {
  const { renderSoundFXController, playSoundEffect } = useSound()

  const rewards = useSelector((s) => ({
    coinsDailyGoal: s.rewards.coinsDailyGoal,
    coinsAccumulated: s.rewards.coinsAccumulated,
    coinsStreak: s.rewards.coinsStreak,
    streakDay: s.rewards.streakDay
  }))

  useEffect(() => {
    Analysis.sendEvent(Analysis.EVENT.COINS_EARNED, {
      coins_daily_goal_earned: rewards.coinsDailyGoal,
      coins_accumulated: rewards.coinsAccumulated
    })

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Daily Goal Popup Viewed']
    )

    getSoundAssistance() &&
      getEffectsUserConfig() === 1 &&
      playSoundEffect('rewardDailyGoal_' + getCurrentLangString())
  }, [])

  return (
    <>
      <div className="reward-popup__backdrop" onClick={handleCheck}></div>
      <div className="reward-popup reward-daily-goal">
        {renderSoundFXController()}
        <div className="reward-popup__top"></div>
        {/* <div className="reward-popup__close-icon" onClick={handleCheck}>
          <CloseIcon />
        </div> */}
        <div
          className="reward-popup__title"
          dangerouslySetInnerHTML={{
            __html: translate('reward_daily_goal_text_1')
          }}
        ></div>
        <div className="reward-popup__subtitle">
          {translate('reward_daily_goal_streak_text')}
        </div>
        <div className="reward-daily-goal__streak-cards">
          <StreakCard streakDay={rewards.streakDay} step={1} />
          <StreakCard streakDay={rewards.streakDay} step={2} />
          <StreakCard streakDay={rewards.streakDay} step={3} />
          <StreakCard streakDay={rewards.streakDay} step={4} />
          <StreakCard streakDay={rewards.streakDay} step={5} />
        </div>
        <div className="reward-daily-goal__streak-progress">
          <StreakProgress currentStreak={rewards.streakDay + 1} />
        </div>

        <div className="reward-daily-goal__actions">
          <div className="reward-daily-goal__button" onClick={handleCheck}>
            {translate('reward_daily_goal__button')}
          </div>
        </div>
        <div className="reward-popup__bottom"></div>
      </div>
    </>
  )
}
export default RewardDailyGoal
