import React from 'react'
import translate from '../../../../i18n/translate'
import FormButton from '../../../BlueberryFamilyPages/components/FormButton/FormButton'
import { ReactComponent as BackIcon } from '../../../../assets/icons/arrow-circle-left.svg'
import './emailSent.scss'

function EmailForm({ onButtonClick }) {
  return (
    <div className={'email-form email-sent'}>
      <h2 className="form-title">
        {translate('recover_password_email_sent_title')}
      </h2>
      <div className="form-text">
        {translate('recover_password_email_sent_text')}
      </div>
      <div className="submit-control">
        <FormButton
          icon={<BackIcon />}
          text={translate('recover_password_back')}
          textAlign="left"
          onClick={onButtonClick}
          className="form-button__recover-password"
        />
      </div>
    </div>
  )
}

export default EmailForm
