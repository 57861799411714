import extractDefaultLessonData from './extractDefaultLessonData'

export default function updateLessons(lessonData, currentUnit) {
  return currentUnit.lessons.map((lesson) => {
    const currentLessonData = findLessonData(lesson.lesson_guid, lessonData)
    return {
      ...lesson,
      ...extractDefaultLessonData(currentLessonData)
    }
  })
}

function findLessonData(guid, lessonData) {
  return lessonData.find(({ lesson_guid }) => lesson_guid === guid)
}
