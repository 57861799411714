import React from 'react'
import translate from '../../i18n/translate'
import {
  ACTIVITY_TYPES,
  ActivityBlueberryComponent
} from '../../_practice-components'
import Header from './components/Header/Header'
import QuizPodium from './components/QuizPodium/QuizPodium'
import QuizLoading from './components/QuizLoading/QuizLoading'
import { getCurrentLangString } from '../../i18n/initI18n'

const TeacherPracticePage = ({
  showPage = false,
  contentQuizz = {},
  statusMapPractice,
  statusPractice,
  numberQuestion,
  numberCorrectQuestion,
  showSolution,
  sendAnswerData,
  formatTime,
  timerQuestion,
  userAvatarImg,
  onExitPractice,
  playSoundEffect,
  goFullScreen,
  exitFullScreen,
  isFullscreen,
  playerGuid,
  isPlayerJoinGameStarted,
  langQuiz,
  analysis,
  onActivityEvent,
  finishedTimeQuestion,
  pathAssetsSchool
}) => {
  return (
    showPage && (
      <div className="teacher-practice-page container">
        {statusPractice.phase === statusMapPractice.IN_QUESTION && (
          <>
            <div className="header">
              <Header
                totalQuestions={contentQuizz?.questions?.length}
                currentQuestion={numberQuestion + 1}
                formatTime={formatTime}
                timerQuestion={timerQuestion}
                userAvatarImg={userAvatarImg}
                translate={translate}
                goFullScreen={goFullScreen}
                exitFullScreen={exitFullScreen}
                isFullscreen={isFullscreen}
              ></Header>
            </div>

            <div className="main">
              {numberQuestion !== null && (
                <ActivityBlueberryComponent
                  activityType={ACTIVITY_TYPES.QUIZ}
                  activity={
                    contentQuizz.questions[numberQuestion]?.question?.type ===
                    'scaffold'
                      ? contentQuizz.questions[numberQuestion]?.question
                          ?.questions[0]
                      : contentQuizz.questions[numberQuestion]?.question
                  }
                  uiLanguage={langQuiz || getCurrentLangString()}
                  exerciseLanguage={langQuiz || getCurrentLangString()}
                  showSolutionQuestion={showSolution}
                  disabledQuestion={false}
                  isHideFooter={showSolution}
                  showAllFeedback={showSolution}
                  onActivityEvent={onActivityEvent}
                  numberQuestion={numberQuestion + 1}
                  timerQuestion={timerQuestion}
                  finishedTimeQuestion={finishedTimeQuestion}
                  mathRenderEngine="katex"
                  pathAssetsSchool={pathAssetsSchool}
                />
              )}
            </div>
          </>
        )}

        {statusPractice.phase === statusMapPractice.IN_RANKING && (
          <QuizLoading
            message={translate(
              isPlayerJoinGameStarted
                ? 'teacher_practice_waiting_message_join'
                : numberQuestion + 1 < contentQuizz?.questions?.length
                ? 'teacher_practice_waiting_message'
                : 'teacher_practice_podium_waiting'
            )}
            goFullScreen={goFullScreen}
            exitFullScreen={exitFullScreen}
            isFullscreen={isFullscreen}
            isPlayerJoinGameStarted={isPlayerJoinGameStarted}
            userAvatarImg={userAvatarImg}
          />
        )}

        {statusPractice.phase === statusMapPractice.IN_PODIUM && (
          <QuizPodium
            playerGuid={playerGuid}
            playerAvatarUrl={userAvatarImg}
            playerList={statusPractice.playerList}
            questionsTotal={contentQuizz?.questions?.length}
            questionsRight={numberCorrectQuestion}
            exitQuizz={onExitPractice}
            translate={translate}
            goFullScreen={goFullScreen}
            exitFullScreen={exitFullScreen}
            isFullscreen={isFullscreen}
            analysis={analysis}
            quizGuid={contentQuizz.guid}
          />
        )}
      </div>
    )
  )
}

export default TeacherPracticePage
