import React from 'react'
import './CardDetails.scss'
import { ReactComponent as Coins } from '../../../../assets/img/pets_coins.svg'

const CardDetails = ({
  name,
  description,
  price,
  image,
  isLocked,
  isExpensive,
  isOwned
}) => {
  return (
    <>
      <div className={'card-details__container'}>
        {name && (
          <div
            className={`card-details__name-container ${
              price && 'card-details__name-container-pet'
            }`}
          >
            <div className="card-details__name">{name}</div>
            <div className="card-details__image">
              {image ? (
                <div
                  className="card-details__image-sprite"
                  style={{
                    backgroundImage: `url(${image})`,
                    filter: isLocked && !isOwned && 'contrast(0)'
                  }}
                ></div>
              ) : (
                <div className="card-details__image-sprite"></div>
              )}
            </div>
            {price && !isOwned && (
              <div className="card-details__image-coins">
                <Coins />
                <span className="card-details__image-coins-price">{price}</span>
              </div>
            )}
          </div>
        )}

        {description && (
          <div
            className={`card-details__description ${
              price && !isOwned && 'card-details__description-coins'
            }`}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </>
  )
}

export default CardDetails
