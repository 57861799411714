import React, { useCallback, useEffect, useState } from 'react'
import {
  clearToken,
  errorMessages,
  getToken,
  setToken,
  fetchWithToken,
  createEndPoint
} from '../../services/util'
import LoginForm from './components/loginForm/LoginForm'
import {
  login,
  loginProvider,
  loginWithLayers
} from '../../services/loginService'
import { useHistory, useLocation } from 'react-router-dom'
// import { getCourseInfo } from '../../services/courseInfoService'
import BBCoverImg from '../../assets/img/bg-login.png'
import rocket from '../../assets/img/rocket.png'
import luna from '../../assets/img/luna.png'
import logoSimple from '../../assets/img/logotipo_morado.svg'
import { ReactComponent as OCLogo } from '../../assets/img/logo_OC.svg'
import FormButton from '../BlueberryFamilyPages/components/FormButton/FormButton'

import './login-page.scss'
import translate from '../../i18n/translate'
import { resetStore } from '../../store/configureStore'
import { setCourseGuid, setSchoolGroup } from '../../assets/data/api'
import usePopupManager from '../../modules/components/PopupManager/usePopupManager'
import { getCourseInfo, getWhoAmI } from '../../services/courseInfoService'
import { getSettings, updateSettings } from '../../services/settingsService'
import { updateRewardsSettings } from '../../services/rewardsService'
import AccessHelper from '../../services/util/accessHelper'
import Loading from '../../modules/components/Loading/Loading'
import { GoogleLogin } from '@react-oauth/google'
import {
  resetPlayerState,
  sendMagicLinkEmail
} from '../../services/userManagementService'
import { enableQAMode, isQAMode } from '../../services/qaModeService'
import { Analysis } from '../../services/analysisService'
import {
  getSourceMetadataParams,
  getSourceInfoParam
} from '../../services/sourceService'
import { isDeviceSupported } from '../../util/mobile'
import { getSsoAppParams } from '../../config/constants/appSchemesAndStoreUrls'

/* eslint-disable */
const domainMap = {
  'blueberry.tangerine-qa1.oneclickaws.tk':
    'https://dashboard-blueberry.tangerine-qa1.oneclicklabs.es/',

  'blueberry.tangerine-qa1.oneclicklabs.es':
    'https://dashboard-blueberry.tangerine-qa1.oneclicklabs.es/',

  'blueberry.tangerine-dev1.oneclicklabs.es':
    'https://dashboard-blueberry.tangerine-dev1.oneclicklabs.es/',

  'blueberry.oneclick.es': 'https://dashboard.blueberry.oneclick.es/',

  'positivo-hml.blueberry.app.br':
    'https://dashboard.positivo-hml.blueberry.app.br/',

  'positivo.blueberry.app.br': 'https://dashboard.positivo.blueberry.app.br/',

  'layers.blueberry.app.br': 'https://dashboard.layers.blueberry.app.br/',

  'localhost:3000': 'http://localhost:3001'
}
/* eslint-enable */

function LoginPage({ isLoginQA, isBlueberryFamily }) {
  const history = useHistory()
  const location = useLocation()

  const { resetPopupStore } = usePopupManager()
  const [isErrorFromToken, setIsErrorFromToken] = useState(false)
  const [isErrorFromGoogle, setIsErrorFromGoogle] = useState(false)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [shouldHideLoginForm, setShouldHideLoginForm] = useState(true)
  const [isWaitingFromServerResponse, setIsWaitingFromServerResponse] =
    useState(false)

  const [errorUser, setErrorUser] = useState(false)

  useEffect(() => {
    // Temporal - para el cambio de urls de tener # a no tener y ser llamadas desde APPs
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ function: 'isNotPractice' })
      )
    }

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Signin,
      Analysis.SEGMENT_EVENTS['Log In Form Viewed']
    )

    // fallback function to hande # in the url because params are not a searchParam if it has a #
    const extractParamFromUrl = (url, propertyName) => {
      // Use a more specific regular expression to match the 'token' parameter
      const regex = new RegExp(`${propertyName}=([^&]+)`)
      const match = url.match(regex)

      if (match) {
        return match[1] // Return the captured value directly
      } else {
        return undefined // Return undefined if not found
      }
    }

    // Check if the url contains a token or an error 1/2
    const url = new URL(window.location.href)
    const layersSession = url.searchParams.get('layers_session')
    const ssoToken =
      url.searchParams.get('token') ||
      extractParamFromUrl(window.location.href, 'token')
    const courseGuid =
      url.searchParams.get('courseGuid') ||
      extractParamFromUrl(window.location.href, 'courseGuid')
    const shortToken = location.search.includes('shortToken')
      ? location.search.substring(1).split('=')[1]
      : null

    if (isLoginQA) {
      enableQAMode()
    }

    if (isQAMode()) {
      console.log('👻 %cGhost Mode', 'color: blueviolet; font-weight:bold')
    }

    const hasToLoginFromOutside = !!(layersSession || ssoToken || shortToken)

    // Login por algún tipo de token "externo"
    if (hasToLoginFromOutside) {
      clearToken()
      window.localStorage.removeItem('bb_game_initialized')

      if (layersSession) {
        loginWithLayersSession()
      } else if (ssoToken) {
        loginWithSSoToken(ssoToken, courseGuid)
      } else if (shortToken) {
        loginWithShortToken(shortToken)
      }
    }
    // Login por token guardado previamente
    else if (getToken()) {
      history.push('/game')
    }
    // Comprobación de errores en URL o esperara acción de usuario en pantalla Login
    else {
      const errorMessage = url.searchParams.get('error')

      if (errorMessage) {
        setIsErrorFromToken(true)
        // Display the error message
        const formattedErrorMessage = decodeURIComponent(errorMessage)
        // check if error is not found then redirect to 404 page
        if (formattedErrorMessage.toLowerCase().indexOf('not_found') > -1) {
          errorRedirectUser('405')
        } else {
          errorRedirectUser('500')
        }
      } else {
        // No token or error found, do nothing
        setIsLoading(false)
        setShouldHideLoginForm(false)
        checkIfThirdPartDomain()
      }
    }
  }, [])

  const checkCookie = useCallback(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 300)
    })
  }, [])

  const loginWithLayersSession = async () => {
    const url = new URL(window.location.href)

    const layersSession = url.searchParams.get('layers_session')
    const layersUserId = url.searchParams.get('layers_user_id')
    const layersCommunityId = url.searchParams.get('layers_community_id')

    const loginResponse = await loginWithLayers({
      layersSession,
      layersUserId,
      layersCommunityId
    })

    if (loginResponse?.token) {
      const token = loginResponse?.token
      loginWithSSoToken(token)
    } else {
      errorRedirectUser('405-contact')
    }
  }

  const loginWithSSoToken = async (ssoToken, courseGuid) => {
    setIsLoading(true)
    setShouldHideLoginForm(true)
    // FYI: Token is set multiple times because component state updates resets the token

    // Set the token in the local storage
    setToken(ssoToken)

    // check if the user is a teacher or a student and redirect to the correct page
    // use the whoami endpoint to get the user role
    const whoAmI = await getWhoAmI(true)
    const role = whoAmI.role_guid
    const isTeacher = role === 'R02'

    if (isTeacher && !courseGuid) {
      // redirect to teacher page
      window.location.href =
        domainMap[window.location.host] + '?token=' + ssoToken
      // clearing the token to logout the teacher from the student page
      clearToken()
      return
    }

    // get settings from the server
    const settings = await getSettings(ssoToken)
    updateSettings({
      ...settings.data,
      loggedInViaToken: true,
      isTeacher: isTeacher
    })

    // Solicitar settings recompensas
    if (!isBlueberryFamily || whoAmI.role_guid !== 'R03') {
      const memberRewardsSettings = await fetchWithToken(
        createEndPoint('users/rewards-settings')
      )
      updateRewardsSettings(memberRewardsSettings.data)
    }

    if (isTeacher && courseGuid) {
      setToken(ssoToken)
      setCourseGuid(courseGuid)
    } else {
      setToken(ssoToken)
      // get the course info
      const courseInfo = await getCourseInfo()
      if (courseInfo) {
        setCourseGuid(courseInfo.courseGuid)
        setSchoolGroup(courseInfo.schoolGroup)
      } else {
        window.location.href = `${window.location.protocol}//${window.location.host}/error-405`
      }
    }
    setToken(ssoToken)

    resetStore()
    resetPopupStore()

    AccessHelper.setAccessInformation(true, AccessHelper.ACCESS_TYPE_BB_SSO)

    // Si es SSO pasa por la pantalla de inicio
    goToSsoStartPage()
  }

  const goToSsoStartPage = () => {
    const auxHost = window.location.host
    const ssoSourceName = auxHost.includes('layers')
      ? 'layers'
      : auxHost.includes('positivo')
      ? 'positivo'
      : 'blueberrySso'
    const ssoUrlParams =
      '?ssoSource=' + ssoSourceName + getSsoAppParams(ssoSourceName)

    const _search = window.location.search
    const [, params] = _search.split('?')
    const fullParams = ssoUrlParams + (params ? '&' + params : '')

    if (isDeviceSupported()) {
      // Se resetea el GUID del curso y el estado del store y establecer acceso reciente y tipo
      // resetPlayerState()
      // history.replace('/title' + fullParams)
      window.location =
        window.location.protocol +
        '//' +
        window.location.host +
        '/title' +
        fullParams
    } else {
      const isBlueberryFamily = window?.organizationData?.isBlueberryFamily

      if (isBlueberryFamily) {
        sendMagicLinkEmail()
        // history.replace({ pathname: '/unsupported' + fullParams })
        window.location =
          window.location.protocol +
          '//' +
          window.location.host +
          '/unsupported' +
          fullParams
      } else {
        // history.replace({ pathname: '/download-app' + fullParams })
        window.location =
          window.location.protocol +
          '//' +
          window.location.host +
          '/download-app' +
          fullParams
      }
    }
  }

  const preLogin = () => {
    setError('')
    setIsWaitingFromServerResponse(true)
    setIsErrorFromGoogle(false)
  }

  const postLogin = async (accessType, isCustomSso, isLoginViaProvider) => {
    const isMainUser = true
    const whoAmI = await getWhoAmI(isMainUser)
    const userToken = getToken(isMainUser)

    // Solicitar settings recompensas si no es un padre
    if (
      !isLoginViaProvider &&
      (!isBlueberryFamily || whoAmI.role_guid !== 'R03')
    ) {
      // role_name:  "School Admin"
      try {
        const memberRewardsSettings = await fetchWithToken(
          createEndPoint('users/rewards-settings')
        )

        updateRewardsSettings(memberRewardsSettings.data)
      } catch (r) {}
    }

    // Para analisis
    const sourceMetadata = JSON.parse(getSourceMetadataParams())
    const metadataDeconstruction = {}
    Object.keys(sourceMetadata).forEach((key) => {
      metadataDeconstruction['analytics_metadata_' + key] = sourceMetadata[key]
    })

    Analysis.sendEvent(Analysis.EVENT.LOGIN, {
      sourceOfUser: getSourceInfoParam(),
      ...metadataDeconstruction
    })
    // Fin analisis --------------------------------------------------

    // check if the user is a teacher or a student and redirect to the correct page
    // use the whoami endpoint to get the user role
    const role = whoAmI.role_guid
    const isTeacher = role === 'R02'

    if (isTeacher && userToken.token) {
      // redirect to teacher page
      window.location.href =
        domainMap[window.location.host] + '?token=' + userToken.token

      // clearing the token to logout the teacher from the student page
      clearToken()
      return
    }

    // Por si hay cambio de usuario (ej: familiares con mismo dispositivo)
    // Se resetea el GUID del curso y el estado del store y establecer acceso reciente y tipo
    // resetPlayerState()
    resetPopupStore()
    AccessHelper.setAccessInformation(true, accessType)

    // Redireccionar a inicio
    if (!isCustomSso) {
      setTimeout(() => {
        history.replace('/game')
      }, 10)
    } else {
      // Se resetea el GUID del curso y el estado del store y establecer acceso reciente y tipo
      resetPlayerState()
      // Si es con token pasa por la pantalla de inicio (si no, audio no funciona)
      goToSsoStartPage()
    }
  }

  const credentialsLogin = useCallback(
    async (credentials) => {
      try {
        await preLogin()
        await login(credentials)
        await postLogin(AccessHelper.ACCESS_TYPE_CREDENTIALS)
      } catch (error) {
        switch (error) {
          case errorMessages.LOGIN_ERROR:
            setError(translate('LOGIN_CREDENTIALS_ERROR'))
            break

          case errorMessages.USER_NOT_FOUND:
            setErrorUser(true)
            setError(translate('LOGIN_PROVIDER_ERROR_USER_NOT_FOUND'))
            break

          case errorMessages.PASS_INCORRECT:
            setErrorUser(false)
            setError(translate('LOGIN_CREDENTIALS_ERROR'))
            break

          case errorMessages.LOGIN_WRONG_FORMAT:
            setErrorUser(false)
            setError(translate('LOGIN_WRONG_FORMAT'))
            break

          case undefined:
            setErrorUser(false)
            setError(translate('LOGIN_WRONG_FORMAT'))
            break

          default:
            setErrorUser(false)
            setError(translate('LOGIN_INTERNET_ERROR'))
            break
        }

        setIsWaitingFromServerResponse(false)

        // errorRedirectUser('405', true)
      }
    },
    [setError, history, checkCookie, setIsWaitingFromServerResponse]
  )

  // check if the domains are positivo-hml.blueberry.app.br and positivo.blueberry.app.br and redirect to 405 page if they are
  const checkIfThirdPartDomain = () => {
    const thirdPartyDomains = [
      'positivo-hml.blueberry.app.br',
      'positivo.blueberry.app.br',
      'layers.blueberry.app.br'
    ]
    const isThirdPartyDomain = thirdPartyDomains.includes(window.location.host)
    if (isThirdPartyDomain) {
      errorRedirectUser('405')
    }
  }

  const loginWithShortToken = async (shortToken) => {
    try {
      await preLogin()

      const shortTokenResponse = await loginProvider(shortToken, 'i2c')

      if (shortTokenResponse.error) {
        throw Error(shortTokenResponse.error)
      }

      await postLogin(AccessHelper.ACCESS_TYPE_GOOGLE_SSO, true, true)

      setIsWaitingFromServerResponse(false)
    } catch (error) {
      console.error('loginWithShortToken ', error)
      if (error === errorMessages.USER_NOT_FOUND) {
        setError(translate('LOGIN_PROVIDER_ERROR_USER_NOT_FOUND'))
        errorRedirectUser('405', true)
      } else {
        setError(translate('LOGIN_INTERNET_ERROR'))
        errorRedirectUser('token', true) // TODO: Este debería ser otro mensaje, pero no tenemos uno genérico
      }
    }
  }

  const googleSuccessHandler = async (result) => {
    try {
      await preLogin()
      await loginProvider(result.credential, 'google')
      await postLogin(AccessHelper.ACCESS_TYPE_GOOGLE_SSO, false, true)
    } catch (error) {
      console.error('- Login with Google error (s): ', error)

      if (error === errorMessages.USER_NOT_FOUND) {
        setError(translate('LOGIN_PROVIDER_ERROR_USER_NOT_FOUND'))
        errorRedirectUser('405', true)
      } else {
        setError(translate('LOGIN_GOOGLE_ERROR'))
        errorRedirectUser('405', true) // TODO: Este debería ser otro mensaje, pero no tenemos uno genérico
      }
    }

    setIsErrorFromGoogle(true)
    setIsWaitingFromServerResponse(false)
  }

  const googleErrorHandler = (error) => {
    console.error('- Login with Google error (e): ', error)

    setIsErrorFromGoogle(true)
    setError(translate('LOGIN_GOOGLE_ERROR'))

    errorRedirectUser('500', true) // TODO: Este me parece el más apropiado, pero tampoco es lo mejor
  }

  // Redirige a pantalla informativa
  // errorType:
  //   '404' not found
  //   '405' user not found
  //   '405-contact' user not found, contact school admin
  //   '500' network problem
  const errorRedirectUser = (errorType, hasReturnButton) => {
    const url =
      errorType !== 'token' ? '/error-' + errorType : '/error-token-expired'
    const returnParameter = hasReturnButton === true ? '?return=true' : ''

    // window.location.href = url + returnParameter
    window.location.href = `${window.location.protocol}//${window.location.host}${url}${returnParameter}`
  }

  return (
    <div className="login-page">
      <img
        className="login-page__cover-img"
        src={BBCoverImg}
        alt="Blueberry background"
      />
      <img className="login-page_rocket" src={rocket} alt="Rocket" />

      <div className="login-page_scroll-container">
        {!shouldHideLoginForm && (
          <div className="login-page__container">
            <img className="login-page_luna" src={luna} alt="Luna" />
            <div className="login-page__bb-logo-container">
              <img
                className="login-page__bb-logo"
                src={logoSimple}
                alt="Blueberry Math"
              />
            </div>
            <div className="login-page-content">
              <div className="login-page__login-form">
                <h2 className="login-title">{translate('login')}</h2>

                <div className="login-page__google-button-container">
                  <GoogleLogin
                    shape="pill"
                    className="login-page__google-button"
                    logo_alignment="left"
                    size="medium"
                    width="360px"
                    onSuccess={googleSuccessHandler}
                    onError={googleErrorHandler}
                  />
                </div>

                <div className="login-page__separator">
                  <span className="login-page__separator-line"></span>
                  <span className="login-page__separator-text">
                    {translate('or_separator')}
                  </span>
                  <span className="login-page__separator-line"></span>
                </div>

                <LoginForm
                  onSubmit={credentialsLogin}
                  error={!isErrorFromToken && !isErrorFromGoogle && error}
                  isWaitingFromServerResponse={isWaitingFromServerResponse}
                  isBlueberryFamily={isBlueberryFamily}
                  errorUser={errorUser}
                />

                {isBlueberryFamily && (
                  <div className="login-page__signup-button">
                    <FormButton
                      variant="transparent"
                      text={translate('family_home_signup')}
                      onClick={() => {
                        history.replace('/blueberry-family-signup')
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="login-page__oc-logo">
                <OCLogo />
              </div>
            </div>
          </div>
        )}
      </div>
      {isLoading && (
        <div className="login-page__loading">
          <Loading />
        </div>
      )}
    </div>
  )
}

export default LoginPage
