import getStatus from '../../helpers/getStatus'
import getShowAsDisabled from './getShowAsDisabled'
import checkFirstTimeClouds from './checkFirstTimeClouds'

export default function createLearningMap({ learningMapData }) {
  return learningMapData.map((mapData, idx) => {
    const { unit_guid, unit_name } = mapData

    return {
      unit_guid,
      unit_name,
      isFirstTime: checkFirstTimeClouds(mapData), // para mostrar las nubes por primera vez
      showAsDisabled: getShowAsDisabled(idx, learningMapData), // para mostrar bloqueada con nubes
      order: idx,
      status: getStatus(mapData)
    }
  })
}
