import React from 'react'
import clock from '../../assets/clock.svg'
import planet from '../../assets/planet_header.svg'
import backgroundHeader from '../../assets/background_header.svg'
import fullscreen from '../../assets/fullscreen.svg'
import fullscreenExit from '../../assets/fullscreen-exit.svg'
import useHeader from './useHeader'

const Header = ({
  totalQuestions = 0,
  currentQuestion = 0,
  formatTime,
  timerQuestion,
  userAvatarImg,
  translate,
  goFullScreen,
  exitFullScreen,
  isFullscreen
}) => {
  const { getProgressBarWidth, areLastSeconds } = useHeader(
    totalQuestions,
    currentQuestion,
    timerQuestion
  )
  const imgStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // objectFit: 'contain',
    objectFit: 'cover',
    zIndex: '101'
  }
  return (
    <>
      <img src={backgroundHeader} style={imgStyle} alt="backgroundHeader" />
      <div className="grid-container">
        <div className="container-fullscreen">
          <div className="container-fullscreen-child">
            <button
              className="container-fullscreen-button"
              onClick={() => {
                isFullscreen ? exitFullScreen() : goFullScreen()
              }}
            >
              <img
                src={isFullscreen ? fullscreenExit : fullscreen}
                style={{ width: '16px', height: '16px', color: 'white' }}
              />
            </button>
          </div>
        </div>
        <div className="grid-item">
          <div
            className={
              'item-content-timer' +
              (areLastSeconds() ? ' item-content-timer_alert' : '')
            }
          >
            <img src={clock} alt="" />
            {formatTime(timerQuestion)}
          </div>
        </div>
        <div className="grid-item progress-bar-container">
          <div className="progress-bar">
            <div className="progress-bar-background" />
            <div
              className="progress-bar-fill"
              style={{ width: getProgressBarWidth() }}
            />
            <img
              src={userAvatarImg}
              alt=""
              className="imgAvatar"
              style={{ left: getProgressBarWidth() }} // aqui ajustamos el avatar para que esté al final de progress-bar-fill
            />
            <img src={planet} alt="" className="imgPlanet" />
          </div>
        </div>
        <div className="grid-item">
          <div className="item-content">
            {currentQuestion} {translate('teacher_practice_header_out_of')}{' '}
            {totalQuestions}
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
