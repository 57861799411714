import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import { Analysis } from '../services/analysisService'
import LoginPage from './LoginPage/LoginPage'
import PrivateRoute from './components/PrivateRoute'
// import LessonPage from './LessonPage/LessonPage'
import ResetDB from './ResetDB/ResetDB'
// import DemoCounter from './demos/DemoCounter/DemoCounter'
import MetaBerryPage from './MetaBerryPage/MetaBerryPage'
import LoadingPage from './LoadingPage/LoadingPage'

import Error404Page from './ErrorPages/Error404Page'
import Error405Page from './ErrorPages/Error405Page'
import Error405ContactPage from './ErrorPages/Error405ContactPage'
import Error500Page from './ErrorPages/Error500Page'
import ErrorUserContentPage from './ErrorPages/ErrorUserContentPage'
import ErrorLicenseExpired from './ErrorPages/ErrorLicenseExpired'
import ErrorTokenExpiredPage from './ErrorPages/ErrorTokenExpiredPage'

import TitleScreenPage from './TitleScreenPage/TitleScreenPage'
// import BlueberryFamilyHome from './BlueberryFamilyPages/BlueberryFamilyHome'
import BlueberryFamilySelectProfile from './BlueberryFamilyPages/BlueberryFamilySelectProfile'
import BlueberryFamilyMyProfile from './BlueberryFamilyPages/BlueberryFamilyMyProfile'
import BlueberryFamilySignup from './BlueberryFamilyPages/BlueberryFamilySignup'
/* import DemoProgress from './demos/DemoProgress/DemoProgress' */
import PricingPage from './PricingPage/PricingPage'
import BlockagePhonesPage from './BlockagePhonesPage/BlockagePhonesPage'
import DownloadAppPage from './DownloadAppPage/DownloadAppPage'
import BlueberryFamilySubscriptionResult from './BlueberryFamilyPages/BlueberryFamilySubscriptionResult'
import RecoverPasswordPage from './RecoverPasswordPage/RecoverPasswordPage'
import RecoverPasswordChangePage from './RecoverPasswordPage/RecoverPasswordChangePage'

import LogoutPage from './LogoutPage/LogoutPage'
import RulerPage from './RulerPage/RulerPage'

import AppPracticePage from './AppPracticePage/AppPracticePage'
import ContestPage from './ContestPage/ContestPage'
import ContestInfoPage from './ContestPage/ContestInfoPage'
import ContestGraphicPage from './ContestPage/ContestGraphicPage'
import ContestResultPage from './ContestPage/ContestResultPage'

import { GoogleOAuthProvider } from '@react-oauth/google'

import { getSubscriptionData } from '../services/licenseService'
import { store } from '../store/configureStore'
import { setSubscriptionData } from '../store/SubscriptionSlice/SubscriptionSlice'

function Routes() {
  const [googleClientId, setGoogleClientId] = useState(null)
  const [isBlueberryFamily, setIsBlueberryFamily] = useState(null)
  const [trialLicense, setTrialLicense] = useState(null)
  const [subscriptionDataRetrieved, setSubscriptionDataRetrieved] =
    useState(false)

  // Hasta que index.html no termina (no se puede usar setEnvironmentData)
  const waitForEnvironmentDataTimerRef = useRef()
  useEffect(() => {
    const checkIfEnvironmentDataLoaded = () => {
      if (!window.organizationData) {
        waitForEnvironmentDataTimerRef.current = setTimeout(
          checkIfEnvironmentDataLoaded,
          100
        )
      } else {
        clearTimeout(waitForEnvironmentDataTimerRef.current)
        setEnvironmentData()
      }
    }

    waitForEnvironmentDataTimerRef.current = setTimeout(
      checkIfEnvironmentDataLoaded,
      100
    )

    return () => {
      clearTimeout(waitForEnvironmentDataTimerRef.current)
    }
  }, [])

  const setEnvironmentData = () => {
    if (!window.isReactiveNativeApp()) {
      setBlueberryData()

      // Analysis.initializeAmplitude()
      // Analysis.initializeHotjar()
      Analysis.initializeGoogleAnalytics()
    } else {
      // En React Native App no se usan, pero para que se cargue les doy valor
      setSubscriptionDataRetrieved({
        subscriptionDataRetrieved: 'appReactNative'
      })
      setGoogleClientId('googleClientId-appReactNative')
      setIsBlueberryFamily('appReactNative')
    }
  }

  const setBlueberryData = async () => {
    // setGoogleClientId('557577454957-hp15tf9rd79ksde6fdj6k54v89ei1mm5.apps.googleusercontent.com')
    setGoogleClientId(window.organizationData.googleClientId)

    setIsBlueberryFamily(window.organizationData.isBlueberryFamily)

    const trialLicenseAux = window.organizationData.trialLicense
    setTrialLicense(typeof trialLicenseAux === 'string' ? trialLicenseAux : '')

    const subscriptionData = await getSubscriptionData()
    store.dispatch(
      setSubscriptionData({
        customerPortalLink: subscriptionData.customer_portal_link,
        pricingTableId: subscriptionData.pricing_table_id,
        stripeKey: subscriptionData.stripe_key
      })
    )

    setSubscriptionDataRetrieved(true)
  }

  // Para establecer todos los textos en mayúsculas si se define por grupo o usuario
  const isUpperCaseRequiredByGroup = useSelector(
    (state) => state?.configuration?.upperCaseRequiredGroup
  )
  const isUpperCaseRequiredByUser = useSelector(
    (state) => state?.configuration?.upperCaseRequiredUser
  )
  useEffect(() => {
    if (isUpperCaseRequiredByGroup || isUpperCaseRequiredByUser) {
      document.body.classList.add('uppercase-required')
    } else {
      document.body.classList.remove('uppercase-required')
    }
  }, [isUpperCaseRequiredByGroup, isUpperCaseRequiredByUser])

  return (
    <>
      {subscriptionDataRetrieved &&
      googleClientId &&
      isBlueberryFamily !== null ? (
        <GoogleOAuthProvider clientId={googleClientId}>
          <Router>
            <div style={{ height: '100%' }}>
              <Switch>
                <PrivateRoute
                  path="/back/:unitGuid"
                  isBlueberryFamily={isBlueberryFamily}
                >
                  <MetaBerryPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/unit/:unitGuid"
                  isBlueberryFamily={isBlueberryFamily}
                >
                  <MetaBerryPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/lesson/:unitGuid/:lessonGuid"
                  isBlueberryFamily={isBlueberryFamily}
                >
                  <MetaBerryPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/game"
                  isBlueberryFamily={isBlueberryFamily}
                >
                  <MetaBerryPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/login"
                  isBlueberryFamily={isBlueberryFamily}
                >
                  <LoginPage
                    isBlueberryFamily={isBlueberryFamily}
                    isLoginQA={false}
                  />
                </PrivateRoute>
                <Route path="/login-qa" isBlueberryFamily={isBlueberryFamily}>
                  <LoginPage
                    isBlueberryFamily={isBlueberryFamily}
                    isLoginQA={true}
                  />
                </Route>
                <Route path="/__lemonade-preview">
                  <AppPracticePage />
                </Route>
                <PrivateRoute path="/reset-db/:unitOrder">
                  <ResetDB />
                </PrivateRoute>
                <Route path="/error-404">
                  <Error404Page />
                </Route>
                <Route path="/error-405">
                  <Error405Page />
                </Route>
                <Route path="/error-405-contact">
                  <Error405ContactPage />
                </Route>
                <Route path="/error-500">
                  <Error500Page />
                </Route>
                <Route path="/error-license-expired">
                  <ErrorLicenseExpired />
                </Route>
                <Route path="/error-token-expired">
                  <ErrorTokenExpiredPage />
                </Route>
                <Route
                  path={[
                    '/error-BBE-100',
                    '/error-BBE-101',
                    '/error-BBE-102',
                    '/error-BBE-103',
                    '/error-BBE-104',
                    '/error-BBE-105',
                    '/error-BBE-106',
                    '/error-BBE-107',
                    '/error-BBE-108'
                  ]}
                >
                  <ErrorUserContentPage />
                </Route>
                <Route path="/subscription-success">
                  <BlueberryFamilySubscriptionResult type="success" />
                </Route>
                <Route path="/subscription-error">
                  <BlueberryFamilySubscriptionResult type="error" />
                </Route>
                <Route path="/title">
                  <TitleScreenPage />
                </Route>
                <Route path="/logout">
                  <LogoutPage />
                </Route>
                {/* <Route path="/blueberry-family">
                  <BlueberryFamilyHome />
                </Route> */}
                <Route path="/blueberry-family-signup">
                  <BlueberryFamilySignup trialLicense={trialLicense} />
                </Route>
                <PrivateRoute path="/change-profile">
                  <BlueberryFamilySelectProfile
                    showMessage={false}
                    isBlueberryFamily={isBlueberryFamily}
                  />
                </PrivateRoute>
                <PrivateRoute path="/select-profile">
                  <BlueberryFamilySelectProfile
                    showMessage={true}
                    isBlueberryFamily={isBlueberryFamily}
                  />
                </PrivateRoute>
                <PrivateRoute path="/my-profile">
                  <BlueberryFamilyMyProfile
                    isBlueberryFamily={isBlueberryFamily}
                  />
                </PrivateRoute>
                <Route path="/recover-password">
                  <RecoverPasswordPage />
                </Route>
                <Route path="/recover-password-change/:token">
                  <RecoverPasswordChangePage />
                </Route>
                <PrivateRoute
                  path="/pricing"
                  isBlueberryFamily={isBlueberryFamily}
                >
                  <PricingPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/unsupported"
                  isBlueberryFamily={isBlueberryFamily}
                >
                  <BlockagePhonesPage />
                </PrivateRoute>
                <Route
                  path="/download-app"
                  isBlueberryFamily={isBlueberryFamily}
                >
                  <DownloadAppPage />
                </Route>
                <Route path="/ruler">
                  <RulerPage />
                </Route>

                <Route path="/concurso">
                  <ContestPage />
                </Route>
                <Route path="/concurso-info">
                  <ContestInfoPage />
                </Route>
                <Route path="/concurso-graphic">
                  <ContestGraphicPage />
                </Route>
                <Route path="/concurso-result">
                  <ContestResultPage />
                </Route>

                <Route path="/loading">
                  <LoadingPage />
                </Route>

                <Route path="/appPractice">
                  <AppPracticePage />
                </Route>

                <Redirect to={'/login' + window.location.search} />
              </Switch>
            </div>
          </Router>
        </GoogleOAuthProvider>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default Routes
