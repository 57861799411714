import React, { useState, useEffect } from 'react'
import Select, { components } from 'react-select'
import { ReactComponent as SelectChevronDown } from '../../../../assets/icons/select-chevron-down.svg'

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectChevronDown />
    </components.DropdownIndicator>
  )
}

// The onChange callback handler gets called with the whole choice object instead of the event object
// onChange={(choice) => setUserChoice(choice.value)}

const FormSelect = ({
  id,
  className = '',
  label,
  options,
  value,
  onChange,
  disabled = false,
  errorMessage = '',
  placeholder,
  tooltip
}) => {
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  return (
    <div className={`form-select ${tooltip ? 'withTooltip' : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      {tooltip && <span className="tooltiptext">{tooltip}</span>}

      <Select
        id={id}
        options={options}
        className="form-select-container"
        classNamePrefix="form-select"
        value={currentValue}
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null
        }}
        closeMenuOnSelect={true}
        isDisabled={disabled}
        isSearchable={false}
        placeholder={placeholder}
        onChange={(selection, id) => {
          setCurrentValue(selection)
          onChange(selection, id)
        }}
      />
      {errorMessage && <div className="form-select__error">{errorMessage}</div>}
    </div>
  )
}

export default FormSelect
