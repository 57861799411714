import React from 'react'
import translate from '../../../../i18n/translate'

import './ShopLevel.scss'

const ShopLevel = ({ level, isLocked }) => {
  return (
    <div
      className={`shop-level shop-level--${isLocked ? 'locked' : 'default'}`}
    >
      {translate('level')}
      <span>{level}</span>
    </div>
  )
}

export default ShopLevel
