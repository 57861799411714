import React from 'react'

const FormButton = ({
  variant,
  display,
  text,
  textAlign,
  icon,
  onClick,
  disabled
}) => {
  return (
    <div
      className={`form-button form-button--${
        variant || 'default'
      } form-button--${
        disabled ? 'disabled' : 'enabled'
      } form-button--text-align-${textAlign || 'center'} form-button--display-${
        display || 'fullwidth'
      }`}
      onClick={onClick}
    >
      {icon && <span className="form-button__icon">{icon}</span>}
      {text && <span className="form-button__text">{text}</span>}
    </div>
  )
}

export default FormButton
