import React, { useEffect } from 'react'
import translate from '../../i18n/translate'
import './LoadingPage.scss'
import { Analysis } from '../../services/analysisService'
import logo from './../../assets/img/logo.svg'

const LoadingPage = ({ count }) => {
  const LOADING_BAR_MAX_SIZE = 343
  const LOADING_STEPS = 26
  const MIN_FILL_SIZE = 24
  useEffect(() => {
    // Temporal - para el cambio de urls de tener # a no tener y ser llamadas desde APPs
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ function: 'isNotPractice' })
      )
    }

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Map Page']
    )
  }, [])

  function normalizeToRange(value) {
    const normalizedValue = (value / LOADING_STEPS) * LOADING_BAR_MAX_SIZE

    return normalizedValue
  }

  function getProgressBarFill(value) {
    const fillValue = Math.max(normalizeToRange(value), MIN_FILL_SIZE)

    return fillValue + 'px'
  }

  return (
    <div className="map-loading_page">
      <div className="map-loading_background">
        <div className="map-loading_avatars">
          <div className="map-loading_container">
            <div className="map-loading_header"></div>
            <div className="map-loading_logo">
              <img src={logo} alt="Loading..." />
            </div>
            <div className="map-loading_footer">
              <div className="map-loading_footer-progress-bar_background">
                <div
                  className="map-loading_footer-fill-progress-bar"
                  style={{ width: getProgressBarFill(count) }}
                ></div>
              </div>
              <div className="map-loading_footer-text">
                {translate('map_loading_text')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingPage
