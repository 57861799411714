import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Analysis } from '../../services/analysisService'

import BlueberryFamilyLayout from './components/BlueberryFamilyLayout/BlueberryFamilyLayout'
import BlueberryFamilyPanel from './components/BlueberryFamilyPanel/BlueberryFamilyPanel'
import FormTabs from './components/FormTabs/FormTabs'
import MyProfilePlayers from './forms/MyProfilePlayers/MyProfilePlayers'
// import MyProfilePassword from './forms/MyProfilePassword/MyProfilePassword'
// import MyProfileInfo from './forms/MyProfileInfo/MyProfileInfo'
import translate from '../../i18n/translate'

const BlueberryFamilyMyProfile = () => {
  const history = useHistory()
  // const [hideProfilePanelUI, setHideProfilePanelUI] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)

  useEffect(() => {
    Analysis.sendEvent(Analysis.EVENT.PROFILE_SCREEN)
  }, [])

  const goToMap = () => history.push('/game')
  const goToSelectPlayer = () => history.push('/select-profile')

  // const handleHideProfilePanelUI = () => setHideProfilePanelUI(true)

  // const handleClosePopups = () => {
  //   setHideProfilePanelUI(false)
  //   setCurrentTab(0)
  // }

  const TAB_TITLES = [
    translate('family_my_profile_tab_players')
    // translate('family_my_profile_tab_password'),
    // translate('family_my_profile_tab_my_info'),
    // translate('family_my_profile_tab_contact')
  ]

  return (
    <div className="blueberry-family-my-profile">
      <BlueberryFamilyLayout>
        <BlueberryFamilyPanel>
          {/* {!hideProfilePanelUI && ( */}
          <div className="blueberry-family-panel__title">
            {translate('family_my_profile_title')}
          </div>
          <FormTabs
            tabTitles={TAB_TITLES}
            currentTab={currentTab}
            handleTabChange={setCurrentTab}
          />
          {/* end hideProfilePanelUI */}
          <div className="blueberry-family-panel__separator"></div>
          <div className="blueberry-family-panel__form">
            {currentTab === 0 && (
              <MyProfilePlayers
                onBackToMap={goToMap}
                onSelectPlayer={goToSelectPlayer}
              />
            )}
            {/* {currentTab === 1 && (
              <MyProfilePassword
                onBackToMap={goToMap}
                onBackToProfile={handleClosePopups}
                onOpenPopup={handleHideProfilePanelUI}
              />
            )}
            {currentTab === 2 && (
              <MyProfileInfo
                onBackToMap={goToMap}
                onBackToProfile={handleClosePopups}
                onOpenPopup={handleHideProfilePanelUI}
              />
            )} */}
          </div>
        </BlueberryFamilyPanel>
      </BlueberryFamilyLayout>
    </div>
  )
}

export default BlueberryFamilyMyProfile
