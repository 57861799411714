import React, { useEffect } from 'react'
import { clearToken } from '../../services/util'
import { Analysis } from '../../services/analysisService'
import GenericError from './GenericError'

const Error405Page = () => {
  // silent logout for user on 405
  // silent means no redirect to login page
  // This is to allow the user to go to login page on refresh because the page does not have any actions
  const logout = () => {
    clearToken()
    window.localStorage.clear()
  }

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Error,
      Analysis.SEGMENT_EVENTS['Error Page 405']
    )

    logout()
  }, [])

  return <GenericError />
}

export default Error405Page
