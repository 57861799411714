import { React, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router'
import translate from '../../i18n/translate'
import {
  getLicenseInformation,
  CONTACT_EMAIL
} from '../../services/licenseService'
import { getWhoAmI } from '../../services/courseInfoService'
import PricingLayout from './components/PricingLayout/PricingLayout'
import PricingPanel from './components/PricingPanel/PricingPanel'
import { logout } from '../../services/logoutService'
import { ReactComponent as LogoutIcon } from '../../assets/img/metaberry_ui/logout.svg'
import FormButton from '../BlueberryFamilyPages/components/FormButton/FormButton'
import { isDeviceSupported } from '../../util/mobile'
import { store } from '../../store/configureStore'
import {
  setIsGameKeyboardBlocked,
  setIsGameUIVisible
} from '../../store/MetaberrySlice/MetaberrySlice'
import { getToken } from '../../services/util'
import { Analysis } from '../../services/analysisService'

const Pricing = () => {
  const location = useLocation()
  const history = useHistory()

  const [userGuid, setUserGuid] = useState(null)
  const [title, setTitle] = useState('')
  const [information, setInformation] = useState('')
  const [daysLeft, setDaysLeft] = useState(0)
  const [_isDeviceSupported, set_isDeviceSupported] = useState(false)

  const [pricingTableId, setPricingTableId] = useState('')
  const [stripeKey, setStripeKey] = useState('')

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Subscription,
      Analysis.SEGMENT_EVENTS['Pricing Page Viewed']
    )

    const subscriptionDataStore = store.getState().subscription
    // console.log(subscriptionDataStore)
    setPricingTableId(subscriptionDataStore.pricingTableId)
    setStripeKey(subscriptionDataStore.stripeKey)

    set_isDeviceSupported(isDeviceSupported())

    if (location?.state?.status) {
      setPageStates(location.state)
    } else {
      getUserLicense()
    }

    getUserGuid()
  }, [])

  const setPageStates = (licenseData) => {
    const isTrial = licenseData.status.includes('trial')
    if (isTrial) {
      if (parseInt(licenseData.leftDays) <= 0) {
        setTitle(translate('pricing_page_title_trial'))
        setInformation(translate('pricing_page_information'))
      } else {
        setTitle(translate('pricing_page_title_trial_with_days_left'))
        setInformation(
          translate('pricing_page_information_trial_with_days_left')
        )
      }
    } else {
      setTitle(translate('pricing_page_title'))
      setInformation(translate('pricing_page_information'))
    }

    setDaysLeft(parseInt(licenseData.leftDays))

    Analysis.sendEvent(Analysis.EVENT.SUBSCRIPTION_PLANS_SCREEN, {
      days_left: licenseData.leftDays,
      is_paid_user: isTrial,
      subscription_status: licenseData?.stripe?.subscription_status,
      plan_interval: licenseData?.stripe?.plan_interval
    })
  }

  const getUserLicense = async () => {
    const licenseInformation = await getLicenseInformation()
    setPageStates(licenseInformation)
  }

  const getUserGuid = async () => {
    const whoAmI = await getWhoAmI(true)
    setUserGuid(whoAmI.guid)
  }

  const doLogout = () => {
    Analysis.sendSegmentTrackEvent(
      Analysis.SEGMENT_EVENTS['Logout Button Click']
    )

    logout()
  }

  const goToGame = () => {
    store.dispatch(setIsGameUIVisible(false))
    store.dispatch(setIsGameKeyboardBlocked(false))
    history.push('/game')
  }

  const sendSupportMail = () => {
    window.open('mailto:' + CONTACT_EMAIL, '_blank').focus()
  }

  return (
    <div className="pricing">
      {_isDeviceSupported &&
        (getToken() && daysLeft > 0 ? (
          <FormButton text={translate('go_back')} onClick={goToGame} />
        ) : (
          <div className="pricing_logout" onClick={doLogout}>
            <LogoutIcon />
            <span>{translate('logout_label')}</span>
          </div>
        ))}
      <PricingLayout>
        {userGuid && pricingTableId && stripeKey && (
          <PricingPanel>
            <div className="pricing-panel__form">
              <div className="pricing-panel__title">{title}</div>
              <div className="pricing-panel__text">{information}</div>

              <div className="pricing-panel__pricing-table-container">
                <stripe-pricing-table
                  pricing-table-id={pricingTableId}
                  publishable-key={stripeKey}
                  client-reference-id={userGuid}
                ></stripe-pricing-table>
              </div>
              <div className="pricing-panel_cancel_account-container">
                <div className="pricing-panel_cancel_account-text">
                  {translate('pricing_page_cancel_account')}
                </div>
                <div
                  className="pricing-panel_cancel_account-mail"
                  onClick={sendSupportMail}
                >
                  {CONTACT_EMAIL}
                </div>
              </div>
            </div>
          </PricingPanel>
        )}
      </PricingLayout>
    </div>
  )
}

export default Pricing
