import { useEffect, useState } from 'react'
import { getDevMode } from '../../../services/settingsService'
import { getAudio } from '../../../services/audioService'
import { getDataSeed } from '../../../assets/data/api'
import { identifyLang } from '../../../i18n/initI18n'

const useMapSearchMenu = () => {
  const [lemonadeData, setLemonadeData] = useState()
  const [seedInputValue, setSeedInputValue] = useState('')
  const [visible, setVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [sizeActivityClass, setSizeActivityClass] = useState('normal')
  const langId =
    window.localStorage.getItem('bb_custom_language') ||
    identifyLang(navigator.language)
  const blueberry_devmode = getDevMode()

  const handleInputChange = (event) => {
    // Función para manejar el cambio de input
    setSeedInputValue(event.target.value) // Actualizar el estado con el nuevo valor del input
  }

  const getData = async (inputSeedData, langId) => {
    const data = await getDataSeed(inputSeedData, langId)
    if (data.error) {
      setErrorMessage('*Semilla no encontrada')
    } else {
      setErrorMessage('')
    }

    setLemonadeData(data.data)
  }
  const previewActivity = () => {
    if (!seedInputValue) {
      setErrorMessage('*Escribe en el campo antes de buscar')
      return
    }

    setVisible(!visible)
    getData(seedInputValue, langId)
  }

  const changeSizeJSONContainer = () => {
    if (sizeActivityClass === 'normal') {
      setSizeActivityClass('fullScreen')
    } else if (sizeActivityClass === 'fullScreen') {
      setSizeActivityClass('normal')
    }
  }

  const getStimulusAudio = async (signal) => {
    // return await getAudio(
    //   undefined,
    //   lemonadeData.reference,
    //   langId,
    //   signal,
    //   false
    // )
    return await getAudio(
      lemonadeData.data.stimulus,
      undefined,
      langId,
      signal,
      false
    )
  }

  const getTextAudio = async (text, signal) => {
    return await getAudio(text, undefined, langId, signal, false)
  }

  useEffect(() => {}, [lemonadeData])
  return {
    handleInputChange,
    seedInputValue,
    previewActivity,
    errorMessage,
    visible,
    lemonadeData,
    setLemonadeData,
    sizeActivityClass,
    changeSizeJSONContainer,
    blueberry_devmode,
    langId,
    getTextAudio,
    getStimulusAudio
  }
}

export default useMapSearchMenu
