import React from 'react'
import PracticeButton from '../../atoms/PracticeButton/PracticeButton'
import { APP_ANALYSIS } from '../../../services/analysisServiceReactNative'
import { store } from '../../../../../store/configureStore'
import SvgRay from '../../../assets/icons/lightning-bolt-yellow.svg'
import translate from '../../../../../i18n/translate'
import './abortPractice.scss'

const AbortPractice = ({
  experience,
  setShowAbortPracticePopup,
  messageToReactNative,
  practiceStartedDate,
  currentExercise,
  previousSeedId,
  numberExercisesCorrect,
  numberExercisesIncorrect,
  isFromApp
}) => {
  const abortPractice = () => {
    APP_ANALYSIS.sendAnalysisEventToReactNative(
      isFromApp,
      APP_ANALYSIS.APP_ANALYSIS_EVENT['Practice Session Aborted'],
      {
        practice_session_id: currentExercise?.session_id,
        program_id: isFromApp
          ? 'from App'
          : store.getState()?.practice?.programId,
        unit_id: currentExercise?.unit_guid,
        lesson_id: currentExercise?.lesson_guid,
        current_seed_id: currentExercise?.question?.seed_guid,
        previous_seed_id: previousSeedId,
        number_activities_correct: numberExercisesCorrect,
        number_activities_incorrect: numberExercisesIncorrect,
        time_taken: new Date() - practiceStartedDate,
        current_session_points: experience
      }
    )

    if (isFromApp) {
      messageToReactNative({ function: 'onPracticeAborted' })
    } else {
      window.dispatchEvent(new CustomEvent('exit-activities'))
    }
  }

  const continuePractice = () => {
    setShowAbortPracticePopup(false)
  }

  return (
    <div className="abort-practice" onClick={continuePractice}>
      <div className="abort-practice_frame">
        <div className="abort-practice_message">
          <div className="abort-practice_message-title">
            {translate('app_practice_abort_title')}
          </div>
          <div className="abort-practice_message-text-1">
            {translate('app_practice_abort_warning')}
          </div>
          <div className="abort-practice_message-text-2">
            <span>{translate('app_practice_abort_earned_1')}</span>
            <span className="abort-practice_message-text-2-experience">
              {experience}
            </span>
            <img src={SvgRay} />
            <span>{translate('app_practice_abort_earned_2')}</span>
          </div>
        </div>

        <div className="abort-practice__action-buttons">
          <PracticeButton
            text={translate('app_practice_abort_exit_button')}
            action={abortPractice}
            type={2}
          />
          <PracticeButton
            text={translate('app_practice_abort_continue_button')}
            action={continuePractice}
            type={1}
          />
        </div>
      </div>
    </div>
  )
}

export default AbortPractice
