import lessonStatus from '../../../config/constants/lessonStatus'

const { NOT_AVAILABLE } = lessonStatus

export default function getAvailableUnits(state) {
  return state.learningMap
    .filter(
      ({ status, showAsDisabled }) => status !== NOT_AVAILABLE || showAsDisabled
    )
    .reverse()
}
