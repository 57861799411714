import React, { useEffect, useState } from 'react'
import MetaTabAvatar from '../MetaTabAvatar/MetaTabAvatar'
import MetaTabPets from '../MetaTabPets/MetaTabPets'
import MetaTabMissions from '../MetaTabMissions/MetaTabMissions'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as CloseIconColor } from '../../../assets/icons/close_color.svg'

const MetaOptionsMenuDrawer = ({
  showOptionsDrawer,
  onCloseDrawer,
  optionsDrawerTab,
  userExperience
}) => {
  const [currentTab, setCurrentTab] = useState(optionsDrawerTab)

  useEffect(() => {
    setCurrentTab(optionsDrawerTab)
  }, [optionsDrawerTab])

  // TODO: Refactor and change names

  let tabClass = ''
  switch (currentTab) {
    case 1:
      // tabClass = 'meta-options-menu-drawer__avatar'
      tabClass = 'meta-options-menu-drawer__pets'
      break
    case 2:
      tabClass = 'meta-options-menu-drawer__pets'
      break
    case 3:
      tabClass = 'meta-options-menu-drawer__missions'
      break
  }

  return showOptionsDrawer &&
    currentTab > 0 &&
    currentTab !== 1 &&
    currentTab !== 2 ? (
    <>
      <div
        className="meta-options-menu-drawer__backdrop"
        onClick={onCloseDrawer}
      ></div>
      <div
        className={`meta-options-menu-drawer__tab-content-container ${
          showOptionsDrawer &&
          'meta-options-menu-drawer__tab-content-container--open'
        } ${tabClass}`}
      >
        <div
          className="meta-options-menu-drawer__tab-content-container-close-button"
          onClick={onCloseDrawer}
        >
          <CloseIcon />
        </div>
        {currentTab === 3 && (
          <div className="meta-options-menu-drawer__tab_content">
            <MetaTabMissions />
          </div>
        )}
      </div>
    </>
  ) : (
    showOptionsDrawer && currentTab > 0 && currentTab > 0 && currentTab < 3 && (
      <>
        <div
          className="meta-options-menu-drawer-new__backdrop"
          onClick={onCloseDrawer}
        ></div>
        <div
          className={`meta-options-menu-drawer-new__tab-content-container ${
            showOptionsDrawer &&
            'meta-options-menu-drawer-new__tab-content-container--open'
          } ${tabClass}`}
        >
          <div
            className="meta-options-menu-drawer-new__tab-content-container-close-button"
            onClick={onCloseDrawer}
          >
            <CloseIconColor />
          </div>

          {currentTab === 2 && <MetaTabPets />}

          {currentTab === 1 && (
            <MetaTabAvatar
              isOpen={showOptionsDrawer && currentTab === 1}
              userExperience={userExperience}
            />
          )}
        </div>
      </>
    )
  )
}

export default MetaOptionsMenuDrawer
