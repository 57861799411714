import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  customerPortalLink: null,
  pricingTableId: null,
  stripeKey: null
}

export const SubscriptionSlice = createSlice({
  name: 'Subscription',
  initialState,
  reducers: {
    setSubscriptionData: (state, { payload }) => {
      state.customerPortalLink = payload.customerPortalLink
      state.pricingTableId = payload.pricingTableId
      state.stripeKey = payload.stripeKey
    }
  }
})

// Action creators are generated for each case reducer function
export const { setSubscriptionData } = SubscriptionSlice.actions

export default SubscriptionSlice.reducer
