import applyTemplate from './applyTemplate'
import { getCurrentLang } from './initI18n'

const translate = (text, templateValues) => {
  if (!text) return ''

  const textsObject = getCurrentLang()
  const literal =
    textsObject[text] !== undefined ? textsObject[text] : '__' + text
  const translation = !templateValues
    ? literal
    : applyTemplate(templateValues, literal)

  return translation
}

window.t = translate

export default translate
