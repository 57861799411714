import { getFamilyCourses } from '../assets/data/api'

const LOCAL_STORAGE_FAMILY_USER_TOKEN_ITEM = 'bb_family_user_token'
const LOCAL_STORAGE_FAMILY_USER_DATA_ITEM = 'bb_family_user_data'
const LOCAL_STORAGE_ALONE_USER_DATA_ITEM = 'bb_alone_user_data'

export function getFamilyUserToken() {
  return window.localStorage.getItem(LOCAL_STORAGE_FAMILY_USER_TOKEN_ITEM)
}

export function setFamilyUserToken(familyUserToken) {
  window.localStorage.setItem(
    LOCAL_STORAGE_FAMILY_USER_TOKEN_ITEM,
    familyUserToken
  )
}

export function removeFamilyUserToken() {
  window.localStorage.removeItem(LOCAL_STORAGE_FAMILY_USER_TOKEN_ITEM)
}

export function getFamilyUserData() {
  let data = window.localStorage.getItem(LOCAL_STORAGE_FAMILY_USER_DATA_ITEM)
  if (data) {
    data = JSON.parse(data)
  }

  return data
}

export function setFamilyUserData(familyUserData) {
  window.localStorage.setItem(
    LOCAL_STORAGE_FAMILY_USER_DATA_ITEM,
    JSON.stringify(familyUserData)
  )
}

export function removeFamilyUserData() {
  window.localStorage.removeItem(LOCAL_STORAGE_FAMILY_USER_DATA_ITEM)
}

export function getAloneUserData() {
  let data = window.localStorage.getItem(LOCAL_STORAGE_ALONE_USER_DATA_ITEM)
  if (data) {
    data = JSON.parse(data)
  }

  return data
}

export function setAloneUserData(familyUserData) {
  window.localStorage.setItem(
    LOCAL_STORAGE_ALONE_USER_DATA_ITEM,
    JSON.stringify(familyUserData)
  )
}

export function removeAloneUserData() {
  window.localStorage.removeItem(LOCAL_STORAGE_ALONE_USER_DATA_ITEM)
}

const parseApiCoursesData = (apiCoursesData) => {
  const members = []

  for (const c in apiCoursesData) {
    const course = apiCoursesData[c]
    for (const u in course.users) {
      const user = course.users[u]

      members.push({
        courseGuid: course.guid,
        educationLevel: course.education_level_name,
        educationYear: course.education_year_name,
        guid: user.person_guid,
        roleGuid: user.role_guid,
        roleName: user.role_name,
        name: user.name,
        lastname: user.lastname
      })
    }
  }

  return members
}

export const getFamilyMembers = async () => {
  const apiCoursesData = await getFamilyCourses()
  const members = parseApiCoursesData(apiCoursesData)

  return members
}
