import Phaser from 'phaser'
import unitStatus from '../../../../config/constants/unitStatus'
import Bar from './Bar'
import translate from '../../../../i18n/translate'

const INFORMATION_PADDING_MODIFIER = 0.9
const INFORMATION_DEPTH = 10
const BAR_RADIUS = 7
const BAR_BACK_COLOR = '#88a7b8'
const BAR_FRONT_COLOR_LOW = '#ff3d30'
const BAR_FRONT_COLOR_MEDIUM = '#ffae56'
const BAR_FRONT_COLOR_FULL = '#00e19a'
const LOCK_PADDING_MODIFIER_X = 0.96
const LOCK_PADDING_MODIFIER_Y = 0.92
const LOCK_RADIUS = 8
const TRUNCATED_PERCENT_CONTROL = 25 // Al menos este porcentaje mayor en el tamaño de fuente para truncar los apellidos

// En el JSON se indica la tile top-left de donde irá el cartel (2x2 tiles)

export default class UnitInformation {
  // spriteFilename se refiere al nombre compartido por su png y json
  constructor(
    scene,
    tileSize,
    frameX,
    frameY,
    mapMarginX,
    mapMarginY,
    unitTitle,
    status,
    ranking,
    isAvailable,
    isRankingShowed
  ) {
    this.scene = scene

    this.tileSize = tileSize

    this.originalX = frameX + mapMarginX
    this.originalY = frameY + mapMarginY
    this.mapMarginX = mapMarginX
    this.mapMarginY = mapMarginY
    this.originalWidth = tileSize * 2
    this.originalHeight = tileSize + tileSize / 4

    this.unitTitle = unitTitle
    this.status = status
    this.ranking = ranking
    this.showRanking =
      isRankingShowed && this.ranking && this.ranking.length > 0
    this.isAvailable = isAvailable

    this.width = this.originalWidth * INFORMATION_PADDING_MODIFIER
    this.height = this.originalHeight * INFORMATION_PADDING_MODIFIER
    this.x = this.originalX + (this.originalWidth - this.width) / 2
    this.y = this.originalY + (this.originalHeight - this.height) / 2
    this.modifierHiddenRanking = this.showRanking ? 0 : this.height / 6

    this.lockWidth = this.originalWidth * LOCK_PADDING_MODIFIER_X
    this.lockHeight = this.originalHeight * LOCK_PADDING_MODIFIER_Y
    this.lockX = this.originalX + (this.originalWidth - this.lockWidth) / 2
    this.lockY = this.originalY + (this.originalHeight - this.lockHeight) / 2

    this.unitTitleText = null
    this.unitProgressBar = null
    this.unitRankingIcon = null
    this.unitBestUserText = null
    this.unitLockRectangle = null
    this.unitLockIcon = null
    this.unitLockText = null
  }

  generateInformation() {
    this.drawSign()

    this.editUnitTitle(this.unitTitle)

    this.editProgressBar(this.status)

    if (this.showRanking) {
      this.editRankingIcon()
      this.editRankingBestUser(this.ranking[0].name, this.ranking[0].lastname)
    }

    if (!this.isAvailable) {
      this.editUnitLock()
    }
  }

  /*
  splitString(text, maxCharsNum) {
    const regularExpression = new RegExp('.{1,' + maxCharsNum + '}(s|$)', 'g')
    const splitedText = text.match(regularExpression)

    for (let s = 1, sMax = splitedText.length; s < sMax; s++) {
      splitedText[s] = splitedText[s].trim()
    }

    return splitedText
  }
  */

  splitString(str) {
    const textLength = str.length
    const spaceIndexes = []

    // Obtener indeces de los espacios
    for (let c = 0; c < textLength; c++) {
      if (str[c] === ' ') spaceIndexes.push(c)
    }

    // Obtener espacio más apto para dividir la cadena
    const halfLength = Math.floor(str.length / 2)
    let auxDistance = null
    let splitIndex = -1
    for (let s = 0, sMax = spaceIndexes.length; s < sMax; s++) {
      const distanceToHalf = Math.abs(spaceIndexes[s] - halfLength)
      if (auxDistance === null || distanceToHalf <= auxDistance) {
        auxDistance = distanceToHalf
        splitIndex = s
      }
    }

    if (splitIndex > 0) {
      return [
        str.slice(0, spaceIndexes[splitIndex]),
        str.slice(spaceIndexes[splitIndex]).trim()
      ]
    }

    return str
  }

  truncateLastNames(lastnames) {
    lastnames = lastnames.split(' ')

    for (let l = 0, lMax = lastnames.length; l < lMax; l++) {
      const lastName = lastnames[l]

      lastnames[l] =
        lastName.length > 4 ? lastName[0].toLocaleUpperCase() + '.' : lastName
    }

    return lastnames.join(' ')
  }

  drawSign() {
    if (this.signTop) this.signTop.destroy()
    if (this.signBottom) this.signBottom.destroy()

    this.signTop = this.scene.add
      .image(this.originalX, this.originalY + 1, 'sign_top')
      .setDisplaySize(this.tileSize * 2, this.tileSize)
      .setOrigin(0)
      .setDepth(INFORMATION_DEPTH)

    this.signBottom = this.scene.matter.add
      .image(
        this.originalX + this.tileSize,
        this.originalY + this.originalHeight + this.tileSize / 4,
        'sign_bottom',
        null,
        { label: 'sign-bottom-' + this.unitTitle, isStatic: true }
      )
      .setDisplaySize(this.tileSize * 2, this.tileSize)
      .setOrigin(0.5)
      .setDepth(INFORMATION_DEPTH)
  }

  // TODO
  editText(text, frameX, frameY, frameWidth, frameHeight, isOneLineForced) {
    let fontSizeOneLine = 8

    const phaserText = this.scene.add
      .text(frameX + frameWidth / 2, frameY + frameHeight / 2, text, {
        fontFamily: 'Quicksand',
        fontStyle: 'bold', // es correcto, pero quicksand no parece tener bold, por lo que no tiene efecto real...
        fontSize: fontSizeOneLine + 'px',
        color: '#000000',
        align: 'center'
      })
      .setOrigin(0.5)
      .setDepth(INFORMATION_DEPTH)

    // Se obtienen el tamaño de fuente más grande para el texto en una línea
    //  y para el texto en 2 líneas y entre ellos se escoge el mayor
    // Salvo que sea obligatorio usar una sóla linea.

    // Una línea
    let isTooBig = false

    while (!isTooBig) {
      if (phaserText.width > frameWidth || phaserText.height > frameHeight) {
        isTooBig = true
        --fontSizeOneLine
      } else {
        phaserText.setFontSize(++fontSizeOneLine + 'px')
      }
    }

    // Dos líneas
    let splitText = ''
    let fontSizeTwoLines = 8
    if (!isOneLineForced) {
      isTooBig = false
      splitText = this.splitString(text)

      phaserText.setText(splitText)
      phaserText.setFontSize(fontSizeTwoLines + 'px')

      while (!isTooBig) {
        if (phaserText.width > frameWidth || phaserText.height > frameHeight) {
          isTooBig = true
          --fontSizeTwoLines
        } else {
          phaserText.setFontSize(++fontSizeTwoLines + 'px')
        }
      }
    }

    // Se establece el texto y su tamaño según lo calculado anteriormente
    if (!isOneLineForced) {
      const finalText = fontSizeTwoLines >= fontSizeOneLine ? splitText : text
      const finalFontSize =
        fontSizeTwoLines >= fontSizeOneLine ? fontSizeTwoLines : fontSizeOneLine

      phaserText.setText(finalText)
      phaserText.setFontSize(finalFontSize + 'px')
    } else {
      phaserText.setFontSize(fontSizeOneLine + 'px')
    }

    return phaserText
  }

  editUnitTitle(title) {
    if (this.unitTitleText) this.unitTitleText.destroy()

    this.unitTitleText = this.editText(
      title,
      this.x,
      this.y + this.modifierHiddenRanking,
      this.width,
      this.height / 3
    )
  }

  editRankingBestUser(name, lastNames) {
    name = name.trim()
    lastNames = lastNames.trim()

    if (this.unitBestUserText) this.unitBestUserText.destroy()

    const margin = 2
    const iconMaxWidth = (2 * this.width) / 7
    const maxWidth = this.width - iconMaxWidth - margin * 2
    const heightThird = this.height / 3

    // Se obtine el tamaño con el texto sin truncar los apellidos
    const bestUser = (name + ' ' + lastNames).trim()
    const unTruncatedText = this.editText(
      bestUser,
      this.x + iconMaxWidth + margin,
      this.y + 2 * heightThird - 2 - margin,
      maxWidth,
      heightThird - 2 * margin
    )
    const unTruncatedSize = parseFloat(unTruncatedText.style.fontSize)

    // Seo obtiene el tamaño del texto con los apellidos truncados
    const bestUserTruncated = (
      name +
      ' ' +
      this.truncateLastNames(lastNames)
    ).trim()
    const truncatedText = this.editText(
      bestUserTruncated,
      this.x + iconMaxWidth + margin,
      this.y + 2 * heightThird - 2 - margin,
      maxWidth,
      heightThird - 2 * margin
    )
    const truncatedSize = parseFloat(truncatedText.style.fontSize)

    // Si el tamaño truncado es al menos un () más grande se usa ese texto y tamaño, sino se usa sin truncar
    const percentDifference = (100 * truncatedSize) / unTruncatedSize - 100
    const isMuchBiggerTruncated = percentDifference >= TRUNCATED_PERCENT_CONTROL

    if (isMuchBiggerTruncated) {
      unTruncatedText.destroy()
      this.unitBestUserText = truncatedText
    } else {
      truncatedText.destroy()
      this.unitBestUserText = unTruncatedText
    }
  }

  editRankingIcon() {
    if (this.unitRankingIcon) this.unitRankingIcon.destroy()

    const maxWidth = (2 * this.width) / 7
    const maxHeight = (2 * this.height) / 7

    this.unitRankingIcon = this.scene.add
      .image(
        this.x + maxWidth / 2,
        this.y + (this.height / 3) * 2 + maxHeight / 2,
        'crown'
      )
      .setOrigin(0.5)
      .setDepth(INFORMATION_DEPTH)

    let ratioA = maxWidth / this.unitRankingIcon.width
    const ratioB = maxHeight / this.unitRankingIcon.height

    if (ratioA > ratioB) ratioA = ratioB

    this.unitRankingIcon.setDisplaySize(
      this.unitRankingIcon.width * ratioA,
      this.unitRankingIcon.height * ratioA
    )
  }

  editProgressBar(status) {
    if (this.unitProgressBar) this.unitProgressBar.destroy()

    let frontColor
    let percent

    switch (status) {
      case unitStatus.LOW_ENERGY: {
        frontColor =
          Phaser.Display.Color.HexStringToColor(BAR_FRONT_COLOR_LOW).color
        percent = 25
        break
      }

      case unitStatus.MEDIUM_ENERGY: {
        frontColor = Phaser.Display.Color.HexStringToColor(
          BAR_FRONT_COLOR_MEDIUM
        ).color
        percent = 50
        break
      }

      case unitStatus.FULL_ENERGY: {
        frontColor =
          Phaser.Display.Color.HexStringToColor(BAR_FRONT_COLOR_FULL).color
        percent = 100
        break
      }

      case unitStatus.NOT_VISITED:
      default: {
        frontColor =
          Phaser.Display.Color.RGBStringToColor('rgba(0,0,0,0)').color
        percent = 0
        break
      }
    }

    const heightThird = this.height / 3

    this.unitProgressBar = new Bar(
      this.scene,
      {
        x: this.x,
        y: this.y + heightThird + heightThird / 4 + this.modifierHiddenRanking,
        width: this.width,
        height: heightThird / 2
      },
      frontColor,
      Phaser.Display.Color.HexStringToColor(BAR_BACK_COLOR).color,
      percent,
      BAR_RADIUS,
      INFORMATION_DEPTH
    )

    this.unitProgressBar.generate()
  }

  editUnitLock() {
    if (this.unitLockRectangle) this.unitLockRectangle.destroy()
    if (this.unitLockIcon) this.unitLockIcon.destroy()
    if (this.unitLockText) this.unitLockText.destroy()

    const centerX = this.lockX + this.lockWidth / 2
    const widthFifth = this.lockWidth / 5
    const heightThird = this.lockHeight / 3
    const verticalMargin = heightThird / 3

    // Rectangulo que tapa la información
    this.unitLockRectangle = this.scene.add.graphics()
    this.unitLockRectangle.setDepth(INFORMATION_DEPTH)
    this.unitLockRectangle.clear()
    this.unitLockRectangle.fillStyle(0x88a7b8, 0.97)
    this.unitLockRectangle.fillRoundedRect(
      this.lockX,
      this.lockY,
      this.lockWidth,
      this.lockHeight,
      LOCK_RADIUS
    )

    // Icono de candado
    const iconCenterY = this.lockY + verticalMargin + heightThird / 2
    this.unitLockIcon = this.scene.add
      .image(centerX, iconCenterY, 'lock')
      .setOrigin(0.5)
      .setDepth(INFORMATION_DEPTH)

    this.unitLockIcon.setDisplaySize(
      this.unitLockIcon.width * (heightThird / this.unitLockIcon.height),
      heightThird
    )

    // Texto bloqueo
    this.unitLockText = this.editText(
      translate('unit_information_locked_unit'),
      this.lockX + widthFifth / 2,
      iconCenterY + heightThird / 2 + verticalMargin,
      4 * widthFifth,
      heightThird,
      true
    )
  }

  updateMapMargins(mapMarginX, mapMarginY) {
    try {
      const modX = this.mapMarginX - mapMarginX
      const modY = this.mapMarginY - mapMarginY

      if (this.signTop) {
        this.signTop.x -= modX
        this.signTop.y -= modY
      }

      if (this.signBottom) {
        this.signBottom.x -= modX
        this.signBottom.y -= modY
      }

      if (this.unitTitleText) {
        this.unitTitleText.x -= modX
        this.unitTitleText.y -= modY
      }

      if (this.unitProgressBar) {
        const backBar = this.unitProgressBar.backgroundBar
        backBar.x -= modX
        backBar.y -= modY

        const foreBar = this.unitProgressBar.foregroundBar
        if (foreBar) {
          foreBar.x -= modX
          foreBar.y -= modY
        }
      }

      if (this.unitRankingIcon) {
        this.unitRankingIcon.x -= modX
        this.unitRankingIcon.y -= modY
      }

      if (this.unitBestUserText) {
        this.unitBestUserText.x -= modX
        this.unitBestUserText.y -= modY
      }

      if (this.unitLockRectangle) {
        this.unitLockRectangle.x -= modX
        this.unitLockRectangle.y -= modY
      }

      if (this.unitLockIcon) {
        this.unitLockIcon.x -= modX
        this.unitLockIcon.y -= modY
      }

      if (this.unitLockText) {
        this.unitLockText.x -= modX
        this.unitLockText.y -= modY
      }

      this.mapMarginX = mapMarginX
      this.mapMarginY = mapMarginY
    } catch (updateMapMarginsError) {
      console.error('UnitInformation updateMapMargins', updateMapMarginsError)
    }
  }

  destroy() {
    if (this.unitTitleText) this.unitTitleText.destroy()
    if (this.unitProgressBar) this.unitProgressBar.destroy()
    if (this.unitRankingIcon) this.unitRankingIcon.destroy()
    if (this.unitBestUserText) this.unitBestUserText.destroy()
    if (this.unitLockRectangle) this.unitLockRectangle.destroy()
    if (this.unitLockIcon) this.unitLockIcon.destroy()
    if (this.unitLockText) this.unitLockText.destroy()
    if (this.signTop) this.signTop.destroy()
    if (this.signBottom) this.signBottom.destroy()
  }
}
