import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  level: 0,
  progress: 0,
  totalExperience: 100
}

export const UserExperienceSlice = createSlice({
  name: 'UserExperience',
  initialState,
  reducers: {
    setUserExperienceProgress: (state, { payload }) => {
      state.level = payload.level
      state.totalExperience = payload.next_level
      state.previousExperience = payload.previous_level
      state.progress = payload.exp % payload.next_level
    },
    resetUserExperience: () => initialState
  }
})

// Action creators are generated for each case reducer function
export const { setUserExperienceProgress, resetUserExperience } =
  UserExperienceSlice.actions

export default UserExperienceSlice.reducer
