import React, { useCallback, useEffect, useState } from 'react'
import translate from '../../../../i18n/translate'
import InputWithLabel from '../../../LoginPage/components/InputWithLabel/InputWithLabel'

function PasswordForm({
  onSubmit,
  newPasswordError,
  newPasswordCopyError,
  validatePasswordFormat,
  disabled
}) {
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordCopy, setNewPasswordCopy] = useState('')

  const isFormError =
    newPassword === '' ||
    newPasswordCopy === '' ||
    newPasswordError !== '' ||
    newPasswordCopyError !== ''
      ? 'error'
      : ''

  useEffect(() => {
    validatePasswordFormat(newPassword, newPasswordCopy)
  }, [newPassword, newPasswordCopy])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit({ newPassword })
    },
    [onSubmit, newPassword, newPasswordCopy]
  )

  return (
    <div className={'password-form'}>
      <h2 className="form-title">{translate('new_password_title')}</h2>
      <form>
        <div className="form-group">
          <div className="form-input-container">
            <InputWithLabel
              id="password"
              label={translate('family_my_profile_password_label')}
              value={newPassword}
              isError={newPassword === '' || newPasswordError !== ''}
              onChange={setNewPassword}
              inputType="password"
              placeholder={translate(
                'family_signup_signup_password_placeholder'
              )}
            />
            {newPasswordError.length > 0 && (
              <div className="form-input-error">{newPasswordError}</div>
            )}
          </div>
          <div className="form-input-container">
            <InputWithLabel
              id="passwordCopy"
              label={translate('family_my_profile_password_copy_label')}
              value={newPasswordCopy}
              isError={newPasswordCopy === '' || newPasswordCopyError !== ''}
              onChange={setNewPasswordCopy}
              inputType="password"
              placeholder={translate(
                'family_my_profile_password_copy_placeholder'
              )}
            />
            {newPasswordCopyError.length > 0 && (
              <div className="form-input-error">{newPasswordCopyError}</div>
            )}
          </div>
        </div>

        <div className="submit-control">
          <button
            type="submit"
            className="btn"
            onClick={handleSubmit}
            disabled={isFormError || disabled}
          >
            {translate('recover_password_save')}
          </button>
        </div>
      </form>
    </div>
  )
}

export default PasswordForm
