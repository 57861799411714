import { React } from 'react'
import './AvatarCardSlider.scss'
import { ReactComponent as CheckImg } from '../../../../../assets/img/button_check.svg'

const AvatarCardSlider = ({
  isSelected,
  onClickSelect,
  avatarImage,
  avatarName,
  avatarDescription,
  isIntro,
  index,
  moveToIndex
}) => {
  let cardVariantStyle = 'default'

  if (isSelected) {
    cardVariantStyle = 'selected'
  }

  return (
    <>
      <div
        className={`avatar-card-slider avatar-card-slider--default`}
        onClick={
          isIntro
            ? () => {}
            : () => {
                // moveToIndex(index)
              }
        }
      >
        {isSelected && !isIntro && (
          <CheckImg className="avatar-card-slider__check" />
        )}

        <div
          className={`avatar-card-slider__wrapper avatar-card-slider__wrapper--${cardVariantStyle}`}
        >
          <div
            className={`avatar-card-slider__image avatar-card-slider__image--default`}
          >
            {avatarImage ? (
              <>
                <img src={avatarImage} />
              </>
            ) : (
              <div className="avatar-card-slider__sprite"></div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AvatarCardSlider
