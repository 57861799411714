import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'
import Unlock from '../../assets/unlock.png'
import { store } from '../../../../../store/configureStore'
import {
  setMoveCameraToPlayer,
  setMoveCameraToObject
} from '../../../../../store/MetaberrySlice/MetaberrySlice'
import translate from '../../../../../i18n/translate'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
import {
  getPanelRenderCount,
  updatePanelRenderCount,
  getSoundAssistance,
  getEffectsUserConfig
} from '../../../../../services/settingsService'
import { getCurrentLangString } from '../../../../../i18n/initI18n'

const RewardBatteryUnlock = ({ handleCheck }) => {
  const { renderSoundFXController, playSoundEffect } = useSound()
  const [isPopupVisible, setIsPopupVisible] = useState(false)

  useEffect(() => {
    // Hasta que termine el movimiento de cámara el popup ha de ser invisible
    const parent = document.querySelector('.popup-manager')
    if (parent) parent.className += ' popup-manager-transparent'

    updatePanelRenderCount(getPanelRenderCount() + 1)

    store.dispatch(setMoveCameraToObject({ active: true }))

    return () => {
      updatePanelRenderCount(getPanelRenderCount() - 1)
    }
  }, [])

  useSelector((state) => {
    const moveCameraToObjectIndex = state.metaberry.moveCameraToObjectIndex

    if (moveCameraToObjectIndex === -1 && !isPopupVisible) {
      const parent = document.querySelector('.popup-manager')
      if (parent) parent.className = 'popup-manager'

      setIsPopupVisible(true)

      getSoundAssistance() &&
        getEffectsUserConfig() === 1 &&
        playSoundEffect('rewardUnlockObject_' + getCurrentLangString())
    }
  })

  const onClose = () => {
    handleCheck()

    // Mover al personaje
    store.dispatch(setMoveCameraToPlayer(true))
  }

  return (
    isPopupVisible && (
      <>
        <div className="reward-popup__backdrop" onClick={onClose}></div>
        <div className="reward-popup">
          {renderSoundFXController()}
          <div className="reward-popup__top"></div>
          <div className="reward-popup__close-icon" onClick={onClose}>
            <CloseIcon />
          </div>
          <div className="reward-popup__text">
            {translate('reward_unlock_battery')}
          </div>
          <img className="reward-popup__center-image" src={Unlock} />
          <div className="reward-popup__bottom"></div>
        </div>
      </>
    )
  )
}
export default RewardBatteryUnlock
