import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import translate from '../../i18n/translate'
import logoSimple from '../../assets/img/logotipo_morado.svg'
import BBCoverImg2 from '../../assets/img/bg-login.png'
import luna from '../../assets/img/luna.png'
import rocket from '../../assets/img/rocket.png'
import { ReactComponent as OCLogo } from '../../assets/img/oc-logo.svg'
import { ReactComponent as BackIcon } from '../../assets/icons/arrow-circle-left.svg'
import FormButton from '../BlueberryFamilyPages/components/FormButton/FormButton'
import EmailForm from './components/EmailForm/EmailForm'
import EmailSent from './components/EmailSent/EmailSent'
import './recoverPasswordPage.scss'
import { recoverAccount } from '../../services/userManagementService'
import { getCurrentLangString } from '../../i18n/initI18n'
import { Analysis } from '../../services/analysisService'

function RecoverPasswordPage() {
  const ERROR_MESSAGES = {
    EMAIL_NOT_VALID: translate('family_signup_signup_error_email_not_valid'),
    EMAIL_NOT_FOUND: translate('recover_password_error_user_not_found')
  }

  let sendingTimeout = null

  const history = useHistory()

  const [isWorking, setIsWorking] = useState(false)
  const [error, setError] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  useEffect(() => {
    Analysis.sendEvent(Analysis.EVENT.RECOVER_PASSWORD_SCREEN)
  }, [])

  const goToLogin = useCallback(() => {
    history.push(`/login`)
  }, [history])

  const isEmail = (emailToTest) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailToTest)

  const checkEmail = async ({ email }) => {
    setIsWorking(true)
    clearTimeout(sendingTimeout)

    sendingTimeout = setTimeout(async () => {
      const recoverAccountResponse = await recoverAccount(
        email,
        window.location.origin,
        getCurrentLangString()
      )

      if (recoverAccountResponse.status !== 'success') {
        setError(ERROR_MESSAGES.EMAIL_NOT_FOUND)
      } else {
        setEmailSent(true)
        Analysis.sendEvent(Analysis.EVENT.RECOVER_PASSWORD)
      }

      setIsWorking(false)
    }, 250)
  }

  const validateEmailFormat = (email) => {
    if (email.length > 0) {
      if (!isEmail(email)) {
        setError(ERROR_MESSAGES.EMAIL_NOT_VALID)
      } else {
        setError('')
      }
    }
  }

  return (
    <div className="recover-password-page">
      <img
        className="recover-password-page__cover-img"
        src={BBCoverImg2}
        alt="Blueberry background"
      />
      <img className="recover-password-page_rocket" src={rocket} alt="Rocket" />
      <div className="recover-password-page-container">
        <div className="recover-password-page__content">
          <img className="recover-password-page_luna" src={luna} alt="Luna" />

          <div className="recover-password-page__bb-logo-container">
            <img
              className="recover-password-page__bb-logo"
              src={logoSimple}
              alt="Blueberry Math"
            />
          </div>
          <div className="recover-password-page__content">
            {!emailSent ? (
              <EmailForm
                onSubmit={checkEmail}
                error={error}
                validateEmailFormat={validateEmailFormat}
                disabled={isWorking}
              />
            ) : (
              <EmailSent onButtonClick={goToLogin} />
            )}
          </div>

          <div className="recover-password-page__footer">
            {!emailSent && (
              <FormButton
                icon={<BackIcon />}
                text={translate('recover_password_back')}
                variant="transparent"
                textAlign="left"
                onClick={goToLogin}
              />
            )}
            <div className="recover-password-page__oc-logo">
              <OCLogo />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecoverPasswordPage
