import React from 'react'
import { createPortal } from 'react-dom'

const FormPopup = ({
  aditionalClass,
  size,
  title,
  subtitle,
  children,
  actions
}) => {
  return (
    <>
      {createPortal(
        <div className={`form-popup__backdrop ${aditionalClass || ''}`}>
          <div className={`form-popup form-popup--size-${size || 'default'}`}>
            {title && <div className="form-popup__title">{title}</div>}
            {subtitle && <div className="form-popup__subtitle">{subtitle}</div>}
            {children && <div className="form-popup__content">{children}</div>}
            {actions && <div className="form-popup__actions">{actions}</div>}
          </div>
        </div>,
        document.body
      )}
    </>
  )
}

export default FormPopup
