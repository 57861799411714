const INFORMATION_PADDING_MODIFIER = 0.75
const INFORMATION_DEPTH = 10

const MOVEMENT_NONE = ['disabled', 'blocked']
const MOVEMENT_SHAKE = ['broked_start', 'empty']
const MOVEMENT_PULSE = ['init', 'install']
const MOVEMENT_FLASH = ['charging', 'complete', 'start']

export default class LessonInformation {
  // spriteFilename se refiere al nombre compartido por su png y json
  constructor(
    scene,
    frameX,
    frameY,
    mapMarginX,
    mapMarginY,
    frameWidth,
    frameHeight,
    challenges,
    status,
    isAvailable,
    isPreviousLessonAvailable
  ) {
    this.scene = scene

    this.width = frameWidth * INFORMATION_PADDING_MODIFIER
    this.height = frameHeight * INFORMATION_PADDING_MODIFIER
    this.x = frameX + frameWidth / 2
    this.y = frameY + frameHeight / 2
    this.mapMarginX = mapMarginX
    this.mapMarginY = mapMarginY

    this.challenges = challenges
    this.status = status
    this.isAvailable = isAvailable
    this.isPreviousLessonAvailable = isPreviousLessonAvailable

    this.lessonIcon = null
    this.movementKey = null
  }

  generateInformation() {
    this.editLessonIcon(
      this.status,
      this.isAvailable,
      this.isPreviousLessonAvailable
    )
  }

  editLessonIcon(
    status,
    isAvailable,
    isPreviousLessonAvailable,
    updatedMapMarginX,
    updatedMapMarginY
  ) {
    if (this.scene && this.scene.add && this.scene.add.scene) {
      // Antes de destroy para que no falle por falta de icono en el momento
      if (updatedMapMarginX && updatedMapMarginY) {
        this.updateMapMargins(updatedMapMarginX, updatedMapMarginY)
      }

      this.destroy()

      let lessonIconKey = status

      if (!isAvailable) {
        if (isPreviousLessonAvailable) lessonIconKey = 'blocked'
        else lessonIconKey = 'disabled'
      }

      // Se decide usar el mismo icono para ambos para no confundir a los estudiantes
      if (lessonIconKey === 'start') {
        lessonIconKey = 'complete'
      }

      this.lessonIcon = this.scene.add
        .image(
          this.x + this.mapMarginX,
          this.y + this.mapMarginY,
          lessonIconKey
        )
        .setOrigin(0.5)
        .setDepth(INFORMATION_DEPTH)

      let ratioA = this.width / this.lessonIcon.width
      const ratioB = this.height / this.lessonIcon.height

      if (ratioA > ratioB) ratioA = ratioB

      this.lessonIcon.setDisplaySize(
        this.lessonIcon.width * ratioA,
        this.lessonIcon.height * ratioA
      )

      this.setMovement(lessonIconKey)
    }
  }

  setMovement(key) {
    this.movementKey = key
    let movement = -1

    if (MOVEMENT_NONE.indexOf(key) !== -1) return

    if (MOVEMENT_SHAKE.indexOf(key) !== -1) movement = 0
    if (MOVEMENT_FLASH.indexOf(key) !== -1) movement = 1
    if (MOVEMENT_PULSE.indexOf(key) !== -1) movement = 2

    switch (movement) {
      case 0: {
        let modifier = this.width * 0.1
        let count = 0

        this.lessonIconTween = this.scene.tweens.add({
          targets: this.lessonIcon,
          duration: 100,
          x: this.x + this.mapMarginX + modifier,
          ease: 'Expo',
          repeat: -1,
          onRepeat: () => {
            if (++count < 5) {
              modifier = -modifier
              this.lessonIconTween.data[0].end =
                this.x + this.mapMarginX + modifier
            } else if (count < 20) {
              this.lessonIconTween.data[0].end = this.x + this.mapMarginX
            } else count = 0
          }
        })

        break
      }

      case 1: {
        this.lessonIconTween = this.scene.tweens.add({
          targets: this.lessonIcon,
          duration: 1000, // Tanto ida como vuelta (yoyo v y ^)
          alpha: 0.4,
          ease: 'Linear',
          yoyo: true,
          repeat: -1,
          delay: 1000, // Antes de empezar
          repeatDelay: 1200, // En estado final
          hold: 300 // Antes de yoyo
        })

        break
      }

      case 2: {
        this.lessonIconTween = this.scene.tweens.add({
          targets: this.lessonIcon,
          duration: 700,
          scale: 0.5,
          ease: 'Quintic.easeInOut',
          repeat: -1,
          yoyo: true
        })

        break
      }
    }
  }

  updateMapMargins(mapMarginX, mapMarginY) {
    const modX = this.mapMarginX - mapMarginX
    const modY = this.mapMarginY - mapMarginY

    if (this.lessonIcon) {
      this.lessonIcon.x -= modX
      this.lessonIcon.y -= modY

      // Si su "Tween" es de tipo "SHAKE" (customo que afecta a eje X)
      if (
        this.lessonIconTween &&
        MOVEMENT_SHAKE.indexOf(this.movementKey) !== -1
      ) {
        this.lessonIconTween.stop()
        this.lessonIconTween.remove()
        this.lessonIconTween = null

        this.setMovement(this.movementKey)
      }
    }

    this.mapMarginX = mapMarginX
    this.mapMarginY = mapMarginY
  }

  destroy() {
    if (this.lessonIconTween) {
      this.lessonIconTween.stop()
      this.lessonIconTween.remove()
      this.lessonIconTween = null
    }

    if (this.lessonIcon) {
      this.lessonIcon.destroy()
      this.lessonIcon = null
    }
  }
}
