import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import BlueberryFamilyLayout from './components/BlueberryFamilyLayout/BlueberryFamilyLayout'
import BlueberryFamilyPanel from './components/BlueberryFamilyPanel/BlueberryFamilyPanel'
import SuccessImg from '../../assets/img/blueberro_exclamation.png'
import ErrorImg from '../../assets/img/blueberro_question.png'
import FormButton from './components/FormButton/FormButton'
import translate from '../../i18n/translate'
import { getLicenseInformation } from '../../services/licenseService'
import { Analysis } from '../../services/analysisService'

const BlueberryFamilySubscriptionResult = ({ type }) => {
  const history = useHistory()

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Subscription,
      type !== 'error'
        ? Analysis.SEGMENT_EVENTS['Subscription Success Page']
        : Analysis.SEGMENT_EVENTS['Subscription Error Page']
    )

    checkUserSubscriptionAction()
  }, [])

  const checkUserSubscriptionAction = async () => {
    const licenseInformation = await getLicenseInformation()

    if (licenseInformation?.stripe?.subscription_status) {
      Analysis.sendEvent(Analysis.EVENT.SUBSCRIPTON_ACTION_OUTSIDE, {
        subscription_status: licenseInformation?.stripe?.subscription_status,
        plan_interval: licenseInformation?.stripe?.plan_interval
      })
    }
  }

  const handleGoToGame = () => history.push('/game')

  return (
    <div className="blueberry-family-subscription-result">
      <BlueberryFamilyLayout>
        <BlueberryFamilyPanel>
          <div className="blueberry-family-panel__title">
            {translate(
              type === 'success'
                ? 'family_subscription_success_title'
                : 'family_subscription_error_title'
            )}
          </div>
          <div className="blueberry-family-panel__img">
            {type === 'success' ? (
              <img
                className="blueberry-family-panel__img--success"
                src={SuccessImg}
                alt=""
              />
            ) : (
              <img
                className="blueberry-family-panel__img--error"
                src={ErrorImg}
                alt=""
              />
            )}
          </div>
          <div className="blueberry-family-panel__actions blueberry-family-panel__actions--column">
            <FormButton
              text={translate('back_to_map')}
              onClick={handleGoToGame}
            />
          </div>
        </BlueberryFamilyPanel>
      </BlueberryFamilyLayout>
    </div>
  )
}

export default BlueberryFamilySubscriptionResult
