// Generated by CoffeeScript 1.8.0
const _heap = () => {
  const floor = Math.floor
  const min = Math.min

  /*
    Default comparison function to be used
     */

  const defaultCmp = function (x, y) {
    if (x < y) {
      return -1
    }
    if (x > y) {
      return 1
    }
    return 0
  }

  /*
    Insert item x in list a, and keep it sorted assuming a is sorted.
    
    If x is already in a, insert it to the right of the rightmost x.
    
    Optional args lo (default 0) and hi (default a.length) bound the slice
    of a to be searched.
     */

  const insort = function (a, x, lo, hi, cmp) {
    let mid
    if (lo == null) {
      lo = 0
    }
    if (cmp == null) {
      cmp = defaultCmp
    }
    if (lo < 0) {
      throw new Error('lo must be non-negative')
    }
    if (hi == null) {
      hi = a.length
    }
    while (lo < hi) {
      mid = floor((lo + hi) / 2)
      if (cmp(x, a[mid]) < 0) {
        hi = mid
      } else {
        lo = mid + 1
      }
    }
    return [].splice.apply(a, [lo, lo - lo].concat(x))
  }

  const _siftdown = function (array, startpos, pos, cmp) {
    let parent, parentpos
    if (cmp == null) {
      cmp = defaultCmp
    }
    const newitem = array[pos]
    while (pos > startpos) {
      parentpos = (pos - 1) >> 1
      parent = array[parentpos]
      if (cmp(newitem, parent) < 0) {
        array[pos] = parent
        pos = parentpos
        continue
      }
      break
    }

    array[pos] = newitem

    return array[pos]
  }

  const _siftup = function (array, pos, cmp) {
    let childpos, rightpos
    if (cmp == null) {
      cmp = defaultCmp
    }
    const endpos = array.length
    const startpos = pos
    const newitem = array[pos]
    childpos = 2 * pos + 1
    while (childpos < endpos) {
      rightpos = childpos + 1
      if (rightpos < endpos && !(cmp(array[childpos], array[rightpos]) < 0)) {
        childpos = rightpos
      }
      array[pos] = array[childpos]
      pos = childpos
      childpos = 2 * pos + 1
    }
    array[pos] = newitem
    return _siftdown(array, startpos, pos, cmp)
  }

  /*
    Push item onto heap, maintaining the heap invariant.
     */

  const heappush = function (array, item, cmp) {
    if (cmp == null) {
      cmp = defaultCmp
    }
    array.push(item)
    return _siftdown(array, 0, array.length - 1, cmp)
  }

  /*
    Pop the smallest item off the heap, maintaining the heap invariant.
     */

  const heappop = function (array, cmp) {
    let returnitem
    if (cmp == null) {
      cmp = defaultCmp
    }
    const lastelt = array.pop()
    if (array.length) {
      returnitem = array[0]
      array[0] = lastelt
      _siftup(array, 0, cmp)
    } else {
      returnitem = lastelt
    }
    return returnitem
  }

  /*
    Pop and return the current smallest value, and add the new item.
    
    This is more efficient than heappop() followed by heappush(), and can be
    more appropriate when using a fixed size heap. Note that the value
    returned may be larger than item! That constrains reasonable use of
    this routine unless written as part of a conditional replacement:
        if item > array[0]
          item = heapreplace(array, item)
     */

  const heapreplace = function (array, item, cmp) {
    if (cmp == null) {
      cmp = defaultCmp
    }
    const returnitem = array[0]
    array[0] = item
    _siftup(array, 0, cmp)
    return returnitem
  }

  /*
    Fast version of a heappush followed by a heappop.
     */

  const heappushpop = function (array, item, cmp) {
    let _ref
    if (cmp == null) {
      cmp = defaultCmp
    }
    if (array.length && cmp(array[0], item) < 0) {
      _ref = [array[0], item]
      item = _ref[0]
      array[0] = _ref[1]
      _siftup(array, 0, cmp)
    }
    return item
  }

  /*
    Transform list into a heap, in-place, in O(array.length) time.
     */

  const heapify = function (array, cmp) {
    let i, _i, _len
    if (cmp == null) {
      cmp = defaultCmp
    }
    const _ref1 = function () {
      const _results1 = []
      // eslint-disable-next-line yoda
      for (
        let _j = 0, _ref = floor(array.length / 2);
        _ref >= 0 ? _j < _ref : _j > _ref;
        _ref >= 0 ? _j++ : _j--
      ) {
        _results1.push(_j)
      }
      return _results1
    }
      .apply(this)
      .reverse()
    const _results = []
    for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
      i = _ref1[_i]
      _results.push(_siftup(array, i, cmp))
    }
    return _results
  }

  /*
    Update the position of the given item in the heap.
    This function should be called every time the item is being modified.
     */

  const updateItem = function (array, item, cmp) {
    if (cmp == null) {
      cmp = defaultCmp
    }
    const pos = array.indexOf(item)
    if (pos === -1) {
      return
    }
    _siftdown(array, 0, pos, cmp)
    return _siftup(array, pos, cmp)
  }

  /*
    Find the n largest elements in a dataset.
     */

  const nlargest = function (array, n, cmp) {
    let elem, _i, _len
    if (cmp == null) {
      cmp = defaultCmp
    }
    const result = array.slice(0, n)
    if (!result.length) {
      return result
    }
    heapify(result, cmp)
    const _ref = array.slice(n)
    for (_i = 0, _len = _ref.length; _i < _len; _i++) {
      elem = _ref[_i]
      heappushpop(result, elem, cmp)
    }
    return result.sort(cmp).reverse()
  }

  /*
    Find the n smallest elements in a dataset.
     */

  const nsmallest = function (array, n, cmp) {
    let elem, los, result, _i, _j, _len, _ref, _ref1
    if (cmp == null) {
      cmp = defaultCmp
    }
    if (n * 10 <= array.length) {
      result = array.slice(0, n).sort(cmp)
      if (!result.length) {
        return result
      }
      los = result[result.length - 1]
      _ref = array.slice(n)
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        elem = _ref[_i]
        if (cmp(elem, los) < 0) {
          insort(result, elem, 0, null, cmp)
          result.pop()
          los = result[result.length - 1]
        }
      }
      return result
    }
    heapify(array, cmp)
    const _results = []
    for (
      _j = 0, _ref1 = min(n, array.length);
      _ref1 >= 0 ? _j < _ref1 : _j > _ref1;
      _ref1 >= 0 ? ++_j : --_j
    ) {
      _results.push(heappop(array, cmp))
    }
    return _results
  }

  const Heap = (function () {
    Heap.push = heappush

    Heap.pop = heappop

    Heap.replace = heapreplace

    Heap.pushpop = heappushpop

    Heap.heapify = heapify

    Heap.updateItem = updateItem

    Heap.nlargest = nlargest

    Heap.nsmallest = nsmallest

    function Heap(cmp) {
      this.cmp = cmp != null ? cmp : defaultCmp
      this.nodes = []
    }

    Heap.prototype.push = function (x) {
      return heappush(this.nodes, x, this.cmp)
    }

    Heap.prototype.pop = function () {
      return heappop(this.nodes, this.cmp)
    }

    Heap.prototype.peek = function () {
      return this.nodes[0]
    }

    Heap.prototype.contains = function (x) {
      return this.nodes.indexOf(x) !== -1
    }

    Heap.prototype.replace = function (x) {
      return heapreplace(this.nodes, x, this.cmp)
    }

    Heap.prototype.pushpop = function (x) {
      return heappushpop(this.nodes, x, this.cmp)
    }

    Heap.prototype.heapify = function () {
      return heapify(this.nodes, this.cmp)
    }

    Heap.prototype.updateItem = function (x) {
      return updateItem(this.nodes, x, this.cmp)
    }

    Heap.prototype.clear = function () {
      this.nodes = []
      return this.nodes
    }

    Heap.prototype.empty = function () {
      return this.nodes.length === 0
    }

    Heap.prototype.size = function () {
      return this.nodes.length
    }

    Heap.prototype.clone = function () {
      const heap = new Heap()
      heap.nodes = this.nodes.slice(0)
      return heap
    }

    Heap.prototype.toArray = function () {
      return this.nodes.slice(0)
    }

    Heap.prototype.insert = Heap.prototype.push

    Heap.prototype.top = Heap.prototype.peek

    Heap.prototype.front = Heap.prototype.peek

    Heap.prototype.has = Heap.prototype.contains

    Heap.prototype.copy = Heap.prototype.clone

    return Heap
  })()
  ;(function (root, factory) {
    if (typeof exports === 'object') {
      module.exports = factory()
      return module.exports
    } else {
      root.Heap = factory()
      return root.Heap
    }
  })(this, function () {
    return Heap
  })
}

_heap.call(this)
