import { createEndPoint, getToken } from './util'
import {
  getAppHostReactNative,
  getAppTokenReactNative
} from '../pages/AppPracticePage/services/practiceServicesReactNative'

const { fetch } = window

export async function getAudio(
  text,
  questionGuid,
  language,
  signal,
  isFromApp
) {
  let fetchEndPoint = !isFromApp
    ? createEndPoint('blueberry/text-to-speech')
    : getAppHostReactNative() + '/app/blueberry/text-to-speech'

  if (text) {
    const auxDiv = document.createElement('div')
    auxDiv.innerHTML = text
    const cleanText = auxDiv.innerText.trim()
    auxDiv.remove()

    fetchEndPoint += `?text=${encodeURIComponent(
      cleanText
    )}&lang=${language.toLowerCase()}`
  } else if (questionGuid) {
    fetchEndPoint += `/question/${questionGuid}/lang/${language.toLowerCase()}`
  }

  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: !isFromApp
        ? getToken()
        : `Bearer ${getAppTokenReactNative()}`,
      'Content-Type': 'audio/mpeg'
    },
    referrerPolicy: 'no-referrer',
    signal
  }

  return await fetch(fetchEndPoint, fetchOptions)
    .then(function (response) {
      if (!response.ok) {
        throw Error('Status:', response.status)
      }

      return response
    })
    .then(async function (outcome) {
      const blob = await outcome.blob()

      // Convert blob to URL and replace source
      const url = window.URL.createObjectURL(blob)
      const audio = new Audio()
      audio.src = url

      return audio
    })
    .catch((reason) => {
      console.error('Audio Button Error:', reason)
      throw reason
    })
}
