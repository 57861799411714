import React, { useEffect, useState } from 'react'
import { ReactComponent as SvgLoadingFrame_0 } from '../../../assets/loading/loading_0.svg'
import { ReactComponent as SvgLoadingFrame_1 } from '../../../assets/loading/loading_1.svg'
import './activityLoading.scss'
import { useSelector } from 'react-redux'

const ActivityLoading = ({ title, message }) => {
  const FRAME_MAX = 1
  let updater = null

  const pathSchool = useSelector((state) => state.configuration.pathSchool)

  const [frameIndex, setFrameIndex] = useState(0)
  const [blueberroLoadingMoon, setBlueberroLoadingMoon] = useState()
  const [blueberroLoadingBoy, setBlueberroLoadingBoy] = useState()

  useEffect(() => {
    updateFrame()

    return () => {
      if (updater) clearTimeout(updater)
    }
  }, [frameIndex])

  useEffect(() => {
    if (pathSchool) {
      setBlueberroLoadingMoon(
        `/assets/graphics/gamification/${pathSchool}/luna.png`
      )
      setBlueberroLoadingBoy(
        `/assets/graphics/gamification/${pathSchool}/astronauta.png`
      )
    }
  }, [pathSchool])

  const updateFrame = () => {
    updater = setTimeout(() => {
      let auxFrameIndex = frameIndex + 1
      if (auxFrameIndex > FRAME_MAX) auxFrameIndex = 0
      setFrameIndex(auxFrameIndex)
    }, 500)
  }

  return (
    <div className="activity-loading">
      {!pathSchool && frameIndex === 0 && (
        <SvgLoadingFrame_0 className="activity-loading_frame" />
      )}
      {!pathSchool && frameIndex === 1 && (
        <SvgLoadingFrame_1 className="activity-loading_frame" />
      )}
      {pathSchool && (
        <div className="activity-loading_frame-boy-moon">
          <img className="activity-loading_moon" src={blueberroLoadingMoon} />
          <img className="activity-loading_boy" src={blueberroLoadingBoy} />
        </div>
      )}
      {title && <div className="activity-loading_title">{title}</div>}
      {message && <div className="activity-loading_message">{message}</div>}
    </div>
  )
}

export default ActivityLoading
