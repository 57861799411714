import { useState, useEffect } from 'react'
import { Game } from 'phaser'

export function usePhaserGame(config, containerRef) {
  const [game, setGame] = useState(null)

  useEffect(() => {
    if (!game && containerRef.current) {
      const newGame = new Game({ ...config, parent: containerRef.current })
      setGame(newGame)
    }

    return () => {
      game?.destroy(true)
    }
  }, [config, containerRef, game])

  return game
}
