import React, { useEffect } from 'react'
import { clearToken } from '../../services/util'
import { Analysis } from '../../services/analysisService'
import GenericError from './GenericError'

const ErrorLicenseExpired = () => {
  // silent logout for user, silent means no redirect to login page
  // This is to allow the user to go to login page on refresh because the page does not have any actions
  const logout = () => {
    clearToken()
    window.localStorage.clear()
  }

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Error,
      Analysis.SEGMENT_EVENTS.ErrorLicenseExpired
    )

    setTimeout(() => {
      logout()
    }, 1000)
  }, [])

  return <GenericError />
}

export default ErrorLicenseExpired
