import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  piecesAccumulated: 0,
  coinsAccumulated: 0,
  coinsDailyGoal: 0,
  coinsStreak: 0,
  missionsNotification: false,
  petsNotification: false,
  avatarsNotification: false,
  streakDay: 0,
  coinsDailyGoalEarned: 0
}

export const RewardsSlice = createSlice({
  name: 'Rewards',
  initialState,
  reducers: {
    setPiecesAccumulated: (state, { payload }) => {
      state.piecesAccumulated = payload
    },
    setCoinsAccumulated: (state, { payload }) => {
      state.coinsAccumulated = payload
    },
    setCoinsDailyGoal: (state, { payload }) => {
      state.coinsDailyGoal = payload
    },
    setCoinsStreak: (state, { payload }) => {
      state.coinsStreak = payload
    },
    setStreakDay: (state, { payload }) => {
      state.streakDay = payload
    },
    setCoinsDailyGoalEarned: (state, { payload }) => {
      state.coinsDailyGoalEarned = payload
    },
    setMissionsNotification: (state, { payload }) => {
      state.missionsNotification = payload
    },
    setPetsNotification: (state, { payload }) => {
      state.petsNotification = payload
    },
    setAvatarsNotification: (state, { payload }) => {
      state.avatarsNotification = payload
    },

    resetRewards: () => initialState
  }
})

// Action creators are generated for each case reducer function
export const {
  setPiecesAccumulated,
  setCoinsAccumulated,
  setCoinsDailyGoal,
  setCoinsStreak,
  setStreakDay,
  setCoinsDailyGoalEarned,
  resetRewards,
  setPetsNotification,
  setAvatarsNotification
} = RewardsSlice.actions

export default RewardsSlice.reducer
