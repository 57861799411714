import React from 'react'
import { createPortal } from 'react-dom'
import './ConfirmPopup.scss'
import translate from '../../../i18n/translate'

const ConfirmPopup = ({
  aditionalClass,
  size,
  children,
  onClose,
  acceptButtonText,
  cancelButtonText,
  onAcceptButtonClick,
  isAcceptButtonDisabled
}) => {
  return (
    <>
      {createPortal(
        <>
          <div
            className={`confirm-popup__backdrop ${aditionalClass || ''}`}
            onClick={onClose}
          ></div>
          <div
            className={`confirm-popup confirm-popup--size-${size || 'default'}`}
          >
            <div className="confirm-popup__content">{children}</div>

            <div className="confirm-popup__actions">
              <div
                className="confirm-popup__button confirm-popup__button--default"
                onClick={onClose}
              >
                {cancelButtonText || translate('pets_cancel')}
              </div>
              {acceptButtonText && onAcceptButtonClick && (
                <div
                  className={`confirm-popup__button ${
                    isAcceptButtonDisabled
                      ? 'confirm-popup__button--disabled'
                      : 'confirm-popup__button--available'
                  }`}
                  onClick={onAcceptButtonClick}
                >
                  {acceptButtonText}
                </div>
              )}
            </div>
          </div>
        </>,
        document.body
      )}
    </>
  )
}

export default ConfirmPopup
