import React, { useState, useEffect } from 'react'
import { ReactComponent as ShowIcon } from '../../../../assets/icons/show_password.svg'
import { ReactComponent as HideIcon } from '../../../../assets/icons/hide_password.svg'

const FormInput = ({
  id,
  className = '',
  label,
  inputType,
  placeholder,
  value,
  onChange,
  handleFormatValidation,
  disabled,
  errorMessage = '',
  handleChangeOutside,
  preventCopyPaste = false
}) => {
  const [visible, setVisible] = useState(false)

  const _inputType =
    inputType === 'password' ? (visible ? 'text' : 'password') : inputType

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  const handleCopyPaste = (e) => {
    e.preventDefault()
  }

  useEffect(() => {
    handleFormatValidation && handleFormatValidation(value)
  }, [value])

  return (
    <div className={`form-input ${className} ${errorMessage ? 'error' : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="form-input__wrapper">
        <input
          id={id}
          title={placeholder}
          placeholder={placeholder}
          type={_inputType}
          value={value}
          disabled={disabled}
          onChange={handleChangeOutside ? onChange : handleChange}
          onCopy={preventCopyPaste ? (e) => handleCopyPaste(e) : null}
          onPaste={preventCopyPaste ? (e) => handleCopyPaste(e) : null}
          onCut={preventCopyPaste ? (e) => handleCopyPaste(e) : null}
          autoComplete={'new-' + inputType}
        />
        {inputType === 'password' && (
          <div className="form-input__right-icon">
            <PasswordIcon visible={visible} setVisible={setVisible} />
          </div>
        )}
      </div>
      {errorMessage && <div className="form-input__error">{errorMessage}</div>}
    </div>
  )
}

const PasswordIcon = ({ visible, setVisible }) => (
  <div className="password-icon" onClick={() => setVisible((v) => !v)}>
    {visible ? <ShowIcon /> : <HideIcon />}
  </div>
)

export default FormInput
