const loadinProgressBarCount = { current: 0 }

export const increaseCountProgress = (hasToReset) => {
  const current = loadinProgressBarCount.current + 1
  loadinProgressBarCount.current = hasToReset ? 0 : current

  window.dispatchEvent(
    new CustomEvent('increase-progress-bar', {
      detail: {
        count: current
      }
    })
  )
}
