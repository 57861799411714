import { useEffect, useRef } from 'react'
import anime from 'animejs'

export default function useModalSimple(portalId, showModal, startHandle) {
  let modalRoot = document.getElementById(portalId)

  if (!modalRoot) {
    modalRoot = document.createElement('div')
    modalRoot.id = portalId
    document.body.appendChild(modalRoot)
  }

  const root = useRef()
  const container = useRef()
  const shadow = useRef()

  useEffect(() => {
    const refs = { shadow, root, container }
    anime.set(shadow.current, { opacity: 0 })

    if (showModal) {
      showContent(refs)
      if (startHandle) {
        startHandle()
      }
    } else {
      hideContent({ ...refs })
    }
  }, [showModal])

  function showContent({ shadow, root, container }) {
    anime.set(container.current, { translateY: -20 })

    anime({
      targets: shadow.current,
      opacity: 0.3,
      easing: 'linear',
      duration: 350
    })

    anime({
      targets: container.current,
      translateY: 0,
      opacity: 1,
      duration: 500,
      delay: 100,
      easing: 'easeOutBack'
    })
  }

  function hideContent({ shadow, root, container }) {
    anime({
      targets: container.current,
      translateY: -20,
      opacity: 0,
      duration: 350,
      easing: 'easeInBack'
    })

    anime({
      targets: shadow.current,
      opacity: 0,
      duration: 300,
      delay: 100,
      easing: 'linear'
    })
  }

  return {
    root,
    container,
    shadow
  }
}
