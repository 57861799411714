export default class Bar {
  constructor(
    scene,
    barArea,
    foregroundColor,
    backgroundColor,
    fillPercent,
    radius,
    depth
  ) {
    this.scene = scene
    this.x = barArea.x
    this.y = barArea.y
    this.width = barArea.width
    this.height = barArea.height
    this.foregroundColor = foregroundColor
    this.backgroundColor = backgroundColor
    this.fillPercent = fillPercent
    this.radius = radius === null || radius === undefined ? 0 : radius
    this.depth = depth

    this.backgroundBar = null
    this.foregroundBar = null
  }

  generate() {
    // Background bar
    this.backgroundBar = this.scene.add.graphics()
    if (this.depth) this.backgroundBar.setDepth(this.depth)
    this.createBar(this.backgroundBar, 100, this.backgroundColor)

    // Foreground fill
    if (this.fillPercent > 0) {
      this.foregroundBar = this.scene.add.graphics()
      if (this.depth) this.foregroundBar.setDepth(this.depth)
      this.createBar(this.foregroundBar, this.fillPercent, this.foregroundColor)
    }
  }

  createBar(barGraphic, barPercent, barColor) {
    barGraphic.clear()
    barGraphic.fillStyle(barColor, 1)

    const barWidth = (this.width * barPercent) / 100
    barGraphic.fillRoundedRect(
      this.x,
      this.y,
      barWidth,
      this.height,
      this.radius
    )
  }

  destroy() {
    this.foregroundBar.destroy()
    this.backgroundBar.destroy()
  }
}
