export default function useHeader(
  totalQuestions,
  currentQuestion,
  timerQuestion
) {
  const getProgressBarWidth = () =>
    `${(currentQuestion / totalQuestions) * 90}%`

  const SECONDS_LEFT_TO_PULSE = 10
  const areLastSeconds = () => {
    return timerQuestion <= SECONDS_LEFT_TO_PULSE && timerQuestion > 0
  }

  return {
    getProgressBarWidth,
    areLastSeconds
  }
}
