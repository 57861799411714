import { Analysis } from '../../../services/analysisService'

export const APP_ANALYSIS = {
  APP_ANALYSIS_EVENT: {
    'Activity Viewed': 'Activity Viewed',
    'Hint Clicked': 'Hint Clicked',
    'Show Solution Clicked': 'Show Solution Clicked',
    'See My Answer Clicked': 'See My Answer Clicked',
    'Practice Session Aborted': 'Practice Session Aborted',
    'Exit Practice Button Clicked': 'Exit Practice Button Clicked',
    'Keep Practicing Button Clicked': 'Keep Practicing Button Clicked',
    'TTS Clicked': 'TTS Clicked',
    'Activity Feedback Viewed': 'Activity Feedback Viewed',
    'Error Notification': 'Error Notification'
  },

  sendAnalysisEventToReactNative: (isFromApp, eventName, eventProperties) => {
    if (isFromApp === true) {
      const analysisEvent = {
        function: 'onAnalysisEvent',
        params: {
          eventName,
          eventProperties
        }
      }

      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify(analysisEvent))
      } else {
        window.postMessage(JSON.stringify(analysisEvent))
      }
    } else {
      Analysis.sendSegmentTrackEvent(eventName, eventProperties)
    }
  }
}
