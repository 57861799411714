export default {
  NOT_AVAILABLE: 'disabled',
  NOT_VISITED: 'install',
  LOW_ENERGY_LATE: 'empty',
  LOW_ENERGY: 'init',
  BLOCKED: 'blocked',

  MEDIUM_ENERGY: 'charging',

  FULL_ENERGY: 'complete',
  MAX_LEVEL: 'start',
  MAX_LEVEL_LATE: 'broked_start'
}
