import checkFirstTimeClouds from './checkFirstTimeClouds'

export default function getShowAsDisabled(idx, learningMapData) {
  const currentMap = learningMapData[idx]
  const previousMap = idx > 0 ? learningMapData[idx - 1] : false
  const isPreviousFirstTime = previousMap && checkFirstTimeClouds(previousMap)

  if (!previousMap || isAvailable(currentMap) || isPreviousFirstTime) {
    return false
  }

  return isAvailable(previousMap)
}

function isAvailable(mapData) {
  return mapData?.is_available === 1
}
