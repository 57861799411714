import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  wasStoreInitialized: false,
  canUseDevMode: false,
  isDevMode: false,
  upperCaseRequiredGroup: false,
  upperCaseRequiredUser: false,
  pathSchool: null
}

export const Configuration = createSlice({
  name: 'Configuration',
  initialState,
  reducers: {
    initializeStore: (state) => {
      state.wasStoreInitialized = true
    },

    changeDevMode: (state) => {
      state.isDevMode = !state.isDevMode
    },

    updateDevMode: (state, { payload }) => {
      payload.isDevMode &&
        console.log('%cDevMode', 'color: green; font-weight:bold')
      state.isDevMode = payload.isDevMode
      state.canUseDevMode = payload.isDevMode
    },

    updateUpperCaseRequiredGroup: (state, { payload }) => {
      state.upperCaseRequiredGroup = payload
    },

    updateUpperCaseRequiredUser: (state, { payload }) => {
      state.upperCaseRequiredUser = payload
    },

    updatePathSchool: (state, { payload }) => {
      state.pathSchool = payload
    },

    resetStoreConfiguration: () => {
      return initialState
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  initializeStore,
  changeDevMode,
  updateDevMode,
  updateUpperCaseRequiredGroup,
  updateUpperCaseRequiredUser,
  updatePathSchool,
  resetStoreConfiguration
} = Configuration.actions

export default Configuration.reducer
