import { fetchWithToken, createEndPoint } from './util'

export const CONTACT_EMAIL = 'soporte@blueberrymath.ai'
export const FAQ_LINK =
  'https://oneclickes.notion.site/Conoce-Blueberry-eb0c756db66e4fb8b4b26c6670b61de3'

export const isSubscriptionHost = () => {
  // Inicialmente se comprobaba el host, pero ahora basta con esto
  const isFamily = window.organizationData.isBlueberryFamily
  return isFamily === 1 || isFamily === true
}

export const getLicenseInformation = async () => {
  /* Response 
        "status": "ENUM('trial-active','trial-inactive','subscription-active','subscription-inactive')",  
        "leftDays": "{numberOfDays}",
        "code": "{licenseCode}"
        "expires_after": "1 YEAR / 6 MONTHS / 1 MONTH"
  */

  return fetchWithToken(
    createEndPoint('front/profile/licenses?mode=freeTrial'),
    true
  ).then((json) => json.data)
}

export const getSubscriptionData = async () => {
  return fetchWithToken(
    createEndPoint('organization?module=stripe'),
    true
  ).then((json) => json.data)
}
