import React, { useEffect } from 'react'
import {
  getPanelRenderCount,
  updatePanelRenderCount
} from '../../../../../services/settingsService'

const QAElement = ({ qaIdentifier, qaAction, order }) => {
  const qaElementStyle = {
    position: 'absolute',
    // top => Alto barra superior (algo más) + order (sino hay una herramienta que no puede "clickar")
    top: 60 + order + 'px',
    left: '0',
    width: '1px',
    height: '1px'
  }

  useEffect(() => {
    updatePanelRenderCount(getPanelRenderCount() + 1)
    return () => {
      updatePanelRenderCount(getPanelRenderCount() - 1)
    }
  }, [])

  return (
    <div
      id={qaIdentifier}
      className={'qa-element phaser-dom'}
      style={qaElementStyle}
      onClick={qaAction}
    ></div>
  )
}

export default QAElement
