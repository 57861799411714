import React from 'react'
import './mapLevelCounter.scss'
import shieldImg from '../../../../../assets/img/metaberry_ui/level_shield.png'

const MapLevelCounter = ({ level }) => {
  return (
    <div className="map-level-counter">
      <img className="map-level-counter-img" src={shieldImg} alt="" />
      <div className="map-level-counter-text">{level}</div>
    </div>
  )
}

export default MapLevelCounter
