import React from 'react'
import './contestPage.scss'
import logo from './assets/logo.svg'
import blueMonster from './assets/blueMonster.png'
import redMonster from './assets/redMonster.png'
import oneEyeGreenMonster from './assets/oneEyeGreenMonster.png'
import logoSimple from './assets/logotipo.png'
import instagram from './assets/instagramIcon.png'
import linkedin from './assets/linkedinIcon.png'
import youtube from './assets/youtubeIcon.png'
import planet from './assets/planet.png'
import blueberro from './assets/Blueberro.png'
import podium from './assets/Ellipse 163.svg'

const ContestResultPage = () => {
  const WINNER_SCHOOL_NAME = 'Colegio Madre Matilde'
  const WINNER_SCHOOL_CLASS = '6.º Primaria'
  const WINNER_SCHOOL_ACTIVITIES = '1883'
  return (
    <div className="contest-info__page">
      <div className="contest-info__page-bg">
        <div className="content__container">
          <div className="content__header">
            <img
              className="content-results__logo"
              src={logo}
              alt="Blueberry Math"
            />
            <img
              className="content__red-monster pulsate-fwd"
              src={redMonster}
              alt="Red Monster"
            />
          </div>
          <div className="contest-result-page__title">¡Ganador!</div>
          <div className="content-result__highlight-container">
            <div className="content-result__highlight-activities-text">
              {WINNER_SCHOOL_ACTIVITIES} actividades
            </div>
          </div>
          <div className="contest-result__podium-content">
            <div className="contest-result__podium-content-images">
              <img
                className="contest-result__blueberro-podium"
                src={blueberro}
                alt="Blueberro celebrando"
              />
              <img src={podium} alt="podium" />
            </div>
            <div className="contest-result__podium-school-container">
              <div className="contest-result__podium-school-name-container">
                <div className="contest-result__podium-school-name">
                  <div>{WINNER_SCHOOL_CLASS}</div>
                  <div>{WINNER_SCHOOL_NAME}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="contest-result__congratulation-container">
            <span className="contest-result__congratulation-title">
              ¡Enhorabuena!
            </span>
            <p className="contest-result__contact-text">
              Nos pondremos en contacto contigo para darte todos los detalles
              del premio.
            </p>
            <img
              className="content-result__one-eye-green-monster pulsate-fwd"
              src={oneEyeGreenMonster}
              alt="Green Monster"
            />
          </div>
          <div className="contest-result__end-container">
            <div className="contest-result__end-contest-text">
              Sorteo finalizado
            </div>
          </div>
        </div>
      </div>

      <div className="contents__footer">
        <div className="contents__footer-redes">
          <a
            href="https://www.instagram.com/blueberry_math/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="instagram" src={instagram} alt="Instagram" />
          </a>
          <a
            href="https://www.linkedin.com/showcase/blueberrymath/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="linkedin" src={linkedin} alt="Linkedin" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCdeFPf80P9ZRlaThqUf1cmg"
            target="_blank"
            rel="noreferrer"
          >
            <img className="youtube" src={youtube} alt="Youtube" />
          </a>
        </div>

        <div className="contents__footer-web">
          <img className="planetImage" src={planet} alt="Planet Image" />
          <a
            href="https://www.blueberrymath.ai/ "
            target="_blank"
            rel="noreferrer"
          >
            <p>Visita nuestra web</p>
          </a>
        </div>
        <div className="layouts-demo__footer-logo">
          <img src={logoSimple} alt="Blueberry" />
        </div>
        <img
          className="content-results__blue-monster pulsate-fwd"
          src={blueMonster}
          alt="Blue Monster"
        />
      </div>
    </div>
  )
}

export default ContestResultPage
