import React from 'react'

const BlueberryFamilyLayout = ({ children }) => {
  return (
    <div className="blueberry-family-layout">
      <div className="blueberry-family-layout__content">{children}</div>
      <div className="blueberry-family-layout__footer">© Oneclick 2023</div>
    </div>
  )
}

export default BlueberryFamilyLayout
