import React, { useEffect, useState } from 'react'
import translate from '../../../../../i18n/translate'
import { ReactComponent as AudioBtn } from '../../../../../assets/img/metaberry_ui/sound_on.svg'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
import { Analysis } from '../../../../../services/analysisService'
import { getSoundAssistance } from '../../../../../services/settingsService'
import { getCurrentLangString } from '../../../../../i18n/initI18n'

const OnboardingIntroductionPanel2 = ({ handleButton, pathSchool }) => {
  const { renderSoundFXController, playSoundEffect } = useSound()
  const langCode = getCurrentLangString()
  const audioFile = 'intro02_' + langCode
  const [blueberroImage, setBlueberroImage] = useState()

  useEffect(() => {
    getSoundAssistance() && playSoundEffect(audioFile)
    setBlueberroImage(
      pathSchool
        ? `/assets/graphics/gamification/${pathSchool}/bb_sad.svg`
        : `/assets/graphics/gamification/bb_intro_scared.svg`
    )
  }, [])

  const handleAudio = () => {
    Analysis.sendEvent(Analysis.EVENT.REQUEST_TEXT_TO_SPEECH, {
      type: 'onboarding'
    })

    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['TTS Clicked'], {
      object: 'onboarding',
      ui_element_id: 'onboarding_introduction_2',
      seed_id: undefined
    })

    playSoundEffect(audioFile)
  }

  return (
    <div className="onboarding-panel--intro onboarding-white-design">
      <div className="onboarding-panel__bb-image_new-popup">
        <img src={blueberroImage} alt="" />
      </div>
      <div className="onboarding-panel_text-container">
        {renderSoundFXController()}
        <AudioBtn className="onboarding-panel__audio" onClick={handleAudio} />
        <div
          className="onboarding-panel__text-title onboarding-panel__margin"
          dangerouslySetInnerHTML={{
            __html: translate('onboarding_intro_text_2')
          }}
        ></div>

        <div
          className="onboarding-panel__button-purple-design onboarding-panel__button--inline"
          onClick={handleButton}
        >
          {translate('onboarding_intro_button_2')}
        </div>
      </div>
    </div>
  )
}
export default OnboardingIntroductionPanel2
