import { useEffect, useState } from 'react'

export default function useLobbyTeacherPracticePage(
  players,
  conectedPlayers,
  totalPlayers,
  translate
) {
  const [playersWithParsedSettings, setPlayersWithParsedSettings] = useState([])
  const [allPlayersOnline, setAllPlayersOnline] = useState(false)
  const [messageLobby, setMessageLobby] = useState(
    translate('teacher_practice_lobby_message')
  )

  const messages = [
    translate('teacher_practice_lobby_message'),
    translate('teacher_practice_lobby_message_alt'),
    translate('teacher_practice_lobby_message_alt_2')
  ]

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const NUMBER_PLAYERS_FULL = 11

  useEffect(() => {
    let index = 0

    // define el intervalo
    const intervalId = setInterval(() => {
      setMessageLobby(messages[index % messages.length])
      index += 1
    }, 5000)

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      clearInterval(intervalId)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    // Parseamos el json_settings solo una vez por cada jugador.
    const parsedPlayers = players.map((player) => ({
      ...player,
      parsedSettings: JSON.parse(player.json_settings)
    }))

    // Actualizamos el estado con la nueva lista de jugadores.
    setPlayersWithParsedSettings(parsedPlayers)
    setAllPlayersOnline(conectedPlayers === totalPlayers)
  }, [players])

  return {
    playersWithParsedSettings,
    allPlayersOnline,
    messageLobby,
    messages,
    windowSize,
    NUMBER_PLAYERS_FULL
  }
}
