import { createSlice } from '@reduxjs/toolkit'

const initialState = true

export const Loading = createSlice({
  name: 'Loading',
  initialState,
  reducers: {
    showLoading: () => {
      return true
    },
    hideLoading: () => {
      return false
    }
  }
})

// Action creators are generated for each case reducer function
export const { showLoading, hideLoading } = Loading.actions

export default Loading.reducer
