import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { hideLoading, showLoading } from '../../../store/Loading/Loading'
import { getFamilyMembers } from '../../../services/userTypeService'

export default function useFamilyMembers() {
  const [familyMembers, setFamilyMembers] = useState([])
  const [processDone, setProcessDone] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(showLoading)

    getFamilyMembers()
      .then((members) => {
        setFamilyMembers(members)
        setProcessDone(true)
        dispatch(hideLoading)
      })
      .catch((error) => {
        console.error('useFamilyMembers error', error)
        setProcessDone(true)
        dispatch(hideLoading)
      })
  }, [])

  return {
    familyMembers,
    processDone
  }
}
