import React from 'react'
import './mapLevelBadge.scss'
import MapBarTooltip from '../MapBarTooltip/MapBarTooltip'
import MapLevelCounter from './components/MapLevelCounter/MapLevelCounter'
import MapLevelProgress from './components/MapLevelProgress/MapLevelProgress'
import translate from '../../../i18n/translate'

const MapLevelBadge = ({
  level = 0,
  progress = 0,
  totalExperience = 0,
  previousExperience = 0
}) => {
  return (
    <div className="map-level-badge">
      <MapBarTooltip
        width="320px"
        arrowPosition="left"
        message={translate('info_tooltip_level')}
        contentComponent={
          <MapLevelProgress
            progress={progress}
            totalExperience={totalExperience}
            previousExperience={previousExperience}
            currentLevel={level}
            nextLevel={level + 1}
          />
        }
      >
        <MapLevelCounter level={level} />
      </MapBarTooltip>
    </div>
  )
}

export default MapLevelBadge
