import React, { useEffect, useRef } from 'react'
import cn from '@aqrojo/class-names'
import ReactDOM from 'react-dom'
import anime from 'animejs'

const drawerPortalId = '__blueberry-modal-container'

if (!document.getElementById(drawerPortalId)) {
  const div = document.createElement('div')
  div.id = drawerPortalId
  document.body.appendChild(div)
}

function Modal({
  children,
  className = '',
  open = false,
  onCloseStart = () => {},
  onCloseComplete = () => {},
  withCloseButton = false
}) {
  const root = useRef()
  const container = useRef()
  const shadow = useRef()

  const isModalVisible = () => root.current.style.display !== 'none'

  useEffect(() => {
    anime.set(root.current, { display: 'none' })
    anime.set(shadow.current, { opacity: 0 })
  }, [])

  useEffect(() => {
    const refs = { shadow, root, container }
    if (open) {
      showContent(refs)
    } else if (isModalVisible()) {
      hideContent({ ...refs, onCloseComplete })
    }
  }, [open])

  // const isModalDisplayable = () => root?.current?.style?.display !== undefined

  return ReactDOM.createPortal(
    <div
      ref={root}
      className={
        'modal ' + className // + (isModalDisplayable() ? '' : ' modal__hidden')
      }
    >
      <div
        ref={shadow}
        className="modal__shadow-layer"
        // onClick={onCloseStart}
      />
      <div className="modal__content-wrapper" onClick={onCloseStart}>
        <div
          ref={container}
          className={cn('modal__content', {
            show: open,
            hide: !open
          })}
        >
          {withCloseButton && (
            <button className="modal__close-button" onClick={onCloseStart}>
              x
            </button>
          )}
          {children}
        </div>
      </div>
    </div>,
    document.getElementById(drawerPortalId)
  )
}

function showContent({ shadow, root, container }) {
  anime.set(shadow.current, { opacity: 0, display: 'block' })
  anime.set(container.current, { translateY: -20 })

  anime({
    targets: shadow.current,
    opacity: 0.3,
    easing: 'linear',
    duration: 350
  })

  anime.set(root.current, { display: 'block' })
  anime({
    targets: container.current,
    translateY: 0,
    opacity: 1,
    duration: 500,
    delay: 100,
    easing: 'easeOutBack'
  })
}

function hideContent({ shadow, root, container, onCloseComplete }) {
  const onComplete = () => {
    anime.set(root.current, { display: 'none' })
    onCloseComplete()
  }

  anime({
    targets: container.current,
    translateY: -20,
    opacity: 0,
    duration: 350,
    easing: 'easeInBack',
    complete: onComplete
  })

  anime({
    targets: shadow.current,
    opacity: 0,
    duration: 300,
    delay: 100,
    easing: 'linear'
  })
}

export default Modal
