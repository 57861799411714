import React, { useEffect, useState, useRef } from 'react'
import './mapItemsCounter.scss'
import translate from '../../../i18n/translate'
import MapBarTooltip from '../MapBarTooltip/MapBarTooltip'
import { ReactComponent as CoinSvg } from '../../../assets/img/metaberry_ui/coin.svg'
import { ReactComponent as GearSvg } from '../../../assets/img/metaberry_ui/gold-gear.svg'
import useSound from '../../views/MetaberryView/hooks/useSound'

const MapItemsCounter = ({
  variant,
  count,
  total,
  practiceVersion,
  arrowPosition,
  animationDelay,
  pathSchool
}) => {
  const ANIMATION_DURATION = 1600 // En mapItemsCounter.scss se estable a 2000ms
  const VARIANTS = {
    coin: {
      icon: <CoinSvg />,
      text: pathSchool
        ? translate('info_tooltip_coins_adventista')
        : translate('info_tooltip_coins'),
      sound: 'collectCoins'
    },
    gear: {
      icon: <GearSvg />,
      text: translate('info_tooltip_pieces'),
      sound: 'collectGear'
    }
  }

  const { renderSoundFXController, playSoundEffect } = useSound()
  const [countPracticeVersion, setCountPracticeVersion] = useState(count)
  const [showAnimation, setShowAnimation] = useState(false)
  const [digitsInTotal] = useState(
    total && !isNaN(total) ? total.toString().length : 1
  )

  useEffect(() => {
    if (practiceVersion && showAnimation) {
      setTimeout(
        () => playSoundEffect(VARIANTS[variant].sound),
        ANIMATION_DURATION * 0.7
      )

      setTimeout(() => {
        setCountPracticeVersion(count)
      }, ANIMATION_DURATION * 0.9)

      const timeoutId = setTimeout(() => {
        setShowAnimation(false)
      }, ANIMATION_DURATION)

      return () => clearTimeout(timeoutId)
    }
  }, [showAnimation])

  const prevCountRef = useRef()
  useEffect(() => {
    if (
      practiceVersion &&
      prevCountRef.current !== null &&
      count !== null &&
      count > prevCountRef.current
    ) {
      setTimeout(() => {
        setShowAnimation(true)
      }, animationDelay || 0)
    }

    prevCountRef.current = count

    if (countPracticeVersion === null && count !== null) {
      setCountPracticeVersion(count)
    }
  }, [count])

  return (
    <>
      {renderSoundFXController()}
      <MapBarTooltip
        width="265px"
        arrowPosition={arrowPosition || 'left'}
        message={VARIANTS[variant].text}
      >
        <div className="map-items-counter">
          <div
            className={
              'map-items-counter__background' +
              (practiceVersion ? ' map-items-counter__background-light' : '')
            }
          >
            <div className="map-items-counter__icon">
              {VARIANTS[variant].icon}
            </div>

            <div className="map-items-counter__icon-animated">
              {showAnimation && VARIANTS[variant].icon}
            </div>

            <div
              className={
                'map-items-counter__count' +
                (practiceVersion && variant === 'gear' // Esto es para que al cambiar el ancho de los digitos no se vea rara la animación
                  ? ' map-items-counter__count-min-width-' + digitsInTotal
                  : '')
              }
            >
              {practiceVersion && total ? (
                <>
                  <span className="map-items-counter__count_current">
                    {countPracticeVersion + '/'}
                  </span>
                  <span className="map-items-counter__count_total">
                    {total}
                  </span>
                </>
              ) : practiceVersion ? (
                countPracticeVersion
              ) : (
                count
              )}
            </div>
          </div>
        </div>
      </MapBarTooltip>
    </>
  )
}

export default MapItemsCounter
