// QA Mode Service

export function enableQAMode() {
  window.localStorage.setItem('bb_qaMode', 'true')
}

export function disableQAMode() {
  window.localStorage.setItem('bb_qaMode', 'false')
}

export function isQAMode() {
  const bb_qaMode = window.localStorage.getItem('bb_qaMode')
  return bb_qaMode === 'true'
}

export function clearQAMode() {
  window.localStorage.removeItem('bb_qaMode')
}
