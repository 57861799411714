import React from 'react'
import CrownImg from '../../../../../assets/img/metaberry_ui/crown.svg'
import translate from '../../../../../i18n/translate'

const MetaRankingItem = ({ isCurrentUser, rank, fullName, experience }) => {
  return (
    <div
      className={`meta-ranking-item${
        isCurrentUser ? ' meta-ranking-item--selected' : ''
      }`}
    >
      <div className="meta-ranking-item__icon">
        {rank === 1 && <img src={CrownImg} alt="" />}
      </div>
      <div className="meta-ranking-item__position">{rank}</div>
      <div
        className="meta-ranking-item__name"
        title={isCurrentUser ? translate('unit_ranking_you') : fullName}
      >
        {isCurrentUser ? translate('unit_ranking_you') : fullName}
      </div>
      <div className="meta-ranking-item__experience">{experience}</div>
    </div>
  )
}

export default MetaRankingItem
