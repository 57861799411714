import React, { useEffect } from 'react'
import { Analysis } from '../../services/analysisService'
import GenericError from './GenericError'

const Error500Page = () => {
  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Error,
      Analysis.SEGMENT_EVENTS['Error Page 500']
    )
  }, [])

  return <GenericError />
}

export default Error500Page
