import React from 'react'
import translate from '../../../i18n/translate'
import useSound from '../../views/MetaberryView/hooks/useSound'
import './mapSoundMenu.scss'

const MetaSoundMenu = ({
  onChangeMusicConfig,
  musicOn,
  onChangeEffectsConfig,
  effectsOn,
  onCloseMenu
}) => {
  const { renderSoundFXController, playSoundEffect } = useSound()

  return (
    <>
      <div className="meta-sound-menu__backdrop" onClick={onCloseMenu}></div>

      <div className="meta-sound-menu">
        {renderSoundFXController()}

        <div className="meta-sound-menu__content">
          <div className="meta-sound-menu__item">
            <label
              className="switch"
              onClick={() => playSoundEffect('uiToggle')}
            >
              <input
                type="checkbox"
                checked={musicOn}
                onChange={onChangeMusicConfig}
              />
              <span className="slider"></span>
              <span className="switch__text">{translate('music_label')}</span>
            </label>
          </div>
          <div className="meta-sound-menu__item">
            <label
              className="switch"
              onClick={() => playSoundEffect('uiToggle')}
            >
              <input
                type="checkbox"
                checked={effectsOn}
                onChange={onChangeEffectsConfig}
              />
              <span className="slider"></span>
              <span className="switch__text">{translate('effects_label')}</span>
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

export default MetaSoundMenu
