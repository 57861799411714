import React, { useCallback, useEffect } from 'react'
import translate from '../../i18n/translate'
import { useHistory, useParams } from 'react-router-dom'
import { resetDBEndPoint } from '../../services/resetDBEndPoint'
import { updateRespawn } from '../../services/settingsService.js'
import { store, resetStore } from '../../store/configureStore'
import { resetRespawn } from '../../store/MetaberrySlice/MetaberrySlice'
import { removePetAvatar } from '../../services/rewardsService'
import usePopupManager from '../../modules/components/PopupManager/usePopupManager'
import './resetDB.scss'

function ResetDB() {
  const { unitOrder } = useParams()
  // console.log('RESET UNIT ORDER', unitOrder)

  const history = useHistory()
  const { resetOnboarding, resetPopupStore } = usePopupManager()

  const goHome = useCallback(async () => {
    // Reset respawn
    await updateRespawn(null)
    setTimeout(() => {
      store.dispatch(
        resetRespawn({
          unitGuid: null,
          lessonGuid: null,
          unitExitTile: null
        })
      )
      history.replace('/game')
      // TODO: Cambiar por un refresco del mapa y no del navegador
      window.location.reload()
    }, 5000)
  }, [])

  useEffect(() => {
    resetStore()
    resetPopupStore()
    resetOnboarding()
    removePetAvatar()
    resetDBEndPoint(unitOrder).then(() => setTimeout(goHome, 5000))
  }, [])

  return (
    <div className="reset-db">
      <div className="reset-db__content">
        <span>{translate('reset_db')}</span>
      </div>
    </div>
  )
}

export default ResetDB
