import Cookie from 'js-cookie'
import {
  getFamilyUserToken,
  removeFamilyUserToken,
  removeFamilyUserData,
  removeAloneUserData
} from '../userTypeService'

const tokenName = 'blueberry_user_token'

let token = ''

export function getToken(hasToUseMainToken) {
  let _token = localStorage.getItem(tokenName) || token // Cookie.get(tokenName) || token

  if (!hasToUseMainToken) {
    const currentFamilyUserToken = getFamilyUserToken()
    if (currentFamilyUserToken) _token = currentFamilyUserToken
  }

  return _token
}

export function setToken(value) {
  localStorage.setItem(tokenName, value)
  Cookie.set(tokenName, value)
  token = value
}

export function clearToken() {
  localStorage.removeItem(tokenName)
  Cookie.set(tokenName, '')
  token = ''
  removeFamilyUserToken()
  removeFamilyUserData()
  removeAloneUserData()
}

// window.setTok = () => setToken('aaaa')
