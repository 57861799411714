import { React, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import translate from '../../i18n/translate'
import { getLicenseInformation } from '../../services/licenseService'
import { logout } from '../../services/logoutService'
import FormButton from '../BlueberryFamilyPages/components/FormButton/FormButton'
import { sendMagicLinkEmail } from '../../services/userManagementService'
import { store } from '../../store/configureStore'
import { Analysis } from '../../services/analysisService'
import BlueberroSendImg from '../../assets/img/blueberro_send.png'
import { ReactComponent as CheckImg } from '../../assets/icons/check-circle.svg'
import { getDeviceType } from '../../util/mobile'

const BlockagePhonesPage = () => {
  // const location = useLocation()
  const history = useHistory()

  // const [isFromSignup, setIsFromSignup] = useState(true)
  const [isTrial, setIsTrial] = useState(true)

  const DELAY_TO_REQUSET_EMAIL_AGAIN_SECONDS = 60
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  useEffect(() => {
    Analysis.sendSegmentTrackEvent(
      Analysis.SEGMENT_EVENTS['Device Not Supported Page Viewed'],
      {
        device_type: getDeviceType(),
        is_tablet: getDeviceType() === 'tablet',
        is_mobile: getDeviceType() === 'phone',
        resolution: window.screen.width + 'x' + window.screen.height,
        screen_width: window.screen.width,
        screen_height: window.screen.height,
        devicePixelRatio: window.devicePixelRatio
      }
    )

    // Set origin
    // if (!location?.state?.isFromSignup) {
    //   setIsFromSignup(false)
    // }

    // Set if the user is on trial
    getUserLicense()

    // Send analytics event
    Analysis.sendEvent(Analysis.EVENT.UNSUPPORTED_SCREEN)
  }, [])

  const getUserLicense = async () => {
    // Sets if the user is on trial
    const licenseInformation = await getLicenseInformation()
    if (
      licenseInformation?.status &&
      !licenseInformation.status.includes('trial')
    ) {
      setIsTrial(false)
    }
  }

  const handleGoToSubscription = async () => {
    if (isTrial) {
      history.push('/pricing')
    } else {
      const stripeLink = store.getState().subscription.customerPortalLink
      window.open(stripeLink, '_blank').focus()
    }
  }

  const handleLogout = () => {
    Analysis.sendSegmentTrackEvent(
      Analysis.SEGMENT_EVENTS['Logout Button Click']
    )

    logout()
  }

  let sendButtonTimeout = null

  const sendMagicLinkAndRestartCountdown = () => {
    clearTimeout(sendButtonTimeout)

    sendButtonTimeout = setTimeout(async () => {
      setCounter(DELAY_TO_REQUSET_EMAIL_AGAIN_SECONDS)
      sendMagicLinkEmail()
    }, 250)
  }

  const secondsToMinutesAndSeconds = (seconds) => {
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0')
    const _seconds = (seconds - minutes * 60).toString().padStart(2, '0')
    return minutes + ':' + _seconds
  }

  return (
    <div className="blockage-phones-page">
      <div
        className={`blockage-phones-page__toast ${
          counter > 0 ? 'visible' : ''
        }`}
      >
        <div className="blockage-phones-page__toast-message">
          <div className="blockage-phones-page__toast-icon">
            <CheckImg />
          </div>
          <div className="blockage-phones-page__toast-text">
            {translate('blockage_phones_page_toast')}
          </div>
        </div>
      </div>
      <div className="blockage-phones-page-layout">
        <div className="blockage-phones-page-layout__container">
          <div className="blockage-phones-page__title">
            {translate('blockage_phones_page_title')}
          </div>

          <div className="blockage-phones-page__image">
            <img src={BlueberroSendImg} alt="" />
          </div>

          <div className="blockage-phones-page__text">
            {translate('blockage_phones_page_text')}
          </div>

          {counter > 0 ? (
            <span className="blockage-phones-page__counter">
              {translate('blockage_phones_page_try_again')}
              <span className="blockage-phones-page__counter-monospace">
                {secondsToMinutesAndSeconds(counter)}
              </span>
            </span>
          ) : (
            <div className="blockage-phones-page__no-mail">
              {translate('blockage_phones_page_no_mail')}
              <span
                className="blockage-phones-page__resend"
                onClick={sendMagicLinkAndRestartCountdown}
              >
                {translate('blockage_phones_page_resend_link')}
              </span>
            </div>
          )}

          <div className="blockage-phones-page__subscription-button">
            <FormButton
              variant="outline"
              display="inline"
              text={
                isTrial
                  ? translate('blockage_phones_page_subscribe')
                  : translate('blockage_phones_page_manage_subscription')
              }
              onClick={handleGoToSubscription}
            />
          </div>
        </div>

        <div className="blockage-phones-page__logout" onClick={handleLogout}>
          {translate('logout_label')}
        </div>
      </div>
    </div>
  )
}

export default BlockagePhonesPage
