export default function createEndPoint(endPoint) {
  const path = endPoint ? `/${endPoint}` : ''
  const { origin } = window.location

  //*
  return origin.match(/http:\/\/localhost:/)
    ? window.bbDevApiURL + path
    : `${origin}/api` + path
  // */

  /* para browser stack con iphone/ipad
  return origin.match(/http:\/\/bs-local.com:/)
    ? window.bbApiURL + path
    : `${origin}/api` + path
  // */
}
