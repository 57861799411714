import React, { useEffect, useState } from 'react'
import translate from '../../i18n/translate'
import spacialCraft from './assets/spacialCraft.svg'
import usersIcon from './assets/usersIcon.svg'
import malla from './assets/malla.png'
import pompa from './assets/pompa.png'
import fullscreen from './assets/fullscreen.svg'
import fullscreenExit from './assets/fullscreen-exit.svg'
import useLobbyTeacherPracticePage from './useLobbyTeacherPracticePage'
import { useSelector } from 'react-redux'

const LobbyTeacherPracticePage = ({
  showPage = false,
  totalPlayers = 0,
  conectedPlayers = 0,
  players = [],
  countDownTeacherPractice = 0,
  keyCountDown,
  goFullScreen,
  exitFullScreen,
  isFullscreen
}) => {
  const {
    playersWithParsedSettings,
    allPlayersOnline,
    messageLobby,
    windowSize,
    NUMBER_PLAYERS_FULL
  } = useLobbyTeacherPracticePage(
    players,
    conectedPlayers,
    totalPlayers,
    translate
  )

  const [pathSchool] = useState(
    useSelector((state) => state.configuration.pathSchool)
  )
  const [defaultAvatarImage, setDefaultAvatarImage] = useState(null)
  useEffect(() => {
    setDefaultAvatarImage(
      pathSchool
        ? `/assets/graphics/gamification/${pathSchool}/default_single_avatar.png`
        : `/assets/graphics/gamification/default_single_avatar.png`
    )
  }, [])

  return (
    showPage && (
      <div className="lobby-teacher-practice-page">
        <div className="container-fullscreen">
          <div className="container-fullscreen-child">
            <button
              className="container-fullscreen-button"
              onClick={() => {
                isFullscreen ? exitFullScreen() : goFullScreen()
              }}
            >
              <img
                src={isFullscreen ? fullscreenExit : fullscreen}
                style={{ width: '16px', height: '16px', color: 'white' }}
              />
            </button>
          </div>
        </div>
        {countDownTeacherPractice === null && (
          <>
            <div
              className={`
              ${
                players && players.length > 0
                  ? 'main-container'
                  : 'main-container center'
              }
              ${
                playersWithParsedSettings.length >= NUMBER_PLAYERS_FULL
                  ? 'main-container-full'
                  : ''
              }
            `}
            >
              {!players ||
                (players.length === 0 && (
                  <div className="">
                    <img src={spacialCraft} alt="" />
                  </div>
                ))}
              {players && players.length > 0 && (
                <>
                  <div className="container-block-number-players">
                    <div className="waiting-players">
                      {allPlayersOnline
                        ? translate('teacher_practice_lobby_ready')
                        : translate('teacher_practice_lobby_waiting_players')}
                    </div>

                    <div className="container-malla">
                      <img src={malla} alt="" style={{ width: '97%' }} />
                    </div>

                    <div className="container-number-players">
                      <img src={usersIcon} alt="" />
                      <div className="container-number-players-child">
                        {conectedPlayers}{' '}
                        {translate('teacher_practice_lobby_of')} {totalPlayers}{' '}
                        {translate('teacher_practice_lobby_jugadores')}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div
                className={
                  playersWithParsedSettings.length >= NUMBER_PLAYERS_FULL
                    ? 'container-content container-content-full'
                    : 'container-content'
                }
              >
                {playersWithParsedSettings &&
                  playersWithParsedSettings.length > 0 && (
                    <>
                      <div
                        className={
                          playersWithParsedSettings.length >=
                          NUMBER_PLAYERS_FULL
                            ? 'players-container players-container-full'
                            : 'players-container'
                        }
                      >
                        {playersWithParsedSettings
                          .sort(
                            (a, b) =>
                              (b.is_online === true ? 1 : -1) -
                              (a.is_online === true ? 1 : -1)
                          )
                          .map((player, index) => (
                            <div
                              className={
                                playersWithParsedSettings.length >=
                                NUMBER_PLAYERS_FULL
                                  ? 'player-card player-card-full'
                                  : 'player-card'
                              }
                              key={index}
                              style={{
                                flex: `0 0 calc((100% / ${
                                  windowSize.width <= 1024
                                    ? windowSize.width < 800
                                      ? windowSize.width < 600
                                        ? 3
                                        : 4
                                      : playersWithParsedSettings.length <= 5
                                      ? playersWithParsedSettings.length
                                      : 5
                                    : playersWithParsedSettings.length <= 10
                                    ? playersWithParsedSettings.length
                                    : playersWithParsedSettings.length <= 20
                                    ? 10
                                    : 15
                                }) - 1em)`
                              }}
                            >
                              <div
                                className={
                                  player.is_online
                                    ? 'background-avatar lobby-player-online'
                                    : 'background-avatar blocked'
                                }
                              >
                                <img
                                  className={
                                    playersWithParsedSettings.length >=
                                    NUMBER_PLAYERS_FULL
                                      ? 'pompa-full'
                                      : 'pompa'
                                  }
                                  src={pompa}
                                />
                                <div
                                  className={
                                    playersWithParsedSettings.length >=
                                    NUMBER_PLAYERS_FULL
                                      ? 'container-avatar-full'
                                      : 'container-avatar'
                                  }
                                >
                                  <img
                                    className={
                                      playersWithParsedSettings.length >=
                                      NUMBER_PLAYERS_FULL
                                        ? 'player-avatar-full'
                                        : 'player-avatar'
                                    }
                                    src={
                                      player.parsedSettings?.blueberry?.avatar
                                        ?.single_image
                                        ? player.parsedSettings.blueberry.avatar
                                            .single_image
                                        : defaultAvatarImage
                                    }
                                    alt={player.name}
                                  />
                                </div>
                              </div>
                              <div
                                className={
                                  playersWithParsedSettings.length >=
                                  NUMBER_PLAYERS_FULL
                                    ? 'player-name player-name-full'
                                    : 'player-name'
                                }
                              >
                                <span>
                                  {player.name +
                                    ' ' +
                                    player.lastname.charAt(0) +
                                    '.'}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                <div
                  className={
                    playersWithParsedSettings.length > 24
                      ? 'message-container message-container-full'
                      : 'message-container'
                  }
                >
                  <div className="text">{messageLobby}</div>
                </div>
              </div>
            </div>
          </>
        )}

        {countDownTeacherPractice !== 0 && (
          <div className="container-countdown">
            <div className="text-countdown" key={keyCountDown}>
              {countDownTeacherPractice}
            </div>
          </div>
        )}
        {countDownTeacherPractice === 0 && (
          <div className="container-countdown">
            <div className="text-countdown-end">
              {translate('teacher_practice_lobby_go')}
            </div>
          </div>
        )}
      </div>
    )
  )
}

export default LobbyTeacherPracticePage
