import React, { useEffect, useState } from 'react'
import useQuery from '../../hooks/useQuery'
import translate from '../../i18n/translate'
import SimpleButton from '../../modules/atoms/SimpleButton/SimpleButton'
import './_genericError.scss'

const GenericError = ({ disableButton }) => {
  const query = useQuery()
  const [showUserGoBack, setShowUserGoBack] = useState(false)

  useEffect(() => {
    const _return = query.get('return')
    if (_return) {
      setShowUserGoBack(true)
    }
  }, [])

  const goBackToLogin = () => {
    window.location.href = '/login'
  }

  return (
    <div className="generic-error-page">
      <div className="generic-error-page_background">
        <div className="generic-error-page_container">
          <div className="generic-error-page_message-container">
            <div className="error-page__message">
              {translate('generic_error_message')}
            </div>
          </div>
          <div className="error-page__button-container">
            {showUserGoBack && (
              <SimpleButton
                onClick={goBackToLogin}
                variant={'minimal'}
                text={translate('go_back')}
                display="inline"
                disabled={disableButton}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenericError
