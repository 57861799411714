import React from 'react'

/* NOTA: revisar y actualizar cuando corresponda:

  Textos legales según Tenant
    1 Globales para España y Brasil
    2 Específicos para España desde 'family.blueberrymath.ai'
*/

const FALLBACK_HOST = 'global'
const FALLBACK_LANGUAGE = 'ES'

const TEXTS = {
  // Global
  global: {
    ES: (
      <>
        <h3>POLÍTICA DE PRIVACIDAD</h3>
        <h5>1.- ¿Quién es el responsable del tratamiento de sus datos?</h5>
        <p>
          El responsable del tratamiento de sus datos personales es ONECLICK
          DISEÑO Y SOFTWARE, S.L. (en adelante, <b>"ONECLICK"</b>), con N.I.F.
          B-83839647, y domicilio en Calle Alameda, 22. Bajo 2. 28014 - Madrid.
        </p>
        <p>
          La presente Política de Privacidad regula el acceso y el uso del Sitio
          Web (en adelante, el "<b>Sitio Web</b>") que ONECLICK pone a
          disposición de los usuarios de Internet (en adelante, los "
          <b>Usuarios</b>") interesados en los contenidos (en adelante, los "
          <b>Contenidos</b>") y los servicios (en adelante, los "
          <b>Servicios</b>") ofertados por ONECLICK en el mismo.
        </p>
        <p>
          Correo electrónico de contacto:
          <a href="mailto:proteccion.datos@oneclick.es">
            proteccion.datos@oneclick.es
          </a>
        </p>
        <h5>2.- Recomendaciones</h5>
        <p>Por favor, lea atentamente y siga las siguientes recomendaciones:</p>
        <ul>
          <li>
            Mantenga su equipo equipado con software antivirus debidamente
            actualizado contra software malicioso y aplicaciones de spyware que
            puedan poner en peligro su navegación por Internet y la información
            alojada en su equipo.
          </li>
          <li>
            Lea y revise esta Política de Privacidad y todos los textos legales
            puestos a disposición por ONECLICK en el Sitio Web.
          </li>
        </ul>
        <h5>
          3.- Información sobre los datos que ONECLICK recopila a través del
          Sitio Web
        </h5>
        <p>
          Para el correcto funcionamiento del Sitio Web, ONECLICK puede tener
          acceso a los siguientes datos facilitados, en su caso, por parte de
          Usuario:
        </p>
        <ol>
          <li>Datos de identificación: nombre y apellidos del usuario.</li>
          <li>Datos de contacto: email, teléfono y dirección postal.</li>
        </ol>
        <h5>4.- ¿Por qué ONECLICK está legitimada para procesar sus datos?</h5>
        <p>
          La base de legitimación de ONECLICK para el tratamiento de los datos
          de los Usuarios a través de este Sitio Web es el consentimiento de los
          Usuarios al tratamiento de sus datos personales, así como la ejecución
          de un contrato en el que el interesado es parte o la aplicación a
          petición de este de medidas precontractuales.
        </p>
        <p>
          En este sentido, ONECLICK trata los datos personales de los Usuarios
          con la finalidad de permitir la relación comercial solicitada por el
          usuario, así como también para la remisión de comunicaciones
          comerciales y/o Newsletters sobre sus propios productos y/o servicios.
        </p>
        <p>
          Aceptando la presente Política de Privacidad y marcando las casillas
          correspondientes, el Usuario presta su consentimiento a dicho
          tratamiento.
        </p>
        <p>
          ONECLICK se toma muy en serio la protección de su privacidad y sus
          datos personales. Por esta razón, sus datos personales se conservan de
          forma segura y se tratan con el máximo cuidado, de conformidad con las
          disposiciones del Reglamento (UE) 2016/679 del Parlamento Europeo y
          del Consejo, de 27 de abril de 2016, relativo a la protección de las
          personas físicas en lo que respecta al tratamiento de datos personales
          y a la libre circulación de estos datos (el "Reglamento general de
          protección de datos" o "
          <b>Reglamento de protección de datos o RGPD</b>
          ").
        </p>
        <p>
          La presente Política de Privacidad regula el acceso y el uso del
          servicio que ONECLICK pone a disposición del Usuario interesado en los
          servicios y contenidos alojados en el Sitio Web.
        </p>
        <p>
          Por lo que respecta a este punto, el Usuario podrá retirar su
          consentimiento en cualquier momento mandando un mensaje a la siguiente
          dirección de correo electrónico:{' '}
          <a href="mailto:proteccion.datos@oneclick.es">
            proteccion.datos@oneclick.es
          </a>{' '}
          sin que ello afecte a la licitud del tratamiento basado en el
          consentimiento previo a su retirada.
        </p>
        <h5>
          5.- ¿Cuáles son las finalidades del tratamiento de sus datos
          personales?
        </h5>
        <p>
          Sus datos de carácter personal recabados por ONECLICK podrán ser
          utilizados para las siguientes finalidades, según el consentimiento
          otorgado en el formulario de registro correspondiente:
        </p>
        <ol>
          <li>
            Permitir que el Sitio Web cumpla su función de proporcionar los
            servicios solicitados.
          </li>
          <li>
            Recibir por carta, teléfono, correo electrónico, SMS/MMS, o por
            otros medios de comunicación electrónica equivalentes,
            comunicaciones comerciales o información sobre productos y servicios
            de ONECLICK.
          </li>
        </ol>
        <h5>6.- Veracidad de los datos facilitados por los Usuarios</h5>
        <p>
          El Usuario garantiza la veracidad de los datos personales facilitados
          y se compromete a comunicar a ONECLICK cualquier modificación de los
          mismos. El Usuario responderá, en cualquier caso, de la veracidad de
          los datos facilitados, reservándose ONECLICK el derecho a excluir de
          los servicios a todo Usuario que haya facilitado datos falsos, sin
          perjuicio de las demás acciones que procedan en Derecho.
        </p>
        <p>
          Se recomienda que los Usuarios protejan sus datos con la máxima
          diligencia utilizando las herramientas de seguridad adecuadas.
          ONECLICK no será responsable de ningún robo, modificación ilegal o
          pérdida de datos.
        </p>
        <p>
          Cualquier modificación o actualización de los datos deberá ser
          comunicada a ONECLICK a través de los medios de comunicación previstos
          en la presente Política de Privacidad.
        </p>
        <h5>7.- Conservación de datos </h5>
        <p>
          La protección de la privacidad y de los datos personales de los
          Usuarios es muy importante para ONECLICK. Por lo tanto, ONECLICK hace
          todo lo posible para evitar que los datos del Usuario sean utilizados
          de manera inadecuada. Sólo el personal autorizado tiene acceso a los
          datos del Usuario.
        </p>
        <p>
          Los datos personales del Usuario se conservarán mientras permanezca su
          cuenta de ONECLICK activa para los fines del tratamiento para los que
          han sido facilitados, siempre que el Usuario no haya revocado su
          consentimiento, solicitando la eliminación de su cuenta o el borrado
          de determinados datos personales del Sitio Web.
        </p>
        <p>
          Posteriormente, en caso de ser necesario, ONECLICK mantendrá la
          información bloqueada los plazos legalmente establecidos.
        </p>
        <p>
          ONECLICK se compromete al cumplimiento de su obligación de secreto y
          confidencialidad de los datos de carácter personal conforme a lo
          dispuesto en la legislación aplicable.
        </p>
        <h5>8.- Derechos de los usuarios </h5>
        <p>Los usuarios tienen derecho a:</p>
        <ol>
          <li>
            <b>Acceder</b> a sus datos personales;
          </li>
          <li>
            Solicitar la <b>rectificación</b> de cualquier dato inexacto;
          </li>
          <li>
            Solicitar la <b>supresión</b> de sus datos;
          </li>
          <li>
            Solicitar que se <b>limite el tratamiento</b> de sus datos;
          </li>
          <li>
            <b>Oponerse</b> al tratamiento de sus datos;
          </li>
          <li>
            Solicitar la <b>portabilidad</b> de sus datos.
          </li>
          <li>
            Asimismo, los Usuarios tendrán derecho a{' '}
            <b>
              no ser objeto de decisiones basadas únicamente en el tratamiento
              automatizado de datos
            </b>
            .
          </li>
        </ol>
        <p>
          El Usuario puede ejercer todos estos derechos en la siguiente
          dirección de correo electrónico:
          <a href="mailto:proteccion.datos@oneclick.es">
            proteccion.datos@oneclick.es
          </a>
          indicando el motivo de su petición y aportando una copia de su D.N.I.
        </p>
        <p>
          El Usuario puede también enviar su petición por correo ordinario a la
          siguiente dirección: Calle Alameda, 22. Bajo 2. 28014 - Madrid.
        </p>
        <p>
          La comunicación dirigida a ONECLICK deberá contener la siguiente
          información:
        </p>
        <ul>
          <li>
            Nombre y apellidos del Usuario; fotocopia de su documento nacional
            de identidad, o de su pasaporte u otro documento válido que lo
            identifique.
          </li>
          <li>Petición en que se concreta la solicitud.</li>
        </ul>
        <p>
          Sin perjuicio de cualquier otro recurso administrativo o acción
          judicial, el Usuario tendrá derecho a presentar una reclamación ante
          una Autoridad de Control, en particular en el Estado miembro en el que
          tenga su residencia habitual, lugar de trabajo o lugar de la supuesta
          infracción, en caso de que considere que el tratamiento de sus datos
          personales no es adecuado a la normativa, así como en el caso de no
          ver satisfecho el ejercicio de sus derechos. La autoridad de control
          ante la que se haya presentado la reclamación informará al reclamante
          sobre el curso y el resultado de la reclamación.
        </p>
        <h5>9.- Seguridad de datos</h5>
        <p>
          La protección de la privacidad y de los datos personales de los
          Usuarios es muy importante para ONECLICK. Por ello, ONECLICK ha tomado
          todas las medidas a su alcance para evitar que sus datos sean
          utilizados de forma indebida, permitiendo el acceso a los mismos
          únicamente al personal autorizado.
        </p>
        <p>
          ONECLICK mantiene la seguridad adecuada para la protección de los
          datos personales de acuerdo con la legislación aplicable y ha
          establecido todos los medios técnicos a su alcance para evitar la
          pérdida, mal uso, alteración, acceso no autorizado y robo de los datos
          facilitados por el Usuario a través del Sitio Web, sin perjuicio de
          informarle de que las medidas de seguridad en Internet no son
          inexpugnables.
        </p>
        <p>
          ONECLICK se compromete a cumplir con el deber de secreto y
          confidencialidad respecto a los datos de carácter personal de acuerdo
          con la legislación aplicable.
        </p>
        <p>
          ONECLICK no será responsable de los daños y perjuicios que pudieran
          derivarse del incumplimiento de esta obligación por parte del Usuario.
        </p>

        <h5>10.- Transferencias y/o cesiones a terceros</h5>
        <p>
          Se informa al Usuario de que sus datos de carácter personal no podrán
          ser cedidos a terceras empresas o entidades.
        </p>
        <p>
          Los datos de los Usuarios únicamente podrán ser utilizados por
          ONECLICK para cualquier uso que sirva para cumplir correctamente con
          las finalidades del tratamiento de datos de carácter personal
          recogidas en el punto 5 de esta Política de Privacidad.
        </p>

        <h5>11.- Preguntas</h5>
        <p>
          Si tiene alguna duda sobre nuestra Política de Privacidad, póngase en
          contacto con nosotros enviando un correo electrónico a
          <a href="mailto:proteccion.datos@oneclick.es">
            proteccion.datos@oneclick.es
          </a>
        </p>
        <p>
          Asimismo, si considera que sus derechos no están debidamente
          salvaguardados, tiene derecho a presentar una reclamación ante la
          Agencia Española de Protección de Datos.
        </p>

        <h5>12.- Aceptación y Consentimiento</h5>
        <p>
          El Usuario declara haber sido informado de las condiciones sobre
          protección de datos personales, aceptando y consintiendo el
          tratamiento de los mismos por parte de ONECLICK, en la forma y para
          las finalidades indicadas en la presente Política de Privacidad.
        </p>
      </>
    ),

    PT: (
      <>
        <h3>POLÍTICA DE PRIVACIDADE</h3>
        <h5>1.- Quem é o responsável pelo tratamento dos seus dados?</h5>
        <p>
          O responsável pelo tratamento dos seus dados pessoais é a ONECLICK
          DISEÑO Y SOFTWARE, S.L. (doravante, <b>"ONECLICK"</b>), com N.I.F
          B-83839647, e domicílio na Calle Alameda, 22. Bajo 2. 28014 - Madrid.
        </p>
        <p>
          Esta Política de Privacidade regula o acesso e o uso do Site Web
          (doravante, o "<b>Site Web</b>") que a ONECLICK disponibiliza aos
          usuários da Internet (doravante, os "<b>Usuários</b>") interessados
          nos conteúdos (doravante, os "<b>Conteúdos</b>") e nos serviços
          (doravante, os "<b>Serviços</b>") oferecidos pela ONECLICK no mesmo.
        </p>

        <p>
          E-mail de contato:
          <a href="mailto:proteccion.datos@oneclick.es">
            proteccion.datos@oneclick.es
          </a>
        </p>
        <h5>2.- Recomendações</h5>
        <p>Por favor, leia atentamente e siga as seguintes recomendações: </p>
        <ul>
          <li>
            Mantenha seu dispositivo equipado com um antivírus devidamente
            atualizado contra software malicioso e aplicativos de spyware que
            possam colocar em risco sua navegação na Internet e as informações
            armazenadas no seu dispositivo.
          </li>
          <li>
            Leia e revise esta Política de Privacidade e todos os textos legais
            disponibilizados pela ONECLICK no Site Web.
          </li>
        </ul>
        <h5>
          3.- Informações sobre os dados que a ONECLICK coleta através do Site
          Web
        </h5>
        <p>
          Para o correto funcionamento do Site, a ONECLICK pode ter acesso aos
          seguintes dados fornecidos, se for o caso, pelo Usuário:
        </p>
        <ol>
          <li>Dados de identificação: nome e sobrenome do usuário.</li>
          <li>Dados de contato: e-mail, telefone e endereço postal.</li>
        </ol>
        <h5>4.- Por que a ONECLICK está legitimada a processar seus dados?</h5>
        <p>
          A base de legitimação da ONECLICK para o tratamento dos dados dos
          Usuários através deste Site é o consentimento dos Usuários ao
          tratamento dos seus dados pessoais, bem como a execução de um contrato
          em que o interessado é parte ou a aplicação, a pedido deste, de
          medidas pré-contratuais.
        </p>
        <p>
          Nesse sentido, a ONECLICK trata os dados pessoais dos Usuários com o
          objetivo de permitir a relação comercial solicitada pelo usuário,
          assim como para o envio de comunicações comerciais e/ou{' '}
          <i>newsletters</i> sobre seus próprios produtos e/ou serviços.
        </p>
        <p>
          Ao aceitar esta Política de Privacidade e marcar as caixas
          correspondentes, o Usuário dá seu consentimento a esse tratamento.
        </p>
        <p>
          A ONECLICK leva muito a sério a proteção da sua privacidade e dos seus
          dados pessoais. Por esta razão, seus dados pessoais são armazenados de
          forma segura e são tratados com o máximo cuidado, em conformidade com
          as disposições do Regulamento (UE) 2016/679 do Parlamento Europeu e do
          Conselho, de 27 de abril de 2016, relativo à proteção das pessoas
          físicas no que diz respeito ao tratamento de dados pessoais e à livre
          circulação desses dados (o "
          <b>Regulamento Geral de Proteção de Dados</b>" ou "
          <b>Regulamento de Proteção de Dados ou RGPD</b>").
        </p>
        <p>
          A presente Política de Privacidade regula o acesso e o uso do serviço
          que a ONECLICK disponibiliza ao Usuário interessado nos serviços e
          conteúdos hospedados no Site Web.
        </p>
        <p>
          Nesse sentido, o Usuário poderá retirar seu consentimento a qualquer
          momento enviando uma mensagem para o seguinte endereço de e-mail:
          <a href="mailto:proteccion.datos@oneclick.es">
            proteccion.datos@oneclick.es
          </a>{' '}
          sem que isso afete a legalidade do tratamento baseado no consentimento
          prévio à sua retirada.
        </p>
        <h5>
          5.- Quais são os propósitos do tratamento dos seus dados pessoais?
        </h5>
        <p>
          Seus dados pessoais coletados pela ONECLICK podem ser usados para os
          seguintes fins, de acordo com o consentimento dado no formulário de
          registro correspondente:
        </p>
        <ol>
          <li>
            Permitir que o Site Web cumpra sua função de fornecer os serviços
            solicitados.
          </li>
          <li>
            Receber por carta, telefone, e-mail, SMS/MMS ou por outros meios de
            comunicação eletrônica equivalentes, comunicações comerciais ou
            informações sobre produtos e serviços da ONECLICK.
          </li>
        </ol>
        <h5>6.- Veracidade dos dados fornecidos pelos Usuários</h5>
        <p>
          O Usuário garante a veracidade dos dados pessoais fornecidos e se
          compromete a comunicar à ONECLICK qualquer modificação dos mesmos. O
          Usuário será responsável, em qualquer caso, pela veracidade dos dados
          fornecidos, reservando-se à ONECLICK o direito de excluir dos serviços
          todo Usuário que tenha fornecido dados falsos, sem prejuízo das demais
          ações que caibam em Direito.
        </p>
        <p>
          Recomenda-se que os Usuários protejam seus dados com a máxima
          diligência, utilizando as ferramentas de segurança adequadas. A
          ONECLICK não será responsável por qualquer roubo, modificação ilegal
          ou perda de dados.
        </p>
        <p>
          Qualquer modificação ou atualização dos dados deverá ser comunicada à
          ONECLICK através dos meios de comunicação previstos na presente
          Política de Privacidade.
        </p>
        <h5>7.- Conservação de dados </h5>
        <p>
          A proteção da privacidade e dos dados pessoais dos Usuários é muito
          importante para a ONECLICK. Por isso, a ONECLICK faz todo o possível
          para evitar que os dados do Usuário sejam utilizados de maneira
          inadequada. Somente o pessoal autorizado tem acesso aos dados do
          Usuário.
        </p>
        <p>
          Os dados pessoais do Usuário serão conservados enquanto sua conta
          ONECLICK permanecer ativa para os fins do tratamento para os quais
          foram fornecidos, desde que o Usuário não tenha revogado seu
          consentimento, solicitando a exclusão de sua conta ou a eliminação de
          determinados dados pessoais do Site Web.
        </p>
        <p>
          Posteriormente, se necessário, a ONECLICK manterá as informações
          bloqueadas pelos prazos legalmente estabelecidos.
        </p>
        <p>
          A ONECLICK se compromete ao cumprimento de sua obrigação de sigilo e
          confidencialidade dos dados pessoais, de acordo com o estabelecido na
          legislação aplicável.
        </p>
        <h5>8.- Direitos dos usuários</h5>
        <p>Os usuários têm o direito de:</p>
        <ol>
          <li>
            <b>Acessar </b> seus dados pessoais;
          </li>
          <li>
            Solicitar a <b>retificação</b> de qualquer dado impreciso;
          </li>
          <li>
            Solicitar a <b>exclusão </b> de seus dados;
          </li>
          <li>
            Solicitar a <b>limitação do tratamento</b> de seus dados;
          </li>
          <li>
            <b>Opor-se</b> ao tratamento de seus dados;
          </li>
          <li>
            Solicitar a <b>portabilidade </b> de seus dados.
          </li>
          <li>
            Além disso, os Usuários terão o direito de{' '}
            <b>
              não serem objeto de decisões baseadas apenas no tratamento
              automatizado de dados
            </b>
            .
          </li>
        </ol>
        <p>
          O Usuário pode exercer todos esses direitos no seguinte endereço de
          e-mail:
          <a href="mailto:proteccion.datos@oneclick.es">
            proteccion.datos@oneclick.es
          </a>
          indicando o motivo do seu pedido e fornecendo uma cópia do seu RG.
        </p>
        <p>
          O Usuário também pode enviar seu pedido por correio normal para o
          seguinte endereço: Calle Alameda, 22. Bajo 2. 28014 - Madrid.
        </p>
        <p>
          A comunicação dirigida à ONECLICK deve conter as seguintes
          informações:
        </p>
        <ul>
          <li>
            Nome e sobrenome do Usuário; fotocópia do seu documento nacional de
            identidade, passaporte ou outro documento válido que o identifique.
          </li>
          <li>Solicitação concreta do pedido.</li>
        </ul>
        <p>
          Sem prejuízo de qualquer outro recurso administrativo ou ação
          judicial, o Usuário terá o direito de apresentar uma reclamação a uma
          Autoridade de Controle, em particular no Estado-membro em que tenha
          sua residência habitual, local de trabalho ou local da suposta
          infração, caso considere que o tratamento de seus dados pessoais não
          está adequado à regulamentação, bem como no caso de não ver satisfeito
          o exercício de seus direitos. A autoridade de controle perante a qual
          a reclamação foi apresentada informará o reclamante sobre o andamento
          e o resultado da reclamação.
        </p>
        <h5>9.- Segurança de dados</h5>
        <p>
          A proteção da privacidade e dos dados pessoais dos Usuários é muito
          importante para a ONECLICK. Por isso, a ONECLICK tomou todas as
          medidas ao seu alcance para evitar que seus dados sejam utilizados de
          forma indevida, permitindo o acesso aos mesmos apenas ao pessoal
          autorizado.
        </p>
        <p>
          A ONECLICK mantém a segurança adequada para a proteção dos dados
          pessoais de acordo com a legislação aplicável e estabeleceu todos os
          meios técnicos ao seu alcance para evitar a perda, mau uso, alteração,
          acesso não autorizado e roubo dos dados fornecidos pelo Usuário
          através do site web, sem prejuízo de informar que as medidas de
          segurança na Internet não são inexpugnáveis.
        </p>
        <p>
          A ONECLICK compromete-se a cumprir com o dever de sigilo e
          confidencialidade em relação aos dados de caráter pessoal de acordo
          com a legislação aplicável.
        </p>
        <p>
          A ONECLICK não será responsável pelos danos e prejuízos que possam
          surgir do descumprimento desta obrigação por parte do Usuário.
        </p>
        <h5>10.- Transferências e/ou cessões a terceiros</h5>
        <p>
          Informa-se ao Usuário que seus dados de caráter pessoal não poderão
          ser cedidos a empresas ou entidades terceiras.
        </p>
        <p>
          Os dados dos Usuários somente poderão ser utilizados pela ONECLICK
          para qualquer uso que sirva para cumprir corretamente com as
          finalidades do tratamento de dados de caráter pessoal mencionadas no
          ponto 5 desta Política de Privacidade.
        </p>
        <h5>11.- Perguntas</h5>
        <p>
          Se tiver alguma dúvida sobre nossa Política de Privacidade, entre em
          contato conosco enviando um e-mail para
          <a href="mailto:proteccion.datos@oneclick.es">
            proteccion.datos@oneclick.es
          </a>
        </p>
        <p>
          Além disso, se considerar que seus direitos não estão devidamente
          salvaguardados, tem o direito de apresentar uma reclamação à Agência
          Espanhola de Proteção de Dados.
        </p>
        <h5>12.- Aceitação e Consentimento</h5>
        <p>
          O Usuário declara ter sido informado das condições sobre proteção de
          dados pessoais, aceitando e consentindo o tratamento dos mesmos por
          parte da ONECLICK, na forma e para as finalidades indicadas na
          presente Política de Privacidade.
        </p>
      </>
    )
  },

  // Para 'family.blueberrymath.ai'
  mathai: {
    ES: (
      <>
        <h3>TÉRMINOS Y CONDICIONES DE USO DE LA PLATAFORMA "BLUEBERRY"</h3>
        <p>
          Los presentes Términos y Condiciones (en adelante, los "Términos y
          Condiciones") regulan, la descarga, acceso, navegación y uso de la
          plataforma propiedad de ONECLICK (en adelante, la "Plataforma"),
          accesible desde el sitio web
          <a href="https://oneclick.es/es/">https://oneclick.es/es/</a>
          (en adelante, el "Sitio Web"), con correo electrónico
          <a href="mailto:info@oneclick.es">info@oneclick.es</a>, la cual es
          titularidad de ONECLICK DISEÑO Y SOFTWARE S.L., con CIF B-83839647,
          con domicilio social en la Calle Alameda,22; planta baja, 28014,
          Madrid, (en adelante, "ONECLICK").
        </p>
        <h5>1.- OBJETO DE LA PLATAFORMA</h5>
        <p>
          ONECLICK es una empresa especializada en el diseño y desarrollo de
          soluciones digitales para el sector educativo. ONECLICK ofrece un
          software llamado BLUEBERRY, el cual es un entorno de aprendizaje
          avanzado que permite acceder a contenido de matemáticas.
        </p>
        <p>
          La adquisición de las licencias deberá efectuarse según las
          instrucciones mencionadas en estos Términos y Condiciones, así como
          cualesquiera otras condiciones específicas que se indiquen en pantalla
          durante la navegación o de cualquier otro modo, de manera que el
          usuario (en adelante el "Usuario") declara conocer y aceptar dichos
          trámites como necesarios para acceder a los servicios y/o Cursos
          ofrecidos en el Sitio Web.
        </p>

        <h5>2.- ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES</h5>
        <p>
          Mediante la aceptación de los Términos y Condiciones, el Usuario se
          compromete a actuar en todo momento de conformidad con las
          disposiciones establecidas en el clausulado del presente texto legal.
          En caso de no estar de acuerdo con todo o parte de estos Términos y
          Condiciones, el Usuario debe abstenerse de utilizar la Plataforma y
          los servicios en ella ofrecidos por ONECLICK.
        </p>
        <p>El servicio prestado en esta Plataforma se rige por:</p>
        <ol>
          <li>estos Términos y Condiciones;</li>
          <li style={{ paddingTop: '1em' }}>
            Condiciones Particulares que puedan publicarse en la Plataforma o
            que queden regulados y reflejados en el Sitio web;
          </li>
          <li style={{ paddingTop: '1em' }}>
            las leyes vigentes y aplicables, regulaciones sectoriales y los
            criterios y prácticas generales.
          </li>
        </ol>
        <p>
          Al aceptar los presentes Términos y Condiciones Generales, el usuario
          manifiesta ser mayor de 18 años y/o tener capacidad legal plena para
          entender y aceptar el contenido de estos Términos y Condiciones,
          eximiendo y manteniendo indemne a ONECLICK de cualesquiera
          reclamaciones, denuncias o daños causados por el incumplimiento de
          esta condición, la cual se entiende como conditio sine qua non para el
          uso y aprovechamiento de los servicios ofrecidos por ONECLICK en la
          misma.
        </p>
        <p>
          En el caso de los usuarios menores de edad, se requiere que los padres
          o tutores legales firmen y acepten estos Términos y Condiciones en
          nombre del menor y se responsabilicen de su cumplimiento. Los padres o
          tutores legales asumen toda la responsabilidad y consecuencias legales
          derivadas del uso de los servicios por parte del menor.
        </p>

        <h5>3.- REGISTRO</h5>
        <p>
          El Usuario deberá registrarse en la plataforma de ONECLICK para
          acceder a sus servicios. Para completar el proceso de registro, el
          Usuario deberá proporcionar información precisa, actualizada y veraz,
          así como crear un nombre de usuario y una contraseña seguros.
        </p>
        <p>
          El Usuario es el único responsable de mantener la confidencialidad de
          su nombre de usuario y contraseña, y de todas las actividades que
          ocurran bajo su cuenta. Se compromete a notificar de inmediato a
          ONECLICK cualquier uso no autorizado de su cuenta o cualquier otra
          violación de seguridad.
        </p>
        <p>
          El registro en la plataforma de ONECLICK está sujeto a la aprobación
          de la empresa. ONECLICK se reserva el derecho de rechazar o cancelar
          el registro de un Usuario en cualquier momento y por cualquier motivo,
          sin previo aviso.
        </p>
        <p>
          El Usuario acepta que la información proporcionada durante el proceso
          de registro será verídica y precisa, y se compromete a mantenerla
          actualizada en todo momento. Asimismo, el usuario acepta que ONECLICK
          pueda utilizar la información proporcionada durante el registro de
          acuerdo con su Política de Privacidad.
        </p>
        <p>
          El Usuario declara ser el titular legítimo de la información
          proporcionada durante el registro y garantiza que tiene la capacidad
          legal para aceptar estos Términos y Condiciones.
        </p>
        <p>
          El registro en la plataforma de ONECLICK no garantiza el acceso
          inmediato a todos los servicios ofrecidos. Algunos servicios pueden
          estar sujetos a condiciones adicionales, como la aceptación de
          contratos específicos o el pago de tarifas adicionales.
        </p>
        <p>
          ONECLICK se reserva el derecho de modificar o cancelar el registro de
          un Usuario en caso de incumplimiento de estos Términos y Condiciones o
          de cualquier otra normativa aplicable.
        </p>
        <p>
          El Usuario acepta que ONECLICK no será responsable de ninguna pérdida
          o daño causado por el incumplimiento de las obligaciones relacionadas
          con el registro, incluyendo la pérdida de acceso a los servicios o la
          divulgación no autorizada de información personal.
        </p>
        <p>
          El registro en la plataforma de ONECLICK implica la aceptación de
          estos Términos y Condiciones, así como de cualquier otra política o
          condición adicional establecida por ONECLICK.
        </p>

        <h5>4.- ACCESO A LA LICENCIA</h5>
        <ul>
          <li>Acceso y uso de la Licencia:</li>
          <li style={{ listStyleType: 'none', paddingTop: '1em' }}>
            El Usuario tendrá únicamente el derecho a acceder y usar el Software
            para sus propósitos y en las condiciones establecidas en estos
            Términos y Condiciones. Cualquier otro acceso y/o uso del Software
            constituirá un supuesto de incumplimiento de conformidad con lo
            dispuesto en el presente Contrato. Asimismo, el Usuario se
            compromete a mantener la confidencialidad de las cuentas, las
            credenciales y cualquier contraseña del Usuario necesaria para el
            acceso y utilización del Software, por lo que será responsable de
            cualquier uso dado al Software a través de las credenciales del
            Usuario o cualquier cuenta que el Usuario pueda establecer. En caso
            de pérdida, sustracción, sospecha de un uso no autorizado o
            cualquier otro hecho que pudiera afectar a la confidencialidad de
            sus cuentas, credenciales o contraseñas, el Usuario lo deberá
            notificar inmediatamente a ONECLICK para que pueda adoptar las
            medidas necesarias. Hasta que ONECLICK no sea informado de dichos
            hechos, quedará exonerado de toda responsabilidad por las
            operaciones que pudieran efectuarse utilizando las cuentas,
            credenciales o contraseñas del Usuario.
          </li>
          <li style={{ paddingTop: '1em' }}>
            Requisitos técnicos para visualizar contenidos:
          </li>
          <li style={{ listStyleType: 'none', paddingTop: '1em' }}>
            El contenido del Software solo se puede visualizar a través de los
            navegadores Chrome, Firefox, Safari, Opera y Edge, e incluye un modo
            offline en las aplicaciones de tablets con sistemas operativos iOS y
            Android que permite a los usuarios seguir utilizándolo sin necesidad
            de conexión a internet, así como una aplicación web disponible para
            la última generación de navegadores. ONECLICK no puede garantizar el
            acceso a través de Internet Explorer u otros navegadores nativos, o
            mediante navegadores no actualizados. Corresponde al Usuario
            garantizar la compatibilidad de su entorno informático y de sus
            terminales móviles, así como los de terceros, con la Licencia SaaS,
            bajo su propia responsabilidad. La Licencia SaaS solo puede ser
            utilizada en una configuración de uso que cumpla con los requisitos
            establecidos por ONECLICK en estos Términos y Condiciones.
          </li>
          <li style={{ listStyleType: 'none', paddingTop: '1em' }}>
            En caso de modificación de la configuración inicial de uso por parte
            del Usuario que no cumpla con los requisitos previos indicados por
            ONECLICK, ONECLICK se mantendrá indemne de responsabilidad relativa
            al funcionamiento o disponibilidad del Software.
          </li>
        </ul>

        <h5>5.- OBLIGACIONES DE ONECLICK</h5>
        <ul>
          <li>
            Proveer acceso a la plataforma: ONECLICK se compromete a brindar a
            los Usuarios registrados acceso a su plataforma y a los servicios
            ofrecidos de acuerdo con los términos y condiciones establecidos.
            Esto incluye garantizar un funcionamiento adecuado de la plataforma
            y la disponibilidad de los servicios, salvo en casos de
            interrupciones planificadas o imprevistas.
          </li>
          <li style={{ paddingTop: '1em' }}>
            ONECLICK tomará las medidas necesarias para proteger la seguridad de
            la plataforma y de la información de los Usuarios. Esto implica
            implementar medidas de seguridad técnicas y organizativas adecuadas
            para prevenir accesos no autorizados, pérdidas, alteraciones o
            divulgación de datos personales.
          </li>
          <li style={{ paddingTop: '1em' }}>
            ONECLICK se compromete a cumplir con todas las leyes y regulaciones
            aplicables en relación con la prestación de sus servicios. Esto
            incluye el cumplimiento de las normas de protección de datos, la
            privacidad y cualquier otra legislación relevante en el ámbito de su
            actividad.
          </li>
          <li style={{ paddingTop: '1em' }}>
            ONECLICK brindará soporte y atención al cliente a los Usuarios para
            resolver consultas, problemas técnicos o cualquier otro tipo de
            inconveniente relacionado con la plataforma. Esto puede incluir
            asistencia por correo electrónico, chat en línea u otros medios de
            comunicación establecidos por ONECLICK.
          </li>
          <li style={{ paddingTop: '1em' }}>
            ONECLICK se compromete a habilitar la licencia de uso del software
            una vez que se haya realizado el pago correspondiente. Una vez que
            el Usuario haya cumplido con sus obligaciones de pago, ONECLICK
            procederá a activar y habilitar la licencia, lo que permitirá al
            Usuario acceder y utilizar plenamente los servicios y
            funcionalidades ofrecidos por la plataforma. Esta activación se
            realizará en un plazo razonable y de conformidad con los presentes
            términos y condiciones.
          </li>
        </ul>

        <h5>6.- OBLIGACIONES DE LOS USUARIOS</h5>
        <ul>
          <li>
            Los Usuarios deben cumplir con los términos y condiciones
            establecidos por ONECLICK en relación con el uso de los servicios.
            Esto incluye respetar las políticas de uso, restricciones y
            cualquier otra regla específica aplicable.
          </li>
          <li style={{ paddingTop: '1em' }}>
            Los Usuarios deberán abonar el precio, siguiendo las indicaciones
            previstas en los presentes Términos y Condiciones. En caso de que se
            produzca un impago, ONECLICK podrá suspender de forma inmediata y
            automática el acceso a todos los servicios formativos existentes en
            la Plataforma hasta que se regularice la situación de impagos.
          </li>
          <li style={{ paddingTop: '1em' }}>
            Los Usuarios son exclusivamente responsables de garantizar la
            veracidad de los datos personales proporcionados en todo momento, de
            forma que tanto la identificación, como la prestación de los
            servicios por parte de ONECLICK en la Plataforma, como la
            facturación por la prestación de los mismos se pueda llevar a cabo
            de forma correcta
          </li>
          <li style={{ paddingTop: '1em' }}>
            Igualmente, los Usuarios mantendrán en secreto las claves que
            ONECLICK les proporcione en cada momento, siendo responsables por
            ello de cualquier uso ilegítimo o ilícito que se produzca con sus
            credenciales. Si los Usuarios tienen sospechas de que sus
            credenciales están siendo utilizadas por un tercero, deberán ponerlo
            en conocimiento de ONECLICK de forma inmediata.
          </li>
          <li style={{ paddingTop: '1em' }}>
            Los Usuarios se comprometen expresamente a la no reproducción,
            distribución, comunicación pública, transformación, comercialización
            y puesta a disposición del material didáctico en cualquier soporte o
            formato, puestos a su disposición en el Sitio Web.
          </li>
        </ul>

        <h5>7.- DURACIÓN</h5>
        <p>
          La duración de la relación entre ONECLICK y los Usuarios será
          determinada por el período de tiempo en que los Usuarios utilicen los
          servicios proporcionados por ONECLICK. Los Usuarios podrán acceder y
          utilizar los servicios durante el tiempo que mantengan una cuenta
          activa y cumplan con los términos y condiciones establecidos.
        </p>
        <p>
          ONECLICK se reserva el derecho de suspender o cancelar la cuenta de un
          Usuario en caso de incumplimiento de los términos y condiciones, o si
          considera que la cuenta está siendo utilizada de manera fraudulenta,
          perjudicial o contraria a los intereses de ONECLICK o de otros
          Usuarios.
        </p>
        <p>
          Los Usuarios pueden optar por dar de baja su cuenta en cualquier
          momento, notificándolo a ONECLICK de acuerdo con los procedimientos
          establecidos. En caso de terminación de la cuenta, los Usuarios ya no
          podrán acceder ni utilizar los servicios de ONECLICK, y deberán
          cumplir con cualquier obligación pendiente, como el pago de tarifas o
          la devolución de materiales proporcionados por ONECLICK.
        </p>
        <p>
          Las disposiciones y derechos que, por su naturaleza, deban subsistir a
          la terminación de la relación entre ONECLICK y los Usuarios, seguirán
          en vigor incluso después de la finalización de la cuenta o de la
          suspensión de los servicios.
        </p>

        <h5>8.- CONFIDENCIALIDAD</h5>
        <p>
          Los Usuarios se obligan a guardar secreto y confidencialidad sobre
          cuantos datos le sean facilitados por ONECLICK, relativos a nombre de
          usuario y claves para acceder a la página web y chats incluidos en el
          Sitio Web y la Plataforma.
        </p>
        <p>
          Los Usuarios se obligan a tratar confidencialmente toda la
          documentación y material didáctico, el cual se utilizará únicamente
          para su uso privado formativo, comprometiéndose a no reproducirlo,
          distribuirlo, comunicarlo públicamente, transformarlo, comercializarlo
          o ponerlo a disposición de terceros a través de cualquier formato,
          soporte o medio de difusión.
        </p>
        <p>
          Toda la información ya compartida y/o aún por compartir ONECLICK y los
          Usuarios, facilitada con el objetivo de ejecutar la prestación de
          servicios formativos, será considerada confidencial, especialmente los
          datos de carácter personal de los Usuarios.
        </p>

        <h5>9.- PROTECCIÓN DE DATOS PERSONALES</h5>
        <p>
          Los Usuarios se comprometen a aceptar la Política de Privacidad la
          cual está alojada en el Sitio Web.
        </p>
        <p>
          En consecuencia, los Usuarios están informados informado de que sus
          datos de carácter personal se almacenan con arreglo a lo dispuesto en
          el Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo, de
          27 de abril de 2016, relativo a las personas físicas en lo que
          respecta al tratamiento de datos personales y a la libre circulación
          de estos datos (en adelante, <b>RGPD</b>) y lostablecido en la
          normativa española vigente en esta materia.
        </p>
        <p>
          De acuerdo con lo establecido en el RGPD, los datos personales de los
          Usuarios serán incorporados a nuestro sistema de tratamiento de datos
          personales, con la finalidad de gestionar la presente relación
          contractual y conservándose mientras se mantenga la misma, así como el
          tiempo necesario para el cumplimiento de responsabilidades por parte
          de ONECLICK.
        </p>
        <p>
          ONECLICK informa de la posibilidad que tienen los Usuarios de ejercer
          los siguientes derechos sobre sus datos personales: derecho de acceso,
          rectificación, supresión u olvido, limitación, oposición, portabilidad
          y a retirar el consentimiento prestado (sin perjuicio de la legitimad
          de los tratamientos realizados mientras se prestó dicho
          consentimiento), lo que también aparece definido y explicado con más
          detalle en la Política de Privacidad de ONECLICK.
        </p>
        <p>
          Para ello podrá enviar un email a
          <a href="mailto:info@oneclick.es">info@oneclick.es</a>, acompañado de
          una fotocopia del DNI, o dirigir un escrito ejerciendo sus derechos a
          la siguiente dirección postal: Calle Alameda, 22, Bajo 2, Madrid,
          España, 28014. Igualmente, los Usuarios podrán dirigir sus
          reclamaciones a la Agencia Española de Protección de Datos (
          <a href="https://www.aepd.es/es">https://www.aepd.es/es</a>) si
          entienden que sus derechos no han sido atendidos correctamente.
        </p>

        <h5>10.- PROPIEDAD INTELECTUAL E INDUSTRIAL</h5>
        <p>
          Mediante la aceptación de los presentes Términos y Condiciones,
          ONECLICK manifiesta, y los Usuarios aceptan, que ONECLICK es titular o
          cesionario de todos los derechos de propiedad intelectual, industrial
          y cualesquiera otros existentes sobre el Sitio Web, la Plataforma,
          nombres de dominio y cualesquiera otros que se entiendan incluidos en
          los mismos.
        </p>
        <p>
          En este sentido, ONECLICK y los Usuarios manifiestan y reconocen que
          en ningún caso la prestación de los servicios formativos aquí
          definidos se entenderá como una cesión, autorización, transferencia o
          licencia de los derechos de propiedad intelectual, industrial y de
          cualquier otro tipo de los que sea titular o cesionario ONECLICK,
          siendo sólo posible, por parte de los Usuarios, la utilización de
          cualesquiera contenidos, marcas, nombres de dominio y signos
          distintivos en el más estricto ámbito de la formación ofrecida por
          ONECLICK y única y exclusivamente mientras dure la relación
          contractual entre ONECLICK y los Usuarios.
        </p>
        <p>
          Asimismo, los Usuarios tienen terminantemente prohibida la
          utilización, reproducción, copia, distribución, comunicación pública,
          transformación y/o cualquier otra explotación de, con carácter
          meramente enunciativo, pero no limitativo, el código fuente, código
          objeto, nombres de dominio y materiales didácticos, de parte o de
          totalidad del Sitio Web y/o la Plataforma, así como, de forma
          meramente enunciativa, pero no limitativa, cualquier acto de
          ingeniería inversa respecto del Sitio Web, la Plataforma y/o
          cualquiera de sus contenidos.
        </p>

        <h5>11.- RESPONSABILIDAD</h5>
        <p>
          Los Usuarios mantendrán indemne a ONECLICK en todo momento frente a
          cualquier reclamación, queja, sanción, multa o cualquier otra acción
          judicial y/o administrativa, pública y/o privada, que pueda suponer o
          haya supuesto un daño patrimonial o moral a ONECLICK.
        </p>
        <p>
          En relación con lo anterior, los Usuarios responderán frente a
          ONECLICK por cualquier incumplimiento o actuación realizada por los
          mismos contraviniendo los presentes Términos y Condiciones, la
          legislación aplicable en cualquier materia y cualesquiera condiciones
          particulares de cada uno de los cursos.
        </p>

        <h5>12.- MISCELÁNEA</h5>
        <p>
          En el caso de que cualquiera de las previsiones de estos Términos y
          Condiciones fuera considerada en todo o en parte contraria a la Ley o
          inejecutable, quedará eliminada de los mismos y, si ello fuera
          posible, se sustituirá por otra estipulación con similar efecto a la
          anulada, continuando en todo caso la validez del contrato en sus
          estipulaciones restantes, que no se verán afectadas por la nulidad
          parcial.
        </p>
        <p>
          Los presentes Términos y Condiciones están redactados en español, sin
          perjuicio de que se puedan traducir bajo determinadas condiciones a
          otros idiomas, como el inglés. Sin perjuicio de lo anterior, en caso
          de duda o conflicto entre la redacción de los Términos y Condiciones
          en la versión española y cualquier otro idioma, prevalecerá siempre la
          versión española.
        </p>
        <p>
          ONECLICK se reserva el derecho a modificar la presentación,
          configuración y contenido de los presentes Términos y Condiciones, así
          como de la Plataforma, y/o las condiciones requeridas para su acceso
          y/o utilización, previa notificación por escrito a los Usuarios. El
          acceso y utilización de los contenidos y servicios tras la entrada en
          vigor de sus modificaciones o los cambios en las condiciones suponen
          la aceptación de las mismas.
        </p>
        <p>
          Los presentes Términos y Condiciones se regirán por la legislación
          española. En caso de litigio sobre la interpretación, ejecución o
          validez de estos Términos y Condiciones, serán competentes los
          Juzgados y Tribunales del territorio de residencia del Alumno.
        </p>
        <p>
          En virtud de lo dispuesto en el Reglamento (UE) nº 524/2013 del
          Parlamento Europeo y del Consejo, de 21 de mayo de 2013, relativo a la
          resolución de litigios en línea en materia de consumo, ONECLICK
          también le informa que, en caso de controversia, los Usuarios
          residente de la Unión Europea podrán acudir a la "Plataforma Online de
          Resolución de Conflictos" que ha desarrollado la Comisión Europea, con
          el fin de intentar resolver extrajudicialmente cualquier controversia
          que se derive de la prestación de servicios por parte de ONECLICK.
        </p>
        <p>
          Para a acceder a la "Plataforma Online de Resolución de Conflictos"
          puede hacerlo a través del siguiente enlace:
          <a href="http://ec.europa.eu/consumers/odr/">
            http://ec.europa.eu/consumers/odr/
          </a>
        </p>
      </>
    )
  }
}

const getHostIdentifier = () => {
  const currentHostname = window.location.hostname

  switch (currentHostname) {
    case 'blueberry.tangerine-qa1.oneclickaws.tk': // TODO quitar despues de probar en QA
    case 'blueberry.tangerine-qa1.oneclicklabs.es': // TODO quitar despues de probar en QA
    case 'family.blueberrymath.ai':
      return 'mathai'

    default:
      return 'global'
  }
}

const TermsText = ({ lang }) => {
  let host = getHostIdentifier()
  let language = lang

  if (!TEXTS[host][language]) language = FALLBACK_LANGUAGE
  if (!TEXTS[host][language]) host = FALLBACK_HOST

  return TEXTS[host][language]
}

export default TermsText
