import React from 'react'
import PracticeButton from '../../atoms/PracticeButton/PracticeButton'
import { APP_ANALYSIS } from '../../../services/analysisServiceReactNative'
import { store } from '../../../../../store/configureStore'
import { ReactComponent as SvgBatteryEmpty } from '../../../assets/batteries/battery-1-empty.svg'
import { ReactComponent as SvgBatteryLow } from '../../../assets/batteries/battery-2-low.svg'
import { ReactComponent as SvgBatteryMedium } from '../../../assets/batteries/battery-3-medium.svg'
import { ReactComponent as SvgBatteryFull } from '../../../assets/batteries/battery-5-full.svg'
import { ReactComponent as SvgBatteryBroken } from '../../../assets/batteries/battery-6-broken.svg'
import translate from '../../../../../i18n/translate'
import './practiceCompleteView.scss'
import InformationPopup from '../../../../../modules/atoms/InformationPopup/InformationPopup'

const PracticeCompleteView = ({
  practiceBaseData,
  setPracticeBaseData,
  practiceProgressData,
  setPracticeProgressData,
  setPracticeState,
  messageToReactNative,
  isIphone
}) => {
  let completeTitle = ''
  let batteryIcon = null
  let showContinueButton = true
  // let earnedPieces = 0

  // ------------------------------------------------------------------
  //      challenge   status
  // inicio            install / install
  // carga             charge  /  init
  // carga             charge  /  charging
  // todas las piezas  train   /  complete
  // rompe             train   /  broked_start
  // arregla           train   /  start
  const hasUserAllPieces = () => {
    return (
      practiceBaseData.lessonPiecesHave[0] >=
        practiceBaseData.lessonPiecesTotal[0] &&
      practiceBaseData.lessonPiecesHave[1] >=
        practiceBaseData.lessonPiecesTotal[1] &&
      practiceBaseData.lessonPiecesHave[2] >=
        practiceBaseData.lessonPiecesTotal[2]
    )
  }

  const earnedPiecesAux = practiceProgressData.earnedPieces.reduce(
    (total, current) => total + current,
    0
  )
  // if (earnedPiecesAux > 0) {
  //   earnedPieces = earnedPiecesAux
  // }

  const earnedCoinsAux = practiceProgressData.earnedCoins

  const earnedExperienceAux = practiceProgressData.earnedExperience
  const setMasteredLesson = () => {
    const wasBroken = practiceBaseData.lessonStatus === 'broked_start'
    completeTitle = translate(
      wasBroken
        ? 'app_practice_complete_title_repair'
        : 'app_practice_complete_title_done'
    )

    // Si ha llenado toda la batería y tiene las piezas necesarias para que la siguiente esté desbloqueada
    if (hasUserAllPieces()) {
      showContinueButton = false
    }
  }

  // No uso useEffect para asignar los SVGs directamente sino tarda en cargar el icono y queda mal
  switch (practiceBaseData.updatedLessonStatus) {
    case 'install':
    case 'empty':
      completeTitle = translate('app_practice_complete_title_start')
      batteryIcon = (
        <SvgBatteryEmpty className="practice-complete-view__battery-icon" />
      )
      break

    case 'init':
      completeTitle = translate('app_practice_complete_title_start')
      batteryIcon = (
        <SvgBatteryLow className="practice-complete-view__battery-icon" />
      )
      break

    case 'charging':
      completeTitle = translate('app_practice_complete_title_keep')
      batteryIcon = (
        <SvgBatteryMedium className="practice-complete-view__battery-icon" />
      )
      break

    case 'complete':
      setMasteredLesson()
      batteryIcon = (
        <SvgBatteryFull className="practice-complete-view__battery-icon" />
      )
      break

    case 'broked_start':
      completeTitle = translate('app_practice_complete_title_still_broken')
      batteryIcon = (
        <SvgBatteryBroken className="practice-complete-view__battery-icon" />
      )
      break

    case 'start':
      setMasteredLesson()
      batteryIcon = (
        <SvgBatteryFull className="practice-complete-view__battery-icon" />
      )
      break
  }

  // Si era una lección dominada, se modifican los mensajes para advertir de la reducción de puntos ganados
  if (
    practiceBaseData.lessonStatus === 'start' &&
    practiceBaseData.lessonChallenge === 'train'
  ) {
    completeTitle = translate('app_practice_complete_title_done')
    // masteredLessonMessage = translate('app_practice_complete_message_mastered')
  }

  // Para que al pedir el siguiente ejercicio sea del challenge
  const continuePracticing = () => {
    APP_ANALYSIS.sendAnalysisEventToReactNative(
      practiceBaseData.isFromApp,
      APP_ANALYSIS.APP_ANALYSIS_EVENT['Keep Practicing Button Clicked'],
      {
        practice_session_id: practiceProgressData?.sessionId,
        program_id: practiceBaseData.isFromApp
          ? 'from App'
          : store.getState()?.practice?.programId,

        unit_id: practiceBaseData?.unitGuid,
        lesson_id: practiceBaseData?.lessonGuid,
        lesson_status: practiceBaseData?.updatedLessonStatus
      }
    )

    // Reestablecer datos base de práctica
    setPracticeBaseData({
      ...practiceBaseData,
      lessonChallenge: practiceBaseData.updatedLessonChallenge,
      lessonChallengeExp: practiceBaseData.updatedLessonChallengeExp,
      lessonStatus: practiceBaseData.updatedLessonStatus,
      updatedLessonChallenge: undefined,
      updatedLessonChallengeExp: undefined,
      updatedLessonStatus: undefined
    })

    // Resetear datos de progreso de práctica
    setPracticeProgressData({
      sessionId: undefined,
      earnedExperience: 0,
      earnedExtraExperience: 0,
      earnedPieces: [0, 0, 0],
      earnedCoins: 0
    })

    // Volver a la práctica
    setPracticeState(1)
  }

  const exitPractice = () => {
    APP_ANALYSIS.sendAnalysisEventToReactNative(
      practiceBaseData.isFromApp,
      APP_ANALYSIS.APP_ANALYSIS_EVENT['Exit Practice Button Clicked'],
      {
        practice_session_id: practiceProgressData?.sessionId,
        program_id: 'from App',
        unit_id: practiceBaseData?.unitGuid,
        lesson_id: practiceBaseData?.lessonGuid,
        lesson_status: practiceBaseData?.updatedLessonStatus
      }
    )

    if (practiceBaseData.isFromApp) {
      messageToReactNative({ function: 'onPracticeCompleted' })
    } else {
      window.dispatchEvent(new CustomEvent('exit-activities'))
    }
  }

  return (
    <div
      className={
        'practice-complete-view' +
        (!practiceBaseData.isFromApp ? ' web-version' : '') +
        (isIphone ? ' practice-complete-view_iPhone' : '')
      }
    >
      <div className="practice-complete__principal-container">
        <InformationPopup
          practiceProgressData={practiceProgressData}
          setPracticeBaseData={setPracticeBaseData}
          earnedPiecesAux={earnedPiecesAux}
          earnedCoinsAux={earnedCoinsAux}
          earnedExperienceAux={earnedExperienceAux}
          isChallengeEnd={true}
          batteryIcon={batteryIcon}
          completeTitle={completeTitle}
        />
        <div className="practice-complete-view__action-buttons">
          <PracticeButton
            text={translate('app_practice_complete_exit_button')}
            action={exitPractice}
            type={
              practiceBaseData.isFromApp
                ? showContinueButton
                  ? 4
                  : 2
                : showContinueButton
                ? 2
                : 5
            }
          />
          {showContinueButton && (
            <PracticeButton
              text={translate('app_practice_complete_continue_button')}
              action={continuePracticing}
              type={practiceBaseData.isFromApp ? 2 : 1}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PracticeCompleteView
